

const Index = (props) => {
  return (
    <svg {...props} viewBox="0 0 18 107" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 8C1 3.58172 4.58172 2.14577e-06 9 2.14577e-06C13.4183 2.14577e-06 17 3.58172 17 8C17 12.4183 13.4183 16 9 16C4.58172 16 1 12.4183 1 8ZM10.5 92L17.6603 107H0.339746L7.5 92H10.5ZM7.5 99.575V94.625H10.5V99.575H7.5ZM7.5 89.675V84.725H10.5V89.675H7.5ZM7.5 79.775V74.825H10.5V79.775H7.5ZM7.5 69.875V64.925H10.5V69.875H7.5ZM7.5 59.975V55.025H10.5V59.975H7.5ZM7.5 50.075V45.125H10.5V50.075H7.5ZM7.5 40.175V35.225H10.5V40.175H7.5ZM7.5 30.275V25.325H10.5V30.275H7.5ZM7.5 20.375V15.425H10.5V20.375H7.5ZM7.5 10.475V8H10.5V10.475H7.5Z" fill="#163C3C" />
    </svg>

  )
}

export default Index