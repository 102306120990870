import styled from "styled-components";
import { TaskInfoTag, TaskInfoTagIcon } from '../reading_path'
import AddIcon from '../../components/icons/add'
import TickIcon from '../../components/icons/tick'
import Button from '../../components/ButtonV2'
import { useEffect, useImperativeHandle, useRef, useState, useContext } from 'react'
import lottie from 'lottie-web'
import { Context as RequestContext } from '../request'
import Steps from '../../components/StepProgress'

const Index = (props) => {
  const { onAddToPath = () => { } } = props
  const [show, setShow] = useState(false)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)
  const [current, setCurrent] = useState(-1)
  const [lexile, setLexile] = useState()
  const [steps, setSteps] = useState([])
  const [stepIndex, setStepIndex] = useState(0)
  const [stepProgress, setStepProgress] = useState(0)
  const [libraryShow, setLibraryShow] = useState(false)
  const [library, setLibrary] = useState([])
  const [currentFun, setCurrentFun] = useState(-1)
  const requestCtx = useContext(RequestContext)
  const request = requestCtx.useRequest()
  const animateDom = useRef()
  const animate = useRef()

  const getTopicFun = async () => {
    try {
      return await request.get("/client/v2/api/topic/fun")
    } catch (error) {

    }
  }

  const getFlowSteps = async () => {
    try {
      const steps = await request.get("/client/v2/api/llmflow/steps?id=4")
      if (steps) {
        steps.steps = steps.steps || []
        if (steps.steps.length === 1) {
          steps.steps.push({ title: "Done" })
        }
        setSteps(steps.steps || [])
      }
      return steps
    } catch (error) {

    }
  }

  useEffect(() => {
    getTopicFun().then(funs => {
      setLibrary(funs || [])
    }).catch(() => { })


  }, [])

  useImperativeHandle(props.action, () => ({
    open: async (open) => {
      if (open) {
        await getFlowSteps()
        // playAnimate()
      } else {
        setCurrent(-1)
        setLoading(false)
        setCurrent(-1)
        setCurrentFun(-1)
        setLibraryShow(false)
      }
      setShow(open)
    },
    setData,
    setLoading,
    setLexile,
    setSteps,
    setStepIndex,
    setStepProgress
  }));

  const close = () => {
    if (window.confirm("确定关闭吗?")) {
      setShow(false)
      setLoading(false)
      setCurrent(-1)
      setCurrentFun(-1)
      setLibraryShow(false)
    }
  }

  const playAnimate = () => {
    console.log(animateDom.current)
    animateDom.current.style.display = "block"
    animate.current = lottie.loadAnimation({
      container: animateDom.current, // the dom element that will contain the animation
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: `https://www.bigread.ai/lottie/ProgressBar.json` // the path to the animation json
    })
    animate.current.play()
    animate.current.setSpeed(0.1)
    // 监听动画播放完毕
    animate.current.addEventListener('complete', () => {
      // 在这里执行你想要的操作
      // animate.current.destroy()
      // animateDom.current.style.display = "none"
    }, {
      once: true
    });
  }

  const openLibrary = async () => {
    if (libraryShow) {
      setCurrentFun(-1)
      setLibraryShow(false)
      return
    }
    setCurrent(-1)
    setLibraryShow(true)
  }

  return (
    <Wrapper $show={show}>
      <Content>
        <img onClick={close} style={{
          width: "calc(84px * var(--ratio))",
          height: "calc(84px * var(--ratio))",
          position: "absolute",
          top: "calc(20px * var(--ratio))",
          right: "calc(16px * var(--ratio))"
        }} src="https://www.bigread.ai/icon/20240530-153800.png" alt="" />
        <div style={{ height: "calc(60px * var(--ratio))" }}></div>
        {
          loading ? <>
            <Title>Continue to challenge</Title>
            <SubTitle>We‘ll provide you more contents that are not too challenging and not too easy--depending upon your reading level.</SubTitle>
          </> : <>
            <Title style={{ fontSize: "calc(32px * var(--ratio))", color: "#6E6566" }}>Which one would you like to read <span style={{ color: "#161A19" }}>next?</span></Title>
            <SubTitle>These contents are not too challenging and not too easy--depending upon your reading level.</SubTitle>
          </>
        }
        <AnimateWrapper $show={loading} ref={animateDom}>
          <Steps
            current={stepIndex}
            steps={steps}
          />
        </AnimateWrapper>
        <div style={{ display: loading ? "none" : "flex", width: "calc(912px * var(--ratio))", justifyContent: "space-between", margin: "0 auto" }}>
          {
            libraryShow ? <div>
              <div style={{ height: "calc(24px * var(--ratio))" }}></div>
              <div style={{ display: "flex" }}>
                <div style={{ display: "flex" }}>
                  {
                    library.map((v, k) => (
                      <ArticleCard key={k} style={{
                        boxShadow: currentFun === k && "none",
                        border: currentFun === k && "2px solid #6A9B9A"
                      }}>
                        <div style={{ padding: "calc(16px * var(--ratio))" }} onClick={() => {
                          setCurrentFun(k)
                        }}>
                          <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <TaskInfoTag $bgColor="linear-gradient(90deg, rgba(153, 246, 243, 0.60) 0%, rgba(192, 194, 255, 0.60) 100%)">Lexile: {v.lexile}</TaskInfoTag>
                            {
                              v.status === 3 && <TaskInfoTag $bgColor="rgba(0,0,0,0)" style={{
                                color: "#161A19",
                                fontWeight: 400
                              }}>{v.correct_star * 2} points</TaskInfoTag>
                            }
                          </div>
                          <div style={{ height: "calc(11px * var(--ratio))" }}></div>
                          <ArticleCover $src={process.env.REACT_APP_OSS_HOST + v.img + "?x-oss-process=image/resize,w_600"}></ArticleCover>
                          <div style={{ height: "calc(16px * var(--ratio))" }}></div>
                          <ArticleTitle>{v.title}</ArticleTitle>
                          <ArticleInfo>
                            <div>
                              {
                                v.wordcount !== 0 && <TaskInfoTag $bgColor="#F5F0F0">
                                  <TaskInfoTagIcon src="https://www.bigread.ai/icon/20240521-162328.png"></TaskInfoTagIcon>
                                  Words: {v.wordcount}
                                </TaskInfoTag>
                              }
                              <div style={{ height: "calc(4px * var(--ratio))" }}></div>
                              {
                                v.subject !== "" && <TaskInfoTag $bgColor="#F5F0F0" $icon="https://www.bigread.ai/icon/20240521-162328.png">
                                  <TaskInfoTagIcon src="https://www.bigread.ai/icon/20240521-162337.png"></TaskInfoTagIcon>
                                  {v.subject}
                                </TaskInfoTag>
                              }
                            </div>
                            <div style={{
                              display: "flex",
                              justifyContent: "center",
                              position: "absolute",
                              right: "calc(16px * var(--ratio))",
                              bottom: "calc(13px * var(--ratio))"
                            }}>
                              {
                                currentFun === k ? <TickIcon style={{ width: "calc(18px * var(--ratio))", height: "calc(14px * var(--ratio))" }} /> :
                                  <AddIcon style={{ width: "calc(18px * var(--ratio))", height: "calc(18px * var(--ratio))" }} />
                              }
                            </div>
                          </ArticleInfo>
                        </div>
                      </ArticleCard>
                    ))
                  }
                </div>
                <div style={{
                  display: "flex",
                  alignItems: "center"
                }}>
                  <img onClick={() => {
                    getTopicFun().then(funs => {
                      setLibrary(funs || [])
                    })
                  }} style={{ width: "calc(60px * var(--ratio))", height: "calc(60px * var(--ratio))" }} src="https://www.bigread.ai/icon/20240606-113338.png" alt="" />
                </div>
              </div>
              <div style={{ height: "calc(32px * var(--ratio))" }}></div>
            </div> :
              <div>
                <div style={{ height: "calc(102px * var(--ratio))" }}></div>
                <div style={{ display: "flex", width: "calc(912px * var(--ratio))", justifyContent: "space-between", margin: "0 auto" }}>
                  {
                    data.map((v, k) => (
                      <TopicCard style={{
                        boxShadow: current === k && "none",
                        border: current === k && "2px solid var(--Secondary-700, #6A9B9A)",
                        background: current === k && "linear-gradient(180deg, #E1F7F6 0%, #FFF 100%)"
                      }} onClick={() => {
                        setCurrent(k)
                      }} key={k}>
                        <img style={{
                          width: "calc(32px * var(--ratio))",
                          height: "calc(32px * var(--ratio))",
                          position: "absolute",
                          top: "calc(20px * var(--ratio))",
                          right: "calc(24px * var(--ratio))"
                        }} src="https://www.bigread.ai/icon/20240530-160408.png" alt="" />
                        <div style={{ padding: "calc(20px * var(--ratio)) calc(24px * var(--ratio))" }}>
                          <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <TaskInfoTag $bgColor="linear-gradient(90deg, rgba(246, 195, 213, 0.60) 0%, rgba(255, 116, 113, 0.60) 100%)">Lexile: {lexile}</TaskInfoTag>
                          </div>
                          <div style={{ height: "calc(30px * var(--ratio))" }}></div>
                          <div style={{ color: "#898081", fontSize: "calc(12px * var(--ratio))", fontWeight: 400 }}>Topic:</div>
                          <div style={{ height: 5 }}></div>
                          <TopicTitle>{v}</TopicTitle>
                          <div style={{ height: 1, background: "#D3CCCD", width: 'calc(100% - calc(48px * var(--ratio)))', position: "absolute", left: "calc(24px * var(--ratio))", bottom: "calc(68px * var(--ratio))" }}></div>
                          <div style={{
                            display: "flex",
                            justifyContent: "center",
                            position: "absolute",
                            left: 0,
                            right: 0,
                            margin: "auto",
                            bottom: "calc(27px * var(--ratio))"
                          }}>
                            {
                              current === k ? <TickIcon style={{ width: "calc(18px * var(--ratio))", height: "calc(14px * var(--ratio))" }} /> :
                                <AddIcon style={{ width: "calc(18px * var(--ratio))", height: "calc(18px * var(--ratio))" }} />
                            }
                          </div>
                        </div>
                      </TopicCard>
                    ))
                  }
                </div>
                <div style={{ height: "calc(92px * var(--ratio))" }}></div>
              </div>
          }
        </div>
        {
          !loading && <>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              {
                library.length !== 0 && <div onClick={openLibrary} style={{
                  color: "#467776",
                  fontSize: "calc(15px * var(--ratio))",
                  fontWeight: 500,
                  textDecorationLine: "underline",
                  marginRight: "calc(32px * var(--ratio))",
                  cursor: "pointer",
                  userSelect: "none"
                }}>{libraryShow ? 'Topics recommended by AI' : 'All article'}</div>
              }
              <Button onClick={async () => {
                setBtnLoading(true)
                await onAddToPath({
                  "topic": current !== -1 && data[current],
                  "hash_id": currentFun !== -1 && library[currentFun].hash_id
                })
                setBtnLoading(false)
              }} disabled={btnLoading || (current === -1 && currentFun === -1)}>{btnLoading ? 'loading...' : 'Add to Path'}</Button>
            </div>
          </>
        }
      </Content>
    </Wrapper>
  )
}

const ArticleCard = styled.div`
  border-radius: calc(24px * var(--ratio));
  border: 2px solid #DBD4D4;
  background: #FFF;
  box-shadow: calc(-4px * var(--ratio)) calc(8px * var(--ratio)) calc(12px * var(--ratio)) 0px rgba(18, 18, 18, 0.08);
  width: calc(252px * var(--ratio));
  height: calc(358px * var(--ratio));
  margin-right: calc(40px * var(--ratio));
  position: relative;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  cursor: pointer;
  &:hover{
    box-shadow: calc(-4px * var(--ratio))  calc(16px * var(--ratio)) calc(12px * var(--ratio)) 0px rgba(18, 18, 18, 0.12);
    border: 2px solid #6A9B9A;
  }
`

const ArticleTitle = styled.div`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: #161A19;
  font-size: calc(16px * var(--ratio));
  font-weight: 500;
  max-height: calc(60px * var(--ratio));
  line-height: 120%;
`

const ArticleInfo = styled.div`
  display: flex;
  justify-content: space-between;
  height: calc(48px * var(--ratio));
  position: absolute;
  bottom: calc(15px * var(--ratio));
  width: calc(100% - calc(32px * var(--ratio)));
`

const ArticleCover = styled.div`
  width: 100%;
  height: calc(165px * var(--ratio));
  border-radius: calc(6.48px * var(--ratio));
  border: 1px solid #898081;
  position: relative;
  overflow: hidden;
  background: url(${props => props.$src}) lightgray 50% / cover no-repeat;
`

const TopicTitle = styled.div`
  color: #161A19;
  font-size: calc(15px * var(--ratio));
  font-weight: 500;
  line-height: 1.2;
  max-height: calc(36px * var(--ratio));
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 指定最多显示的行数 */
  -webkit-box-orient: vertical;
`

const AnimateWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(400px * var(--ratio));
  width: 80%;
  font-size: calc(32px * var(--ratio));
  ${props => {
    let sty = ""
    if (props.$show) {
      sty += "opacity: 1;"
      sty += "pointer-events: auto;"
      return sty
    }
    sty += "opacity: 0;"
    sty += "pointer-events: none;"
    return sty
  }}
  left: 0;
  right: 0;
  margin: auto;
`

const TopicCard = styled.div`
  border-radius: 24px;
  border: 2px solid #D3CCCD;
  background: #FFF;
  /* box-shadow: calc(-4px * var(--ratio)) calc(16px * var(--ratio)) calc(12px * var(--ratio)) 0px rgba(18, 18, 18, 0.12); */
  box-shadow: calc(-2px * var(--ratio)) calc(8px * var(--ratio)) calc(20px * var(--ratio)) calc(17px * var(--ratio)) rgba(18, 18, 18, 0.12);
  width: calc(288px * var(--ratio));
  height: calc(220px * var(--ratio));
  position: relative;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  cursor: pointer;
  &:hover{
    /* border: 2px solid #6A9B9A;
    background: linear-gradient(180deg, #E1F7F6 0%, #FFF 100%);
    box-shadow: none; */
    box-shadow: calc(-4px * var(--ratio)) calc(16px * var(--ratio)) calc(12px * var(--ratio)) 0px rgba(18, 18, 18, 0.12);
  }
`

const Title = styled.div`
  color: #6E6566;
  text-align: center;
  font-size: calc(32px * var(--ratio));
  font-weight: 400;
  text-align: center;
  margin-bottom: calc(8px * var(--ratio));
`

const SubTitle = styled.div`
  color: #6E6566;
  text-align: center;
  font-size: calc(14px * var(--ratio));
  font-weight: 400;
`

const Wrapper = styled.div`
  background: rgba(18, 18, 18, 0.30);
  backdrop-filter: blur(100px);
  width: var(--vw);
  height: var(--vh);
  position: fixed;
  z-index: 9;
  left: 0;
  top: calc(-1 * var(--head-height));
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  ${props => {
    let sty = ""
    if (props.$show) {
      sty += "opacity: 1;"
      sty += "pointer-events: auto;"
      return sty
    }
    sty += "opacity: 0;"
    sty += "pointer-events: none;"
    return sty
  }}
`

const Content = styled.div`
  width: calc(1128px * var(--ratio));
  height: calc(628px * var(--ratio));
  border-radius: calc(16px * var(--ratio)) calc(50px * var(--ratio)) calc(16px * var(--ratio)) calc(16px * var(--ratio));
  background: linear-gradient(180deg, #FFF 0%, #E1F7F6 100%);
  position: relative;
`

export default Index