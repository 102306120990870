import {
  Vector3
} from "babylonjs";

export const MAIN_CAMERA_NAME = "MainCamera";
export const DEFAULT_MODEL = "vroid_girl1";

const v3 = (x = 0, y = 0, z = 0) => {
  return new Vector3(x, y, z);
};

export const defaultCameraConfig = {
  alpha: Math.PI / 2,
  beta: Math.PI / 2.5,
  radius: 2.5,
  target: v3(-0.34, 0.7, 0),
};

const defaultConfig = {
  cameraConfig: defaultCameraConfig,
  voice: "en-US-Neural2-H",
  initialAnimation: "idle3_hand_hips",
  faceMeshName: "Face",
  morphTargets: {
    mouthMovement: "Face.M_F00_000_00_Fcl_MTH_A",
    leftEyeClose: "Face.M_F00_000_00_Fcl_EYE_Close_L",
    rightEyeClose: "Face.M_F00_000_00_Fcl_EYE_Close_R",
  },
  idleAnimations: ["idle1", "idle2", "idle3_hand_hips"],
  talkingBodyAnimations: ["talking1", "talking2_head_shake", "talking3"],
  positionOffset: v3(0, 0.085, 0.2),
};

export const models = {
  vroid_girl1: defaultConfig,
  vest_dude: {
    ...defaultConfig,
    morphTargets: {
      mouthMovement: "mouth_open",
    },
    faceMeshName: "rp_eric_rigged_001_geo",
    cameraConfig: {
      alpha: Math.PI / 2,
      beta: Math.PI / 2.5,
      radius: 3,
      target: v3(0, 0.9, 0),
    },
    positionOffset: v3(0, 0.03, 0),
  },
};
