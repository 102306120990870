import styled from "styled-components";

const Index = (props) => {
  const { children, value = 0, width = "100%", height = "calc(6px * var(--ratio))" } = props
  return (
    <div>
      {children}
      <ProgressWrapper $width={width} $height={height}>
        <Progress $value={value} $width={width} $height={height}></Progress>
      </ProgressWrapper>
    </div>
  )
}

const ProgressWrapper = styled.div`
    width: ${props => props.$width};
    height: ${props => props.$height};
    border-radius: ${props => props.$height};
    overflow: hidden;
    position: relative;
    background: #EFE9E9;
`

const Progress = styled.div`
    width: ${props => props.$width};
    height: ${props => props.$height};
    position: absolute;
    background: ${props => props.$value <= 50 ? '#E46962' : '#55D252'};
    width: ${props => props.$value}%;
`

export default Index