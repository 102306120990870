export const UUID = `/uuid`

export const EMAIL_CODE_SEND = `/client/open/code/send`
export const EMAIL_RESET_SEND = `/client/open/reset/send`
export const PASSWORD_RESET = `/client/open/reset/do`

export const WEEK_OPTION = `/client/api/week/option`
export const WEEK_LEARN = `/client/api/week/learn`
export const WEEK_LEARN_GENERATE = `/client/api/week/learn/generate`
export const WEEK_LEARN_DETAIL = `/client/api/week/learn/detail`


export const TOPIC_RAND = `/client/api/topic/rand`
export const TOPIC_FUN = `/client/api/topic/fun`


export const REGISTER = `/client/open/user/register`
export const PRE_REGISTER = `/client/open/user/register/pre`
export const LOGIN = `/client/open/user/login`
export const LOGIN_GOOGLE = `/client/open/user/login/google`

export const ARTICLE_DETAIL = `/client/api/article/detail`
export const LEARN_PLAN_JOIN = `/client/api/learn/plan/join`
export const MESSAGE_SEND = `/client/api/message/send`
export const MESSAGE_SEND_ABORT = `/client/api/message/send/abort`

export const MESSAGE_SUB = `/client/api/message/sub`
export const TOPIC_MAKE = `/client/api/article/topic/make`
export const VIDEO_MAKE = `/client/api/article/video/make`

export const WORD_CARD_MARKED = `/client/api/article/word/marked`
export const WORD_CARD_CANCEL = `/client/api/article/word/marked/cancel`
export const WORD_CARD_GET = `/client/api/article/word/marked/get`

export const TOPICS = `/client/api/article/topics`
export const CHAT_RECORD_LIST = `/client/api/chat/record/list`
export const SUB_PLAN_LIST = `/client/api/sub_plan/list`
export const SUB_ORDER_CREATE = `/client/api/sub_order/create`
export const SUB_ORDER_CANCEL = `/client/api/sub_order/cancel`
export const SUB_ORDER_ACTIVE = `/client/api/sub_order/active`

export const SUB_ORDER_WX_SCAN = `/client/api/sub_order/wx/scan`
export const SUB_ORDER_WX_SCAN_RESULT = `/client/api/sub_order/wx/scan/result`




export const CHAT_QUIZ_CRERATE = `/client/api/chat/quiz/create`
export const CHAT_RECORD_CREATE = `/client/api/chat/record/create`
export const CHAT_RECORD_UPDATE = `/client/api/chat/record/update`
export const CHAT_QUIZ_ANSWER = `/client/api/chat/quiz/answer`
export const CHAT_QUIZ_ANSWER_V2 = `/client/api/chat/quiz/answer/v2`

export const CHAT_QUIZ_SCORE_INFO = `/client/api/quiz/score`


export const ARTICLE_QUIZ = `/client/api/article/quiz`
export const MODULE_LIST = `/client/api/module/list`
export const QUIZ_SCORE_UPDATE = `/client/api/article/quiz/score`
export const TTS_PARSE = `/client/open/tts/parse`
export const TTS_MAKE = `/client/api/tts/make`
export const PDF_MAKE = `/client/api/article/pdf/make`


export const TTS_VOICE = `/client/api/tts/voice`



export const PROFILE_LIST = `/client/api/profile/list`
export const PROFILE_CREATE = `/client/api/profile/create`
export const PROFILE_UPDATE = `/client/api/profile/update`
export const PROFILE_DELETE = `/client/api/profile/delete`
export const PROFILE_SLIDE = `/client/api/profile/slide`
export const PROFILE_PROGRESS = `/client/api/profile/progress`

export const USER_INFO = `/client/api/user/info`

export const READ_PLAN = `/client/api/read/plan`
export const READ_PLAN_GENERATE = `/client/api/readplan/generate`
export const READ_PLAN_JOIN = `/client/api/read/plan/join`

export const FREE_LIST = `/client/api/free/list`
export const FREE_CREATE = `/client/api/free/create`
export const FREE_UPDATE = `/client/api/free/update`
export const FREE_DETAIL = `/client/api/free/detail`
export const FREE_DELETE = `/client/api/free/delete`




export const READ_WEEK = `/client/v2/api/user/read/week`
export const USER_STAR_COUNT = `/client/v2/api/user/star/count`

export const LEARN_ARLEVEL_PROGRESS = `/client/v2/api/learn/arlevel/progress`
export const LEARN_ARLEVEL_PROGRESS_ADD = `/client/v2/api/learn/arlevel/progress/add`

export const USER_INFO_V2 = `/client/v2/api/user/info`

export const TOPIC_FUN_V2 = `/client/v2/api/topic/fun`













