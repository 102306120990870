import { models } from "../constants";
import { Humanoid } from "./Humanoid";

export const setHumanoid = (modelName, scene) => {
  const modelConfig = models[modelName];

  const humanoidRef = new Humanoid(modelName, scene, modelConfig, () => {
    // console.log("After import callback called!");
  });

  // XXX: For debugging purposes:
  // @ts-ignore
  window.humanoid = humanoidRef;
};
