import styled from "styled-components";
import LevelItemProgress from '../../components/RoundProgress'
import { useNavigate } from "react-router-dom";

const Index = () => {
  const n = useNavigate()
  return (
    <Wrapper>
      <div style={{ height: "calc(34px * var(--ratio))" }}></div>
      <TopContent>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div>
            <HelloTxt>Good Job, Joe! </HelloTxt>
            <HelloTxtDesc>You have been studying for 3 days this week.</HelloTxtDesc>
          </div>
          <WeekWrapper>
            <WeekItem $status={1}>Mon</WeekItem>
            <WeekItem $status={2}>Mon</WeekItem>
            <WeekItem $status={3}>Mon</WeekItem>
            <WeekItem $status={1}>Mon</WeekItem>
            <WeekItem $status={2}>Mon</WeekItem>
            <WeekItem $status={3}>Mon</WeekItem>
            <WeekItem $status={3}>Mon</WeekItem>
          </WeekWrapper>
        </div>
        <div style={{ height: "calc(87.81px * var(--ratio))" }}></div>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <LevelWrapper>
            <div style={{ display: "flex", alignItems: "center" }}>
              <LevelItemTitle>AR Level:<br />(Grade)</LevelItemTitle>
            </div>
            <LexileLevel>Lexile <br /> Level:</LexileLevel>
            <LevelItem $hiddenBefor={true} $status={0}>
              <LevelItemStatus>Achieved</LevelItemStatus>
              2.0
              <LevelItemLansi>350L</LevelItemLansi>
            </LevelItem>
            <LevelItem $status={1}>
              <LevelItemStatus style={{
                top: "calc(-29px * var(--ratio))",
                color: "#6A0828"
              }}>In progress</LevelItemStatus>
              <LevelItemProgress
                size={106}
                progress={70}
              >
                <div style={{
                  width: "100%",
                  height: "100%",
                  background: "#ED8395",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}>
                  2.1
                </div>
              </LevelItemProgress>
              <LevelItemProgressTxt>
                <span>25</span>
                <span>/100Points</span>
              </LevelItemProgressTxt>
              <LevelItemLansi style={{
                bottom: "calc(-44px * var(--ratio))"
              }}>350L</LevelItemLansi>
            </LevelItem>
            <LevelItem $status={2}><LevelItemBefor></LevelItemBefor><LevelItemStatus>Achieved</LevelItemStatus>2.2<LevelItemLansi>350L</LevelItemLansi></LevelItem>
            <LevelItem $status={2}><LevelItemBefor></LevelItemBefor><LevelItemStatus>Achieved</LevelItemStatus>2.3<LevelItemLansi>350L</LevelItemLansi></LevelItem>
            <LevelItem $status={2}><LevelItemBefor></LevelItemBefor>2.4<LevelItemLansi>350L</LevelItemLansi></LevelItem>
          </LevelWrapper>
        </div>
      </TopContent>
      <div style={{ height: "calc((71.39px + 13px + 41.17px) * var(--ratio))" }}></div>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <BottomWrapper>
          <TaskWrapper>
            <TaskRadio>Right For You</TaskRadio>
            <div style={{ height: "calc(48px * var(--ratio))" }}></div>
            <TaskInfo>
              <div style={{ display: "flex", alignItems: "center" }}>
                <TaskInfoTag $bgColor="linear-gradient(90deg, rgba(246, 195, 213, 0.60) 0%, rgba(255, 116, 113, 0.60) 100%)">Lexile: 200L</TaskInfoTag>
                <TaskInfoTag $bgColor="#F5F0F0">
                  <TaskInfoTagIcon src="https://www.bigread.ai/icon/20240521-162328.png"></TaskInfoTagIcon>
                  Words: 600
                </TaskInfoTag>
                <TaskInfoTag $bgColor="#F5F0F0" $icon="https://www.bigread.ai/icon/20240521-162328.png">
                  <TaskInfoTagIcon src="https://www.bigread.ai/icon/20240521-162337.png"></TaskInfoTagIcon>
                  Science
                </TaskInfoTag>
              </div>
              <TaskInfoTag $bgColor="rgba(0,0,0,0)" style={{
                color: "#251F1F",
                fontWeight: 600
              }}>20points</TaskInfoTag>
            </TaskInfo>
            <div style={{ height: "calc(16px * var(--ratio))" }}></div>
            <div style={{ display: "flex", margin: "0 calc(80px * var(--ratio))" }}>
              <ArtcleCover $src="https://aigc-file-hk.oss-cn-hongkong.aliyuncs.com/files/png/7178648301343309824.png" />
              <div>
                <ArticleTitle>Birds & the Japanese Bullet Train</ArticleTitle>
                <div style={{
                  marginTop: "calc(14px * var(--ratio))",
                  display: "flex",
                  alignItems: "center"
                }}>
                  <ArticleSubTitleIcon src="https://www.bigread.ai/icon/20240521-165428.png" />
                  <ArticleSubTitle>This article is most suitable for your level.</ArticleSubTitle>
                </div>
                <div style={{ height: "calc(53px * var(--ratio))" }}></div>
                <img style={{
                  width: "calc(32px * var(--ratio))",
                  height: "calc(32px * var(--ratio))",
                  borderRadius: "50%"
                }} src="https://www.bigread.ai/icon/20240523-162207.png" alt="" />
              </div>
            </div>
            <div style={{ height: "calc(16px * var(--ratio))" }}></div>
            <ReadButton>
              Read
              <img style={{
                width: "calc(26px * var(--ratio))",
                height: "calc(26px * var(--ratio))",
                marginLeft: "calc(16px * var(--ratio))"
              }} src="https://www.bigread.ai/icon/20240521-165529.png" alt="" />
            </ReadButton>
            <div style={{ height: "calc(16px * var(--ratio))" }}></div>
            <div style={{
              display: "flex",
              justifyContent: "center"
            }}>
              <RefreshWrapper>
                <img style={{
                  width: "calc(17.459px * var(--ratio))",
                  height: "calc(17.459px * var(--ratio))",
                  marginRight: "calc(10.56px * var(--ratio))"
                }} src="https://www.bigread.ai/icon/20240521-165511.png" alt="" />
                Find a new Article
              </RefreshWrapper>
            </div>
          </TaskWrapper>
          <MainWrapper>
            <div style={{ display: "flex" }}>
              <HistoryPath onClick={() => n("/history")} src="https://www.bigread.ai/icon/20240521-154018.png" style={{
                marginRight: "calc(16px * var(--ratio))"
              }} alt="" />
              <HistoryPath src="https://www.bigread.ai/icon/20240521-154010.png" alt="" />
            </div>
            <div style={{ height: "calc(16px * var(--ratio))" }}></div>
            <div>
              <img style={{
                width: "calc(452px * var(--ratio))",
                height: "calc(250px * var(--ratio))"
              }} src="https://www.bigread.ai/icon/20240521-153956.png" alt="" />
            </div>
          </MainWrapper>
        </BottomWrapper>
      </div>
    </Wrapper>
  )
}

const LevelItemTitle = styled.div`
  color: #6E6566;
  font-size: calc(11.544px * var(--ratio));
  font-weight: 500;
  margin-right: calc(24.92px * var(--ratio));
  width: calc(62px * var(--ratio));
`

const LexileLevel = styled.div`
  color: #6E6566;
  font-size: calc(11.544px * var(--ratio));
  font-weight: 500;
  margin-right: calc(24.92px * var(--ratio));
  width: calc(62px * var(--ratio));
  position: absolute;
  bottom: calc(-43px * var(--ratio));
`

const RefreshWrapper = styled.div`
  color: #898081;
  font-size: calc(14px * var(--ratio));
  font-weight: 400;
  display: flex;
  align-items: center;
`

const ReadButton = styled.div`
  width: calc(674px * var(--ratio));
  height: calc(58px * var(--ratio));
  margin-left: calc(80px * var(--ratio));
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: calc(30px * var(--ratio));
  border: 1px solid #B14E62;
  background: #FFFCFC;
  box-shadow: 0px calc(6px * var(--ratio)) calc(30px * var(--ratio)) calc(-4px * var(--ratio)) rgba(104, 100, 145, 0.14);
  color: #6A0828;
  font-family: Roboto;
  font-size: calc(22px * var(--ratio));
  font-weight: 600;
`

const ArticleSubTitleIcon = styled.img`
  width: calc(20px * var(--ratio));
  height: calc(13.22px * var(--ratio));
  margin-right: calc(4px * var(--ratio));;
`

const ArticleSubTitle = styled.div`
  color: #898081;
  font-family: Roboto;
  font-size: calc(15px * var(--ratio));
  font-weight: 400;
`

const ArtcleCover = styled.div`
  width: calc(260px * var(--ratio));
  height: calc(192.5px * var(--ratio));
  border-radius: calc(14px * var(--ratio));
  border: 1px solid #141111;
  background: url(${props => props.$src}) lightgray 50% / cover no-repeat;
  flex-shrink: 0;
  margin-right: calc(16px * var(--ratio));
`

const ArticleTitle = styled.div`
  color: #141111;
  font-family: Roboto;
  font-size: calc(35px * var(--ratio));
  font-weight: 900;
  margin-top: calc(8px * var(--ratio));
`

const BottomWrapper = styled.div`
  display: flex;
`

const MainWrapper = styled.div`
  
`

const HistoryPath = styled.img`
  width: calc(218px * var(--ratio));
  height: calc(174px * var(--ratio));
`

const TaskInfo = styled.div`
  margin: 0 calc(80px * var(--ratio));
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const TaskInfoTag = styled.span`
  border-radius: calc(131.982px * var(--ratio));
  background: ${props => props.$bgColor};
  padding: 0 calc(8px * var(--ratio));
  height: calc(20px * var(--ratio));
  line-height: calc(20px * var(--ratio));
  display: flex;
  color: #141111;
  font-family: Roboto;
  font-size: calc(12px * var(--ratio));
  font-weight: 400;
  margin-right: calc(8px * var(--ratio));
  align-items: center;
  width: fit-content;
`

export const TaskInfoTagIcon = styled.img`
  width: calc(11px * var(--ratio));
  height: calc(11px * var(--ratio));
  margin-right: calc(3px * var(--ratio));
`

const TaskRadio = styled.div`
  width: calc(136px * var(--ratio));
  height: calc(36px * var(--ratio));
  line-height: calc(36px * var(--ratio));
  text-align: center;
  color: #FFF;
  font-family: Roboto;
  font-size: calc(12px * var(--ratio));
  font-style: normal;
  font-weight: 600;
  background: #ED8395;
  border-radius: 0px 0px calc(28px * var(--ratio)) 0px;
  position: absolute;
  left: 0;
  top: 0;
`

const TaskWrapper = styled.div`
  width: calc(834px * var(--ratio));
  height: calc(440px * var(--ratio));
  border-radius: calc(28px * var(--ratio));
  border: 1px solid #6E6566;
  background: #FFF;
  margin-right: calc(18px * var(--ratio));
  overflow: hidden;
  position: relative;
`

const LevelWrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`

const LevelItemStatus = styled.div`
  color: #467776;
  text-align: center;
  font-family: Roboto;
  font-size: calc(14px * var(--ratio));
  font-style: normal;
  font-weight: 500;
  height: calc(14px * var(--ratio));
  line-height: calc(14px * var(--ratio));
  position: absolute;
  top: calc((14px + 25.81px) * -1 * var(--ratio));
  left: 0;
  right: 0;
  margin: auto;
`

const LevelItemLansi = styled.div`
  color: #6E6566;
  text-align: center;
  font-family: Roboto;
  font-size: calc(13px * var(--ratio));
  font-style: normal;
  font-weight: 700;
  height: calc(13px * var(--ratio));
  line-height: calc(13px * var(--ratio));
  position: absolute;
  bottom: calc(-59px * var(--ratio));
  left: 0;
  right: 0;
  margin: auto;
`

const LevelItemBefor = styled.div`
    width: calc(56px * var(--ratio));
    height: calc(4.198px * var(--ratio));
    border-radius: calc(8.395px * var(--ratio));
    background: #C1B8B9;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: calc((78px + 97px) * var(--ratio));
`

export const LevelItemProgressTxt = styled.div`
  width: calc(88px * var(--ratio));
  height: calc(18px * var(--ratio));
  border-radius: calc(27.082px * var(--ratio));
  background: #D3CCCD;
  font-size: calc(12px * var(--ratio));
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: calc(-27px * var(--ratio));
  & span:nth-child(1){
    font-weight: 900;
    color: #ED8395;
  }
  & span:nth-child(2){
    font-weight: 400;
    color: #FFF;
  }
`

const LevelItem = styled.div`
  width: ${props => {
    if (props.$status === 1) {
      return "calc(106px * var(--ratio))"
    }
    return "calc(78px * var(--ratio))"
  }};
  height: ${props => {
    if (props.$status === 1) {
      return "calc(106px * var(--ratio))"
    }
    return "calc(78px * var(--ratio))"
  }};
  line-height: ${props => {
    if (props.$status === 1) {
      return "calc(106px * var(--ratio))"
    }
    return "calc(78px * var(--ratio))"
  }};
  text-align: center;
  background-color: ${props => {
    if (!props.$status || props.$status === 0) {
      return "#96C9C8"
    }
    if (props.$status === 1) {
      return "none"
    }
    if (props.$status === 2) {
      return "#FFF"
    }
  }};
  border-radius: 50%;
  color: ${props => {
    if (props.$status === 2) {
      return "#6E6566"
    }
    return "#FFF"
  }};
  font-family: Roboto;
  font-size: calc(24px * var(--ratio));
  font-weight: 700;
  position: relative;
  flex-shrink: 0;
  margin-top: ${props => {
    if (props.$status === 1) {
      return "calc(-12px * var(--ratio))"
    }
    return 0
  }};
  margin-right: calc(173.71px * var(--ratio));
  &::before{
    content: '';
    width: ${props => {
    if (props.$status === 2) {
      return "calc(56px * var(--ratio))"
    }
    return "calc(125.932px * var(--ratio))"
  }};
    height: calc(4.198px * var(--ratio));
    border-radius: calc(8.395px * var(--ratio));
    background: ${props => {
    if (props.$status === 2) {
      return "#C1B8B9"
    }
    return "#96C9C8"
  }};
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: ${props => {
    if (props.$status === 1) {
      return "calc((78px + 56px) * var(--ratio))"
    }
    return "calc((78px + 23px) * var(--ratio))"
  }};
    display: ${props => props.$hiddenBefor ? "none" : "block"};
  }
  &::after{
    content: '';
    width: calc(4.198px * var(--ratio));
    height: calc(17.84px * var(--ratio));
    background:${props => {
    if (props.$status === 2) {
      return "#C1B8B9"
    }
    return "#96C9C8"
  }};
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: calc((78px + 18.96px) * var(--ratio));
    display: ${props => {
    if (props.$status === 1) {
      return "none"
    }
    return "block"
  }};
  }
  &:last-child{
    margin-right: 0;
  }
`

export const WeekWrapper = styled.div`
  display: flex;
`

export const WeekItem = styled.div`
  --wh: calc(44px * var(--ratio));
  width: var(--wh);
  height: var(--wh);
  border-radius: 50%;
  margin-left: calc(4px * var(--ratio));
  border: ${props => {
    if (props.$status === 1) {
      return "1px dashed var(--Gray-600, #948B8C)";
    } else if (props.$status === 2) {
      return "1px solid var(--Gray-900, #251F1F)";
    } else if (props.$status === 3) {
      return "1px solid #ED8395";
    }
  }};
  color: ${props => {
    if (props.$status === 1) {
      return "#948B8C";
    } else if (props.$status === 2) {
      return "#251F1F";
    } else if (props.$status === 3) {
      return "#FFF";
    }
  }};
  background-color: ${props => {
    if (props.$status === 1) {
      return "none";
    } else if (props.$status === 2) {
      return "none";
    } else if (props.$status === 3) {
      return "#ED8395";
    }
  }};
  text-align: center;
  font-family: Roboto;
  font-size: calc(13.2px * var(--ratio));
  font-weight: 300;
  line-height: var(--wh);
`

const HelloTxt = styled.div`
  color: var(--Primary-800, #B14E62);
  font-family: Roboto;
  font-size: calc(32px * var(--ratio));
  font-style: normal;
  font-weight: 700;
  margin-bottom: 3px;
`
const HelloTxtDesc = styled.div`
  color: var(--Gray-800, #6E6566);
  font-family: Roboto;
  font-size: calc(16px * var(--ratio));
  font-style: normal;
  font-weight: 400;
`

const Wrapper = styled.div`
  background: linear-gradient(135deg, rgba(255, 219, 225, 0.00) 28.16%, #D3F5F4 100%), linear-gradient(152deg, #FFDBE1 18.63%, #D3F5F4 89.16%);
  backdrop-filter: blur(250px);
  height: calc(var(--vh) - var(--head-height));
  overflow: auto;
`

const TopContent = styled.div`
  padding: 0 calc(83px * var(--ratio));
`


export default Index