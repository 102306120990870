import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web'


const Index = (props) => {
  const { loadingJson, ...others } = props
  const animateDom = useRef()
  const animate = useRef()

  useEffect(() => {
    playAnimate()

    return () => {
      animate?.current?.destroy()
    }
  }, [])

  const playAnimate = () => {
    animate.current = lottie.loadAnimation({
      container: animateDom.current, // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: loadingJson
    })
  }
  return (
    <div {...others} ref={animateDom}></div>
  )
}

export default Index

