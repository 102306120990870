

const Index = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none">
      <line y1="8.5" x2="18" y2="8.5" stroke="#161A19" />
      <line x1="9.5" y1="2.18557e-08" x2="9.5" y2="18" stroke="#161A19" />
    </svg>
  )
}

export default Index