export function setCookie(name, value, daysToLive, domain, path, secure) {
    let cookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)};`;

    if (daysToLive) {
        const date = new Date();
        date.setTime(date.getTime() + (daysToLive * 24 * 60 * 60 * 1000));
        cookie += ` expires=${date.toUTCString()};`;
    }

    if (domain) {
        cookie += ` domain=${domain};`;
    }

    cookie += ` path=${path || '/'};`;

    if (secure) {
        cookie += ' secure;';
    }

    document.cookie = cookie;
}

export function deleteCookie(name, domain, path) {
    let cookie = `${encodeURIComponent(name)}=; expires=Thu, 01 Jan 1970 00:00:00 GMT;`;

    if (domain) {
        cookie += ` domain=${domain};`;
    }

    cookie += ` path=${path || '/'};`;

    document.cookie = cookie;
}
