import { useEffect, useState, useContext, useRef } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components";
import { Context as RequestContext } from '../request'
import { Context as RouterContext } from '../../router'
import { Add, DeleteIcon, DeleteConfirmIcon } from '../../components/icons/free'
import confirm from "../../components/Confirm"
import LoadingBall from '../../components/icons/ball_loading'
import { cloneDeep } from "lodash";
import { LoadingWrapper } from '../../utils/full_loading'



const Index = () => {
    const [list, setList] = useState({ list: [], total: 0 })
    const [loading, setLoading] = useState(true)
    const loadingBox = useRef()
    const n = useNavigate()
    const requestCtx = useContext(RequestContext)
    const request = requestCtx.useRequest(false)
    const rctx = useContext(RouterContext)
    const [createLoading, setCreateLoading] = useState(false)
    const [page, setPage] = useState(1)
    const loaded = useRef(false)

    const getList = async (p) => {
        try {
            const ret = await request.get(`/client/v2/api/free/list?page=${p}`)
            ret.list = ret.list || []
            for (let i = 0; i < ret.list.length; i++) {
                let item = ret.list[i]
                item.dname = item.name
                try {
                    item.data = JSON.parse(item.data)
                    item.dnames = []
                    for (let j = 0; j < item.data.nodes.length; j++) {
                        let v = item.data.nodes[j]
                        if (v.type === "diyinput" && v.data.value) {
                            item.dnames.push(v.data.value)
                        }
                    }
                    item.dname = item.dnames.join()
                } catch (error) {

                }
            }
            setList(pre => {
                const cpPre = cloneDeep(pre)
                cpPre.list = [...cpPre.list, ...ret.list]
                cpPre.total = ret.total
                return cpPre
            })
            return ret
        } catch (error) {

        }
    }

    useEffect(() => {
        rctx.onresize()
        let ob
        getList(page).then(() => {
            setLoading(false)
            ob = new IntersectionObserver(async (list) => {
                if (list[0].isIntersecting) {
                    if (!loaded.current) {
                        loaded.current = true
                        let cpPage = cloneDeep(page)
                        cpPage++
                        setPage(cpPage)
                        await getList(cpPage)
                        loaded.current = false
                    }
                }
            }, {
                threshold: 0
            })
            ob.observe(loadingBox.current)
        })

        return () => {
            if (ob) {
                ob.disconnect()
            }
        }
    }, [])

    return (
        <Wrapper>
            <LoadingWrapper bgColor="#E1F7F6" loading={loading}>
                <Content>
                    <div style={{ height: "calc(80px * var(--ratio))" }}></div>
                    <Title>My boards</Title>
                    <div style={{
                        display: "flex",
                        flexWrap: "wrap",
                    }}>
                        <CreateWrapper style={{ pointerEvents: createLoading ? "none" : "all" }} onClick={async () => {
                            setCreateLoading(true)
                            try {
                                const info = await request.post("/client/v2/api/free/create", {})
                                n(`/free/${info.hash_id}`)
                                //window.location.href = process.env.REACT_APP_V1_LOGIN.replace("/login", `/free/${info.hash_id}`)
                            } catch (error) {

                            }
                        }}>
                            {createLoading ? <CreateLoadingBall /> : <CreateWrapperAddIcon />}
                            <NewBoard>New board</NewBoard>
                        </CreateWrapper>
                        {
                            list.list.map((v, k) => (
                                <BoardItem key={v.hash_id} onClick={() => {
                                    //window.location.href = process.env.REACT_APP_V1_LOGIN.replace("/login", `/free/${v.hash_id}`)
                                    n(`/free/${v.hash_id}`)
                                }}>
                                    <BoardItemChild>
                                        <img style={{
                                            width: "100%",
                                            objectFit: "cover",
                                            height: "calc(156px * var(--ratio))"
                                        }} src={v.cover ? `${process.env.REACT_APP_OSS_HOST}/${v.cover}` : 'https://file.bigread.ai/image/board_defaut.svg'} alt="" />
                                        <BoardItemName>{v.dname}</BoardItemName>
                                        <Line></Line>
                                    </BoardItemChild>
                                    <BoardItemTime>
                                        <span>{v.english_date}</span>
                                        <DeleteIconWrapper onClick={(e) => {
                                            e.stopPropagation()
                                            confirm({
                                                title: "Delete this board",
                                                Icon: DeleteConfirmIcon,
                                                onConfirm: async () => {
                                                    try {
                                                        await request.post(`/client/api/free/delete?hash_id=${v.hash_id}`)
                                                        await getList()
                                                    } catch (error) {

                                                    }
                                                }
                                            })
                                        }} />
                                    </BoardItemTime>
                                </BoardItem>
                            ))
                        }
                    </div>
                    {
                        list.total === list.list.length && <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "calc(32px * var(--ratio))" }}>
                            <div style={{ fontSize: "calc(12px * var(--ratio))", color: "#161A19" }}>That's all the boards</div>
                        </div>
                    }
                    <div ref={loadingBox} style={{ display: list.total !== list.list.length ? "flex" : "none", justifyContent: "center", alignItems: "center", marginBottom: "calc(32px * var(--ratio))" }}>
                        <LoadingBall style={{ width: "calc(12px * var(--ratio))", height: "calc(12px * var(--ratio))", marginRight: "calc(10px * var(--ratio))" }}></LoadingBall>
                        <div style={{ fontSize: "calc(12px * var(--ratio))", color: "#161A19" }}>Loading</div>
                    </div>
                </Content>
            </LoadingWrapper>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow: auto;
    position: relative;
    background: var(--Background-CardBackground, linear-gradient(180deg, #E1F7F6 0%, #FFF 100%));
    backdrop-filter: blur(calc(250px * var(--ratio)));
    &::-webkit-scrollbar {
        display: none;
    }
`

const Content = styled.div`
    padding: 0 calc(91px * var(--ratio));
    margin: 0 auto;
`

const BoardItemDel = styled.div`
    color: red;
    position: absolute;
    right: 12px;
    font-size: 13px;
    bottom: 12px;
`

const Title = styled.div`
    color: var(--Function-black, #161A19);
    font-size: calc(15px * var(--ratio));
    font-weight: 500;
    line-height: 1.73;
    padding-left: calc(24px * var(--ratio));
    margin-bottom: calc(16px * var(--ratio));;
`
const CreateWrapper = styled.div`
    border-radius: calc(22px * var(--ratio));
    background: #36B7B1;
    width: calc(224px * var(--ratio));
    height: calc(290px * var(--ratio));
    position: relative;
    cursor: pointer;
    margin-right: calc(16px * var(--ratio));
    margin-bottom: calc(32px * var(--ratio));
    &:hover{
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #36B7B1;
    }
`

const CreateWrapperAddIcon = styled(Add)`
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: calc(156px * var(--ratio));
    width: calc(58px * var(--ratio));
    height: calc(58px * var(--ratio));
`

const CreateLoadingBall = styled(LoadingBall)`
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: calc(156px * var(--ratio));
    width: calc(58px * var(--ratio));
    height: calc(58px * var(--ratio));
`

const NewBoard = styled.div`
    color: #FFF;
    font-size: calc(14px * var(--ratio));
    font-style: normal;
    font-weight: 400;
    line-height: 1.7;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    bottom: calc(28px * var(--ratio));
    display: flex;
    justify-content: center;
    align-items: center;
`

const DeleteIconWrapper = styled(DeleteIcon)`
    width: calc(18px * var(--ratio));
    height:  calc(18px * var(--ratio));
    cursor: pointer;
`

const Line = styled.div`
    background: #EFE9E9;
    height: calc(1px * var(--ratio));
    position: absolute;
    width: calc(100% - calc(24px * var(--ratio)));
    left: 0;
    right: 0;
    margin: auto;
    bottom: calc(56px * var(--ratio));
`

const BoardItemTime = styled.div`
    position: absolute;
    bottom: calc(18px * var(--ratio));
    height:  calc(30px * var(--ratio));
    padding-left:calc(20px * var(--ratio));
    padding-right: calc(20px * var(--ratio));
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    color: #161A19;
    font-size:calc(12px * var(--ratio)) ;
    font-style: normal;
    font-weight: 300;
    line-height: 1.2;
    left: 0;
    right: 0;
    margin: auto;
`

const BoardItemName = styled.span`
    margin-top: calc(16px * var(--ratio));
    overflow: hidden;
    color: #161A19;
    text-overflow: ellipsis;
    font-size: calc(14px * var(--ratio));
    font-style: normal;
    font-weight: 400;
    line-height: 1.42;
    letter-spacing: calc(-0.28px * var(--ratio));
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    align-self: stretch;
    word-break: break-word;
`

const BoardItem = styled.div`
    border-radius: calc(22px * var(--ratio));
    border: calc(1px * var(--ratio)) solid var(--Gray-200, #EFE9E9);
    background: #FFF;
    box-shadow: 0px calc(4px * var(--ratio)) calc(20px * var(--ratio)) calc(-12px * var(--ratio)) rgba(12, 83, 36, 0.40);
    width: calc(222px * var(--ratio));
    height: calc(288px * var(--ratio));
    position: relative;
    margin-bottom: calc(32px * var(--ratio));
    margin-right: calc(16px * var(--ratio));
    &:nth-child(5n) {
        margin-right: 0;
    }
`

const BoardItemChild = styled.div`
    padding: calc(12px * var(--ratio));
`

export default Index