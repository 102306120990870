const arr = []
// 发送请求参数到 Service Worker
export const sendServiceWorker = (event, data) => {
  arr.push(data);
  // if (navigator.serviceWorker && navigator.serviceWorker.controller) {
  //   navigator.serviceWorker.controller.postMessage({
  //     event,
  //     data,
  //   });
  // }
}


const loopRequest = async () => {
  requestAnimationFrame(async () => {
    if (arr.length === 0) {
      loopRequest()
      return
    }
    const requestParams = arr.shift();
    try {
      let response = await fetch(requestParams.url, requestParams.options)
      // eslint-disable-next-line
      response = await response.json()
    } catch (error) {
      console.log(error)
    }
    loopRequest()
  })
}

loopRequest()
