import styled from "styled-components";
import ReactDOM from 'react-dom/client';
import { useState } from "react";
import LoadingBall from '../../components/icons/ball_loading'


const Index = (props) => {
  const { width = 414, height = 375, title, Icon, onCancel, onConfirm, desc = <Desc>You are going to delete <span>How can lose weight with a good eating habits?</span></Desc>, okTxt = "Delete", cancelTxt = "Cancel" } = props.options
  const [loading, setLoading] = useState(false)
  return (
    <DeleteConfirm onClick={e => e.stopPropagation()}>
      <DeleteConfirmInner $width={width} $height={height}>
        {
          Icon && <div style={{ display: "flex", justifyContent: "center", marginTop: "calc(32px * var(--ratio))" }}>
            <Icon style={{ width: "calc(140px * var(--ratio))", height: "calc(140px * var(--ratio))" }} />
          </div>
        }
        {
          !Icon && <div style={{ height: "calc(21px * var(--ratio))" }}></div>
        }
        <Title>{title}</Title>
        {desc}
        <ButtonGroup>
          <Button onClick={onCancel}>{cancelTxt}</Button>
          <div style={{ width: "calc(32px * var(--ratio))" }}></div>
          <Button $loading={loading} onClick={async () => {
            setLoading(true)
            await onConfirm()
            setLoading(false)
          }} style={{ background: "#FFF", color: "#161A19" }}>{okTxt}{loading && <LoadingBall style={{ width: "calc(24px * var(--ratio))", height: "calc(24px * var(--ratio))" }} />}</Button>
        </ButtonGroup>
      </DeleteConfirmInner>
    </DeleteConfirm>
  )
}

const ButtonGroup = styled.div`
    display: flex;
    justify-content: center;
    margin-top: calc(23px * var(--ratio));
`

const Button = styled.div`
  border-radius: calc(40px * var(--ratio));
  border: calc(1px * var(--ratio)) solid #E4DFE0;
  background: #161A19;
  width: calc(138px * var(--ratio));
  height: calc(38px * var(--ratio));
  color: #FFF;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: calc(16px * var(--ratio));
  font-style: normal;
  font-weight: 500;
  line-height: 1.37;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  pointer-events: ${props => props.$loading ? "none" : "all"};
  opacity: ${props => props.$loading ? 0.5 : 1};
  align-items: center;
`

const Title = styled.div`
  color: #161A19;
  font-size: calc(24px * var(--ratio));
  font-style: normal;
  font-weight: 500;
  line-height: 1.3;
  margin-top: calc(16px * var(--ratio));
  text-align: center;
`

const Desc = styled.div`
  color: #467776;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: calc(14px * var(--ratio));
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
  & span{
    font-weight: 700;
  }
  padding-left: calc(48px * var(--ratio));
  padding-right: calc(48px * var(--ratio));
  margin-top: calc(16px * var(--ratio));
`

const DeleteConfirm = styled.div`
    position: fixed;
    width: var(--vw);
    height: var(--vh);
    z-index: 99;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.20);
`

const DeleteConfirmInner = styled.div`
    position: fixed;
    width:calc(${props => props.$width}px * var(--ratio));
    height:calc(${props => props.$height}px * var(--ratio));
    z-index: 100;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    border-radius: calc(16px * var(--ratio));
    border: calc(1px * var(--ratio)) solid #E4DFE0;
    background: #FFF;
`



export default function (options) {
  const { onCancel = () => { }, onConfirm = () => { }, ...others } = options
  const divConfirm = document.createElement("div")
  document.body.appendChild(divConfirm)
  const confirmRoot = ReactDOM.createRoot(divConfirm);
  confirmRoot.render(
    <Index options={{
      onCancel: () => {
        confirmRoot.render(null)
        onCancel()
      },
      onConfirm: async () => {
        await onConfirm()
        confirmRoot.render(null)
      },
      ...others
    }} />
  );
}

