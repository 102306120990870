import { useEffect, useRef, useState, useContext, useImperativeHandle } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from "swiper";
import { EffectCards } from 'swiper/modules';
import { cloneDeep } from "lodash";
import styled from "styled-components";
import { Context as RequestContext } from '../request'
import { AddWordTableIcon, HearIcon, FlipIcon, DefineIcon, SlideIcon, HalfRound } from '../../components/icons/vocabulary'
import { sleep, getFontWidth } from '../../utils'
import { MsgTypeWordParse, MsgTypeTTSVoice } from '../../utils/define'
import { Context as AuthContext } from '../auth'
import BallLoading from '../../components/icons/ball_loading'
import md5 from 'md5'
import PlayingLoading from '../../components/icons/play_loading'

const Index = (props) => {
  let { hash_id, audioPool, onLoad = () => { }, tabarValue = {}, next = () => { }, onActive = () => { }, onClear = () => { }, onRefreshArticle = () => { } } = props
  const [worldSwiperIndexs, setWorldSwiperIndexs] = useState(0)
  const worldSwiperRef = useRef({})
  const [worldsParse, setWorldsParse] = useState({})
  const requestCtx = useContext(RequestContext)
  const request = requestCtx.useRequest()
  const [data, setData] = useState([])
  const authCtx = useContext(AuthContext)
  const emitter = authCtx.useEmitter()
  const cardBgColors = useRef(["#EEFFEE", "#FFDADF", "#FAFFEE", "#EEFBFF", "#F2EEFF"])
  const cardAudioId = useRef(audioPool.getAudio("cardAudio"))
  const socket = useRef()
  const [currentPlayTxt, setCurrentPlayTxt] = useState("")
  const [cardWordPlayStatus, setCardWordPlayStatus] = useState(0)
  const [show, _setShow] = useState(false)
  const [wordFontSize, setWordFontSize] = useState("")
  const [fontSpans, setFontSpans] = useState([])
  const _show = useRef(false)

  const _data = useRef([])
  const observer = useRef()

  useEffect(() => {
    _data.current = data
  }, [data])

  const init = async () => {
    try {
      emitter.on(MsgTypeWordParse, onMsgTypeWordParse)
      emitter.on(MsgTypeTTSVoice, onMsgTypeTTSVoice)
      audioPool.onBePause((aid) => {
        if (aid === cardAudioId.current) {
          audioPool.stopAudio(cardAudioId.current)
          setCardWordPlayStatus(0)
        }
      })
      socket.current = await authCtx.useSocket()
      const _data = await getData(hash_id)
      await sleep(300)
      worldSwiperRef.current.update()
      onLoad(_data)
    } catch (error) {

    }
  }

  const onObserver = () => {
    resetWordFontSize()
  }

  const resetWordFontSize = () => {
    const wordCardDom = document.querySelectorAll(".vocabularyWordCard")
    const nodes = document.querySelectorAll(".vocabularyWord")
    for (let i = 0; i < nodes.length; i++) {
      const ele = nodes[i]
      const width = getFontWidth(ele.innerText, `
        display: inline-block;
        font-size: calc(50px * var(--ratio));
        font-weight: 600;
      `)
      if (width > wordCardDom[0].clientWidth - 50) {
        setData(pre => {
          const cpPre = cloneDeep(pre)
          cpPre[i].fontSize = "calc(32px * var(--ratio))"
          return cpPre
        })
      }
    }
  }

  useImperativeHandle(props.action, () => ({
    init,
    setShow: async (_s) => {
      _show.current = _s
      if (!_s) {
        onClear(cardBgColors.current)
      } else {
        onActive(_data.current[worldSwiperIndexs], cardBgColors.current[worldSwiperIndexs % cardBgColors.current.length], cardBgColors.current)
      }
      _setShow(_s)
      if (_s) {
        await sleep(300)
        worldSwiperRef.current.update()
        resetWordFontSize()
      }
    }
  }));

  const getData = async (_hash_id) => {
    try {
      const _data = await request.get("/client/v2/api/article/vocabulary?hash_id=" + _hash_id) || []
      if (_data && _data.length !== 0) {
        for (let i = 0; i < _data.length; i++) {
          _data[i].fontSize = "calc(50px * var(--ratio))"
          if (_data[i].ctx && typeof _data[i].ctx === "string") {
            _data[i].ctx = JSON.parse(_data[i].ctx)
          }
        }
        setData(_data)
      }
      return _data
    } catch (error) {

    }
  }

  const onMsgTypeWordParse = async ({ msg, data }) => {
    if (data.hash_id !== hash_id) {
      return
    }
    try {
      const _data = await getData(hash_id)
      await sleep(300)
      worldSwiperRef.current.update()
      onLoad(_data)
      onRefreshArticle()
    } catch (error) {

    }
  }

  const onPlayStart = () => {
    setCardWordPlayStatus(2)
  }

  const onPlayEnd = () => {
    setCardWordPlayStatus(0)
  }

  const onPlayLoading = () => {
    setCardWordPlayStatus(1)
  }

  const onMsgTypeTTSVoice = ({ msg, data }) => {
    setCurrentPlayTxt(_txt => {
      if (data.flag !== md5(_txt)) {
        return _txt
      }
      msg = JSON.parse(msg)
      audioPool.playAudioArr(cardAudioId.current, msg, onPlayStart, onPlayEnd, onPlayLoading)
      return _txt
    })
  }

  useEffect(() => {
    if (!props.action) {
      init()
    }

    const observer = new ResizeObserver(onObserver)
    observer.observe(document.body)


    return () => {
      observer.disconnect()
      emitter.off(MsgTypeWordParse, onMsgTypeWordParse)
      emitter.off(MsgTypeTTSVoice, onMsgTypeTTSVoice)
    }

  }, [])

  const playTxt = async (txt) => {
    setCurrentPlayTxt(txt)
    setCardWordPlayStatus(1)
    socket.current.send(JSON.stringify({
      msg_type: MsgTypeTTSVoice,
      content: txt,
      flag: md5(txt),
      voice_type: "TTS_WORD_CARD"
    }))
  }

  const showWorldsParse = async (world, _show) => {
    let cpWorldsParse = cloneDeep(worldsParse)
    if (_show) {
      cpWorldsParse[world] = true
      setWorldsParse(cpWorldsParse)
    } else {
      if (cpWorldsParse[world]) {
        delete cpWorldsParse[world]
      }
      setWorldsParse(cpWorldsParse)
    }
  }

  const addWordTable = async (v) => {
    try {
      request.post("/client/api/article/word/marked", {
        word: v.word,
        hash_id: hash_id
      })
      setData(pre => {
        const cpPre = cloneDeep(pre)
        const fidx = cpPre.findIndex(i => i.word === v.word)
        cpPre[fidx].marked = true
        return cpPre
      })
    } catch (error) {

    }
  }


  return (
    <Wrapper $show={show}>
      <CardWrapper>
        <Title>Vocabulary</Title>
        <div>
          <Swiper
            onSwiper={s => {
              worldSwiperRef.current = s
              SwiperCore.use([EffectCards])
              worldSwiperRef.current.update()
            }}
            style={{ transform: "translateX(calc(-7px * var(--ratio)))" }}
            modules={[EffectCards]}
            effect="cards"
            cardsEffect={{
              slideShadows: false,
              rotate: false,
              perSlideOffset: 6.6
            }}
            onActiveIndexChange={d => {
              const index = d.activeIndex
              audioPool.stopAudio(cardAudioId.current)
              setCardWordPlayStatus(0)
              setWorldSwiperIndexs(index)
              onActive(_data.current[index], cardBgColors.current[index % cardBgColors.current.length], cardBgColors.current)
            }}
          >
            {
              data.map((v2, k2) => (
                <SwiperSlide style={{ overflow: "auto" }} key={k2}>
                  <SwiperSlideItem className="vocabularyWordCard" style={{
                    transform: worldsParse[v2.word] ? "rotateY(180deg)" : "rotateY(0deg)",
                    WebkitTransform: worldsParse[v2.word] ? "rotateY(180deg)" : "rotateY(0deg)",
                  }} $index={k2} onClick={() => {
                    showWorldsParse(v2.word, !worldsParse[v2.word])
                  }}>
                    <SlurBg></SlurBg>
                    <HalfRoundWrapper bgColor={cardBgColors.current[k2 % cardBgColors.current.length]} />
                    <Card>
                      <div style={{
                        display: worldsParse[v2.word] ? "none" : "block"
                      }}>
                        {
                          !v2.marked && <AddWordTableIconZhengMianWrapper onClick={e => {
                            e.stopPropagation()
                            addWordTable(v2)
                          }} />
                        }
                        <Word $wordFontSize={v2.fontSize}><span className="vocabularyWord">{v2.word}</span></Word>
                        <HearIconZhengMianWrapper>
                          {
                            cardWordPlayStatus === 0 && <PlayIconTM onClick={(e) => {
                              e.stopPropagation()
                              playTxt(v2.word)
                            }}><HearIcon style={{ width: "100%", height: '100%' }} /></PlayIconTM>
                          }
                          {
                            cardWordPlayStatus === 1 && <BallLoading style={{ width: "100%", height: '100%' }} />
                          }
                          {
                            cardWordPlayStatus === 2 && <PlayIconTM style={{ width: "100%", height: '100%' }}>
                              <PlayingLoading style={{ width: "100%", height: '100%' }} />
                            </PlayIconTM>
                          }
                        </HearIconZhengMianWrapper>
                        {/* <LoadingIcon right="calc(0px * var(--ratio))" size="calc(48px * var(--ratio))"></LoadingIcon> */}
                        <ClickTxt>Click the blank area to view the Definition.</ClickTxt>
                      </div>
                      <div style={{
                        display: worldsParse[v2.word] ? "block" : "none",
                        transform: worldsParse[v2.word] ? "rotateY(180deg)" : "rotateY(0deg)",
                        width: "100%",
                        height: "100%"
                      }}>
                        <div style={{ display: "flex", alignItems: "center", paddingLeft: "calc(24px * var(--ratio))", paddingTop: "calc(24px * var(--ratio))" }}>
                          <FlipIconWrapper />
                          <span style={{
                            color: "#161A19",
                            fontFeatureSettings: "'clig' off, 'liga' off",
                            fontSize: "calc(12px * var(--ratio))",
                            fontWeight: 400
                          }}>Flip</span>
                        </div>
                        <DefineIconWrapper />
                        <DefinitionWrapper>
                          <Pos>{v2.pos}.</Pos>
                          <Define>{v2.definition}</Define>
                        </DefinitionWrapper>
                        <HearIconWrapper>
                          {
                            cardWordPlayStatus === 0 && <PlayIconTM onClick={(e) => {
                              e.stopPropagation()
                              playTxt(v2.definition)
                            }}><HearIcon style={{ width: "100%", height: '100%' }} /></PlayIconTM>
                          }
                          {
                            cardWordPlayStatus === 1 && <BallLoading style={{ width: "100%", height: '100%' }} />
                          }
                          {
                            cardWordPlayStatus === 2 && <PlayIconTM style={{ width: "100%", height: '100%' }}>
                              <PlayingLoading style={{ width: "100%", height: '100%' }} />
                            </PlayIconTM>
                          }
                        </HearIconWrapper>
                        {
                          !v2.marked && <NewWordButton onClick={e => {
                            e.stopPropagation()
                            addWordTable(v2)
                          }}>
                            <AddWordTableIconWrapper />
                            <NewWordListTxt>New word list</NewWordListTxt>
                          </NewWordButton>
                        }
                      </div>
                    </Card>
                  </SwiperSlideItem>
                </SwiperSlide>
              ))
            }
          </Swiper>
        </div>
        <BottomBtn $hidden={(!data || (data && data.length === 0))}>
          <SlideIconWrapper style={{ opacity: worldSwiperIndexs === 0 && 0.3 }} onClick={(e) => {
            e.preventDefault()
            worldSwiperRef.current.slidePrev()
          }} />
          <WordCardNum>
            <span style={{ opacity: worldSwiperIndexs === 0 && 0.3 }}>{isNaN(worldSwiperIndexs) ? 1 : worldSwiperIndexs + 1}</span>
            <span style={{
              opacity: (worldSwiperIndexs + 1 === data.length || worldSwiperIndexs === 0) && 0.3,
            }}>/</span>
            <span style={{
              opacity: worldSwiperIndexs + 1 === data.length && 0.3
            }}>{data.length}</span>
          </WordCardNum>
          <SlideIconWrapper style={{
            opacity: worldSwiperIndexs + 1 === data.length && 0.3,
            transform: "rotate(180deg)"
          }} onClick={(e) => {
            e.preventDefault()
            worldSwiperRef.current.slideNext()
          }} />
        </BottomBtn>
      </CardWrapper>
      <NextStepButton $disabled={tabarValue["quiz"] === 0} onClick={next}>Next Step</NextStepButton>
    </Wrapper >
  )
}

const Wrapper = styled.div`
  width: calc(434px * var(--ratio));
  overflow: hidden;
  display: ${props => props.$show ? "block" : "none"};
`

const Card = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  backdrop-filter: blur(calc(45px * var(--ratio)));
  -webkit-backdrop-filter: blur(calc(45px * var(--ratio)));
  background: rgba(255, 255, 255, 0.30);
`

const HalfRoundWrapper = styled(HalfRound)`
  position: absolute;
  width: 100%;
  height: calc(181px * var(--ratio));
  bottom: 0;
  z-index: 0;
`

const SlurBg = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #FFF;
  z-index: -1;
`

const DefinitionWrapper = styled.div`
  display: flex;
  padding: 0 calc(24px * var(--ratio));
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: calc(94px * var(--ratio));
  height: calc(72px * var(--ratio));
  overflow: auto;
  &::-webkit-scrollbar{
    display: none;
  }
`

const NewWordButton = styled.div`
  border-radius: calc(18.732px * var(--ratio));
  border: calc(1px * var(--ratio)) solid #E4DFE0;
  width: calc(135px * var(--ratio));
  height: calc(40px * var(--ratio));
  display: flex;
  align-items: center;
  left: 0;
  right: 0;
  margin: auto;
  justify-content: center;
  position: absolute;
  bottom: calc(24px * var(--ratio));
  cursor: pointer;
`

const ClickTxt = styled.div`
  color: #161A19;
  font-feature-settings: "'clig' off, 'liga' off";
  font-size: calc(12px * var(--ratio));
  font-weight: 400;
  text-align: center;
  position: absolute;
  bottom: calc(54px * var(--ratio));
  left: 0;
  right: 0;
  margin: auto;
`

const NewWordListTxt = styled.div`
  color: var(--black, #161A19);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: calc(12px * var(--ratio));
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: calc(8px * var(--ratio));
`

const NextStepButton = styled.button`
  display: flex;
  height: calc(50px * var(--ratio));
  padding: 8px 0px;
  justify-content: center;
  align-items: center;
  border-radius: calc(26px * var(--ratio));
  background: #36B7B1;
  color: var(--white, var(--icon-color, #FFF));
  font-size: calc(20px * var(--ratio));
  font-style: normal;
  font-weight: 600;
  border: none;
  width: 100%;
  margin-top: calc(48px * var(--ratio));
  pointer-events: ${props => props.$disabled ? "none" : "all"};
  opacity: ${props => props.$disabled ? 0.3 : 1};
`

const SlideIconWrapper = styled(SlideIcon)`
  width: calc(50px * var(--ratio));
  height: calc(50px * var(--ratio));
`

const HearIconWrapper = styled.div`
  width: calc(48px * var(--ratio));
  height: calc(48px * var(--ratio));
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: calc(79px * var(--ratio));
`

const HearIconTM = styled(HearIcon)`
  width: calc(46px * var(--ratio));
  height: calc(46px * var(--ratio));
  border: calc(1px * var(--ratio)) solid #DBD4D4; 
  border-radius: 50%;
`

const PlayIconTM = styled.div`
  width: calc(48px * var(--ratio));
  height: calc(48px * var(--ratio));
  border: calc(1px * var(--ratio)) solid #DBD4D4; 
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const HearIconZhengMianWrapper = styled.div`
  width: calc(48px * var(--ratio));
  height: calc(48px * var(--ratio));
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: calc(84px * var(--ratio));
`


const FlipIconWrapper = styled(FlipIcon)`
  width: calc(12px * var(--ratio));
  height: calc(14px * var(--ratio));
  position: relative;
  margin-right:calc(5px * var(--ratio));
`

const AddWordTableIconWrapper = styled(AddWordTableIcon)`
  width: calc(20px * var(--ratio));
  height: calc(20px * var(--ratio));
  cursor: pointer;
`

const AddWordTableIconZhengMianWrapper = styled(AddWordTableIcon)`
  width: calc(24px * var(--ratio));
  height: calc(24px * var(--ratio));
  cursor: pointer;
  top: calc(32px * var(--ratio));
  right: calc(24px * var(--ratio));
  position: absolute;
`



const DefineIconWrapper = styled(DefineIcon)`
  width: calc(14px * var(--ratio));
  height: calc(18px * var(--ratio));
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: calc(72px * var(--ratio));
`

const Pos = styled.div`
  color: #161A19;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: calc(22px * var(--ratio));
  font-style: normal;
  font-weight: 600;
  margin-right: calc(16px * var(--ratio));
`

const Define = styled.div`
  color: #161A19;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: calc(16px * var(--ratio));
  font-style: normal;
  font-weight: 400;
  line-height: 150%
`

const Word = styled.div`
  color: #161A19;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: ${props => props.$wordFontSize};
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: calc(32px * var(--ratio));;
  margin-top: calc(82.5px * var(--ratio));;
`

const Title = styled.div`
  color: #161A19;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: calc(15px * var(--ratio));
  font-style: normal;
  font-weight: 500;
  padding-left: calc(24px * var(--ratio));
  padding-top: calc(16px * var(--ratio));
  margin-bottom: calc(56px * var(--ratio));
`

const CardWrapper = styled.div`
  width: 100%;
  height: calc(526px * var(--ratio));
  border-radius: calc(30px * var(--ratio));
  background: #FFF;
`

const BottomBtn = styled.div`
    display: ${props => props.$hidden ? "none" : "flex"};
    align-items: center;
    justify-content: center;
    margin-top: calc(15px * var(--ratio));
`

const SwiperSlideItem = styled.div`
    width: calc(388px * var(--ratio));
    height: calc(304px * var(--ratio));
    border-radius: calc(30px * var(--ratio));
    border: calc(1px * var(--ratio)) solid #DBD4D4;
    box-shadow: calc(-4px * var(--ratio)) calc(8px * var(--ratio)) calc(30px * var(--ratio)) calc(-20px * var(--ratio)) #121212;
    position: relative;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    transition: transform 0.6s ease 0s;
    -webkit-transition: transform 0.6s ease 0s;
    margin-left: calc(25px * var(--ratio));
    margin-bottom: calc(25px * var(--ratio));
    overflow: hidden;

`

const WordCardNum = styled.div`
    color: #1F1D0F;
    text-align: right;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: calc(20px * var(--ratio));
    font-style: normal;
    margin-left: calc(40px * var(--ratio));
    margin-right: calc(40px * var(--ratio));
`





export default Index