import { useEffect, useContext, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { setCookie } from '../../utils/cookie'
import { Context as RequestContext } from '../request'
import { LoadingWrapper } from '../../utils/full_loading'
import { Context as RouterContext } from '../../router'

// const CLIENT_ID = '258261145476-7gmbi5ttpa02p0b5ac0vocv82mfck8dc.apps.googleusercontent.com'
const CLIENT_ID = '258261145476-7gmbi5ttpa02p0b5ac0vocv82mfck8dc.apps.googleusercontent.com'


export const oauth2SignIn = () => {
    const REDIRECT_URI = `${window.location.origin}/login/google`
    // Google's OAuth 2.0 endpoint for requesting an access token
    var oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth';

    // Create element to open OAuth 2.0 endpoint in new window.
    var form = document.createElement('form');
    form.setAttribute('method', 'GET'); // Send as a GET request.
    form.setAttribute('action', oauth2Endpoint);
    //https://www.googleapis.com/auth/drive.metadata.readonly
    // Parameters to pass to OAuth 2.0 endpoint.
    var params = {
        'client_id': CLIENT_ID,
        'redirect_uri': REDIRECT_URI,
        'scope': 'https://www.googleapis.com/auth/drive.file',
        'state': 'try_sample_request',
        'include_granted_scopes': 'true',
        'response_type': 'token'
    };

    // Add form parameters as hidden input values.
    for (var p in params) {
        var input = document.createElement('input');
        input.setAttribute('type', 'hidden');
        input.setAttribute('name', p);
        input.setAttribute('value', params[p]);
        form.appendChild(input);
    }

    // Add form to page and submit it to open the OAuth 2.0 endpoint.
    document.body.appendChild(form);
    form.submit();
}

const Index = () => {
    const n = useNavigate()
    const l = useLocation()
    const [loading, setLoading] = useState(true)
    const rctx = useContext(RouterContext)

    const requestCtx = useContext(RequestContext)
    const request = requestCtx.useRequest()

    const trySampleRequest = async (accessToken) => {
        try {
            let loginData = await request.post(`/client/open/user/login/google?access_token=${accessToken}`)
            if (loginData.type === "pre_login") {
                n(`/register/pre/${loginData.flag}`)
            } else {
                localStorage.setItem("token", loginData.data.token)
                setCookie('token', loginData.data.token, 365, '.bigread.ai', '/', true);
                localStorage.setItem("name", loginData.data.name)
                n("/")
            }
        } catch (error) {

        }
        setLoading(false)
    }

    useEffect(() => {
        rctx.onresize()
        if (window.location.href.includes("error=")) {
            n("/login")
            return
        }
        const urlParam = new URLSearchParams(l.hash)
        const accessToken = urlParam.get('access_token')
        if (accessToken) {
            trySampleRequest(accessToken);
        }

        // eslint-disable-next-line
    }, [])

    return (
        <LoadingWrapper loading={loading}></LoadingWrapper>
    )
}

export default Index