import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import lottie from 'lottie-web'
import fullpageloading from '../static/lottie/fullpageloading.json'

const div = document.createElement("div")
document.body.appendChild(div)
const root = ReactDOM.createRoot(div);

const Loading = (props) => {
  const { txt = 'Rest assured, it is in AI expert hands...' } = props
  const animateDom = useRef()
  const animate = useRef()

  useEffect(() => {
    playAnimate()

    return () => {
      animate?.current?.destroy()
    }
  }, [])

  const playAnimate = () => {
    animate.current = lottie.loadAnimation({
      container: animateDom.current, // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: fullpageloading
    })
    // animate.current.play()
    // animate.current.setSpeed(0.1)
    // 监听动画播放完毕
    // animate.current.addEventListener('complete', () => {
    //   // 在这里执行你想要的操作
    //   // animate.current.destroy()
    //   // animateDom.current.style.display = "none"
    // }, {
    //   once: true
    // });
  }
  return (
    <div style={{
      position: "fixed",
      zIndex: 999,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: "auto",
      background: "#FFF7F8",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }}>
      <div style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center"
      }}>
        <div style={{
          width: "calc(80px * var(--ratio))",
          height: "calc(80px * var(--ratio))"
        }} ref={animateDom}></div>
        <div style={{ textAlign: "center", fontWeight: 500, fontSize: "calc(14px * var(--ratio))" }}>{txt}</div>
      </div>
    </div>
  )
}

var timeout

export const showLoading = (txt) => {
  if (timeout) {
    clearTimeout(timeout)
  }
  timeout = setTimeout(() => {
    root.render(
      <Loading txt={txt} />
    );
  }, 300)
}

export const hideLoading = () => {
  root.render(
    null
  );
}

export const LoadingWrapper = (props) => {
  const { bgColor = "#FFF7F8", txt = 'Rest assured, it is in AI expert hands...', children, loading = false, position = "fixed" } = props
  const animateDom = useRef()
  const animate = useRef()

  useEffect(() => {
    playAnimate()

    return () => {
      animate?.current?.destroy()
    }
  }, [])

  const playAnimate = () => {
    animate.current = lottie.loadAnimation({
      container: animateDom.current, // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: fullpageloading
    })
  }
  return (
    <>
      <div style={{
        position,
        zIndex: 999,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: "auto",
        background: bgColor,
        display: loading ? "flex" : "none",
        alignItems: "center",
        justifyContent: "center"
      }}>
        <div style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center"
        }}>
          <div style={{
            width: "calc(80px * var(--ratio))",
            height: "calc(80px * var(--ratio))"
          }} ref={animateDom}></div>
          <div style={{ textAlign: "center", fontWeight: 500, fontSize: "calc(14px * var(--ratio))" }}>{txt}</div>
        </div>
      </div>
      <span style={{ opacity: !loading ? 1 : 0, transition: "1s" }}>{children}</span>
    </>
  )
}