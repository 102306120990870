import mitt from 'mitt'



export const sleep = (sec) => {
    let timeId = null
    return new Promise((a, b) => {
        timeId = setTimeout(() => {
            a(timeId)
        }, sec)
    })
}

export const virtualProgress = (speed = 10, onProgress) => {
    let num = 0
    let timerId = null
    let flag = false

    const innerStart = async () => {
        if (num > 90 || flag) {
            onProgress(0)
            return
        }
        num++
        timerId = await sleep(speed)
        onProgress(num)
        innerStart()
    }

    const start = async () => {
        innerStart()
    }

    const stop = () => {
        flag = true
        clearTimeout(timerId)
    }

    return {
        start,
        stop
    }
}

export const onlyEnglish = (input) => {
    const regex = /^[A-Za-z\s\p{P}]*$/u;
    return regex.test(input)
}

export class AudioPool {
    constructor() {
        this.pool = {}
        this.funs = {}
        this.emitter = mitt()
    }

    onBePause(callback) {
        this.emitter.on("onBePause", callback)
    }

    getAudio(audioId = `aid-${new Date().getTime()}`) {
        if (this.pool[audioId]) {
            return audioId
        }
        const a = new Audio()
        console.log("nimmmmmm", audioId)
        this.pool[audioId] = a
        return audioId
    }

    isPaused(aid) {
        return this.pool[aid].paused
    }

    playAudio(aid, url) {
        if (!this.pool[aid]) {
            return
        }
        const audio = this.pool[aid]
        //播放之前暂停所有其他音频
        for (let i in this.pool) {
            if (aid !== i) {
                this.pool[i].pause()
                this.emitter.emit("onBePause", i)
            }
        }
        audio.src = url
        this.funs[`${aid}canplay`] = () => {
            audio.play()
        }
        audio.addEventListener("canplay", this.funs[`${aid}canplay`], { once: true })
        return this.pool[aid]
    }

    pauseAudio(aid) {
        if (!this.pool[aid]) {
            return
        }
        this.pool[aid].pause()
        return this.pool[aid]
    }

    stopAudio(aid) {
        const audio = this.pauseAudio(aid)
        audio.currentTime = 0
        audio.src = null
        audio.removeEventListener("canplay", this.funs[`${aid}canplay`])
        audio.removeEventListener("ended", this.funs[`${aid}ended`])
        audio.removeEventListener("error", this.funs[`${aid}error`])
    }

    goonPlayAudio(aid) {
        if (!this.pool[aid]) {
            return
        }
        for (let i in this.pool) {
            if (aid !== i) {
                this.pool[i].pause()
                this.emitter.emit("onBePause", i)
            }
        }
        this.pool[aid].play()
    }

    setPlaybackRate(aid, val) {
        if (!this.pool[aid]) {
            return
        }
        this.pool[aid].playbackRate = val
    }

    playAudioArr(aid, infos = [], onPlay, onEnd, onLoading) {
        const audio = this.pool[aid]
        if (!audio) {
            return
        }
        if (infos.length === 0) {
            onEnd(aid)
            return
        }
        onLoading(aid)
        const info = infos.shift()
        audio.src = process.env.REACT_APP_OSS_HOST + info.url
        this.funs[`${aid}canplay`] = () => {
            //播放之前暂停所有其他音频
            for (let i in this.pool) {
                if (aid !== i) {
                    this.pool[i].pause()
                    this.emitter.emit("onBePause", i)
                }
            }
            onPlay(info.txt)
            audio.play()
            this.funs[`${aid}ended`] = () => {
                this.playAudioArr(aid, infos, onPlay, onEnd, onLoading)
            }
            this.funs[`${aid}error`] = () => {
                this.playAudioArr(aid, infos, onPlay, onEnd, onLoading)
            }
            audio.addEventListener("ended", this.funs[`${aid}ended`], { once: true })
            audio.addEventListener("error", this.funs[`${aid}error`], { once: true })
        }
        audio.addEventListener("canplay", this.funs[`${aid}canplay`], { once: true })
    }

    destroy() {
        for (let i in this.pool) {
            const audio = this.pool[i]
            audio.pause()
            audio.removeEventListener("canplay", this.funs[`${i}canplay`])
            audio.removeEventListener("ended", this.funs[`${i}ended`])
            audio.removeEventListener("error", this.funs[`${i}error`])
        }
        this.pool = {}
        this.emitter.all.clear()
    }
}

export const QuizMarkColors = [
    "#DEFFDE",
    "#FCC7C7",
    "#FCFFDE",
    "#DEF7FF",
    "#DEE2FF"
]



export const formatQuizParse = (parseTxt, onQuizParseJump = () => { }, onChanged = () => { }) => {
    const regex = /\[citation:(\d+)\]/g;
    let index = 0
    const indexObj = {}
    const changes = []
    const formattedText = parseTxt.replace(regex, (match, p1) => {
        if (!indexObj[p1]) {
            index++
            indexObj[p1] = index
        }
        // if (quizIndex === data.findIndex(j => j.no === v.no)) {
        //     ps.push(parseInt(p1) - 1)
        //     onQuizParseStyle(ps, indexObj)
        // }
        window.gotoArtcle = (p, dx) => {
            onQuizParseJump(p, dx - 1)
        }
        let color = QuizMarkColors[(indexObj[p1] - 1) % QuizMarkColors.length]
        let finded = changes.find(j => j.p1 === parseInt(p1))
        if (!finded) {
            changes.push({
                p1: parseInt(p1),
                index,
                color
            })
        }
        return `<span style="position: relative;width: calc(18px * var(--ratio));height: calc(18px * var(--ratio));display: inline-block;margin: 0 calc(8px * var(--ratio));"><span onclick="gotoArtcle(${p1},${indexObj[p1]})" style="display: inline-block;width: 100%;height: 100%;line-height: calc(20px * var(--ratio));background: ${color};color: #C1B8B9;font-size: calc(12px * var(--ratio));cursor: pointer;text-align: center;position: absolute;top: calc(5px * var(--ratio));">${indexObj[p1]}</span></span>`
    });
    onChanged(changes)
    return formattedText
}

export const getFontWidth = (txt, style) => {
    const div = document.createElement("div")
    if (style) {
        div.style.cssText = style
    }
    div.innerText = txt
    document.body.appendChild(div)
    setTimeout(() => {
        document.body.removeChild(div)
    }, 0)
    return div.clientWidth
}

export const accuracyQuiz = (correctNum, answerNum, sumNum) => {
    if (sumNum === 0) {
        return {
            // color: "#CEDBD2",
            // icon: "https://file.bigread.ai/img/empty_read.svg",
            // txt: "shows after reading"
        }
    }
    if (answerNum !== sumNum) {
        return {
            color: "#CEDBD2",
            icon: "https://file.bigread.ai/img/_wei.svg",
            txt: "shows after reading"
        }
    }
    const num = parseInt(correctNum / sumNum * 100)
    if (num >= 60) {
        return {
            color: "rgba(120, 227, 118, 1)",
            icon: "https://file.bigread.ai/img/point.svg",
            txt: "≥60"
        }
    }
    if (num < 60 && num > 30) {
        return {
            color: "rgba(255, 223, 107, 1)",
            icon: "https://file.bigread.ai/img/30_60.svg",
            txt: "30>%60"
        }
    }
    if (num < 30) {
        return {
            color: "rgba(255, 179, 194, 1)",
            icon: "https://file.bigread.ai/img/_30.svg",
            txt: "<%30"
        }
    }
}


