import styled from "styled-components";
import BackIcon from '../../components/icons/back'
import Tabar from './tabar'
import { useContext, useEffect, useRef, useState } from "react";
import { Context as RouterContext } from '../../router'
import Article from './article'
import { useNavigate, useParams } from "react-router-dom";
import { Context as RequestContext } from '../request'
import { Context as AuthContext } from '../auth'
import { LoadingWrapper } from '../../utils/full_loading'
import { cloneDeep } from "lodash";
import Vocabulary from './vocabulary'
import Quiz from './quiz'
import { sleep, AudioPool, QuizMarkColors } from "../../utils";



const Index = () => {
  const [loading, setLoading] = useState(true)
  const [tabarValue, setTabarValue] = useState({
    "article": 0,
    "vocabulary": 0,
    "quiz": 0
  })
  const rctx = useContext(RouterContext)
  const params = useParams()
  const requestCtx = useContext(RequestContext)
  const [scoreInfo, setScoreInfo] = useState({})
  const request = requestCtx.useRequest()
  const n = useNavigate()
  const authCtx = useContext(AuthContext)
  const scorePlayAnimate = useRef()
  const emitter = authCtx.useEmitter()
  const audioPool = useRef(new AudioPool())
  const articleAction = useRef({})
  const quizAction = useRef({})
  const tabarAction = useRef({})
  const vocabularyAction = useRef({})

  const articleOnLoad = async (info) => {
    setTabarValue(pre => {
      const cpPre = cloneDeep(pre)
      if (cpPre["article"] === 2) {
        return cpPre
      }
      if (info.step_lock) {
        info.step_lock = JSON.parse(info.step_lock)
        if (info.step_lock["vocabulary"]) {
          cpPre["vocabulary"] = info.step_lock["vocabulary"]
        }
        if (info.step_lock["quiz"]) {
          cpPre["quiz"] = info.step_lock["quiz"]
        }
      }
      if (cpPre["article"] === 0 && info && info.content) {
        cpPre["article"] = 2
        return cpPre
      }
      return cpPre
    })

  }

  const vocabularyOnLoad = async (info) => {
    setTabarValue(pre => {
      const cpPre = cloneDeep(pre)
      if (cpPre["vocabulary"] === 2) {
        return cpPre
      }
      if (cpPre["vocabulary"] === 0 && info && info.length !== 0) {
        cpPre["vocabulary"] = 3
        return cpPre
      }
      return cpPre
    })
    // articleAction.current.reloadData()
  }

  const QuizOnLoad = async (info) => {
    setTabarValue(pre => {
      const cpPre = cloneDeep(pre)
      if (cpPre["quiz"] === 2) {
        return cpPre
      }
      if (cpPre["quiz"] === 0 && info && info.length !== 0) {
        cpPre["quiz"] = 3
        return cpPre
      }
      return cpPre
    })
  }

  const onArticleNext = () => {
    setTabarValue(pre => {
      const cpPre = cloneDeep(pre)
      if (cpPre["vocabulary"] === 3) {
        request.post("/client/v2/api/article/step_lock/update", {
          key: "vocabulary",
          value: 1,
          hash_id: params.id
        })
        return cpPre
      }
      return cpPre
    })
    tabarAction.current.setValue(1)
  }

  const onVocabularyNext = () => {
    setTabarValue(pre => {
      const cpPre = cloneDeep(pre)
      if (cpPre["quiz"] === 3) {
        request.post("/client/v2/api/article/step_lock/update", {
          key: "quiz",
          value: 1,
          hash_id: params.id
        })
        return cpPre
      }
      return cpPre
    })
    tabarAction.current.setValue(2)
  }

  const onQuizCorrect = async (_score) => {
    setScoreInfo(_score)
    scorePlayAnimate.current.classList.add("scaleAnimation")
    await sleep(500)
    scorePlayAnimate.current.classList.remove("scaleAnimation")
  }


  const init = async () => {
    try {
      await articleAction.current.init()
      await vocabularyAction.current.init()
      await quizAction.current.init()
      const _scoreInfo = await request.get("/client/v2/api/article/quiz/score?hash_id=" + params.id)
      setScoreInfo(_scoreInfo)
      setLoading(false)
      rctx.onresize()
    } catch (error) {

    }
  }


  useEffect(() => {
    init()
    const unUseCollectOnlineTime = authCtx.useCollectOnlineTime()
    return () => {
      setTimeout(() => {
        unUseCollectOnlineTime()
      }, 0)
      audioPool.current.destroy()

    }
  }, [])

  const loopUnMark = async (changes = []) => {
    if (changes.length === 0) {
      return
    }
    let change = changes.pop()
    const articleContentDomInstance = await articleAction.current.useArticleContentDomInstance()
    const docs = articleAction.current.useArticleDocs()
    // for (let c = 0; c < QuizMarkColors.length; c++) {
    //   const className = `articleQuizHighlight_${QuizMarkColors[c].replace("#", "")}`

    // }
    articleContentDomInstance.unmark({
      done: () => {
        // 移除添加的元素
        const addedElements = document.querySelectorAll(".markspan");
        addedElements.forEach(function (element) {
          element.remove();
        });
        const className = `articleQuizHighlight_${change.color.replace("#", "")}`
        articleContentDomInstance.mark(docs[change.p1 - 1], {
          className,
          separateWordSearch: false,
          each: async (element) => {
            const span = document.createElement("span")
            span.innerText = change.index
            span.className = "markspan"
            element.before(span)
            await loopUnMark()
          }
        });
      }
    })

  }

  return (
    <LoadingWrapper loading={loading}>
      <Wrapper>
        <Header>
          <div onClick={() => n("/")} style={{
            width: "calc(50px * var(--ratio))",
            height: "calc(50px * var(--ratio))",
            background: "#FFF",
            borderRadius: "50%",
            display: 'flex',
            justifyContent: "center",
            alignItems: "center",
            marginRight: "calc(16px * var(--ratio))",
            cursor: "pointer"
          }}>
            <BackIcon style={{ width: "85%", height: "85%" }} />
          </div>
          <Tabar action={tabarAction} onChange={v => {
            quizAction.current.setShow(v["quiz"] === 2)
            vocabularyAction.current.setShow(v["vocabulary"] === 2)
            setTabarValue(v)
          }} value={tabarValue} />
          <Points style={{ marginLeft: "calc(16px * var(--ratio))" }}>
            <div>
              <ScoreAnimatedDiv ref={scorePlayAnimate}>{scoreInfo.star}</ScoreAnimatedDiv>
              <div style={{ color: "#161A19", fontWeight: 300, fontSize: "calc(12px * var(--ratio))" }}>Points</div>
            </div>
          </Points>
        </Header>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Article
            action={articleAction}
            hash_id={params.id}
            onLoad={articleOnLoad}
            miniWindow={tabarValue["vocabulary"] === 2 || tabarValue["quiz"] === 2}
            next={onArticleNext}
            tabarValue={tabarValue}
            audioPool={audioPool.current}
          />
          <Vocabulary
            onRefreshArticle={() => {
              articleAction.current.reloadData()
            }}
            action={vocabularyAction}
            hash_id={params.id}
            onLoad={vocabularyOnLoad}
            show={tabarValue["vocabulary"] === 2}
            tabarValue={tabarValue}
            next={onVocabularyNext}
            audioPool={audioPool.current}
            onClear={async (colors) => {
              const articleContentDomInstance = await articleAction.current.useArticleContentDomInstance()
              for (let c = 0; c < colors.length; c++) {
                const className = `articleVocaBularyHighlight_${colors[c].replace("#", "")}`
                articleContentDomInstance.unmark({ className })
              }
            }}
            onActive={async (info, color, colors) => {
              if (!info) {
                return
              }
              info.ctx = info.ctx || []
              const articleContentDomInstance = await articleAction.current.useArticleContentDomInstance()
              for (let c = 0; c < colors.length; c++) {
                const className = `articleVocaBularyHighlight_${colors[c].replace("#", "")}`
                articleContentDomInstance.unmark({ className })
              }
              for (let i = 0; i < info.ctx.length; i++) {
                let txt = info.ctx[i]
                articleContentDomInstance.mark(txt, {
                  className: `articleVocaBularyHighlight_${color.replace("#", "")}`,
                  "separateWordSearch": false
                });
              }
            }}
          />
          <Quiz
            action={quizAction}
            hash_id={params.id}
            onLoad={QuizOnLoad}
            onCorrect={onQuizCorrect}
            show={tabarValue["quiz"] === 2}
            tabarValue={tabarValue}
            onParse={async changes => {
              const articleContentDomInstance = await articleAction.current.useArticleContentDomInstance()
              const docs = articleAction.current.useArticleDocs()
              // for (let c = 0; c < QuizMarkColors.length; c++) {
              //   const className = `articleQuizHighlight_${QuizMarkColors[c].replace("#", "")}`

              // }
              articleContentDomInstance.unmark({
                done: () => {
                  // 移除添加的元素
                  const addedElements = document.querySelectorAll(".markspan");
                  addedElements.forEach(function (element) {
                    element.remove();
                  });
                  const addedElements2 = document.querySelectorAll(".markspanSuper");
                  addedElements2.forEach(function (element) {
                    element.remove();
                  });
                  for (let i = 0; i < changes.length; i++) {
                    let change = changes[i]
                    const className = `articleQuizHighlight_${change.color.replace("#", "")}`
                    articleContentDomInstance.mark(docs[change.p1 - 1], {
                      className,
                      separateWordSearch: false,
                      each: function (element) {
                        const spanSuper = document.createElement("span")
                        const span = document.createElement("span")
                        spanSuper.appendChild(span)
                        span.innerText = change.index
                        span.className = "markspan"
                        spanSuper.className = "markspanSuper"
                        spanSuper.style.cssText = `position: relative;
                        width: calc(18px* var(--ratio));
                        height: calc(18px* var(--ratio));
                        display: inline-block;
                        margin: 0 calc(8px* var(--ratio));`
                        span.style.cssText = `display: inline-block;
                        width: calc(22px * var(--ratio));
                        height: calc(22px * var(--ratio));
                        line-height: calc(22px * var(--ratio));
                        background: ${change.color};
                        color: #C1B8B9;
                        font-size: calc(12px * var(--ratio));
                        cursor: pointer;
                        text-align: center;
                        position: absolute;
                        font-weight: 500;
                        `
                        element.before(spanSuper)
                      }
                    });
                  }
                }
              })
            }}
            onClear={async () => {
              // 移除添加的元素
              const addedElements = document.querySelectorAll(".markspan");
              addedElements.forEach(function (element) {
                element.remove();
              });
              const addedElements2 = document.querySelectorAll(".markspanSuper");
              addedElements2.forEach(function (element) {
                element.remove();
              });
              const articleContentDomInstance = await articleAction.current.useArticleContentDomInstance()
              // const className = `articleVocaBularyHighlight_FFDADF`
              for (let c = 0; c < QuizMarkColors.length; c++) {
                const className = `articleQuizHighlight_${QuizMarkColors[c].replace("#", "")}`
                articleContentDomInstance.unmark({ className })
              }
            }}
          />
        </div>
      </Wrapper>
    </LoadingWrapper>
  )
}

const Wrapper = styled.div`
  height: calc(var(--vh) - calc(24px * var(--ratio)));
  background-color: #FFF7F8;
  padding-top: calc(16px * var(--ratio));
  padding-left: calc(32px * var(--ratio));
  padding-right: calc(32px * var(--ratio));
  padding-bottom: calc(8px * var(--ratio));
`

const ScoreAnimatedDiv = styled.div`
  color: #161A19;
  font-weight: 500;
  font-size: calc(16px * var(--ratio));
  text-align: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: calc(18px * var(--ratio));
`

const Points = styled.div`
  border-radius: calc(40px * var(--ratio));
  border: calc(1px * var(--ratio)) solid #DBD4D4;
  background: rgba(255, 255, 255, 0.50);
  height: calc(58px * var(--ratio));
  padding: 0 calc(32px * var(--ratio));
  display: flex;
  align-items: center;
`

export default Index