import { useEffect, useRef, useState, useImperativeHandle } from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from 'swiper/react';
import { SlideIcon } from '../../components/icons/vocabulary'
import Mark from "mark.js"


const Index = (props) => {
  const { article, tabarValue, next, miniWindow } = props
  const swiperClient = useRef()
  const imgDoms = useRef({})
  const contentDom = useRef()
  const [update, setUpdate] = useState()
  const [startRead, setStartRead] = useState(false)
  const [index, setIndex] = useState(0)
  const markInstance = useRef({})

  if (miniWindow && !startRead) {
    setStartRead(true)
  }

  const unmark = (option) => {
    for (let i in markInstance.current) {
      markInstance.current[i].unmark(option)
    }
  }

  const useArticleContentDomInstance = async () => {
    const _findTxt = (txt) => {
      for (let i = 0; i < article.page_content.length; i++) {
        let item = article.page_content[i]
        if (item.txt.indexOf(txt) !== -1) {
          const instance = markInstance.current[i]
          swiperClient.current.slideTo(i)
          return instance
        }
      }
    }
    const mark = (txt, option) => {
      const instance = _findTxt(txt)
      if (instance) {
        setStartRead(true)
        instance.mark(txt, option);
      }
    }
    return {
      mark,
      unmark
    }
  }

  useImperativeHandle(props.action, () => ({
    useArticleContentDomInstance
  }))


  useEffect(() => {
    const observer = new ResizeObserver(() => {
      setUpdate(new Date().getTime())
    })
    observer.observe(document.body)

    return () => {
      observer.disconnect()
    }

  }, [])

  return (
    <Wrapper>
      <ContentWrapper ref={contentDom} $miniWindow={miniWindow}>
        {
          startRead && <SlideIconWrapper style={{
            opacity: index === 0 && 0.3,
          }} onClick={(e) => {
            e.preventDefault()
            swiperClient.current.slidePrev()
          }} />
        }
        <div style={{ width: "calc(100% - 124px)", margin: "0 auto" }}>
          <CoverWrapper style={{ display: startRead ? "none" : "flex" }}>
            <Cover src={process.env.REACT_APP_OSS_HOST + article.img} alt="" />
          </CoverWrapper>
          <Swiper
            onSwiper={s => swiperClient.current = s}
            slidesPerView={1}
            onActiveIndexChange={d => {
              setIndex(d.activeIndex)
            }}
            style={{ height: "100%", display: !startRead ? "none" : "block" }}
          >
            {
              article?.page_content?.map((v, k) => (
                <SwiperSlide key={k}>
                  <img src={process.env.REACT_APP_OSS_HOST + v.img} style={{
                    maxWidth: "100%",
                    display: "block",
                    margin: "0 auto",
                    maxHeight: "100%"
                  }} alt="" ref={async e => {
                    if (e) {
                      imgDoms.current[k] = e
                      // if (e.clientWidth <= 300) {
                      //   e.style.float = "left"
                      // } else {
                      //   e.style.float = "none"
                      // }
                    }
                  }} />
                  <div style={{ display: "flex", justifyContent: "center" }} ref={e => {
                    if (e && imgDoms.current[k] && contentDom.current) {
                      let h = `${contentDom.current.clientHeight - e.clientHeight - 24}px`
                      if (e.clientHeight > contentDom.current.clientHeight) {
                        h = 0
                      }
                      imgDoms.current[k].style.maxHeight = h
                    }
                  }}>
                    <Txt ref={e => {
                      if (e && !markInstance.current[k]) {
                        markInstance.current[k] = new Mark(e);
                      }
                    }} dangerouslySetInnerHTML={{ __html: v.txt }}></Txt>
                  </div>
                </SwiperSlide>
              ))
            }
          </Swiper>
        </div>
        {
          startRead && <SlideIconWrapper style={{
            transform: "rotate(180deg)",
            right: 0,
            opacity: index + 1 === article?.page_content?.length && 0.3,
          }} onClick={(e) => {
            e.preventDefault()
            swiperClient.current.slideNext()
          }} />
        }
      </ContentWrapper>
      {
        !startRead && <Button onClick={() => setStartRead(true)}>Start to read</Button>
      }
      {
        (startRead && !miniWindow) && <Button $disabled={!(index + 1 === article?.page_content?.length) || tabarValue["vocabulary"] === 0} onClick={next}>Next Step</Button>
      }
    </Wrapper>
  )
}

const Wrapper = styled.div`
  
`

const ContentWrapper = styled.div`
  height: calc(var(--vh) - calc(${props => props.$miniWindow ? 314 : 406}px * var(--ratio)));
  position: relative;
`

const CoverWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
`

const SlideIconWrapper = styled(SlideIcon)`
  width: calc(50px * var(--ratio));
  height: calc(50px * var(--ratio));
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 9;
`

const Cover = styled.img`
  max-height: 100%;
  max-width: 100%;
`

const Txt = styled.div`
  color:#251F1F;
  font-feature-settings: 'clig' off, 'liga' off;
  /* Article */
  font-family: Inter;
  font-size: calc(18px * var(--ratio));
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  white-space: pre-wrap;
  margin-top: 8px;
`

const Button = styled.button`
  display: flex;
  height: calc(50px * var(--ratio));
  padding: 8px 0px;
  justify-content: center;
  align-items: center;
  border-radius: calc(26px * var(--ratio));
  background: #36B7B1;
  color: var(--white, var(--icon-color, #FFF));
  font-size: calc(20px * var(--ratio));
  font-style: normal;
  font-weight: 600;
  border: none;
  width: calc(260px * var(--ratio));
  pointer-events: ${props => props.$disabled ? "none" : "all"};
  opacity: ${props => props.$disabled ? 0.3 : 1};
  margin: 0 auto;
  margin-top: calc(40px * var(--ratio));
`

export default Index