import { useEffect, useRef, useState, useContext } from 'react';
import HandlePoint from '../../components/HandlePoint'
import BaiAddInputIcon from '../../static/img/20240227-100647.png'
import AddInputIcon from '../../static/img/20240226-174233.png'
import { MsgTypeV2WWWSearchSummary, MsgTypeV2WWWSearchSummaryRelated } from '../../utils/define'
import { message } from 'antd'
import styled from "styled-components";
import ShorAnswer from './short_answer'
import { Context as RequestContext } from '../request'

const Index = (props) => {
    const { id, onInputChange, onInputFocus, onDisabledFocus, xPos, yPos, onShorAnswerValue, onShorAnswerValueEnd, onInputSearch } = props
    const { value, disabled, shorAnswerData = { searchVal: '', showShorAnswer: false, value: '', context: '', sources: [] } } = props.data
    const times = useRef({})
    const [inputFocus, setInputFocus] = useState(false)
    const [mouseOver, setMouseOver] = useState(false)
    const inputRef = useRef()
    const requestCtx = useContext(RequestContext)
    const request = requestCtx.useRequest()

    useEffect(() => {
        const ts = times.current
        return () => {
            for (let i in ts) {
                clearTimeout(ts[i])
                ts[i] = null
            }
        }
    }, [])

    const getRelated = async (result) => {
        try {
            let info = await request.post('/client/api/message/send', {
                title: result.title,
                context: result.context,
                msg_type: MsgTypeV2WWWSearchSummaryRelated
            })
            info = JSON.parse(info)
            if (!info || (info && info.length === 0) || !Array.isArray(info)) {
                times.current[new Date().toString()] = setTimeout(async () => {
                    await getRelated(result)
                }, 1000)
            } else {
                //setRelated(info)
            }
        } catch (error) {
            console.log(error)
            times.current[new Date().toString()] = setTimeout(async () => {
                await getRelated(result)
            }, 1000)
        }
    }

    const onSearch = async (e) => {
        if (e.key === "Enter") {
            const searchVal = e.target.value
            if (!searchVal) {
                return
            }
            const shortAnswerId = `random-id-${new Date().getTime()}`
            onDisabledFocus({ id, disabled: true })
            setInputFocus(false)
            try {
                let info = await request.post("/client/api/message/send", {
                    title: searchVal,
                    msg_type: MsgTypeV2WWWSearchSummary,
                    node_id: shortAnswerId,
                    llm_prompt_id: 29
                })
                // getRelated()
                onInputSearch(info, searchVal, id, shortAnswerId)

                // setShowShorAnswer(true)
                // setShorAnswerData({
                //     id: shortAnswerId,
                //     context: info.context,
                //     sources: JSON.parse(info.sources)
                // })
            } catch (error) {
                message.error(error)
                onDisabledFocus({ id, disabled: false })
            }
        }
    }


    return (
        <div onMouseOver={() => setMouseOver(true)} onMouseOut={() => setMouseOver(false)}>
            <InputBox style={{
                border: inputFocus && '1.952px solid #FFA36F',
                boxShadow: inputFocus && '2px 2px 0px 0px #FFA36F'
            }}>
                <input ref={inputRef} onBlur={() => {
                    setInputFocus(false)
                }} onFocus={() => {
                    setInputFocus(true)
                    onInputFocus(props.id)
                }} disabled={!!disabled} onKeyPress={onSearch} value={value} onChange={e => onInputChange({ id: props.id, val: e.target.value })} placeholder='Ask me anything…' type="text" />
                <InputSearchBtnBox style={{
                    opacity: (!value || disabled) && 0.5,
                    background: (!value || disabled) && "#FFFFFF"
                }}>
                    <img onClick={() => onSearch({ key: 'Enter', target: inputRef.current })} style={{ width: 16, height: 16, pointerEvents: (!value || disabled) ? "none" : "auto" }} src={(!value || disabled) ? AddInputIcon : BaiAddInputIcon} alt='' />
                </InputSearchBtnBox>
            </InputBox>
            <div style={{ height: 24, display: shorAnswerData.showShorAnswer ? "block" : "none" }}></div>
            <div style={{ display: shorAnswerData.showShorAnswer ? "block" : "none" }}>
                <ShorAnswer
                    id={shorAnswerData.id}
                    onShorAnswerValue={(v) => {
                        onShorAnswerValue(id, v.msg)
                    }}
                    onShorAnswerValueEnd={(v) => {
                        onShorAnswerValueEnd({ id, xPos, yPos, context: v.context, title: v.title, getRelated: v.getRelated })
                    }}
                    data={{
                        ...shorAnswerData
                    }} />
            </div>
            <div>
                <HandlePoint mouseOver={mouseOver} id={id} />
            </div>
        </div>
    );
}

const InputSearchBtnBox = styled.div`
    border-radius: 4.645px;
    border: 1.512px solid #1F1D0F;
    background: #FF5251;
    box-shadow: 2px 2px 0px 0px #1F1D0F;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 16px;
`

const InputBox = styled.div`
    border-radius: 6px;
    border: 1.952px solid #1F1D0F;
    background: #FFF;
    box-shadow: 2px 2px 0px 0px #1F1D0F;
    display: flex;
    width: 508px;
    height: 36px;
    padding: 12px 16px;
    justify-content: space-between;
    align-items: center;
    input{
        color: #1F1D0F;
        font-family: "Roboto Flex";
        font-size: 14px;
        flex: auto;
    }
    input::placeholder{
        font-size: 14px;
        color: #BFBFBF;
    }
`


export default Index;
