

const Index = (props) => {
  const { disabled, ...others } = props
  return (
    <svg {...others} viewBox="0 0 108 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="1.1377" width="107" height="41" rx="20.5" fill={disabled ? "#F5F0F0" : "#B14E62"} />
      <line x1="45.0446" y1="21.1402" x2="62.9555" y2="21.1402" stroke={disabled ? "#D3CCCD" : "white"} strokeWidth="0.995054" />
      <line x1="54.4975" y1="12.6826" x2="54.4975" y2="30.5936" stroke={disabled ? "#D3CCCD" : "white"} strokeWidth="0.995054" />
    </svg>
  )
}

export default Index