import styled from "styled-components";
import { RadioChecked } from '../../components/icons/login'

export const Button = (props) => {
  const { disabled, ...others } = props
  return (
    <ButtonWrapper {...others} $disabled={disabled}>{props.children}</ButtonWrapper>
  )
}

const ButtonWrapper = styled.button`
  border: none;
  outline: none;
  border-radius: calc(30px * var(--ratio));
  background:${props => props.$disabled ? "#E1F7F6" : "#163C3C"};
  /* default */
  box-shadow: 0px calc(6px * var(--ratio)) calc(30px * var(--ratio)) calc(-4px * var(--ratio)) rgba(104, 100, 145, 0.14);
  width: 100%;
  height: calc(50px * var(--ratio));
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.$disabled ? "#C1B8B9" : "#FFF"};
  pointer-events: ${props => props.$disabled ? "none" : "all"};
  font-size: calc(20px * var(--ratio));
  font-style: normal;
  font-weight: 600;
  line-height: 1.5;
  cursor: pointer;
  &:focus{
    border: none;
    outline: none;
  }
`

export const Radio = (props) => {
  const { checked = false, onChecked = () => { } } = props
  return (
    <>
      {
        checked ? <RadioChecked onClick={() => {
          onChecked(!checked)
        }} style={{ width: "calc(18px * var(--ratio))", height: "calc(18px * var(--ratio))", cursor: "pointer" }} /> : <RadioUnChoice onClick={() => {
          onChecked(!checked)
        }} />
      }
    </>
  )
}

const RadioUnChoice = styled.div`
  border-radius: 50%;
  border: calc(2px * var(--ratio)) solid #6A9B9A;
  width: calc(14px * var(--ratio));
  height: calc(14px * var(--ratio));
  cursor: pointer;
`

export const Input = (props) => {
  const { after, label, ...others } = props
  return (
    <InputWrapper $l={label}>
      <input autoComplete="new-password"  {...others} />
      {
        after && <After>{after}</After>
      }
    </InputWrapper>
  )
}



const After = styled.div`
  color: #6A9B9A;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: calc(14px * var(--ratio));
  font-style: normal;
  font-weight: 400;
  line-height: 1.14;
  text-decoration-line: underline;
  position: absolute;
  right: calc(32px * var(--ratio));
  top: calc(-22px * var(--ratio));
  cursor: pointer;
`

export const InputWrapper = styled.div`
  border-radius: calc(40px * var(--ratio));
  border: calc(1px * var(--ratio)) solid #6A9B9A;
  background: #FFF;
  box-shadow: 0px calc(4px * var(--ratio)) calc(20px * var(--ratio)) calc(-14px * var(--ratio)) rgba(0, 0, 0, 0.25);
  width: 100%;
  height: calc(58px * var(--ratio));
  display: flex;
  align-items: center;
  position: relative;
  & input{
    margin-left: calc(32px * var(--ratio));
    width: 100%;
    height: 100%;
    margin-right: calc(24px * var(--ratio));
    color: #161A19;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: calc(20px * var(--ratio));
    font-style: normal;
    font-weight: 400;
    line-height: 1.4;
  }
  &:focus-within {
    border-color: #161A19; // 你希望焦点状态下的边框颜色
  }
  & input::placeholder{
    color: var(--Gray-400, #DBD4D4);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: calc(20px * var(--ratio));
    font-style: normal;
    font-weight: 400;
    line-height: 1.4;
  }
  &::before{
    content: "${props => props.$l}";
    position: absolute;
    color: var(--black, #161A19);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: calc(14px * var(--ratio));
    font-style: normal;
    font-weight: 500;
    line-height: 1.14;
    left: calc(32px * var(--ratio));
    top: calc(-22px * var(--ratio));
  }
`


