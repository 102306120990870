import styled from "styled-components";
import BackIcon from '../../components/icons/back'
import { GoogleLoginIcon, OrEmailIcon } from '../../components/icons/login'
import { useEffect, useImperativeHandle, useRef, useState, useContext } from 'react'
import { Input, Radio, Button, InputWrapper } from './components'
import { Context as RouterContext } from '../../router'
import { Context as RequestContext } from '../request'
import { useNavigate } from "react-router-dom";
import { oauth2SignIn } from '../login_google'

export const InputCode = (props) => {
  const { email, label, onError = () => { }, onChange = () => { }, value = '' } = props
  const [code, setCode] = useState('')
  const [optTxt, setOptTxt] = useState('Get a OTP')
  const [codeBtnloading, setCodeBtnloading] = useState(false)
  const [errMsg, setErrMsg] = useState("")
  const requestCtx = useContext(RequestContext)
  const request = requestCtx.useRequest(false)
  const timer = useRef()

  useEffect(() => {


    return () => {
      clearTimeout(timer.current)
    }
  }, [])

  const countDown = (num) => {
    num--
    if (num === 0) {
      setOptTxt(`Get a OTP`)
      setCodeBtnloading(false)
      return
    }
    timer.current = setTimeout(() => {
      setOptTxt(`${num}s`)
      countDown(num)
    }, 1000)
  }

  const getOpt = async () => {
    if (!email) {
      return
    }
    onError('')
    setCodeBtnloading(true)
    setOptTxt('sending')
    try {
      await request.post(`/client/open/code/send?email=${email}`)
      countDown(61)
    } catch (error) {
      setOptTxt('Get a OTP')
      setCodeBtnloading(false)
      onError(error)
    }
  }


  return (
    <InputWrapper $l={label}>
      <input placeholder="Enter the OTP" value={value} onChange={onChange} autoComplete="new-password" />
      <OtpButton $disabled={!email || codeBtnloading} onClick={getOpt}>{optTxt}</OtpButton>
    </InputWrapper>
  )
}

const OtpButton = styled.div`
  border-radius: calc(26px * var(--ratio));
  background: ${props => props.$disabled ? "#F7F5F5" : "#EFE9E9"};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.$disabled ? "rgba(22, 26, 25, 0.60)" : "#161A19"};
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: calc(14px * var(--ratio));
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  width: calc(128px * var(--ratio));
  height: calc(36px * var(--ratio));
  margin-right: calc(12px * var(--ratio));
  flex-shrink: 0;
  pointer-events: ${props => props.$disabled ? "none" : "all"};
`

const Index = (props) => {
  const [agree, setAgree] = useState(true)
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState("")
  const [code, setCode] = useState("")
  const rctx = useContext(RouterContext)
  const requestCtx = useContext(RequestContext)
  const request = requestCtx.useRequest()
  const [errMsg, setErrMsg] = useState("")
  const [codeErrMsg, setCodeErrMsg] = useState("")
  const n = useNavigate()

  useEffect(() => {


    rctx?.onresize()
  }, [])

  const onSubmit = async (e) => {
    e.preventDefault()
    setErrMsg("")
    setLoading(true)
    try {
      const no = await request.post("/client/open/user/register/pre", {
        email,
        code
      })
      n(`/register/pre/${no}`)
    } catch (error) {
      setErrMsg(error)
    }
    setLoading(false)
  }


  return (
    <Wrapper>
      <div style={{ display: "flex", height: "100%" }}>
        <div style={{ width: "calc(var(--vw) - calc(714px * var(--ratio)))", flexShrink: 0 }}>
          <img src="https://file.bigread.ai/img/login.png" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover" }} />
        </div>
        <Content>
          <div style={{ height: "calc(157px * var(--ratio))" }}></div>
          <Title>Sign up</Title>
          <div style={{ background: "#CFF1F0", height: "calc(1px * var(--ratio))", margin: "calc(24px * var(--ratio)) 0" }}></div>
          <GoogleLoginIcon onClick={oauth2SignIn} style={{ width: "calc(490px * var(--ratio))", height: "calc(60px * var(--ratio))", marginBottom: "calc(24px * var(--ratio))" }}></GoogleLoginIcon>
          <div style={{ display: 'flex', justifyContent: "center", marginBottom: "calc(46px * var(--ratio))" }}>
            <OrEmailIcon style={{ width: "calc(490px * var(--ratio))", height: "calc(20px * var(--ratio))" }}></OrEmailIcon>
          </div>
          <form onSubmit={onSubmit}>
            <Input value={email} onChange={e => {
              setEmail(e.target.value)
            }} label="Email" placeholder="Enter the Email" />
            <div style={{ height: "calc(8px * var(--ratio))" }}></div>
            <InputCode onError={err => setCodeErrMsg(err)} email={email} value={code} onChange={e => {
              setCode(e.target.value)
            }} />
            {
              codeErrMsg && <div style={{ color: "#DC362E", fontSize: "calc(14px * var(--ratio))", fontWeight: 500, lineHeight: 1.14, textAlign: "center", marginTop: "calc(8px * var(--ratio))", paddingLeft: "calc(32px * var(--ratio))" }}>{codeErrMsg}</div>
            }
            <div style={{ height: "calc(24px * var(--ratio))" }}></div>
            <div style={{ paddingLeft: "calc(24px * var(--ratio))" }}>
              <div style={{ height: "calc(11px * var(--ratio))" }}></div>
              <div style={{ display: "flex", alignItems: "start" }}>
                <div style={{ marginRight: "calc(8px * var(--ratio))" }}><Radio checked={agree} onChecked={e => setAgree(e)} /></div>
                <div style={{ color: "#161A19", fontSize: "calc(12px * var(--ratio))", fontWeight: 400, lineHeight: 1.3 }}>Our application may share data with third-party tools (such as ChatGPT).By continuing, you indicate that you have read and agree to our <a style={{ color: "#2F64C7", textDecoration: "none" }} href='https://www.bigread.ai/about/terms-and-conditions.html' target="_blank" rel="noreferrer">Terms of Use</a> & <a style={{ color: "#2F64C7", textDecoration: "none" }} href='https://www.bigread.ai/about/privacy-policy.html' target="_blank" rel="noreferrer">Privacy Policy</a></div>
              </div>
              <div style={{ height: "calc(24px * var(--ratio))" }}></div>
            </div>
            <Button type="submit" disabled={!code || !email || !agree || loading}>{loading ? "loading..." : "Create an account"}</Button>
            {
              errMsg && <div style={{ color: "#DC362E", fontSize: "calc(14px * var(--ratio))", fontWeight: 500, lineHeight: 1.14, textAlign: "center", marginTop: "calc(8px * var(--ratio))" }}>{errMsg}</div>
            }
            <div style={{ height: "calc(32px * var(--ratio))" }}></div>
            <div style={{ color: "#161A19", fontSize: "calc(14px * var(--ratio))", fontWeight: 400, lineHeight: 1.42, textAlign: "center" }}>Already Have An Account? <span onClick={() => n("/login")} style={{ color: "#6A9B9A" }}>Log In</span></div>

          </form>
        </Content>
      </div>

    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: #FFF;
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
`

const Content = styled.div`
  width: calc(490px * var(--ratio));
  padding: calc(40px * var(--ratio));
  flex-shrink: 0;
  margin-left: calc(72px * var(--ratio));
`

const Title = styled.div`
  color: var(--black, #161A19);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: calc(48px * var(--ratio));
  font-style: normal;
  font-weight: 800;
  line-height: 1.16;
  padding-left: calc(32px * var(--ratio));
`

const LogoWrapper = styled.div`
    display: flex;
    align-items: center;
    padding-left: calc(32px * var(--ratio));
    padding-top: calc(32px * var(--ratio));
`

const LogoTxt = styled.span`
    color: #161A19;
    font-size: calc(20px * var(--ratio));
    font-style: normal;
    font-weight: 500;
`


export default Index