import React, { useState, useEffect, useContext, useRef } from "react";
import * as apis from '../../lib/api'
import WebsocketHeartbeatJs from 'websocket-heartbeat-js';
import { MsgStatusErr, MsgTypePing, MsgTypeNotVip } from '../../utils/define'
import mitt from 'mitt'
import { Context as RequestContext } from '../request'
import { useNavigate } from "react-router-dom";

export const Context = React.createContext({})

export const EventEmitter = mitt()

const Index = (props) => {
  const [userInfo, setUserInfo] = useState({})
  const _userInfo = useRef()
  const n = useNavigate()
  const emitter = useRef(mitt())
  const socket = useRef()
  const evtSource = useRef()
  const requestCtx = useContext(RequestContext)
  const request = requestCtx.useRequest()

  const useEventSource = () => {
    return new Promise((resolve, reject) => {
      if (evtSource.current) {
        resolve(evtSource.current)
        return
      }
      evtSource.current = new EventSource(`${process.env.REACT_APP_API_HOST}${apis.MESSAGE_SUB}?token=${localStorage.getItem("token")}`);
      evtSource.current.onmessage = (event) => {
        let data = JSON.parse(event.data)
        if (data.msg_type === "ping") {
          return
        }
        EventEmitter.emit("message", data);
      };
      evtSource.current.onopen = () => {
        resolve(evtSource.current)
      }
      evtSource.current.onerror = (e) => {
        reject(e)
      }
      evtSource.current.onclose = (e) => {
        reject(e)
      }
    })

  }



  const useCollectOnlineTime = (joinTime = new Date().getTime(), from = "ARTICLE") => {
    const _documentClick = () => {
      const curTime = new Date().getTime()
      const onlineTime = curTime - joinTime
      if (onlineTime <= 5 * 60 * 1000) {
        const time_sec = parseInt(onlineTime / 1000)
        if (time_sec > 0) {
          request.post("/client/v2/api/learn/times/save", {
            time_sec,
            from
          })
        }
      }
      joinTime = new Date().getTime()
    }
    document.addEventListener("click", _documentClick)
    return () => {
      document.removeEventListener("click", _documentClick)
    }
  }

  const unUseEventSource = () => {
    if (!evtSource.current) {
      return
    }
    evtSource.current.close()
    evtSource.current = null
  }

  const useUserInfo = async () => {
    if (_userInfo.current) {
      return _userInfo.current
    }
    _userInfo.current = await loadUserInfo()
    return _userInfo.current
  }

  const loadUserInfo = async () => {
    try {
      let userInfo = await request.get("/client/v2/api/user/info")
      _userInfo.current = userInfo
      setUserInfo(userInfo)
      return userInfo
    } catch (error) {

    }
  }

  // const offLine = () => {
  //   const onlinePoint = document.getElementById("onlinePoint")
  //   onlinePoint.style.background = "red"
  // }

  // const onLine = () => {
  //   const onlinePoint = document.getElementById("onlinePoint")
  //   onlinePoint.style.background = "green"
  //   onlineTime.current = new Date().getTime()
  // }

  const reConnect = () => {
    // const onlinePoint = document.getElementById("onlinePoint")
    // onlinePoint.style.background = "yellow"
    //onlineTime.current = new Date().getTime()
  }

  // const checkOnline = () => {
  //   fid.current = requestAnimationFrame(async () => {
  //     if (!onlineTime.current) {
  //       offLine()
  //     } else {
  //       if (new Date().getTime() - onlineTime.current > 6000) {
  //         offLine()
  //       }
  //     }
  //     checkOnline()
  //   })
  // }

  const useSocket = (from = '') => {
    return new Promise((resolve, reject) => {
      if (socket.current) {
        resolve(socket.current)
        return
      }
      // checkOnline()
      console.log("链接==========================Socket")
      socket.current = new WebsocketHeartbeatJs({
        url: `${process.env.REACT_APP_API_HOST.replace("https", "wss")}/client/v2/api/message/sub?token=${localStorage.getItem("token")}&from=${from}`,
        pingTimeout: 3000,
        pongTimeout: 5000,
        pingMsg: () => JSON.stringify({ "msg_type": MsgTypePing })
      });
      socket.current.onopen = function () {
        resolve(socket.current)
      }
      socket.current.onmessage = function (e) {
        try {
          const data = JSON.parse(e.data)
          console.log(`onmessage: ${e.data}`);
          if (data.msg_status === MsgStatusErr) {
            window.gLoading(false)
            alert(data.msg)
            return
          }
          if (data.msg_type === MsgTypeNotVip) {
            n("/pay")
            return
          }
          if (data.msg_type === MsgTypePing) {
            // onLine()
            return
          }
          emitter.current.emit(data.msg_type, { msg: data.msg, data })
        } catch (error) {
          console.error(error)
          alert("An error has occurred")
        }
      }
      socket.current.onerror = function (e) {
        console.log(`onerror: `, e);
        reject(e)
        // offLine()
      }
      socket.current.onclose = function (e) {
        reject(e)
        // offLine()
      }
      socket.current.onreconnect = function () {
        console.log('reconnecting...');
        // reConnect()
      }
    })
  }

  const unUseSocket = () => {
    if (!socket.current) {
      return
    }
    console.log("关闭==========================Socket")
    socket.current.close()
    socket.current = null
  }

  const useEmitter = () => {
    return emitter.current
  }

  useEffect(() => {
    loadUserInfo()

  }, [])

  return (
    <Context.Provider value={{
      userInfo,
      loadUserInfo,
      useEmitter,
      useSocket,
      unUseSocket,
      useEventSource,
      unUseEventSource,
      useUserInfo,
      useCollectOnlineTime,
    }}>{props.children}</Context.Provider>
  )
}

export default Index