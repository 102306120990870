
import { useEffect, useState } from "react";

export const useTrackVolume = (stream) => {
  const [volume, setVolume] = useState(0);
  useEffect(() => {
    if (!stream) {
      return;
    }

    const ctx = new AudioContext();
    const source = ctx.createMediaStreamSource(stream);
    const analyser = ctx.createAnalyser();
    analyser.fftSize = 32;
    analyser.smoothingTimeConstant = 0;
    source.connect(analyser);

    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const updateVolume = () => {
      analyser.getByteFrequencyData(dataArray);
      let sum = 0;
      for (let i = 0; i < dataArray.length; i++) {
        const a = dataArray[i];
        sum += a * a;
      }
      setVolume(Math.sqrt(sum / dataArray.length) / 255);
    };

    const interval = setInterval(updateVolume, 1000 / 30);

    return () => {
      source.disconnect();
      clearInterval(interval);
    };
  }, [stream]);

  return volume;
};

const normalizeFrequencies = (frequencies) => {
  const normalizeDb = (value) => {
    const minDb = -100;
    const maxDb = -10;
    let db = 1 - (Math.max(minDb, Math.min(maxDb, value)) * -1) / 100;
    db = Math.sqrt(db);

    return db;
  };

  // Normalize all frequency values
  return frequencies.map((value) => {
    if (value === -Infinity) {
      return 0;
    }
    return normalizeDb(value);
  });
};

export const multibandTrackVolume = (
  stream,
  bands = 5,
  loPass = 100,
  hiPass = 600,
  onFrequencies = () => { }
) => {
  if (!stream) {
    return;
  }
  const ctx = new AudioContext();
  const source = ctx.createMediaStreamSource(stream);
  const analyser = ctx.createAnalyser();
  analyser.fftSize = 2048;
  source.connect(analyser);
  const bufferLength = analyser.frequencyBinCount;
  const dataArray = new Float32Array(bufferLength);
  const updateVolume = () => {
    analyser.getFloatFrequencyData(dataArray);
    let frequencies = new Float32Array(dataArray.length);
    for (let i = 0; i < dataArray.length; i++) {
      frequencies[i] = dataArray[i];
    }
    frequencies = frequencies.slice(loPass, hiPass);

    const normalizedFrequencies = normalizeFrequencies(frequencies);
    const chunkSize = Math.ceil(normalizedFrequencies.length / bands);
    const chunks = [];
    for (let i = 0; i < bands; i++) {
      chunks.push(
        normalizedFrequencies.slice(i * chunkSize, (i + 1) * chunkSize)
      );
    }
    onFrequencies(chunks)
  };
  const interval = setInterval(updateVolume, 10);

  return () => {
    source.disconnect();
    clearInterval(interval);
  };
};
