import { useEffect, useRef, useState, useContext } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from "swiper";
import { EffectCards } from 'swiper/modules';
import { cloneDeep } from "lodash";
import FlipIcon from '../../static/img/20240119-162317.png'
import ReadIcon from '../../static/img/20240119-162403.png'
import HandlePoint from '../HandlePoint'
import { Skeleton } from 'antd'
import styled from "styled-components";
import { Context as RequestContext } from '../../pages/request'

const wordsCardColors = ["#F5FFCE", "#E1F8FF", "#EFE3FF"]

const Index = (props) => {
    let { data = [], id } = props
    if (id) { //画板模式
        data = props.data.value || []
    }
    const [worldSwiperIndexs, setWorldSwiperIndexs] = useState(0)
    const [mouseOver, setMouseOver] = useState(false)
    const worldSwiperRef = useRef({})
    const [worldsParse, setWorldsParse] = useState({})
    const [marked, setMarked] = useState({})
    const requestCtx = useContext(RequestContext)
    const request = requestCtx.useRequest()


    useEffect(() => {
        if (data && data.length !== 0) {
            setTimeout(() => {
                const words = []
                for (let i = 0; i < data.length; i++) {
                    words.push(data[i].word)
                }
                request.post('/client/api/article/word/marked/get', words).then(marked => {
                    setMarked(marked)
                    setTimeout(() => {
                        worldSwiperRef.current.update()
                    }, 300)
                })
            }, 300)

        }
    }, [data])

    const playWorld = async (v) => {
        let a = new Audio()
        const ret = await request.post('/client/api/tts/make', {
            role: "TTS_WORD_CARD",
            content: v.word
        })
        a.src = process.env.REACT_APP_OSS_HOST + ret
        a.play()
    }

    const showWorldsParse = async (world, show) => {
        let cpWorldsParse = cloneDeep(worldsParse)
        if (show) {
            cpWorldsParse[world] = true
            setWorldsParse(cpWorldsParse)
        } else {
            if (cpWorldsParse[world]) {
                delete cpWorldsParse[world]
            }
            setWorldsParse(cpWorldsParse)
        }
    }

    return (
        <div onMouseOver={() => setMouseOver(true)} onMouseOut={() => setMouseOver(false)}>
            {
                id && <div>
                    {
                        (!data || (data && data.length === 0)) && <Skeleton active paragraph={{ rows: 10 }} />
                    }
                </div>
            }
            <div style={{ height: 32 }}></div>
            <Swiper
                onSwiper={s => {
                    worldSwiperRef.current = s
                    SwiperCore.use([EffectCards])
                }}
                modules={[EffectCards]}
                effect="cards"
                cardsEffect={{
                    slideShadows: false
                }}
                onActiveIndexChange={d => {
                    setWorldSwiperIndexs(d.activeIndex)
                }}
            >
                {
                    data.map((v2, k2) => (
                        <SwiperSlide key={k2}>
                            <SwiperSlideItem style={{
                                transform: worldsParse[v2.word] ? "rotateY(180deg)" : "rotateY(0deg)",
                            }} $index={k2} onClick={() => {
                                showWorldsParse(v2.word, !worldsParse[v2.word])
                            }}>
                                <FlipImg style={{
                                    left: worldsParse[v2.word] ? "unset" : 20,
                                    right: worldsParse[v2.word] ? 20 : "unset",
                                    transform: worldsParse[v2.word] ? "rotateY(180deg)" : "rotateY(0deg)",
                                }} src={FlipIcon} alt="" />
                                <div style={{
                                    display: worldsParse[v2.word] ? "none" : "block"
                                }}>
                                    <span onClick={(e) => {
                                        e.stopPropagation()
                                        setMarked(pre => {
                                            const cpPre = cloneDeep(pre)
                                            cpPre[v2.word] = !cpPre[v2.word]
                                            if (cpPre[v2.word]) {
                                                request.post('/client/api/article/word/marked', {
                                                    word: v2.word,
                                                    hash_id: id
                                                }).catch(() => { })
                                            } else {
                                                request.post(`/client/api/article/word/marked/cancel?word=${v2.word}`, {

                                                }).catch(() => { })
                                            }
                                            return cpPre
                                        })
                                    }} style={{
                                        width: 80,
                                        position: "absolute",
                                        bottom: 20,
                                        left: 20,
                                        cursor: "pointer",
                                        textAlign: "center",
                                        fontSize: 14,
                                        background: "#A3F799",
                                        border: "1px solid #1F1D0F",
                                        height: 32,
                                        lineHeight: "32px"
                                    }}>{marked[v2.word] ? "Marked" : "New word"}</span>
                                    <img onClick={(e) => {
                                        e.stopPropagation()
                                        playWorld(v2)
                                    }} style={{
                                        width: 40,
                                        position: "absolute",
                                        bottom: 20,
                                        right: 20,
                                        cursor: "pointer"
                                    }} src={ReadIcon} alt="" />
                                    <div style={{
                                        fontFamily: "Roboto Flex800",
                                        fontSize: 36,
                                        height: 36,
                                        lineHeight: 1,
                                        position: "absolute",
                                        left: 0,
                                        right: 0,
                                        top: 0,
                                        bottom: 0,
                                        margin: "auto",
                                        textAlign: "center",
                                    }}>{v2.word}</div>
                                </div>
                                <div style={{
                                    display: worldsParse[v2.word] ? "block" : "none",
                                    position: "absolute",
                                    top: 106,
                                    width: "80%",
                                    left: 0,
                                    right: 0,
                                    margin: "auto",
                                    transform: worldsParse[v2.word] ? "rotateY(180deg)" : "rotateY(0deg)",
                                }}>
                                    <span style={{
                                        fontFamily: "Roboto Flex800",
                                        fontSize: 24,
                                    }}>{v2?.pos}.</span>
                                    <br />
                                    <span style={{
                                        fontFamily: "Roboto Flex400",
                                        fontSize: 20,
                                        lineHeight: "32px"
                                    }}>{v2?.definition}</span>
                                </div>
                            </SwiperSlideItem>
                        </SwiperSlide>
                    ))
                }
            </Swiper>
            <BottomBtn $hidden={(!data || (data && data.length === 0))}>
                <WordCardBtn style={{ background: worldSwiperIndexs === 0 && "#CCCCCC" }} onClick={(e) => {
                    e.preventDefault()
                    worldSwiperRef.current.slidePrev()
                }}>Previous</WordCardBtn>
                <WordCardNum>{isNaN(worldSwiperIndexs) ? 1 : worldSwiperIndexs + 1}/{data.length}</WordCardNum>
                <WordCardBtn style={{ background: worldSwiperIndexs + 1 === data.length && "#CCCCCC" }} onClick={(e) => {
                    e.preventDefault()
                    worldSwiperRef.current.slideNext()
                }}>Next</WordCardBtn>
            </BottomBtn>
            {
                id && <HandlePoint mouseOver={mouseOver} id={id} />
            }
        </div>
    )
}

const BottomBtn = styled.div`
    display: ${props => props.$hidden ? "none" : "flex"};
    align-items: center;
    justify-content: center;
    margin: 32px 0px;
`

const SwiperSlideItem = styled.div`
    width: 70%;
    max-width: 410px;
    height: 298px;
    background: ${props => wordsCardColors[props.$index % wordsCardColors.length]};
    border-radius: 6px;
    border: 2px solid rgb(31, 29, 15);
    margin: 0px auto;
    position: relative;
    transform-style: preserve-3d;
    transition: transform 0.6s ease 0s;
`
const FlipImg = styled.img`
    width: 40px;
    position: absolute;
    top: 20px;
    cursor: pointer;
`

const WordCardNum = styled.div`
    color: #1F1D0F;
    text-align: right;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 20px;
    font-style: normal;
    font-family: "Roboto Flex600";
    line-height: 22px; /* 110% */
    margin-left: 36px;
    margin-right: 36px;
`

const WordCardBtn = styled.div`
    border-radius: 6px;
    border: 2px solid #1F1D0F;
    background: #FFF978;
    box-shadow: 4px 4px 0px 0px #1F1D0F;
    color: #1F1D0F;
    text-align: right;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-family: "Roboto Flex600";
    line-height: 22px; /* 137.5% */
    width: 128px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    display: inline-block;
    cursor: pointer;
    user-select: none;
`



export default Index