import ZoomOutIcon from '../../static/img/20240306-183118.png'
import ZoomInIcon from '../../static/img/20240306-183218.png'
import { useReactFlow } from 'reactflow'
import { useEffect, useState, useImperativeHandle } from 'react'
import { emitter } from './index'
import styled from "styled-components";


const Index = (props) => {
    const [zoomVal, setZoomVal] = useState(1)
    const flow = useReactFlow();
    const { zoomIn, zoomOut, getZoom, zoomTo } = flow
    // console.log(flow.getRect)
    // setViewport

    const updateZoomVal = () => {
        setZoomVal(getZoom())
    }

    useImperativeHandle(props.action, () => ({
        setViewport: async ({ x, y, dur }) => {
            flow.setViewport({ x, y, zoom: getZoom() }, { duration: dur })
        },
        getFlow: async () => {
            return flow
        },
        updateZoomVal,
        moveToNodeId: (nodeId) => {
            // 获取节点的位置
            const node = flow.getNode(nodeId);
            if (node) {
                const flowWidth = document.body.clientWidth
                const flowHeight = document.body.clientHeight

                const nodeWidth = node.width
                const nodeHeight = node.height

                const nodePosition = node.position

                // 假设我们希望在不改变缩放级别的情况下居中视图
                // 如果您需要考虑缩放级别，则需要获取并应用当前缩放比例
                const zoomLevel = 1
                zoomTo(zoomLevel)
                // console.log(zoomLevel)

                // const { x, y } = flow.project(nodePosition);

                // 计算居中的节点位置时，应根据缩放级别调整宽度和高度
                // const centeredX = -x + (width / 2) / zoomLevel;
                // const centeredY = -y + (height / 2) / zoomLevel;
                // 计算偏移
                const centeredX = -(nodePosition.x - flowWidth / 2 + nodeWidth / 2) * zoomLevel;
                const centeredY = -(nodePosition.y - flowHeight / 2 + nodeHeight / 2) * zoomLevel;

                // 使用ReactFlow的setTransform方法更新视图
                flow.setViewport({ x: centeredX, y: centeredY, zoom: zoomLevel }, { duration: 300 });
                setZoomVal(zoomLevel)
            }

            // const node = flow.getNode(nodeId);
            // if (node) {
            //     console.log("node",node)
            //     let {  width, height } = node;
            //     const { x, y } =  node.position;
            //     height = 1100
            //     // 你可能需要考虑节点的高度和宽度以及zoom级别来决定最舒服的视野
            //     const newX = x / 2 + width;
            //     const newY = y / 2 - height;


            //     flow.setViewport({
            //         x: newX,
            //         y: newY,
            //         zoom: getZoom(), // 保持当前的缩放级别
            //     },{duration: 300});
            // } else {
            //     console.error('Node with the given ID not found');
            // }
        }
    }));

    const setViewport = ({ x, y, dur }) => {
        flow.setViewport({ x, y, zoom: getZoom() }, { duration: dur })
    }

    useEffect(() => {
        emitter.on('setViewport', setViewport)
        return () => {
            emitter.off('setViewport', setViewport)
        }

        // eslint-disable-next-line
    }, [])

    return (
        <ZoomBox>
            <div onClick={() => {
                zoomOut()
                setTimeout(() => {
                    setZoomVal(getZoom())
                }, 300)
            }} style={{ width: 44, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '2px solid #1F1D0F', cursor: 'pointer' }}>
                <img style={{ width: 24, height: 24 }} src={ZoomOutIcon} alt='' />
            </div>
            <div style={{
                color: '#1F1D0F',
                fontFamily: "Roboto Slab800",
                fontSize: 16
            }}>{parseInt(zoomVal * 100)}%</div>
            <div onClick={() => {
                zoomIn()
                setTimeout(() => {
                    setZoomVal(getZoom())
                }, 300)
            }} style={{ width: 44, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderLeft: '2px solid #1F1D0F', cursor: 'pointer' }}>
                <img style={{ width: 24, height: 24 }} src={ZoomInIcon} alt='' />
            </div>
        </ZoomBox>
    )
}

const ZoomBox = styled.div`
    border-radius: 6px;
    border: 2px solid #1F1D0F;
    background: #FFF;
    height: 40px;
    width: 152px;
    position: fixed;
    right: 16px;
    top: 16px;
    box-shadow: 2px 2px 0px 0px #1F1D0F;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 9;
`

export default Index