import styled from "styled-components";
import { Context as RouteContext } from '../../router'
import { Context as RequestContext } from '../request'
import React, { useState, useRef, useContext, useEffect } from "react";
import { Context as AuthContext } from '../auth'
import LexileLevelProgression from './LexileLevelProgression'
import SessionsSndSkills from './SessionsSndSkills'
import ReadingTime from './ReadingTime'
import Tab from '../../components/Tab'
import { LoadingWrapper } from '../../utils/full_loading'
import { cloneDeep } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";

const Index = () => {
  const routeCtx = useContext(RouteContext)
  const authCtx = useContext(AuthContext)
  const requestCtx = useContext(RequestContext)
  const request = requestCtx.useRequest()
  const [loading, setLoading] = useState(true)
  const [tab, setTab] = useState("-1")
  const [baseData, setBaseData] = useState({
    total_star: 0,
    read_num: 0,
    quiz_sum: 0,
    quiz_correct_sum: 0
  })
  const l = useLocation()
  const n = useNavigate()

  useEffect(() => {
    const urlParam = new URLSearchParams(l.search)
    const tab = urlParam.get("tab")
    routeCtx.onresize()
    if (tab) {
      setTab(tab)
    } else {
      n(`/progress?tab=1`)
    }

  }, [l])


  const getReadFinishArticles = async () => {
    const _data = await request.get("/client/v2/api/statistics/read_finish_articles") || []
    setBaseData(pre => {
      const cpPre = cloneDeep(pre)
      cpPre.read_num = _data.length
      for (let v of _data) {
        cpPre.quiz_sum += v.quiz_sum
        cpPre.quiz_correct_sum += v.quiz_correct_num
        cpPre.total_star += v.star
      }
      return cpPre
    })
    setLoading(false)
  }


  useEffect(() => {
    getReadFinishArticles()


  }, [])

  return (
    <Wrapper>
      <LoadingWrapper bgColor="#EBEEF5" loading={loading} position="absolute">
        <div style={{ paddingLeft: "calc(8px * var(--ratio))", paddingRight: "calc(8px * var(--ratio))", paddingTop: "calc(16px * var(--ratio))" }}>
          <Header>
            <HeaderItem>
              <HeaderItemTitle>{authCtx?.userInfo?.pro_file_name}</HeaderItemTitle>
              <HeaderItemSubTitle>{authCtx?.userInfo?.grade}</HeaderItemSubTitle>
            </HeaderItem>
            <HeaderItem>
              <HeaderItemTitle>{authCtx?.userInfo?.ar_level}</HeaderItemTitle>
              <HeaderItemSubTitle>AR Level</HeaderItemSubTitle>
            </HeaderItem>
            <HeaderItem>
              <HeaderItemTitle>{authCtx?.userInfo?.lexile} <span style={{ color: "#6E6566", fontSize: "calc(13px * var(--ratio))", fontWeight: 500 }}>L</span></HeaderItemTitle>
              <HeaderItemSubTitle>Lexile Level</HeaderItemSubTitle>
            </HeaderItem>
            <HeaderItem>
              <HeaderItemTitle>{baseData.total_star} <span style={{ color: "#6E6566", fontSize: "calc(13px * var(--ratio))", fontWeight: 500 }}>Points</span></HeaderItemTitle>
              <HeaderItemSubTitle>Total Points</HeaderItemSubTitle>
            </HeaderItem>
            <HeaderItem>
              <HeaderItemTitle>{baseData.read_num} <span style={{ color: "#6E6566", fontSize: "calc(13px * var(--ratio))", fontWeight: 500 }}>Materials</span></HeaderItemTitle>
              <HeaderItemSubTitle>Reading History</HeaderItemSubTitle>
            </HeaderItem>
            <HeaderItem>
              <HeaderItemTitle style={{ color: "#59BC57" }}>{baseData.quiz_correct_sum}<span style={{ color: "#6E6566", fontSize: "calc(13px * var(--ratio))", fontWeight: 500 }}>/{baseData.quiz_sum}</span></HeaderItemTitle>
              <HeaderItemSubTitle>Quizzes Passed</HeaderItemSubTitle>
            </HeaderItem>
          </Header>
          <Content>
            <ContentTop>
              {
                tab === "1" && <LineTag><span></span>My growth path</LineTag>
              }
              {
                tab === "2" && <LineTag style={{ flexDirection: "column", justifyContent: "center", alignItems: "self-start" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ width: "calc(10px * var(--ratio))", height: "calc(10px * var(--ratio))", marginRight: "calc(8px * var(--ratio))", background: "#FAA4B1" }}></div>
                    <div>Leveled Reading</div>
                  </div>
                  <div style={{ height: "calc(8px * var(--ratio))" }}></div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ width: "calc(10px * var(--ratio))", height: "calc(10px * var(--ratio))", marginRight: "calc(8px * var(--ratio))", background: "#35B7B2" }}></div>
                    <div>Endless Learning</div>
                  </div>
                </LineTag>
              }

              <Tab style={{
                height: "calc(44px * var(--ratio))",
              }} value={tab} onChange={v => {
                n("/progress?tab=" + v)
              }} options={[{
                label: "AR & Lexile Level Progression",
                value: "1"
              }, {
                label: "Reading time by day",
                value: "2"
              }, {
                label: "Sessions and skills",
                value: "3"
              }]} />
              <div></div>
            </ContentTop>
            <TableContent>
              <LexileLevelProgression show={tab === "1"}></LexileLevelProgression>
              <ReadingTime show={tab === "2"}></ReadingTime>
              <SessionsSndSkills show={tab === "3"}></SessionsSndSkills>
            </TableContent>
          </Content>
        </div>
      </LoadingWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: var(--Progress-1, linear-gradient(180deg, #EBEEF5 0%, #FAF1E9 100%));
  backdrop-filter: blur(calc(250px * var(--ratio)));
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
`

const LineTag = styled.div`
  color: #161A19;
  font-size: calc(13px * var(--ratio));
  font-style: normal;
  font-weight: 300;
  & span{
    display: inline-block;
    width: calc(30px * var(--ratio));
    height: calc(1px * var(--ratio));
    background: #146A67;
    margin-right: calc(8px * var(--ratio));
  }
  display: flex;
  align-items: center;
  left: calc(70px * var(--ratio));
  top: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
`

const Content = styled.div`
  border-radius: calc(30px * var(--ratio));
  background: rgba(255, 255, 255, 0.40);
  box-shadow: 0px calc(4px * var(--ratio)) calc(40px * var(--ratio)) calc(-28px * var(--ratio)) rgba(18, 18, 18, 0.65);
  backdrop-filter: blur(calc(20px * var(--ratio)));
  height: calc(var(--vh) - var(--head-height) - calc(130px * var(--ratio)));
  /* overflow: hidden; */
`

const TableContent = styled.div`
  height: calc(var(--vh) - var(--head-height) - calc(208px * var(--ratio)));
  &::-webkit-scrollbar {
    display: none;
  }
`

const ContentTop = styled.div`
  width: 100%;
  height: calc(78px * var(--ratio));
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-bottom: calc(56px * var(--ratio)); */
  position: relative;
`

const Header = styled.div`
  border-radius: calc(30px * var(--ratio));
  background: rgba(255, 255, 255, 0.40);
  height: calc(72px * var(--ratio));
  display: flex;
  align-items: center;
  margin-bottom: calc(8px * var(--ratio));
`

const HeaderItem = styled.div`
  width: calc(170px * var(--ratio));
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: calc(46px * var(--ratio));
  position: relative;
  &::after{
    content: '';
    background: rgba(17, 34, 17, 0.20);
    height: calc(8px * var(--ratio));
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: calc(1px * var(--ratio));
  }
  &:last-child::after{
    display: none;
  }
  &:first-child{
    padding-left: calc(76px * var(--ratio));
  }
`
const HeaderItemTitle = styled.div`
  color: rgba(22, 60, 60, 0.80);
  font-size: calc(26px * var(--ratio));
  font-style: normal;
  font-weight: 600;
  margin-bottom: calc(8px * var(--ratio));
`
const HeaderItemSubTitle = styled.div`
  color: rgba(148, 139, 140, 0.80);
  font-size: calc(13px * var(--ratio));
  font-style: normal;
  font-weight: 400;
`

export default Index