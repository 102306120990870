var chatAudio = new Audio()
var chatAudioQueue = []

const playAudioQueue = () => {
    requestAnimationFrame(async () => {
        if (chatAudioQueue.length === 0) {
            playAudioQueue()
            return
        }
        const url = chatAudioQueue.shift()
        // if(!chatAudio.paused){
        //     chatAudio.pause()
        //     chatAudio.currentTime = 0
        //     chatAudio.src = null
        // }
        chatAudio.src = process.env.REACT_APP_OSS_HOST + url
        chatAudio.volume = 1
        chatAudio.addEventListener("canplay", () => {
            chatAudio.play()
            chatAudio.addEventListener("ended", () => {
                playAudioQueue()
            }, { once: true })
        }, { once: true })
        chatAudio.addEventListener("error", () => {
            playAudioQueue()
        }, { once: true })
    })
}




export const clearPlayAudio = async () => {
    try {
        // if (window.sendInfo) {
        //     await http.post(`${apis.MESSAGE_SEND_ABORT}?flag=${window.sendInfo.flag}`)
        // }
    } catch (error) {
        console.log("error", error)
    }

    chatAudio.volume = 0
    chatAudioQueue = []
}

export const playAudio = (url) => {
    chatAudioQueue.push(url)
}

export const openAudio = () => {
    // if(!chatAudio.paused){
    //     chatAudio.pause()
    //     chatAudio.currentTime = 0
    //     chatAudio.src = null
    // }
    chatAudio.src = process.env.PUBLIC_URL + "/audio/silence.mp3"
    chatAudio.volume = 0
    chatAudio.play()
}

playAudioQueue()