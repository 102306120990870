import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './index_font.css'
import App from './router/index';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/effect-cards';
import SwiperCore from "swiper";
import { FreeMode, Navigation } from 'swiper/modules'
SwiperCore.use([Navigation, FreeMode])
// import 'zarm/dist/zarm.css';

const divConfirm = document.createElement("div")
document.body.appendChild(divConfirm)
const confirmRoot = ReactDOM.createRoot(divConfirm);

window.showConfirm = (options) => {
  confirmRoot.render(
    <Confirm options={options} />
  );
}

const Confirm = (props) => {
  const { options } = props
  return (
    <div style={{
      position: "fixed",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: "auto",
      background: "rgba(0,0,0,0.5)",
      zIndex: 11,
    }}>
      <div style={{
        width: 500,
        height: 200,
        background: "#FFFFFF",
        borderRadius: 5,
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 12,
        margin: "auto",
        border: "2px solid #1F1D0F"
      }}>
        <div style={{
          fontFamily: "Roboto Flex400",
          padding: 36,
          fontSize: 24
        }}>{options.content}</div>
        <div style={{
          fontFamily: "Roboto Flex400",
          padding: "0px 36px",
          display: "flex",
          justifyContent: "right"
        }}>
          <div onClick={async () => {
            const close = await options.onNo()
            if (close) {
              confirmRoot.render(null);
            }
          }} style={{
            borderRadius: 6,
            border: "2px solid #1F1D0F",
            color: "#1F1D0F",
            padding: 10,
            cursor: "pointer"
          }}>No</div>
          <div onClick={async () => {
            const close = await options.onYes()
            if (close) {
              confirmRoot.render(null);
            }
          }} style={{
            borderRadius: 6,
            border: "2px solid #1F1D0F",
            background: "#FF5C5C",
            color: "#FFFFFF",
            padding: 10,
            cursor: "pointer",
            marginLeft: 12
          }}>Yes</div>
        </div>
      </div>
    </div>
  )
}

const Loading = () => {
  return (
    <div className="loadingio-spinner-blocks-vm0cns3xbfb">
      <div className="ldio-hyk492dfhb9">
        <div style={{
          left: 38,
          top: 38,
          animationDelay: "0s"
        }}>
        </div>
        <div style={{
          left: 80,
          top: 38,
          animationDelay: "0.125s"
        }}>
        </div>
        <div style={{
          left: 122,
          top: 38,
          animationDelay: "0.25s"
        }}>
        </div>
        <div style={{
          left: 38,
          top: 80,
          animationDelay: "0.875s"
        }}>
        </div>
        <div style={{
          left: 122,
          top: 80,
          animationDelay: "0.375s"
        }}>
        </div>
        <div style={{
          left: 38,
          top: 122,
          animationDelay: "0.75s"
        }}>
        </div>
        <div style={{
          left: 80,
          top: 122,
          animationDelay: "0.625s"
        }}>
        </div>
        <div style={{
          left: 122,
          top: 122,
          animationDelay: "0.5s"
        }}>
        </div>
      </div>
    </div>
  )
}





const div = document.createElement("div")
div.style.position = "fixed"
div.style.left = 0
div.style.right = 0
div.style.top = 0
div.style.bottom = 0
div.style.margin = "auto"
div.style.background = "rgba(255,255,255,0.8)"
div.style.transition = "0.3s"
div.style.zIndex = 999
div.style.pointerEvents = "none"
// div.style.display = "none"
div.style.opacity = 0
document.body.appendChild(div)
const loadingRoot = ReactDOM.createRoot(div);
loadingRoot.render(
  <Loading />
);
window.gLoading = (show) => {
  if (show) {
    div.style.pointerEvents = "auto"
    div.style.opacity = 1
  } else {
    div.style.opacity = 0
    div.style.pointerEvents = "none"
  }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);

// 检查浏览器是否支持 Service Workers
if ('serviceWorker' in navigator) {
  // 注册 Service Worker
  navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/service-worker.js`)
    .then(function (registration) {
      // 注册成功
      console.log('ServiceWorker registration successful with scope:', registration.scope);
    }).catch(function (error) {
      // 注册失败
      console.log('ServiceWorker registration failed:', error);
    });

  // 监听消息事件
  navigator.serviceWorker.addEventListener('message', function (event) {
    // 处理从 Service Worker 收到的消息
    console.log('Received message from service worker:', event.data);
    // 你可以在这里编写代码，根据 Service Worker 发来的消息进行相应的处理
  });
}





// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
