
const Index = (props) => {
  return (
    <svg {...props} viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.590909" y="-0.590909" width="50.8182" height="50.8182" rx="25.4091" transform="matrix(1 -8.62519e-08 -8.86095e-08 -1 -5.23602e-08 50.8182)" stroke="#6E6566" strokeWidth="1.18182" />
      <path d="M36.8341 25.9984L15.1675 25.9984M15.1675 25.9984L23.8341 17.3317M15.1675 25.9984L23.8341 34.665" stroke="#161A19" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default Index