import { useEffect, useState } from 'react'
import styled from "styled-components";
import { SelectIcon } from '../../components/icons/login'

const Index = (props) => {
    const { options = [], value, placeholder, onChange, title, pos = "bottom" } = props
    const [optionShow, setOptionShow] = useState(false)

    const getLabel = (v) => {
        let item = options?.find(m => m.value === v)
        return item?.label
    }

    const openOption = (e) => {
        e.stopPropagation()
        setOptionShow(!optionShow)
    }

    useEffect(() => {
        const _clickDom = () => {
            console.log("11223")
            setOptionShow(false)
        }

        document.addEventListener("click", _clickDom)

        return () => {
            document.removeEventListener("click", _clickDom)
        }

    }, [])

    return (
        <InputWrapper $optionShow={optionShow} $value={value} $l={title} onClick={openOption}>
            <div style={{ display: "flex", height: "100%", width: "100%", alignItems: "center", justifyContent: "space-between" }}>
                <div style={{ marginLeft: "calc(32px * var(--ratio))" }}>{getLabel(value) ? <ValueTxt>{getLabel(value)}</ValueTxt> : <Placeholder $optionShow={optionShow}>{placeholder}</Placeholder>}</div>
                {
                    optionShow ? <SelectIcon style={{ marginRight: "calc(14px * var(--ratio))", transform: "rotate(180deg)", WebkitTransform: "rotate(180deg)" }} /> : <SelectIcon style={{ marginRight: "calc(14px * var(--ratio))" }} />
                }
            </div>
            {
                options?.length !== 0 && <Option $pos={pos} $optionShow={optionShow}>
                    {
                        options?.map(v => (
                            <OptionItem $active={v.value === value} onClick={(e) => {
                                e.stopPropagation();
                                setOptionShow(false)
                                onChange(v.value)
                            }} key={v.value}>{v.label}</OptionItem>
                        ))
                    }
                </Option>
            }
        </InputWrapper>
    )
}

const Option = styled.div`
    width: 100%;
    max-height: calc(403px * var(--ratio));
    border-radius: calc(15px * var(--ratio));
    border: calc(1px * var(--ratio)) solid #A8DCDA;
    background: #FFF;
    position: absolute;
    left: calc(-2px * var(--ratio));
    top: ${props => props.$pos === "bottom" ? "calc(62px * var(--ratio))" : "unset"};
    bottom: ${props => props.$pos === "top" ? "calc(-62px * var(--ratio))" : "unset"};
    overflow: auto;
    display: ${props => props.$optionShow ? "block" : "none"};
    z-index: 11;
`
const OptionItem = styled.div`
    display: flex;
    width: 100%;
    height: calc(60px * var(--ratio));
    align-items: center;
    background: ${props => props.$active ? "#E1F7F6" : "#FFF"};
    cursor: pointer;
    position: relative;
    color: #161A19;
    font-size: calc(20px * var(--ratio));
    font-weight: 600;
    line-height: 1.4;
    padding-left: calc(32px * var(--ratio));
    &:hover{
        background: #E1F7F6;
    }
`


const Placeholder = styled.div`
    color: ${props => props.$optionShow ? "#161A19" : "#C1B8B9"};
    font-size: calc(20px * var(--ratio));
    font-weight: 600;
    line-height: 1.4;
`

const ValueTxt = styled.div`
    color: #161A19;
    font-size: calc(20px * var(--ratio));
    font-style: normal;
    font-weight: 600;
    line-height: 1.4;
`

const After = styled.div`
  color: #6A9B9A;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: calc(14px * var(--ratio));
  font-style: normal;
  font-weight: 400;
  line-height: 1.14;
  text-decoration-line: underline;
  position: absolute;
  right: calc(32px * var(--ratio));
  top: calc(-22px * var(--ratio));
  cursor: pointer;
`

export const InputWrapper = styled.div`
  cursor: pointer;
  border-radius: calc(40px * var(--ratio));
  border: calc(1px * var(--ratio)) solid #A8DCDA;
  background: ${props => props.$value || props.$optionShow ? "#FFF" : "#CFF1F0"};
  box-shadow: 0px calc(4px * var(--ratio)) calc(20px * var(--ratio)) calc(-14px * var(--ratio)) rgba(0, 0, 0, 0.25);
  width: 100%;
  height: calc(58px * var(--ratio));
  display: flex;
  align-items: center;
  position: relative;
  & input{
    margin-left: calc(32px * var(--ratio));
    width: 100%;
    height: 100%;
    margin-right: calc(24px * var(--ratio));
    color: #161A19;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: calc(20px * var(--ratio));
    font-style: normal;
    font-weight: 400;
    line-height: 1.4;
  }
  & input::placeholder{
    color: var(--Gray-400, #DBD4D4);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: calc(20px * var(--ratio));
    font-style: normal;
    font-weight: 400;
    line-height: 1.4;
  }
  &::before{
    content: "${props => props.$l}";
    position: absolute;
    color: var(--black, #161A19);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: calc(14px * var(--ratio));
    font-style: normal;
    font-weight: 500;
    line-height: 1.14;
    left: calc(32px * var(--ratio));
    top: calc(-22px * var(--ratio));
  }
`

export default Index