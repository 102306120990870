import styled from "styled-components";
import BackIcon from '../../components/icons/back'
import { GoogleLoginIcon, OrEmailIcon } from '../../components/icons/login'
import { useEffect, useImperativeHandle, useRef, useState, useContext } from 'react'
import { Input, Radio, Button } from './components'
import { Context as RouterContext } from '../../router'
import { Context as RequestContext } from '../request'
import { setCookie } from '../../utils/cookie'
import { useLocation, useNavigate } from "react-router-dom";
import { oauth2SignIn } from '../login_google'
import { Logo } from '../../components/icons/welcome'

const Index = (props) => {
  const [show, setShow] = useState(false)
  const [remember, setRemember] = useState(false)
  const [agree, setAgree] = useState(true)
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const rctx = useContext(RouterContext)
  const requestCtx = useContext(RequestContext)
  const request = requestCtx.useRequest(false)
  const [errMsg, setErrMsg] = useState("")
  const n = useNavigate()
  const l = useLocation()

  useEffect(() => {
    let email = localStorage.getItem('email')
    if (email) {
      setEmail(email)
      setRemember(true)
    }

    rctx?.onresize()
  }, [])

  const onSubmit = async (e) => {
    console.log(e)
    e.preventDefault()
    setErrMsg("")
    setLoading(true)
    try {
      let ret = await request.post("/client/open/user/login", {
        email,
        password
      })
      localStorage.setItem("token", ret.token)
      // Set a cookie that is accessible on all subdomains of bigread.ai
      setCookie('token', ret.token, 365, '.bigread.ai', '/', true);
      if (remember) {
        localStorage.setItem('email', email)
      } else {
        localStorage.removeItem('email')
      }
      const urlQuery = new URLSearchParams(l.search)
      if (urlQuery.get("redirect")) {
        window.location.replace(urlQuery.get("redirect"))
        return
      }
      n("/")
    } catch (error) {
      console.log(error)
      setErrMsg(error)
    }
    setLoading(false)
  }


  return (
    <Wrapper $show={show}>
      <LogoWrapper>
        <Logo style={{ width: "calc(157px * var(--ratio))", height: "calc(58px * var(--ratio))" }} color="#161A19" />
      </LogoWrapper>
      <div style={{ height: "calc(70px * var(--ratio))" }}></div>
      <Content>
        <Title>Log in</Title>
        <div style={{ background: "#CFF1F0", height: "calc(1px * var(--ratio))", margin: "calc(24px * var(--ratio)) 0" }}></div>
        <GoogleLoginIcon onClick={oauth2SignIn} style={{ width: "calc(490px * var(--ratio))", height: "calc(60px * var(--ratio))", marginBottom: "calc(24px * var(--ratio))" }}></GoogleLoginIcon>
        <div style={{ display: 'flex', justifyContent: "center", marginBottom: "calc(46px * var(--ratio))" }}>
          <OrEmailIcon style={{ width: "calc(490px * var(--ratio))", height: "calc(20px * var(--ratio))" }}></OrEmailIcon>
        </div>
        <form onSubmit={onSubmit}>
          <Input value={email} onChange={e => {
            setEmail(e.target.value)
          }} label="Email" placeholder="Enter the Email" />
          <div style={{ height: "calc(48px * var(--ratio))" }}></div>
          <Input value={password} onChange={e => {
            setPassword(e.target.value)
          }} type="password" label="Password" after={<span onClick={() => n("/fogot")}>Forgot password?</span>} placeholder="Enter your password" />
          <div style={{ height: "calc(24px * var(--ratio))" }}></div>
          <div style={{ paddingLeft: "calc(24px * var(--ratio))" }}>
            <div style={{ display: "flex", alignItems: "center", height: "calc(21px * var(--ratio))" }}>
              <div style={{ marginRight: "calc(8px * var(--ratio))" }}><Radio checked={remember} onChecked={e => setRemember(e)} /></div>
              <div style={{ color: "#161A19", fontSize: "calc(12px * var(--ratio))", fontWeight: 400, lineHeight: 1.3 }}>Remember me</div>
            </div>
            <div style={{ height: "calc(11px * var(--ratio))" }}></div>
            <div style={{ display: "flex", alignItems: "start" }}>
              <div style={{ marginRight: "calc(8px * var(--ratio))" }}><Radio checked={agree} onChecked={e => setAgree(e)} /></div>
              <div style={{ color: "#161A19", fontSize: "calc(12px * var(--ratio))", fontWeight: 400, lineHeight: 1.3 }}>Our application may share data with third-party tools (such as ChatGPT).By continuing, you indicate that you have read and agree to our <a style={{ color: "#2F64C7", textDecoration: "none" }} href='https://www.bigread.ai/about/terms-and-conditions.html' target="_blank" rel="noreferrer">Terms of Use</a> & <a style={{ color: "#2F64C7", textDecoration: "none" }} href='https://www.bigread.ai/about/privacy-policy.html' target="_blank" rel="noreferrer">Privacy Policy</a></div>
            </div>
            <div style={{ height: "calc(24px * var(--ratio))" }}></div>
          </div>
          <Button type="submit" disabled={!email || !password || !agree || loading}>{loading ? "logging" : "Log in"}</Button>
          {
            errMsg && <div style={{ color: "#DC362E", fontSize: "calc(14px * var(--ratio))", fontWeight: 500, lineHeight: 1.14, textAlign: "center", marginTop: "calc(8px * var(--ratio))" }}>{errMsg}</div>
          }
          <div style={{ height: "calc(32px * var(--ratio))" }}></div>
          <div style={{ color: "#161A19", fontSize: "calc(14px * var(--ratio))", fontWeight: 400, lineHeight: 1.42, textAlign: "center" }}>New to BigRead.ai? <span onClick={() => n("/register")} style={{ color: "#6A9B9A", cursor: "pointer" }}>Sign up for free</span></div>

        </form>
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: #FFF;
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
`

const Content = styled.div`
  width: calc(490px * var(--ratio));
  margin: 0 auto;
  padding: calc(40px * var(--ratio));
`

const Title = styled.div`
  color: var(--black, #161A19);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: calc(48px * var(--ratio));
  font-style: normal;
  font-weight: 800;
  line-height: 1.16;
  padding-left: calc(32px * var(--ratio));
`

const LogoWrapper = styled.div`
    display: flex;
    align-items: center;
    padding-left: calc(32px * var(--ratio));
    padding-top: calc(32px * var(--ratio));
`

const LogoTxt = styled.span`
    color: #161A19;
    font-size: calc(20px * var(--ratio));
    font-style: normal;
    font-weight: 500;
`


export default Index