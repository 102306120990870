import styled from "styled-components";
import ReactDOM from 'react-dom/client';
import { useEffect, useState, useRef } from "react";
import LoadingBall from '../../components/icons/ball_loading'
import Avvvatars from 'avvvatars-react'

const InputBox = styled.div`
  width: calc(364px * var(--ratio));
  height: calc(58px * var(--ratio));
  border-radius: calc(40px * var(--ratio));
  border: calc(1px * var(--ratio)) solid #A8DCDA;
  background: #FFF;
  box-shadow: 0px calc(4px * var(--ratio)) calc(20px * var(--ratio)) calc(-14px * var(--ratio)) rgba(0, 0, 0, 0.25);
  margin: 0 auto;
  display: flex;
  align-items: center;
  overflow: hidden;
  & input{
    color: #163C3C;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: calc(20px * var(--ratio));
    font-style: normal;
    font-weight: 400;
    line-height: 1.4;
    margin-left: calc(40px * var(--ratio));
    margin-right: calc(24px * var(--ratio));
    width: 100%;
  };
  & input::placeholder{
    color: #DBD4D4;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: calc(20px * var(--ratio));
    font-style: normal;
    font-weight: 400;
  }
`

const Index = (props) => {
  const { width = 414, height = 375, title, Icon, onCancel, onConfirm, desc = <Desc>You are going to delete <span>How can lose weight with a good eating habits?</span></Desc>, okTxt = "Delete", cancelTxt = "Cancel" } = props.options
  const [loading, setLoading] = useState(false)
  const avatarBox = useRef()
  const observer = useRef()
  const [avatarSize, setAvatarSize] = useState(0)
  const [name, setName] = useState("")

  const onObserver = (list) => {
    const width = list[0].target.clientWidth
    if (!width) {
      return
    }
    setAvatarSize(width)
  }

  useEffect(() => {


    return () => {
      if (observer.current) {
        observer.current.disconnect()
      }
    }
  }, [])

  return (
    <DeleteConfirm onClick={e => e.stopPropagation()}>
      <DeleteConfirmInner $width={width} $height={height}>
        <div style={{ height: "calc(80px * var(--ratio))" }}></div>
        <Title>{title}</Title>
        <div style={{ height: "calc(33px * var(--ratio))" }}></div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div ref={e => {
            if (e && !avatarBox.current) {
              avatarBox.current = e
              setAvatarSize(e.clientWidth)
              observer.current = new ResizeObserver(onObserver)
              observer.current.observe(e)
            }
          }} style={{
            width: "calc(54px * var(--ratio))",
            height: "calc(54px * var(--ratio))",
            borderRadius: "50%",
            overflow: 'hidden',
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}>
            <Avvvatars style="shape" size={avatarSize - 4 || 0} value={name} />
          </div>
        </div>
        <div style={{ height: "calc(9px * var(--ratio))" }}></div>
        <InputBox>
          <input placeholder="Kid‘s new name" value={name} onChange={e => setName(e.target.value)} />
        </InputBox>
        <div style={{ height: "calc(56px * var(--ratio))" }}></div>
        {
          !name && <div style={{ display: "flex", justifyContent: "center" }}>
            <Button style={{ background: "#FFF", color: "#161A19" }} onClick={onCancel}>Back</Button>
          </div>
        }
        {
          name && <ButtonGroup>
            <Button $loading={loading} onClick={async () => {
              setLoading(true)
              await onConfirm(name)
              setLoading(false)
            }} >{okTxt}{loading && <LoadingBall style={{ width: "calc(24px * var(--ratio))", height: "calc(24px * var(--ratio))" }} />}</Button>
            <div style={{ height: "calc(20px * var(--ratio))" }}></div>
            <Button style={{ background: "#FFF", color: "#161A19", border: "none" }} onClick={onCancel}>{cancelTxt}</Button>
          </ButtonGroup>
        }
      </DeleteConfirmInner>
    </DeleteConfirm>
  )
}

const ButtonGroup = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

const Button = styled.div`
  border-radius: calc(40px * var(--ratio));
  border: calc(1px * var(--ratio)) solid #E4DFE0;
  background: #161A19;
  width: calc(138px * var(--ratio));
  height: calc(38px * var(--ratio));
  color: #FFF;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: calc(16px * var(--ratio));
  font-style: normal;
  font-weight: 500;
  line-height: 1.37;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  pointer-events: ${props => props.$loading ? "none" : "all"};
  opacity: ${props => props.$loading ? 0.5 : 1};
  align-items: center;
`

const Title = styled.div`
  color: #161A19;
  font-size: calc(24px * var(--ratio));
  font-style: normal;
  font-weight: 500;
  line-height: 1.3;
  margin-top: calc(16px * var(--ratio));
  text-align: center;
`

const Desc = styled.div`
  color: #467776;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: calc(14px * var(--ratio));
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
  & span{
    font-weight: 700;
  }
  padding-left: calc(48px * var(--ratio));
  padding-right: calc(48px * var(--ratio));
  margin-top: calc(16px * var(--ratio));
`

const DeleteConfirm = styled.div`
    position: fixed;
    width: var(--vw);
    height: var(--vh);
    z-index: 99;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.20);
`

const DeleteConfirmInner = styled.div`
    position: fixed;
    width:calc(${props => props.$width}px * var(--ratio));
    height:calc(${props => props.$height}px * var(--ratio));
    z-index: 100;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    border-radius: calc(16px * var(--ratio));
    border: calc(1px * var(--ratio)) solid #E4DFE0;
    background: #FFF;
`



export default function (options) {
  const { onCancel = () => { }, onConfirm = () => { }, ...others } = options
  const divConfirm = document.createElement("div")
  document.body.appendChild(divConfirm)
  const confirmRoot = ReactDOM.createRoot(divConfirm);
  confirmRoot.render(
    <Index options={{
      onCancel: () => {
        confirmRoot.render(null)
        onCancel()
      },
      onConfirm: async (name) => {
        await onConfirm(name)
        confirmRoot.render(null)
      },
      ...others
    }} />
  );
}

