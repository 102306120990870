import React, { useRef } from "react"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import { deleteCookie } from '../../utils/cookie'


export const Context = React.createContext({})

const Index = (props) => {
  const reqs = useRef([])
  const n = useNavigate()
  const instance = useRef(null)


  const unUseRequest = () => {
    reqs.current.map(v => (
      v.abort()
    ))
    reqs.current = []
  }

  const response = (response) => {
    if (response.data.code === 401) {
      n("/login")
      deleteCookie('token', '.bigread.ai');
      localStorage.removeItem("token")
      return Promise.reject();
    }
    if (response.data.code === 403) {
      n("/pay")
      return Promise.reject();
    }
    if (response.data.code === 4403) { //未设置profile
      n("/welcome")
      return Promise.reject();
    }
    if (response.data.code === 40001) {
      return Promise.reject(response.data.msg);
    }
    if (response.data.code !== 200) {
      alert(response.data.msg)
      return Promise.reject(response.data.msg);
    }
    return response.data?.data;
  }

  const requestError = function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }

  const request = function (config) {
    // const controller = new AbortController();
    // config.signal = controller.signal
    // reqs.current.push(controller)
    // 在发送请求之前做些什么
    config.headers = { 'Authorization': localStorage.getItem("token") }
    return config;
  }

  const error = function (error) {
    alert(error?.message)
    return Promise.reject(error);
  }

  const useRequest = () => {
    // if (instance.current) {
    //   return instance.current
    // }
    instance.current = axios.create({
      baseURL: process.env.REACT_APP_API_HOST,
      timeout: 1000 * 10
    })
    instance.current.interceptors.request.use(request, requestError);
    instance.current.interceptors.response.use(response, error);

    instance.current.interceptors.request.eject(instance.current);
    instance.current.interceptors.response.eject(instance.current);
    return instance.current
  }

  return (
    <Context.Provider value={{
      useRequest,
      unUseRequest
    }}>{props.children}</Context.Provider>
  )
}

export default Index