import HandlePoint from '../../components/HandlePoint'
import { useState } from 'react';
import { Skeleton } from 'antd';
import styled from "styled-components";

const Index = (props) => {
    const { id, data = {} } = props
    const { value } = data
    const [mouseOver, setMouseOver] = useState(false)
    return (
        <VideoSummaryBox onMouseOver={() => setMouseOver(true)} onMouseOut={() => setMouseOver(false)}>
            <Title>Summary</Title>
            <div style={{ padding: 16, display: value ? "none" : "block" }}>
                <Skeleton active paragraph={{ rows: 10 }} />
            </div>
            <VideoSummaryBoxTxt>{value}</VideoSummaryBoxTxt>
            <HandlePoint mouseOver={mouseOver} id={id} />
        </VideoSummaryBox>
    )
}

const Title = styled.div`
    color: #1f1d0f;
    font-family: "Roboto Flex600";
    font-size: 24px;
    margin-bottom: 16px;
`

const VideoSummaryBox = styled.div`
    width: 400px;
    background: #FFFFFF;
    box-shadow: 2px 2px 0px 0px #1F1D0F;
    border: 2px solid #1F1D0F;
    border-radius: 6px;
    padding: 16px;
`

const VideoSummaryBoxTxt = styled.div`
    color: #1F1D0F;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-family: Inter;
    font-weight: 500;
    white-space: pre-wrap;
    line-height: 24px;
`

export default Index