import styled from "styled-components";


const Index = (props) => {
  return (
    <Wrapper {...props} $disabled={props.disabled}>{props.children}</Wrapper>
  )
}

const Wrapper = styled.div`
  user-select: none;
  padding: calc(16px * var(--ratio)) calc(32px * var(--ratio));
  box-shadow: 0px calc(6px * var(--ratio)) calc(30px * var(--ratio)) calc(-4px * var(--ratio)) rgba(104, 100, 145, 0.14);
  border-radius: 30px;
  font-size: calc(15px * var(--ratio));
  font-weight: 600;
  display: inline-block;
  cursor: pointer;
  ${props => {
    let sty = ""
    if (props.$disabled) {
      sty += "color: #C1B8B9;"
      sty += "background: none;"
      sty += "border: #D3CCCD;"
      sty += "pointer-events: none;"
      sty += "cursor: no-drop;"
    } else {
      sty += "color: #FFFFFF;"
      sty += "background: #6A9B9A;"
      sty += "border: none;"
      sty += "pointer-events: auto;"
    }
    return sty
  }}


  
    
    
`

export default Index