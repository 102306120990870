import { useState } from 'react';
import HandlePoint from '../../components/HandlePoint';
import { Skeleton } from 'antd';
import styled from "styled-components";

const colors = ["#F2FFBD", "#E1F8FF", "#EFE3FF"]

const Index = (props) => {
  const { id, onRelatedClick, xPos, yPos } = props
  const [mouseOver, setMouseOver] = useState(false)
  const { value = [], makeds = {} } = props.data


  return (
    <ModulecardBox onMouseOver={() => setMouseOver(true)} onMouseOut={() => setMouseOver(false)}>
      {
        (!value || (value && value.length === 0)) && <Skeleton active />
      }
      {
        (value && value.length !== 0) && <>
          <Title>Related Concepts</Title>
          <div style={{ display: "contents" }}>
            {
              value?.map((v, k) => (
                <ModulecardBoxTitleItem onClick={async () => {
                  onRelatedClick(v, xPos, yPos, id)
                }} key={k} style={{ background: colors[k % colors.length], pointerEvents: makeds[v] ? 'none' : 'auto', opacity: makeds[v] ? 0.5 : 1 }}>{v}</ModulecardBoxTitleItem>
              ))
            }
          </div>
        </>
      }
      <HandlePoint mouseOver={mouseOver} id={id} />
    </ModulecardBox>
  );
}

const Title = styled.div`
  color: #1f1d0f;
  font-family: "Roboto Flex600";
  font-size: 24px;
  margin-bottom: 20px;
`

const ModulecardBoxTitleItem = styled.div`
  color: #1F1D0F;
  font-family: "Roboto Slab600";
  font-size: 14px;
  font-weight: 600;
  border-radius: 6px;
  border: 2px solid #1F1D0F;
  background: #F2FFBD;
  box-shadow: 2px 2px 0px 0px #1F1D0F;
  margin-top: 10px;
  padding: 5px 15px;
`

const ModulecardBox = styled.div`
  border-radius: 6px;
  border: 2px solid #1F1D0F;
  background: #FFF;
  box-shadow: 2px 2px 0px 0px #1F1D0F;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 400px;
  min-width: 200px;
`

export default Index;
