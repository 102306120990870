import { useContext, useEffect, useRef, useState } from 'react'
import { Context } from '../auth'
import { Context as RContext } from '../../router/index'
import { useNavigate } from 'react-router-dom'
import styled from "styled-components";
import { Context as RequestContext } from '../request'
import { LoadingWrapper } from '../../utils/full_loading'
import BackIcon from '../../components/icons/back'
import { Child, Plus, Edit, More, Add, Delete, DeleteDisabled, Progress, LxProgress, TimeDay, Skill, WhitePlus } from '../../components/icons/profile'
import { deleteCookie } from '../../utils/cookie'
import Avvvatars from 'avvvatars-react'
import { DeleteConfirmIcon } from '../../components/icons/free'
import confirm from "../../components/Confirm"
import confirmInput from "../../components/Confirm/input"

const Index = () => {
    const ctx = useContext(Context)
    const rctx = useContext(RContext)
    const [deleteShow, setDeleteShow] = useState(-1)
    const [proFiles, setProFiles] = useState([])
    const n = useNavigate()
    const requestCtx = useContext(RequestContext)
    const request = requestCtx.useRequest()
    const [loading, setLoading] = useState(true)
    const avatarBox = useRef()
    const observer = useRef()
    const [avatarSize, setAvatarSize] = useState(0)
    const [arData, setArData] = useState({})
    // console.log("ctx", ctx)


    // SwiperCore.use([FreeMode]);

    const getProgressData = async () => {
        try {
            const _arData = await request.get("/client/v2/api/statistics/read_finish_articles") || []
            const obj = {
                leveledReadingTime: 0,
                endlessLearningTime: 0,
                quiz_sum: 0,
                quiz_correct_sum: 0
            }
            for (let i of _arData) {
                if (i.tp === "LEVEL_FLAG") {
                    obj.lexile = i.lexile
                    obj.ar_level = i.ar_level
                }
                obj.quiz_sum += i.quiz_sum
                obj.quiz_correct_sum += i.quiz_correct_num
            }
            const _timeData = await request.get("/client/v2/api/learn/times")

            for (let i of _timeData) {
                if (i.from === "ARTICLE") {
                    obj.leveledReadingTime += i.time_sec
                }
                if (i.from === "FREE") {
                    obj.endlessLearningTime += i.time_sec
                }
            }
            obj.leveledReadingTime = Math.round(obj.leveledReadingTime / 60)
            obj.endlessLearningTime = Math.round(obj.endlessLearningTime / 60)
            setArData(obj)
            console.log("_data", obj)
        } catch (error) {

        }
    }

    const onObserver = (list) => {
        const width = list[0].target.clientWidth
        if (!width) {
            return
        }
        setAvatarSize(width)
    }

    const getProFileList = async () => {
        try {
            let _proFiles = await request.get('/client/api/profile/list')
            _proFiles = _proFiles || []
            setProFiles(_proFiles)
        } catch (error) {

        }
    }

    useEffect(() => {
        rctx.onresize()

        const _clickDom = () => {
            setDeleteShow(-1)
        }

        document.addEventListener("click", _clickDom)

        setLoading(true)
        getProFileList().then(async r => {
            await getProgressData()

            setLoading(false)
        })


        return () => {
            if (observer.current) {
                observer.current.disconnect()
            }
            document.removeEventListener("click", _clickDom)
        }
        // eslint-disable-next-line
    }, [])

    const createnewProfileBtn = async () => {
        n("/welcome?profile")
    }

    const GradeShow = (grade) => {
        try {
            const gradeArr = grade.split(" ")
            return {
                num: gradeArr[0],
                g: <span>Grade</span>
            }
        } catch (error) {

        }
        return {}
    }

    const _deleteShow = (v) => {
        setDeleteShow(v.id)
    }

    const deleteProfile = async (v) => {
        await request.post(`/client/v2/api/user/profile/delete?id=${v.id}`, {})
        await getProFileList()
        await ctx.loadUserInfo()
    }

    const slideProfile = async (v) => {
        await request.post(`/client/v2/api/user/profile/slide?id=${v.id}`, {})
        await ctx.loadUserInfo()
        await getProgressData()
    }

    const updateProfile = async (data) => {
        await request.post(`/client/v2/api/user/profile/update`, data)
        await getProFileList()
        await ctx.loadUserInfo()
    }

    const showEdit = (v) => {
        confirmInput({
            width: 462,
            height: 468,
            okTxt: "Confirm",
            title: "New name",
            // desc,
            onConfirm: async (name) => {
                try {
                    await updateProfile({
                        id: v.id,
                        name
                    })
                } catch (error) {

                }
            }
        })
    }

    return (
        <Wrapper>
            <LoadingWrapper loading={loading} bgColor={"rgba(0,0,0,0)"}>
                <BackIconWrapper onClick={e => n(-1)} />
                <Title>Profile</Title>
                <div style={{ height: "calc(41px * var(--ratio))" }}></div>
                <UserInfoBox>
                    <div>
                        {
                            ctx?.userInfo?.vip_info?.is_vip ? <UserEmail>{ctx.userInfo.email}<PlusIcon /></UserEmail> : <UserEmail style={{ color: "#163C3C" }}>{ctx.userInfo.email}</UserEmail>
                        }
                        <UserRole>{ctx.userInfo.role}</UserRole>
                    </div>
                    <SignOutBtn onClick={() => {
                        let desc = <Desc>Parent&nbsp;&nbsp;<span>{ctx.userInfo.email}</span></Desc>
                        if (ctx?.userInfo?.vip_info?.is_vip) {
                            desc = <Desc>Parent&nbsp;&nbsp;<span style={{ color: "#B229D6" }}>{ctx.userInfo.email}</span><PlusIcon style={{ width: "calc(13px * var(--ratio))", height: "calc(12px * var(--ratio))" }} /></Desc>
                        }
                        confirm({
                            width: 465,
                            height: 201,
                            okTxt: "Log out",
                            title: "Are you sure you want to sign out?",
                            desc,
                            onConfirm: async () => {
                                localStorage.removeItem("token")
                                deleteCookie('token', '.bigread.ai');
                                n("/login")
                            }
                        })
                    }}>Sign out</SignOutBtn>
                </UserInfoBox>
                <div style={{ display: "flex", alignItems: "center", paddingLeft: "calc(78px * var(--ratio))", marginBottom: "calc(26.5px * var(--ratio))" }}>
                    <ChildIcon />
                    <span style={{ color: "rgba(22, 26, 25, 0.90)", fontSize: "calc(17px * var(--ratio))", fontWeight: 500, lineHeight: 1.7, marginLeft: "calc(4px * var(--ratio))" }}>Your child’s profile</span>
                </div>
                <ProfileBox>
                    {
                        proFiles.map((v, k) => (
                            <Profile key={k} $active={ctx?.userInfo?.uid === v.id} onClick={async () => {
                                if (ctx?.userInfo?.uid === v.id) {
                                    return
                                }
                                setLoading(true)
                                try {
                                    await slideProfile(v)
                                } catch (error) {

                                }
                                setLoading(false)
                            }}>
                                <div style={{ paddingLeft: "calc(32px * var(--ratio))", paddingTop: "calc(32px * var(--ratio))" }}>
                                    <div ref={e => {
                                        if (e && !avatarBox.current) {
                                            avatarBox.current = e
                                            setAvatarSize(e.clientWidth)
                                            observer.current = new ResizeObserver(onObserver)
                                            observer.current.observe(e)
                                        }
                                    }} style={{
                                        width: "calc(54px * var(--ratio))",
                                        height: "calc(54px * var(--ratio))",
                                        borderRadius: "50%",
                                        overflow: 'hidden',
                                        marginRight: "calc(20px * var(--ratio))",
                                        border: ctx?.userInfo?.uid === v.id ? "2px solid #ABFFFD" : "1px solid #DBD4D4",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                        <Avvvatars style="shape" size={(avatarSize || 0) - 4 < 0 ? 0 : (avatarSize || 0) - 4} value={v.name} />
                                    </div>
                                </div>
                                <div>
                                    <ProfileName onClick={e => {
                                        e.stopPropagation()
                                        showEdit(v)
                                    }}>{v.name}{ctx?.userInfo?.uid === v.id ? <EditIcon color={["white", "white", "0.6"]} /> : <EditIcon />}</ProfileName>
                                    <ProfileGrade>{GradeShow(v.grade).num} {GradeShow(v.grade).g}</ProfileGrade>
                                </div>
                                {
                                    ctx?.userInfo?.uid === v.id ? <MoreIcon onClick={(e) => {
                                        e.stopPropagation()
                                        _deleteShow(v)
                                    }} color="#FFF" /> : <MoreIcon onClick={(e) => {
                                        e.stopPropagation()
                                        _deleteShow(v)
                                    }} />
                                }
                                {
                                    v.id === deleteShow && <DeleteBox onClick={e => {
                                        e.stopPropagation()
                                        if (proFiles.length === 1) {
                                            return
                                        }
                                        confirm({
                                            title: "Delete this Profile?",
                                            desc: <Desc>After deletion, all&nbsp; <span>{v.name}'s</span>&nbsp; records will be cleared.</Desc>,
                                            Icon: DeleteConfirmIcon,
                                            onConfirm: async () => {
                                                try {
                                                    await deleteProfile(v)
                                                } catch (error) {

                                                }
                                            }
                                        })
                                    }}>
                                        {
                                            proFiles.length === 1 ? <DeleteDisabledIcon /> : <DeleteIcon />
                                        }
                                    </DeleteBox>
                                }
                                <HomePageBtn onClick={() => n("/")}>Back to Homepage</HomePageBtn>
                            </Profile>
                        ))
                    }
                    {
                        ctx?.userInfo?.vip_info?.is_vip && proFiles.length < ctx?.userInfo?.max_profile_num && <>
                            {
                                new Array(ctx?.userInfo?.max_profile_num - proFiles.length).fill(0).map((i, j) => (
                                    <Profile key={j} onClick={createnewProfileBtn} style={{
                                        border: "calc(1px * var(--ratio)) dashed #96C9C8",
                                        boxShadow: "none",
                                        background: "none",
                                        alignItems: "center"
                                    }}>
                                        <div style={{ marginLeft: "calc(32px * var(--ratio))", marginRight: "calc(25px * var(--ratio))" }}>
                                            <AddIcon />
                                        </div>
                                        <div>
                                            <ProfileAddNum>({proFiles.length + j + 1}/{ctx?.userInfo?.max_profile_num})</ProfileAddNum>
                                            <div style={{ height: "calc(8px * var(--ratio))" }}></div>
                                            <ProfileCreateTxt>Create a new profile</ProfileCreateTxt>
                                        </div>
                                    </Profile>
                                ))
                            }
                        </>
                    }
                    {
                        !ctx?.userInfo?.vip_info?.is_vip && proFiles.length < ctx?.userInfo?.max_profile_num && <Profile onClick={() => n("/pay")} style={{
                            border: "calc(1px * var(--ratio)) dashed rgba(122, 122, 122, 0.60)",
                            boxShadow: "none",
                            alignItems: "center",
                            flexDirection: "column",
                            background: "none"
                        }}>
                            <div style={{ height: "calc(24px * var(--ratio))" }}></div>
                            <div style={{ height: "calc(24px * var(--ratio))", color: "#161A19", fontSize: "calc(15px * var(--ratio))", fontWeight: 400, lineHeight: 1.2 }}>Need a new profile? </div>
                            <div style={{ height: "calc(24px * var(--ratio))", color: "#161A19", fontSize: "calc(12px * var(--ratio))", fontWeight: 400, lineHeight: 1.2 }}>Please upgrade to <span style={{ color: "#B229D6" }}>Plus</span>.</div>
                            <UpgradeBtn onClick={() => n("/pay")}>
                                <WhitePlusIcon />
                                <span>Upgrade Now</span>
                            </UpgradeBtn>
                        </Profile>
                    }
                </ProfileBox>
                <div style={{ height: "calc(92px * var(--ratio))" }}></div>
                <div style={{ display: "flex", alignItems: "center", paddingLeft: "calc(78px * var(--ratio))", marginBottom: "calc(26.5px * var(--ratio))" }}>
                    <ProgressIcon />
                    <span style={{ color: "rgba(22, 26, 25, 0.90)", fontSize: "calc(17px * var(--ratio))", fontWeight: 500, lineHeight: 1.7, marginLeft: "calc(4px * var(--ratio))" }}>Progress of {ctx?.userInfo?.pro_file_name}</span>
                </div>
                <ProgressBox>
                    <ProgressItem>
                        <ProgressItemTitle>
                            <LxProgressIcon />
                            <span>AR & Lexile Level Progression</span>
                        </ProgressItemTitle>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <ProgressItemCon>
                                <div>{arData.ar_level}</div>
                                <div>AR Level</div>
                            </ProgressItemCon>
                            <div style={{ width: "calc(42px * var(--ratio))" }}></div>
                            <ProgressItemCon>
                                <div>{arData.lexile}L</div>
                                <div>Lexile Level</div>
                            </ProgressItemCon>
                        </div>
                        <TakeDetailBtn onClick={() => n("/progress?tab=1")}>Take detail</TakeDetailBtn>
                    </ProgressItem>
                    <ProgressItem $vip={ctx?.userInfo?.vip_info?.is_vip}>
                        {
                            !ctx?.userInfo?.vip_info?.is_vip && <ProgressItemUpgrade>
                                <div>To unlock full function of progress.</div>
                                <UpgradeBtn onClick={() => n("/pay")}>
                                    <WhitePlusIcon />
                                    <span>Upgrade Now</span>
                                </UpgradeBtn>
                            </ProgressItemUpgrade>
                        }
                        <ProgressItemTitle>
                            <TimeDayIcon />
                            <span>Learning time</span>
                        </ProgressItemTitle>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <ProgressItemCon>
                                <div>{arData.leveledReadingTime}&nbsp;<span>Mins</span></div>
                                <div style={{ color: "#6A0828" }}>Leveled Reading</div>
                            </ProgressItemCon>
                            <div style={{ width: "calc(42px * var(--ratio))" }}></div>
                            <ProgressItemCon>
                                <div>{arData.endlessLearningTime}&nbsp;<span>Mins</span></div>
                                <div style={{ color: "#146A67" }}>Endless Learning</div>
                            </ProgressItemCon>
                        </div>
                        <TakeDetailBtn onClick={() => n("/progress?tab=2")}>Take detail</TakeDetailBtn>
                    </ProgressItem>
                    <ProgressItem $vip={ctx?.userInfo?.vip_info?.is_vip}>
                        {
                            !ctx?.userInfo?.vip_info?.is_vip && <ProgressItemUpgrade>
                                <div>To unlock full function of progress.</div>
                                <UpgradeBtn onClick={() => n("/pay")}>
                                    <WhitePlusIcon />
                                    <span>Upgrade Now</span>
                                </UpgradeBtn>
                            </ProgressItemUpgrade>
                        }
                        <ProgressItemTitle>
                            <SkillIcon />
                            <span>Sessions and skills</span>
                        </ProgressItemTitle>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <ProgressItemCon>
                                <div style={{ color: "#4EB04D" }}>{arData.quiz_correct_sum}<span style={{ fontSize: "calc(32px * var(--ratio))", color: "rgba(22, 60, 60, 0.80)" }}>/{arData.quiz_sum}</span></div>
                                <div>Quizzes Passed</div>
                            </ProgressItemCon>
                        </div>
                        <TakeDetailBtn onClick={() => n("/progress?tab=3")}>Take detail</TakeDetailBtn>
                    </ProgressItem>
                </ProgressBox>
            </LoadingWrapper>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow: auto;
    background: linear-gradient(180deg, #E1F7F6 0%, #FFF 100%);
`

const WhitePlusIcon = styled(WhitePlus)`
     width: calc(15px * var(--ratio));
     height: calc(15px * var(--ratio));
`

const LxProgressIcon = styled(LxProgress)`
    width: calc(24px * var(--ratio));
    height: calc(24px * var(--ratio));
`

const ProgressIcon = styled(Progress)`
    width: calc(24px * var(--ratio));
    height: calc(24px * var(--ratio));
`
const TimeDayIcon = styled(TimeDay)`
     width: calc(24px * var(--ratio));
     height: calc(24px * var(--ratio));
`

const SkillIcon = styled(Skill)`
    width: calc(24px * var(--ratio));
    height: calc(24px * var(--ratio));
`

const ProgressBox = styled.div`
    display: flex;
    justify-content: center;
`

const ProgressItemTitle = styled.div`
    color: rgba(22, 26, 25, 0.90);
    font-size: calc(15px * var(--ratio));
    font-style: normal;
    font-weight: 500;
    line-height: 1.7;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: calc(24px * var(--ratio));
    margin-bottom: calc(28px * var(--ratio));
    & span{
        margin-left: calc(8px * var(--ratio));
    }
`

const ProgressItemCon = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
   & div:nth-child(1){
    color: rgba(22, 60, 60, 0.80);
    font-size: calc(32px * var(--ratio));
    font-style: normal;
    font-weight: 600;
    margin-bottom: calc(14px * var(--ratio));
    & span{
        color: #163C3C;
        font-size: calc(12px * var(--ratio));
        font-style: normal;
        font-weight: 500;
    }
   }
   & div:nth-child(2){
    color: rgba(22, 26, 25, 0.90);
    font-size: calc(13px * var(--ratio));
    font-style: normal;
    font-weight: 500;
    line-height: 1.23;
   }
`
const TakeDetailBtn = styled.div`
    cursor: pointer;
    border-radius: calc(50px * var(--ratio));
    border: calc(1px * var(--ratio)) solid rgba(22, 60, 60, 0.16);
    width: calc(263px * var(--ratio));
    height: calc(44px * var(--ratio));
    display: flex;
    justify-content: center;
    align-items: center;
    color: #161A19;
    font-size: calc(15px * var(--ratio));
    font-style: normal;
    font-weight: 500;
    margin: 0 auto;
    margin-top: calc(28px * var(--ratio));
`

const ProgressItemUpgrade = styled.div`
    border-radius: calc(22px * var(--ratio));
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.00) 100%);
    backdrop-filter: blur(4px);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    & div:nth-child(1){
        color: #161A19;
        font-size: calc(14px * var(--ratio));
        font-weight: 500;
        line-height: 1.28;
        margin-top: calc(73px * var(--ratio));
        margin-bottom: calc(24px * var(--ratio));
    } 
`

const UpgradeBtn = styled.div`
    cursor: pointer;
    width: calc(126px * var(--ratio));
    height: calc(33px * var(--ratio));
    border-radius: calc(30px * var(--ratio));
    background: #B229D6;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-family: Inter;
    font-size: calc(12px * var(--ratio));
    font-weight: 500;
    ${WhitePlusIcon}{
        margin-right: calc(8px * var(--ratio));
    }
`


const ProgressItem = styled.div`
    border-radius: calc(22px * var(--ratio));
    border: calc(1px * var(--ratio)) solid #FFF;
    background: rgba(169, 255, 161, 0.10);
    box-shadow: 0px calc(4px * var(--ratio)) calc(40px * var(--ratio)) calc(-28px * var(--ratio)) rgba(18, 18, 18, 0.65);
    backdrop-filter: blur(calc(20px * var(--ratio)));
    width: calc(379px * var(--ratio));
    height: calc(228px * var(--ratio));
    margin-right: calc(24px * var(--ratio));
    position: relative;
    &:nth-child(3){
        margin-right: 0;
        background: rgba(22, 26, 25, 0.04);
    }
    &:nth-child(2){
        background: rgba(53, 183, 178, 0.08);
    }
    &:nth-child(2)::after,&:nth-child(3)::after{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;  /* 保证伪元素在内容层之下 */
        filter: blur(4px);  /* 应用模糊滤镜 */
        background: #fff;
        display: ${props => props.$vip ? "none" : "block"};
    }
`

const Desc = styled.div`
  color: #467776;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: calc(14px * var(--ratio));
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
  & span{
    font-weight: 700;
  }
  padding-left: calc(48px * var(--ratio));
  padding-right: calc(48px * var(--ratio));
  margin-top: calc(16px * var(--ratio));
  display: flex;
  align-items: center;
  justify-content: center;
`

const DeleteIcon = styled(Delete)`
    width: calc(18px * var(--ratio));
    height: calc(18px * var(--ratio));
`

const DeleteDisabledIcon = styled(DeleteDisabled)`
    width: calc(18px * var(--ratio));
    height: calc(18px * var(--ratio));
`

const DeleteBox = styled.div`
    width: calc(64px * var(--ratio));
    height: calc(32px * var(--ratio));
    border-radius: calc(6px * var(--ratio));
    background: rgba(255, 255, 255, 0.50);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: calc(12px * var(--ratio));
    top: calc(56px * var(--ratio));
    box-shadow: 0px calc(1px * var(--ratio)) calc(4px * var(--ratio)) 0px rgba(12, 12, 13, 0.10), 0px calc(1px * var(--ratio)) calc(4px * var(--ratio)) 0px rgba(12, 12, 13, 0.05);
`

const ProfileAddNum = styled.div`
    display: flex;
    align-items: center;
    color: #6A9B9A;
    font-size: calc(15px * var(--ratio));
    font-style: normal;
    font-weight: 500;
`

const ProfileCreateTxt = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: #163C3C;
    text-align: center;
    font-size: calc(15px * var(--ratio));
    font-style: normal;
    font-weight: 400;
`

const AddIcon = styled(Add)`
    width: calc(58px * var(--ratio));
    height: calc(58px * var(--ratio));
    display: block;
`

const HomePageBtn = styled.div`
    cursor: pointer;
    border-radius: calc(50px * var(--ratio));
    border: calc(1px * var(--ratio)) solid rgba(22, 60, 60, 0.16);
    background: rgba(22, 60, 60, 0.04);
    width: calc(198px * var(--ratio));
    height: calc(44px * var(--ratio));
    position: absolute;
    bottom: calc(-80px * var(--ratio));
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #161A19;
    font-size: calc(15px * var(--ratio));
    font-style: normal;
    font-weight: 500;
`

const EditIcon = styled(Edit)`
    width: calc(12px * var(--ratio));
    height: calc(12px * var(--ratio));
    margin-left: calc(16px * var(--ratio));
`

const MoreIcon = styled(More)`
    width: calc(24px * var(--ratio));
    height: calc(24px * var(--ratio));
    cursor: pointer;
`

const BackIconWrapper = styled(BackIcon)`
    width: calc(52px * var(--ratio));
    height: calc(52px * var(--ratio));
    position: fixed;
    left: calc(32px * var(--ratio));
    top: calc(16px * var(--ratio));
`

const Title = styled.div`
    color: rgba(22, 60, 60, 0.80);
    font-size: calc(24px * var(--ratio));
    font-style: normal;
    font-weight: 400;
    height: calc(78px * var(--ratio));
    display: flex;
    justify-content: center;
    align-items: center;
`

const UserInfoBox = styled.div`
    width: calc(1208px * var(--ratio));
    margin: 0 auto;
    padding-left: calc(52px * var(--ratio));
    padding-right: calc(42px * var(--ratio));
    justify-content: space-between;
    align-items: center;
    display: flex;
    margin-bottom: calc(35.5px * var(--ratio));
`
const UserEmail = styled.div`
    color: #B229D6;
    font-size: calc(23px * var(--ratio));
    font-style: normal;
    font-weight: 600;
    margin-bottom: calc(8px * var(--ratio));
`

const UserRole = styled.div`
    color: rgba(22, 60, 60, 0.60);
    font-size: calc(17px * var(--ratio));
    font-style: normal;
    font-weight: 400;
`

const SignOutBtn = styled.div`
    cursor: pointer;
    border-radius: calc(50px * var(--ratio));
    border: calc(1px * var(--ratio)) solid rgba(22, 60, 60, 0.16);
    background: rgba(22, 60, 60, 0.04);
    height: calc(44px * var(--ratio));
    width: calc(128px * var(--ratio));
    display: flex;
    justify-content: center;
    align-items: center;
    color: #161A19;
    font-size: calc(15px * var(--ratio));
    font-weight: 500;
`

const ChildIcon = styled(Child)`
    height: calc(24px * var(--ratio));
    width: calc(24px * var(--ratio));
`

const PlusIcon = styled(Plus)`
    width: calc(18px * var(--ratio));
    height: calc(18px * var(--ratio));
    margin-left: calc(8px * var(--ratio));
`

const ProfileBox = styled.div`
    padding-left:  calc(64px * var(--ratio));
    padding-right: calc(64px * var(--ratio));
    display: flex;
    flex-flow: wrap;
`

const ProfileName = styled.div`
    display: flex;
    align-items: center;
`

const ProfileGrade = styled.div`
    /* display: flex;
    align-items: center; */
`

const ProfileAge = styled.div`
    /* display: flex;
    align-items: center; */
`

const ProfileCore = styled.div`
    
`

const Profile = styled.div`
    ${MoreIcon}{
        position: absolute;
        top: calc(32px * var(--ratio));
        right: calc(32px * var(--ratio));
    }
    ${HomePageBtn}{
        display: ${props => props.$active ? "flex" : "none"};
    }
    div${ProfileName}{
        color: ${props => props.$active ? "#FFF" : "#161A19"};
        font-size: calc(24px * var(--ratio));
        font-style: normal;
        font-weight: 400;
        margin-bottom: calc(12px * var(--ratio));
        padding-top: calc(32px * var(--ratio));
    }
    div${ProfileAge}{
        color: ${props => props.$active ? "#FFF" : "#161A19"};
        font-size: calc(15px * var(--ratio));
        font-style: normal;
        font-weight: 400;
        margin-bottom: calc(12px * var(--ratio));
    }
    div${ProfileCore}{
        color: ${props => props.$active ? "#FFF" : "#161A19"};
        font-size: calc(15px * var(--ratio));
        font-style: normal;
        font-weight: 400;
        margin-bottom: calc(12px * var(--ratio));
    }
    div${ProfileGrade}{
        color: ${props => props.$active ? "#FFF" : "#161A19"};
        font-size: calc(15px * var(--ratio));
        font-style: normal;
        font-weight: 400;
        & span{
            color: ${props => props.$active ? "rgba(255, 255, 255, 0.60)" : "rgba(22, 26, 25, 0.40)"};;
        }
        margin-bottom: calc(12px * var(--ratio));
    }
    cursor: pointer;
    width: calc(394px * var(--ratio));
    height: calc(132px * var(--ratio));
    border-radius: calc(22px * var(--ratio));
    box-shadow: 0px calc(4px * var(--ratio)) calc(40px * var(--ratio)) calc(-28px * var(--ratio)) rgba(18, 18, 18, 0.65);
    backdrop-filter: blur(calc(20px * var(--ratio)));
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: calc(24px * var(--ratio));
    position: relative;
    margin-bottom: calc(24px * var(--ratio));
    &:nth-child(3n-2){
        margin-left: 0;
    }
    ${props => {
        let sty = ""
        if (props.$active) {
            sty = `
                background: #163C3C;
                border: calc(1px * var(--ratio)) solid #ABFFFD;
            `
        } else {
            sty = `
                background: #F3FAFA;
                border: calc(1px * var(--ratio)) solid #A8DCDA;
            `
        }
        return sty
    }}
`



export default Index