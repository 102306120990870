import { Handle, Position } from 'reactflow';


const Index = (props) => {
  const { id, mouseOver = false } = props
  return (
    <div>
      <Handle style={{
        background: "#D9D9D9",
        top: mouseOver ? -20 : -10,
        width: mouseOver ? 14 : 4,
        height: mouseOver ? 14 : 4,
        transition: "0.3s",
        WebkitTransition: "0.3s"
      }} id={`source${id}top`} type="source" position={Position.Top} isConnectable={false} />
      <Handle style={{
        background: "#D9D9D9",
        bottom: mouseOver ? -20 : -10,
        width: mouseOver ? 14 : 4,
        height: mouseOver ? 14 : 4,
        transition: "0.3s",
        WebkitTransition: "0.3s"
      }} id={`source${id}bottom`} type="source" position={Position.Bottom} isConnectable={false} />
      <Handle style={{
        background: "#D9D9D9",
        left: mouseOver ? -20 : -10,
        width: mouseOver ? 14 : 4,
        height: mouseOver ? 14 : 4,
        transition: "0.3s",
        WebkitTransition: "0.3s"
      }} id={`source${id}left`} type="source" position={Position.Left} isConnectable={false} />
      <Handle style={{
        background: "#D9D9D9",
        right: mouseOver ? -20 : -10,
        width: mouseOver ? 14 : 4,
        height: mouseOver ? 14 : 4,
        transition: "0.3s",
        WebkitTransition: "0.3s"
      }} id={`source${id}right`} type="source" position={Position.Right} isConnectable={false} />


      <Handle style={{
        background: "#D9D9D9",
        top: mouseOver ? -20 : -10,
        width: mouseOver ? 14 : 4,
        height: mouseOver ? 14 : 4,
        transition: "0.3s",
        WebkitTransition: "0.3s"
      }} id={`target${id}top`} type="target" position={Position.Top} isConnectable={false} />
      <Handle style={{
        background: "#D9D9D9",
        bottom: mouseOver ? -20 : -10,
        width: mouseOver ? 14 : 4,
        height: mouseOver ? 14 : 4,
        transition: "0.3s",
        WebkitTransition: "0.3s"
      }} id={`target${id}bottom`} type="target" position={Position.Bottom} isConnectable={false} />
      <Handle style={{
        background: "#D9D9D9",
        left: mouseOver ? -20 : -10,
        width: mouseOver ? 14 : 4,
        height: mouseOver ? 14 : 4,
        transition: "0.3s",
        WebkitTransition: "0.3s"
      }} id={`target${id}left`} type="target" position={Position.Left} isConnectable={false} />
      <Handle style={{
        background: "#D9D9D9",
        right: mouseOver ? -20 : -10,
        width: mouseOver ? 14 : 4,
        height: mouseOver ? 14 : 4,
        transition: "0.3s",
        WebkitTransition: "0.3s"
      }} id={`target${id}right`} type="target" position={Position.Right} isConnectable={false} />
    </div>
  )
}

export default Index