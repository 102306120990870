import { useRef, useEffect, useState, useImperativeHandle, useContext } from 'react';
import { cloneDeep } from "lodash";
import SendIcon from '../../static/img/20231208-083828.png'
import Button from '../../components/Button';
import SoundRipple from '../../components/SoundRipple'
import MaiKeIcon from '../../static/img/20231125-211755.png'
import TxtIcon from '../../static/img/20231205-131643.png'
import MaikeBlackIcon from '../../static/img/20231205-134358.png'
import { MsgTypeChat, MsgTypeVoice, MsgProgressIng, MsgProgressEnd } from '../../utils/define'
import setupHoldAndRelease, { startRecord } from '../../utils/hold'
import styled from "styled-components";
import { Context as RequestContext } from '../../pages/request'
import { EventEmitter as emitter } from '../../pages/auth'


const Index = (props) => {
  const { data = {}, audioPool, onStopAudio = () => { } } = props
  const hashId = props.hashId || data.hashId
  const [intensity, setIntensity] = useState(0)
  const [soundRippleVisible, setSoundRippleVisible] = useState(false)
  const [chatList, setChatList] = useState({ total: 0, list: [] })
  const chatRecordListNextOffset = useRef(0)
  const times = useRef({})
  const soundOff = useRef(true)
  const [search, setSearch] = useState({ offset: -1, article_hash_id: hashId })
  const [talkMode, setTalkMode] = useState('voice')
  const [soundRippleLoading, setSoundRippleLoading] = useState(false)
  const holdBtnRef = useRef()
  const [searchTxt, setSearchTxt] = useState('')
  const [chatStep, setChatStep] = useState(0) //0.end,1.loading;2.ing
  const chatBoxRef = useRef()
  const scrollLoadRef = useRef(false)
  const audioId = useRef(audioPool.getAudio())
  const isHold = useRef(false)
  const audioQueue = useRef([])
  const audiorequestAnimationFrameId = useRef()
  const sendInfo = useRef()
  const requestCtx = useContext(RequestContext)
  const request = requestCtx.useRequest()

  soundOff.current = props.soundOff

  const stopAudio = async () => {
    audioPool.stopAudio(audioId.current)
    await closeAudio()
  }

  useImperativeHandle(props.action, () => ({
    stopAudio,
    openAudio
  }));

  const openAudio = () => {
    audioPool.playAudio(audioId.current, process.env.PUBLIC_URL + "/audio/silence.mp3")
  }

  const closeAudio = async () => {
    try {
      if (sendInfo.current) {
        await request.post(`/client/api/message/send/abort?flag=${sendInfo.current.flag}`)
      }
    } catch (error) {
      console.log("error", error)
    }
    sendInfo.current = null
    audioQueue.current = []
    window.humanoid.talkAnimationEnd("onAudioEnd");
  }

  //发送文字消息
  const onSendTxt = async (e) => {
    if (e.key === "Enter") {
      if (!searchTxt || chatStep === 1) {
        return
      }
      sendTxt(searchTxt)
    }
  }

  const playAudio = async () => {
    audiorequestAnimationFrameId.current = requestAnimationFrame(async () => {
      if (audioQueue.current.length === 0 || soundOff.current) {
        playAudio()
        return
      }
      const url = audioQueue.current.shift()
      const audio = audioPool.playAudio(audioId.current, url)
      window.humanoid.talkAnimationStart();
      audio.addEventListener("ended", () => {
        window.humanoid.talkAnimationEnd("onAudioEnd");
        playAudio()
      }, { once: true })

      audio.addEventListener("error", () => {
        window.humanoid.talkAnimationEnd("onAudioEnd");
        playAudio()
      }, { once: true })
    })
  }

  //录音声音变化
  const onVolume = (volume) => {
    if (volume > 0) {
      volume = volume / 10
      if (volume >= 1) {
        volume = Math.random() * (1.0 - 0.9) + 0.9
      }
    }
    setIntensity(volume)
  }

  const onMessage = (message) => {
    if (message.hash_id !== hashId) {
      return
    }
    if (message.msg_type === MsgTypeChat) {
      if (message.msg_progress === MsgProgressIng) {
        let char = message.msg
        let id = new Date().getTime()
        setChatList(pre => {
          let coPre = cloneDeep(pre)
          coPre.list = coPre.list || []
          for (let i = 0; i < coPre.list.length; i++) {
            coPre.list[i].loading = false
          }
          if (char === "|") {
            let last = { content: '' }
            last.id = id
            last.loading = false
            last.to_uid = 0
            last.tp = 0
            coPre.list.push(last)
            return coPre
          } else {
            if (coPre.list.length === 0) {
              let last = { content: '' }
              last.content += char
              last.id = id
              last.loading = false
              last.to_uid = 0
              last.tp = 0
              coPre.list.push(last)
              return coPre
            }
            let last = coPre.list[coPre.list.length - 1]
            last.content = last.content || ''
            last.content += char
            last.id = id
            last.loading = false
            last.to_uid = 0
            last.tp = 0
            return coPre
          }
        })
        scrollToBottom(chatBoxRef.current)
      }
      if (message.msg_progress === MsgProgressEnd) {
        setChatStep(0)
        setSearch({ offset: -1, article_hash_id: hashId })
        loadChatList({ offset: -1, article_hash_id: hashId })
      }
    }
    if (message.msg_type === MsgTypeVoice) {
      if (!soundOff.current) {
        const msgJson = JSON.parse(message.msg)
        audioQueue.current.push(process.env.REACT_APP_OSS_HOST + msgJson.url)
      }
    }
  }

  const handleWheel = (event) => {
    // console.log(event)
    // 阻止事件继续传播到父级元素
    event.stopPropagation();
    // 阻止默认缩放行为
    // event.preventDefault();

    const container = event.currentTarget;
    const scrollStep = event.deltaY;
    // 更新滚动条位置
    container.scrollTop += scrollStep;
  };

  const sendTxt = async (sTxt) => {
    if (!sTxt) {
      return
    }
    openAudio()
    request.post('/client/api/message/send', {
      msg_type: MsgTypeChat,
      hash_id: hashId,
      msg: sTxt
    }).then(r => {
      sendInfo.current = r
    }).catch(() => { })
    setSearchTxt('')
    setChatStep(1)
    setChatList(pre => {
      let cp = cloneDeep(pre)
      cp.list.push({
        id: new Date().getTime(),
        content: sTxt,
        to_uid: -1,
        tp: 0
      })
      cp.list.push({
        id: new Date().getTime() + 300,
        loading: true,
        tp: 0,
        to_uid: 0,
      })
      return cp
    })
    times.current[new Date().toString()] = setTimeout(() => {
      scrollToBottom(chatBoxRef.current)
    }, 300)
  }

  //滚动条到底部
  const scrollToBottom = (divElement) => {
    divElement.scrollTop = divElement.scrollHeight;
  }

  const loadChatList = async (s, superposition = false) => {
    try {
      let ret = await request.post('/client/api/chat/record/list', s)
      ret.list = ret.list || []
      for (let i = 0; i < ret.list.length; i++) {
        try {
          ret.list[i].content_json = JSON.parse(ret.list[i].content_json)
        } catch (error) {

        }
      }
      chatRecordListNextOffset.current = ret.next_offset
      if (superposition) {
        setChatList(pre => {
          let cpPre = cloneDeep(pre)
          cpPre.list = cpPre.list || []
          cpPre.list = [...ret.list, ...cpPre.list]
          return cpPre
        })
      } else {
        setChatList(ret)
      }
      return ret
    } catch (error) {
      // console.log(error)
    }
  }

  useEffect(() => {
    audioPool.onBePause(aid => {
      if (audioId.current === aid) {
        stopAudio()
        onStopAudio()
      }
    })

    playAudio()
    loadChatList(search).then(r => {
      setTimeout(() => {
        scrollToBottom(chatBoxRef.current)
      }, 300)
    })
    var mediaRecorder
    setupHoldAndRelease(
      holdBtnRef.current,
      async () => {
        openAudio()
        mediaRecorder = await startRecord(onVolume, (txt) => {
          setSoundRippleLoading(false)
          sendTxt(txt)
        }, () => {
          setSoundRippleVisible(false)
        })
        if (mediaRecorder) {
          setSoundRippleVisible(true)
          isHold.current = true
        }
      },
      () => {
        if (isHold.current) {
          setSoundRippleVisible(false)
          setSoundRippleLoading(false)
          if (mediaRecorder) {
            mediaRecorder.stop();
          }
          isHold.current = false
        }
      }
    );

    emitter.on("message", onMessage)

    let chatBox = chatBoxRef.current
    const scroll = (e) => {
      if (chatBox.scrollTop !== 0) {
        return
      }
      if (chatRecordListNextOffset.current === 0) {
        return
      }
      const currentScrollPosition = cloneDeep(chatBox.scrollHeight)
      if (scrollLoadRef.current) {
        return
      }
      scrollLoadRef.current = true
      setSearch(pre => {
        let cpChatRecordListReq = cloneDeep(pre)
        cpChatRecordListReq.offset = chatRecordListNextOffset.current
        setSearch(cpChatRecordListReq)
        loadChatList(cpChatRecordListReq, true).then(() => {
          scrollLoadRef.current = false
          //等待新的DOM元素渲染
          times.current[new Date().toString()] = setTimeout(() => {
            chatBox.scrollTop = cloneDeep(chatBox.scrollHeight) - currentScrollPosition; // 更新滚动位置
          }, 0);
        })
        return cpChatRecordListReq
      })
    }
    chatBox.addEventListener("scroll", scroll)

    return () => {
      emitter.off("message", onMessage)
      chatBox.removeEventListener("scroll", scroll)
      if (audiorequestAnimationFrameId.current) {
        cancelAnimationFrame(audiorequestAnimationFrameId.current)
      }
    }

    // eslint-disable-next-line
  }, [])

  return (
    <ChatBox>
      <ChatList className='hideScroll' ref={chatBoxRef} onWheel={handleWheel}>
        {
          chatList?.list?.map((v, k) => {
            if (v.to_uid !== -1 && v.tp === 0) {
              return <div key={k}>
                {
                  v.loading ? <AiChatItem><div style={{ padding: "0 12px" }}><Button loading></Button></div></AiChatItem> : <AiChatItem style={{ display: v?.content?.trim() ? "inline-block" : "none" }} dangerouslySetInnerHTML={{ __html: v.content }}></AiChatItem>
                }
              </div>
            }
            if (v.to_uid === -1 && v.tp === 0) {
              return <div key={k}>
                <div style={{ clear: 'both' }}></div>
                <UserChatItem dangerouslySetInnerHTML={{ __html: v.content === "Quiz me" || v.content === "Summary" ? `<b>${v.content}</b>` : v.content }}></UserChatItem>
                <div style={{ clear: 'both' }}></div>
              </div>
            }
            return <></>
          })
        }
      </ChatList>
      <InputSearchBox>
        <div style={{ display: talkMode === "voice" ? "flex" : "none", justifyContent: "space-between", alignItems: "center", width: "100%", position: "relative" }}>
          <div style={{
            position: "absolute",
            zIndex: 3,
            top: -52,
            left: 0,
            right: 0,
            margin: "auto",
            visibility: soundRippleVisible ? "unset" : "hidden",
          }}>
            <SoundRipple width={`100%`} intensity={intensity} />
          </div>
          <div style={{ position: "relative", width: "calc(100% - 64px)" }}>
            <HoldBtn>
              <img style={{ width: 24, height: 24 }} src={MaiKeIcon} alt='' />
              <span>{soundRippleLoading ? 'identifying...' : 'Hold to talk'}</span>
            </HoldBtn>
            <div ref={holdBtnRef} style={{
              position: "absolute",
              left: 0,
              top: 0,
              zIndex: 2,
              width: "100%",
              height: "100%",
              pointerEvents: soundRippleLoading || chatStep === 1 ? 'none' : 'auto'
            }}></div>
          </div>
          <TxtBtn onClick={() => {
            setTalkMode('txt')
          }}>
            <img style={{ width: 24, height: 24 }} src={TxtIcon} alt='' />
          </TxtBtn>
        </div>
        <div style={{ display: talkMode === "txt" ? "flex" : "none", justifyContent: "space-between", alignItems: "center", position: "relative", width: "100%" }}>
          <TxtBtn onClick={() => {
            setTalkMode('voice')
          }}>
            <img style={{ width: 24, height: 24 }} src={MaikeBlackIcon} alt='' />
          </TxtBtn>
          <TalkTxtBox style={{ position: "relative", width: "calc(100% - 64px)" }}>
            <div style={{ width: "calc(100% - 50px)", flexShrink: 0 }}>
              <input disabled={soundRippleVisible || soundRippleLoading || chatStep === 1} value={searchTxt} onChange={e => setSearchTxt(e.target.value)} onKeyPress={onSendTxt} placeholder='Ask me anything…' />
            </div>
            <TalkTxtBoxBtn style={{
              pointerEvents: chatStep === 1 ? 'none' : 'auto'
            }} onClick={() => {
              if (!searchTxt || chatStep === 1) {
                return
              }
              sendTxt(searchTxt)
            }}>
              <img style={{ width: 24, height: 24 }} src={SendIcon} alt="" />
            </TalkTxtBoxBtn>
          </TalkTxtBox>
        </div>
      </InputSearchBox>
    </ChatBox>
  )
}


const TalkTxtBoxBtn = styled.div`
  background: #FF5251;
  width: 48px;
  color: #FFF;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto Flex600";
  line-height: 45px;
  flex-shrink: 0;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1.633px solid #1F1D0F;
`

const TalkTxtBox = styled.div`
  border-radius: 6px;
  border: 1.952px solid #1F1D0F;
  background: #FFF;
  box-shadow: 3.267px 3.267px 0px 0px #1F1D0F;
  height: 45px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  input{
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-family: "Roboto Flex400";
    line-height: 16px; /* 114.286% */
    height: 45px;
    width: 100%;
    margin-left: 12px;
  }
  input::placeholder{
    font-size: 14px;
  }
`

const TxtBtn = styled.div`
  border-radius: 6px;
  border: 1.633px solid #1F1D0F;
  background: #FFF;
  box-shadow: 3.267px 3.267px 0px 0px #1F1D0F;
  display: flex;
  width: 45px;
  height: 45px;
  justify-content: center;
  align-items: center;
`

const HoldBtn = styled.div`
  border-radius: 6px;
  border: 1.633px solid #1F1D0F;
  background: #FF5251;
  box-shadow: 3.267px 3.267px 0px 0px #1F1D0F;
  display: flex;
  height: 45px;
  justify-content: center;
  align-items: center;
  color: #FFF;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 16px;
  font-style: normal;
  font-family: "Roboto Flex600";
  line-height: 24px; /* 150% */
`

const AiChatItem = styled.div`
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto Flex400";
  line-height: 22px; /* 157.143% */
  border-radius: 2px;
  background: #EDF2F3;
  padding: 12px;
  margin-right: 76px;
  display: inline-block;
  margin-bottom: 12px;
  border-radius: 6px;
  border: 1px solid #000;
  background: #EDF2F3;
`
const UserChatItem = styled.div`
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 14px;
  font-style: normal;
  font-family: "Roboto Flex400";
  line-height: 22px; /* 157.143% */
  border-radius: 2px;
  background: #8DE4FF;
  padding: 12px;
  margin-left: 76px;
  /* display: inline-block; */
  margin-bottom: 12px;
  float: right;
  border-radius: 6px;
  border: 1px solid #000;
`

const ChatBox = styled.div`
  box-sizing: border-box;
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 300px;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  overflow: auto;
  justify-content: flex-end;
  max-height: 100%;
  z-index: 1000;
`

const ChatList = styled.div`
  margin-bottom: 0.5rem;
  overflow: auto;
  padding: 5px 10px;
  border-radius: 12px;
  background-color: rgba(0, 0, 0, 0.1);
`

const InputSearchBox = styled.div`
  border-radius: 2px;
  height: 64px;
  display: flex;
  justify-content: space-between;
  position: relative;
`

export default Index