import axios from "axios";

export default function setupHoldAndRelease(element, onHold, onRelease) {
  let isHolding = false;
  let touchIdentifier = null;
  let holdTimeout = null;
  let startTime = null;

  function startHoldTimer() {
    holdTimeout = setTimeout(() => {
      onHold(Date.now() - startTime);
    }, 300);
  }

  function clearHoldTimer() {
    if (holdTimeout) {
      clearTimeout(holdTimeout);
      holdTimeout = null;
    }
  }

  element.addEventListener('mousedown', (event) => {
    event.preventDefault();
    event.stopPropagation();
    isHolding = true;
    startTime = Date.now();
    startHoldTimer();

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  });

  element.addEventListener('touchstart', (event) => {
    if (event.touches.length === 1) {
      event.preventDefault();
      event.stopPropagation();
      isHolding = true;
      touchIdentifier = event.changedTouches[0].identifier;
      startTime = Date.now();
      startHoldTimer();
    }
  });

  function onMouseMove(event) {
    if (!isEventInsideElement(event, element)) {
      onMouseUp();
    }
  }

  function onMouseUp() {
    if (isHolding) {
      isHolding = false;
      clearHoldTimer();
      onRelease();

      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    }
  }

  document.addEventListener('touchmove', onTouchMove);
  document.addEventListener('touchend', onTouchEnd);

  function onTouchMove(event) {
    if (touchIdentifier !== null) {
      const touch = Array.from(event.touches).find(t => t.identifier === touchIdentifier);
      if (!touch || !isEventInsideElement(touch, element)) {
        onTouchEnd();
      }
    }
  }

  function onTouchEnd(event) {
    if (touchIdentifier !== null) {
      touchIdentifier = null;
      if (isHolding) {
        isHolding = false;
        clearHoldTimer();
        onRelease();
      }
    }
  }

  function isEventInsideElement(event, element) {
    const rect = element.getBoundingClientRect();
    const clientX = event.clientX || (event.touches && event.touches[0].clientX);
    const clientY = event.clientY || (event.touches && event.touches[0].clientY);
    return (
      clientX >= rect.left &&
      clientX <= rect.right &&
      clientY >= rect.top &&
      clientY <= rect.bottom
    );
  }
}

//开始录音

export const startRecord = async (onVolume, onTxt, onStop) => {
  var stream
  var mediaRecorder
  var timer
  let chunks = [];
  let startTime = new Date().getTime()
  try {
    stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  } catch (error) {
    return mediaRecorder
  }
  let endTime = new Date().getTime()
  if (endTime - startTime > 500) { //预判用户在处理弹窗事件,视为第一次授权
    return mediaRecorder
  }
  mediaRecorder = new MediaRecorder(stream);
  mediaRecorder.ondataavailable = (event) => {
    if (event.data.size > 0) {
      chunks.push(event.data);
    }
  };
  mediaRecorder.onstop = async () => {
    // 停止录音后释放资源
    if (stream) {
      stream.getTracks().forEach(track => track.stop()); // 关闭音频流
    }
    if (timer) {
      clearInterval(timer)
    }
    mediaRecorder = null;
    stream = null;
    onStop()
    const blob = new Blob(chunks, { type: 'audio/wav' });
    const formData = new FormData();
    // // // 创建临时URL
    // const blobURL = window.URL.createObjectURL(blob);
    // // 创建链接元素
    // const downloadLink = document.createElement('a');
    // downloadLink.href = blobURL;
    // downloadLink.download = 'your-audio.wav'; // 设置下载的文件名
    // // 模拟点击链接元素来触发下载
    // downloadLink.click();
    // // 释放URL对象，以释放资源
    // window.URL.revokeObjectURL(blobURL);
    formData.append('blob', blob, 'audio.wav'); // 第一个参数是字段名，第二个参数是 Blob，第三个参数是文件名
    // 发送 POST 请求来上传 Blob 对象
    let response = await axios.post(`https://api.bigread.ai/client/open/tts/parse`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    onTxt(response.data.data)
    if (!response.data.data) {
      return
    }
    console.log('上传成功:', response.data.data);
  };
  mediaRecorder.start();
  const audioContext = new (window.AudioContext || window.webkitAudioContext)();
  const mediaStreamSource = audioContext.createMediaStreamSource(stream);
  const analyser = audioContext.createAnalyser();
  analyser.fftSize = 256; // FFT (快速傅里叶变换) 大小
  const bufferLength = analyser.frequencyBinCount;
  const dataArray = new Uint8Array(bufferLength);
  mediaStreamSource.connect(analyser);
  // analyser.connect(audioContext.destination);
  // 获取音量的函数
  function getVolume() {
    analyser.getByteFrequencyData(dataArray);
    let sum = 0;
    dataArray.forEach(value => {
      sum += value;
    });
    const average = sum / dataArray.length;
    return average;
  }
  timer = setInterval(() => {
    onVolume(getVolume())
  }, 100)
  return mediaRecorder
}

//获取录音权限
export const checkRecorder = (onPermission) => {
  try {
    navigator.mediaDevices.getUserMedia({ audio: true }).then(s => {
      onPermission(true)
      s.getTracks().forEach(track => track.stop()); // 关闭音频流
    }).catch(() => {
      onPermission(true)
    })
  } catch (error) {
    onPermission(true)
  }
}