import zhengqueIcon from '../../static/img/quiz_zhengque.png'
import cuowuIcon from '../../static/img/quiz_cuowu.png'
import HelpIcon from '../../static/img/20240307-135151.png'
import { cloneDeep } from 'lodash'
import { useEffect, useRef, useState, useContext } from 'react'
import { clearPlayAudio } from "../../utils/audio_queue";
import lottie from 'lottie-web'
import { PlayCircleOutlined, LoadingOutlined, PauseCircleOutlined } from '@ant-design/icons';
import styled from "styled-components";
import HandlePoint from '../../components/HandlePoint'
import { Skeleton } from 'antd'
import { MsgTypeQuizParseFree, MsgProgressEnd, MsgTypeArticleVoice, MsgTypeArticle, MsgTypeArticleTitle, MsgTypeArticleCover, MsgProgressIng, MsgTypeTTSVoice } from '../../utils/define'
import { AudioPool } from '../../utils'
import { EventEmitter } from '../../pages/auth'
import { Context as RequestContext } from '../../pages/request'


export const markColors = [
    "#fed5d5",
    "#fedfbb",
    "#fef3a1",
    "#e1fab1",
    "#adf8e9",
    "#cce2fe",
]
const regex = /\[citation:(\d+)\]/g;
const Index = (props) => {
    const audioPoolRef = useRef(new AudioPool())
    let {
        id,
        data = [],
        onChange = () => { },
        hashId = '',
        onCheck = () => { },
        rid = '',
        onQuizParseStyle = () => { },
        onQuizParseJump = () => { },
        audioPool = audioPoolRef.current
    } = props
    if (id) {
        data = props.data.value || []
        hashId = props.data.hashId
    }
    console.log("data", data)
    const [quizIndex, setQuizIndex] = useState(-1)
    const animate = useRef()
    const [voiceLoading, setVoiceLoading] = useState({})
    const indexObjRef = useRef({})
    const [mouseOver, setMouseOver] = useState(false)
    const animateDom = useRef()
    const articleVoices = useRef({})
    const audioIds = useRef({})
    const requestCtx = useContext(RequestContext)
    const request = requestCtx.useRequest()

    const gotoQuiz = (k, v) => {
        setQuizIndex(k)
        onQuizParseStyle([])

        for (let i in voiceLoading) {
            if (voiceLoading[i] === 1) {
                audioPool.pauseAudio(audioIds.current[i])
                setVoiceLoading(pre => {
                    const cpPre = cloneDeep(pre)
                    cpPre[i] = 4
                    return cpPre
                })
            }
        }
    }

    const nextQuiz = (v) => {
        setQuizIndex(pre => {
            let cp = cloneDeep(pre)
            cp++
            return cp
        })
        onQuizParseStyle([])
        for (let i in voiceLoading) {
            if (voiceLoading[i] === 1) {
                audioPool.pauseAudio(audioIds.current[i])
                setVoiceLoading(pre => {
                    const cpPre = cloneDeep(pre)
                    cpPre[i] = 4
                    return cpPre
                })
            }
        }
        return
    }

    const parseOptions = (ops) => {
        try {
            return JSON.parse(ops)
        } catch (err) {

        }
        return ops
    }

    const onPlayStart = (txt) => {

    }

    const onPlayEnd = (aid) => {
        for (let i in audioIds.current) {
            if (audioIds.current[i] === aid) {
                setVoiceLoading(pre => {
                    const cpPre = cloneDeep(pre)
                    cpPre[i] = 0
                    return cpPre
                })
                break
            }
        }
    }

    const onMessage = (message) => {
        if (message.hash_id !== hashId) {
            return
        }
        if (message.msg_type === MsgTypeTTSVoice) {
            if (message.msg_progress === MsgProgressIng) {
                const tts = JSON.parse(message.msg)
                if (!articleVoices.current[message.quiz_id]) {
                    articleVoices.current[message.quiz_id] = []
                }
                articleVoices.current[message.quiz_id].push(tts)
            }
            if (message.msg_progress === MsgProgressEnd) {
                setVoiceLoading(pre => {
                    const cpPre = cloneDeep(pre)
                    cpPre[message.quiz_id] = 1
                    return cpPre
                })
                audioPool.playAudioArr(audioIds.current[message.quiz_id], articleVoices.current[message.quiz_id], onPlayStart, onPlayEnd, () => { })
            }
        }
    }

    useEffect(() => {
        setTimeout(() => {
            gotoQuiz(0, {})
        }, 300)

        audioPool.onBePause((aid) => {
            for (let i in audioIds.current) {
                if (audioIds.current[i] === aid) {
                    setVoiceLoading(pre => {
                        const cpPre = cloneDeep(pre)
                        cpPre[i] = 4
                        return cpPre
                    })
                }
            }
        })

        EventEmitter.on("message", onMessage)

        return () => {
            EventEmitter.off("message", onMessage)
        }

        // eslint-disable-next-line
    }, [])

    const playAnimate = () => {
        animateDom.current.style.display = "block"
        animate.current = lottie.loadAnimation({
            container: animateDom.current, // the dom element that will contain the animation
            renderer: 'svg',
            loop: false,
            autoplay: false,
            path: `${process.env.PUBLIC_URL}/lottie/quiz_zhengque.json` // the path to the animation json
        })
        animate.current.play()
        // 监听动画播放完毕
        animate.current.addEventListener('complete', () => {
            // 在这里执行你想要的操作
            animate.current.destroy()
            animateDom.current.style.display = "none"
        }, {
            once: true
        });
    }


    const choiceQuiz = (k, v2) => {
        const cpQuizData = cloneDeep(data)
        if (cpQuizData[k].is_answer) {
            return
        }
        cpQuizData[k].parse_status = 0
        cpQuizData[k].user_answer = v2.option
        onChange(cpQuizData, false)
    }

    const onQuizInput = (e, k) => {
        const { value } = e.target
        const cpQuizData = cloneDeep(data)
        if (cpQuizData[k].is_answer) {
            return
        }
        cpQuizData[k].parse_status = 0
        if (!value) {
            delete cpQuizData[k].parse_status
        }
        cpQuizData[k].user_answer = value
        onChange(cpQuizData, false)
    }

    const quizOptionBg = (v, v2) => {
        if (!v.is_answer) {
            if (v.user_answer === v2.option) {
                return '#8DE4FF'
            }
            return
        } else {
            if (v.user_answer === v2.option && v.user_answer === v.answer) { //答对了
                return '#A3F799'
            }
            //答错了
            if (v.user_answer === v2.option) {
                return '#FE9D9D'
            }
            if (v.answer === v2.option) {
                return '#A3F799'
            }
        }
        return '#FFFFFF'
    }

    const showParseTxt = (v) => {
        if (v.parse_status === 2) {
            if (v.knowledge) {
                let knowH = (inputString) => {
                    // 查找第一个空格的位置
                    const firstSpaceIndex = inputString.indexOf(' ');
                    // 如果找到了空格，提取空格后面的文字
                    if (firstSpaceIndex !== -1) {
                        const resultString = inputString.substr(firstSpaceIndex + 1);
                        return resultString
                    } else {
                        // 如果没有找到空格，返回原始字符串或者执行适当的错误处理
                        return inputString
                    }
                }
                return <div style={{ whiteSpace: "pre-wrap" }}>
                    <Knowledge style={{ padding: "0px 4px" }}>Skill to target: {knowH(v.knowledge)}</Knowledge>
                    {formatQuizParse(v)}
                </div>
            }
            return formatQuizParse(v)
        }
        return ''
    }

    const checkBtnTxt = (v) => {
        if (v.parse_status === 0 && !v.is_answer) {
            return 'Check'
        }
        if (v.parse_status === 1 && v.is_answer) {
            return 'Help'
        }
        if (v.parse_status === 3 && v.is_answer) {
            return 'Help...'
        }
    }

    const checkQuiz = async (v) => {
        let btnTxt = checkBtnTxt(v)
        if (btnTxt === 'Check') {
            let cpQuiz = cloneDeep(data)
            let findex = cpQuiz.findIndex(item => item.id === v.id)
            if (!cpQuiz[findex].user_answer) {
                return
            }
            cpQuiz[findex].is_answer = true
            if (v.tp === 1) {
                if (v.answer !== v.user_answer) {
                    window.gAudio.src = "/audio/cuowu.mp3"
                    window.gAudio.play()
                    cpQuiz[findex].parse_status = 1
                } else {
                    window.gAudio.src = "/audio/zhengque.mp3"
                    window.gAudio.play()
                    playAnimate()
                }
            }
            if (v.tp === 4) {
                window.gAudio.src = "/audio/zhengque.mp3"
                window.gAudio.play()
            }
            onChange(cpQuiz, true)

            try {
                const scoreInfo = await request.post("/client/v2/api/article/quiz/answer", {
                    quiz_id: cpQuiz[findex].id,
                    user_answer: cpQuiz[findex].user_answer,
                    is_correct: cpQuiz[findex].answer === cpQuiz[findex].user_answer
                })
                console.log(scoreInfo)
                onCheck(scoreInfo)
            } catch (error) {

            }
            return
        }
        if (btnTxt === 'Help') {
            let cpQuiz = cloneDeep(data)
            let findex = cpQuiz.findIndex(item => item.rid === v.rid)
            if (!cpQuiz[findex].is_answer) {
                return
            }
            await clearPlayAudio()
            cpQuiz[findex].parse_status = 3
            onChange(cpQuiz, false)
            await clearPlayAudio()
            window.sendInfo = await request.post('/client/api/message/send', {
                quiz_id: v.id,
                hash_id: hashId,
                user_answer: v.user_answer,
                msg_type: MsgTypeQuizParseFree,
                rid: rid,
                node_id: id ? id : '',
                llm_prompt_id: id ? 16 : 0
            }).catch(() => { })
        }
    }


    const formatQuizParse = (v) => {
        const ps = []
        indexObjRef.current = {}
        const indexObj = indexObjRef.current
        let index = 0
        const formattedText = v.parse.replace(regex, (match, p1) => {
            if (!indexObj[p1]) {
                index++
                indexObj[p1] = index
            }
            if (quizIndex === data.findIndex(j => j.no === v.no)) {
                ps.push(parseInt(p1) - 1)
                onQuizParseStyle(ps, indexObj)
            }
            window.gotoArtcle = (p) => {
                onQuizParseJump(p)
            }
            let color = markColors[(indexObj[p1] - 1) % markColors.length]
            return `<span style="position: relative;width: 16px;height: 16px;display: inline-block;margin: 0 2px;"><span onclick="gotoArtcle('${p1}')" style="display: inline-block;width: 16px;height: 16px;line-height: 16px;background: ${color};color: #000;font-size: 8px;border-radius: 50%;cursor: pointer;text-align: center;position: absolute;top: -4px;">${indexObj[p1]}</span></span>`
        });
        return <span id={`quizParse${v.no}`} dangerouslySetInnerHTML={{ __html: formattedText }}></span>
    }

    const playVoice = async (v) => {
        const formattedText = v.parse.replace(regex, (match, p1) => `citation ${indexObjRef.current[p1]}`);
        if (!audioIds.current[v.id]) {
            audioIds.current[v.id] = audioPool.getAudio()
        }
        audioPool.playAudio(audioIds.current[v.id], process.env.PUBLIC_URL + "/audio/silence.mp3")
        setVoiceLoading(pre => {
            const cpPre = cloneDeep(pre)
            cpPre[v.id] = 3
            return cpPre
        })
        try {
            await request.post('/client/api/message/send', {
                msg_type: MsgTypeTTSVoice,
                content: formattedText,
                hash_id: hashId,
                quiz_id: v.id
            })
        } catch (error) {
            setVoiceLoading(pre => {
                const cpPre = cloneDeep(pre)
                cpPre[v.id] = 0
                return cpPre
            })
        }
    }

    // setQuizParseStyle()
    // useEffect(()=>{

    // },[data])

    return (
        <div onMouseOver={() => setMouseOver(true)} onMouseOut={() => setMouseOver(false)}>
            <AnimateWrapper ref={animateDom}></AnimateWrapper>
            {
                id && <div>
                    {
                        (!data || (data && data.length === 0)) && <Skeleton active paragraph={{ rows: 10 }} />
                    }
                </div>
            }
            <QuizNoBox>
                {
                    data.map((v, k) => (
                        <QuizNoBoxItem style={{
                            background: k === quizIndex && '#F8C767'
                        }} onClick={() => gotoQuiz(k, v)} key={k}>
                            {
                                (v.is_answer && (v.user_answer === v.answer || v.tp === 4)) && <img style={{ width: 20, height: 20 }} src={zhengqueIcon} alt='' />
                            }
                            {
                                (v.is_answer && v.user_answer !== v.answer && v.tp === 1) && <img style={{ width: 20, height: 20 }} src={cuowuIcon} alt='' />
                            }
                            {
                                !v.is_answer && k + 1
                            }
                        </QuizNoBoxItem>
                    ))
                }
            </QuizNoBox>
            <div>
                {
                    data.map((v, k) => {
                        return <QuizBody key={k} style={{ display: quizIndex === k ? "block" : "none" }}>
                            {
                                v.tp === 4 && <>
                                    <Question dangerouslySetInnerHTML={{ __html: `Q ${k + 1}: ${v.question}` }}></Question>
                                    <div style={{
                                        borderRadius: 6,
                                        border: "2px solid #1F1D0F",
                                        background: "#FFF",
                                        padding: 16,
                                        marginBottom: 12,
                                        height: 220
                                    }}>
                                        {
                                            v.is_answer && <div style={{
                                                outline: "none",
                                                resize: "none",
                                                border: "none",
                                                background: "none",
                                                width: "100%",
                                                height: "100%",
                                                fontFamily: "Roboto Flex",
                                                fontSize: 14,
                                                whiteSpace: "pre-wrap",
                                                overflow: "auto"
                                            }}>{v.user_answer}</div>
                                        }
                                        {
                                            (!v.user_answer || checkBtnTxt(v) === "Check") && <textarea value={v.user_answer} onChange={(e) => { onQuizInput(e, k) }} style={{
                                                outline: "none",
                                                resize: "none",
                                                border: "none",
                                                background: "none",
                                                width: "100%",
                                                height: "100%",
                                                fontFamily: "Roboto Flex",
                                                fontSize: 14
                                            }} placeholder="Type your answer..."></textarea>
                                        }
                                    </div>
                                    <div style={{ display: checkBtnTxt(v) === "Check" ? "block" : "none" }}>
                                        <div style={{ clear: 'both' }}></div>
                                        <BaseBtn onClick={() => checkQuiz(v)}>{checkBtnTxt(v)}</BaseBtn>
                                    </div>
                                    <div style={{ clear: 'both' }}></div>
                                </>
                            }
                            {
                                v.tp === 1 && <>
                                    <Question dangerouslySetInnerHTML={{ __html: `Q ${k + 1}: ${v.question}` }}></Question>
                                    <div>
                                        {
                                            parseOptions(v?.options)?.map(v2 => (
                                                <div key={v2.option} onClick={() => choiceQuiz(k, v2)}>
                                                    <Option id={`q${v.rid}_${v2.option}`} style={{
                                                        background: quizOptionBg(v, v2)
                                                    }}>
                                                        <span>{v2.option}.</span>
                                                        <span dangerouslySetInnerHTML={{ __html: v2.txt }}></span>
                                                    </Option>
                                                    <div style={{ clear: 'both' }}></div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div style={{ display: showParseTxt(v) !== '' ? "block" : "none", position: "relative" }}>
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}>
                                            <div style={{
                                                height: 1,
                                                background: "#1F1D0F",
                                                width: "calc(100% - 12px)"
                                            }}></div>
                                            {
                                                voiceLoading[v.id] === 3 && <div style={{ cursor: "pointer" }}>
                                                    <LoadingOutlined />
                                                </div>
                                            }
                                            {
                                                voiceLoading[v.id] === 4 && <div style={{ cursor: "pointer" }} onClick={() => {
                                                    audioPool.goonPlayAudio(audioIds.current[v.id])
                                                    setVoiceLoading(pre => {
                                                        const cpPre = cloneDeep(pre)
                                                        cpPre[v.id] = 1
                                                        return cpPre
                                                    })
                                                }}>
                                                    <PlayCircleOutlined />
                                                </div>
                                            }
                                            {
                                                !voiceLoading[v.id] && <div style={{ cursor: "pointer" }} onClick={() => playVoice(v)}>
                                                    <PlayCircleOutlined />
                                                </div>
                                            }
                                            {
                                                voiceLoading[v.id] === 1 && <div style={{ cursor: "pointer" }} onClick={() => {
                                                    audioPool.pauseAudio(audioIds.current[v.id])
                                                    setVoiceLoading(pre => {
                                                        const cpPre = cloneDeep(pre)
                                                        cpPre[v.id] = 4
                                                        return cpPre
                                                    })
                                                }}>
                                                    <PauseCircleOutlined />
                                                </div>
                                            }
                                        </div>
                                        <ParseTxt>{showParseTxt(v)}</ParseTxt>
                                    </div>
                                    <div style={{

                                    }}>
                                        <div onClick={() => checkQuiz(v)} style={{
                                            display: checkBtnTxt(v) === "Help" ? "flex" : "none",
                                            width: 40,
                                            height: 40,
                                            borderRadius: 6,
                                            border: "2px solid #1F1D0F",
                                            background: "#D8F5FB",
                                            boxShadow: "2px 2px 0px 0px #1F1D0F",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            float: "left",
                                            marginTop: 4
                                        }}>
                                            <img style={{ width: 24, height: 24 }} src={HelpIcon} alt='' />
                                        </div>
                                        <div style={{
                                            display: (data.length === quizIndex + 1 && data.length !== 0) || !v.is_answer ? "none" : "block",
                                            float: "right"
                                        }}>
                                            <BaseBtn $loading={v.parse_status === 3} onClick={() => nextQuiz(v)}>Next</BaseBtn>
                                        </div>
                                        <div style={{
                                            display: checkBtnTxt(v) === 'Check' ? "block" : "none",
                                            float: "right"
                                        }}>
                                            <BaseBtn $loading={v.parse_status === 3} onClick={() => checkQuiz(v)}>Check</BaseBtn>
                                        </div>
                                        <div style={{ clear: 'both' }}></div>
                                    </div>
                                    <div style={{ clear: 'both' }}></div>
                                </>
                            }
                        </QuizBody>
                    })
                }
            </div>
            {
                id && <HandlePoint mouseOver={mouseOver} id={id} />
            }
        </div>
    )
}

const Knowledge = styled.span`
    color: #1F1D0F;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 12px;
    font-style: normal;
    font-family: "Roboto Flex600";
    display: inline-flex;
    padding: 2px 8px;
    border-radius: 4px;
    background: #A3F799;
`

const BaseBtn = styled.div`
    color: #1F1D0F;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-family: "Roboto Flex600";
    border-radius: 2px;
    box-shadow: 4px 4px 0px 0px #1F1D0F;
    padding: 11px 31px;
    cursor: pointer;
    float: right;
    border-radius: 6px;
    pointer-events: ${props => props.$loading ? "none" : "auto"};
    border: 2px solid #1F1D0F;
    background: rgb(255, 249, 120);
`

const ParseTxt = styled.div`
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: "Roboto Flex400";
    line-height: 24px; /* 171.429% */
    margin: 16px 0;
`

const QuizBody = styled.div`
    clear: both;
    margin-bottom: 12px;
    position: relative;
`

const Question = styled.div`
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-family: "Roboto Flex600";
    line-height: 22px; /* 157.143% */
    margin-bottom: 16px;
`

const Option = styled.div`
    border-radius: 2px;
    border: 2px solid #000;
    background: #FFF;
    box-shadow: 2px 2px 0px 0px #1F1D0F;
    padding: 4px 8px 4px 4px;
    color: #1F1D0F;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-family: "Roboto Flex600";
    line-height: 24px; /* 171.429% */
    cursor: pointer;
    display: inline-block;
    margin-bottom: 16px;
    position: relative;
    &:hover{
        background: #8DE4FF;
    }
`

const QuizNoBox = styled.div`
    display: flex;
    margin-bottom: 4px;
    flex-wrap: wrap;
`
const AnimateWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;
    left: 0;
    top: 0;
    display: none
`
const QuizNoBoxItem = styled.div`
    width: 32px;
    height: 32px;
    line-height: 32px;
    color: #000;
    font-family: "Roboto Flex600";
    font-size: 16px;
    border-radius: 20px;
    border: 2px solid #000;
    background: #FFF;
    text-align: center;
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 12px;
`

export default Index