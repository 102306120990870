import styled from "styled-components";
import { useEffect, useState, useContext } from 'react'
import { Input, Button } from './components'
import { Context as RouterContext } from '../../router'
import { Context as RequestContext } from '../request'
import { setCookie } from '../../utils/cookie'
import { useNavigate, useParams } from "react-router-dom";
import Select from '../../components/Select'
import { roles, countrys } from '../../utils/define'
import { Logo } from '../../components/icons/welcome'

const Index = (props) => {
  const [role, setRole] = useState("")
  const [country, setCountry] = useState("")
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState("")
  const rctx = useContext(RouterContext)
  const requestCtx = useContext(RequestContext)
  const request = requestCtx.useRequest(false)
  const [errMsg, setErrMsg] = useState("")
  const n = useNavigate()
  const params = useParams()

  useEffect(() => {

    rctx?.onresize()
  }, [])

  const onSubmit = async (e) => {
    e.preventDefault()
    setErrMsg("")
    setLoading(true)
    try {
      let ret = await request.post("/client/open/user/register", {
        flag: params.flag,
        password,
        country,
        role
      })
      localStorage.setItem("token", ret.token)
      // Set a cookie that is accessible on all subdomains of bigread.ai
      setCookie('token', ret.token, 365, '.bigread.ai', '/', true);
      n("/")
    } catch (error) {
      console.log(error)
      setErrMsg(error)
    }
    setLoading(false)
  }


  return (
    <Wrapper>
      <LogoWrapper>
        <Logo style={{ width: "calc(157px * var(--ratio))", height: "calc(58px * var(--ratio))" }} color="#161A19" />
      </LogoWrapper>
      <div style={{ height: "calc(70px * var(--ratio))" }}></div>
      <Content>
        <Title>Sign up</Title>
        <div style={{ paddingLeft: "calc(32px * var(--ratio))", fontSize: "calc(14px * var(--ratio))", color: "#161A19" }}>Before we start, please enter your current location</div>
        <div style={{ background: "#CFF1F0", height: "calc(1px * var(--ratio))", margin: "calc(24px * var(--ratio)) 0" }}></div>
        <div style={{ height: "calc(36px * var(--ratio))" }}></div>
        <form onSubmit={onSubmit}>
          <Select
            value={country}
            placeholder="-select-"
            options={countrys}
            title="Country/Area of Residence"
            onChange={v => setCountry(v)}
          />
          <div style={{ height: "calc(46px * var(--ratio))" }}></div>
          <Select
            value={role}
            placeholder="-select-"
            options={roles}
            title="Primary Role"
            onChange={v => setRole(v)}
          />
          <div style={{ background: "#CFF1F0", height: "calc(1px * var(--ratio))", margin: "calc(24px * var(--ratio)) 0" }}></div>
          <div style={{ height: "calc(24px * var(--ratio))" }}></div>
          <Input value={password} onChange={e => {
            setPassword(e.target.value)
          }} type="password" label="Password" placeholder="Enter your password" />
          <div style={{ height: "calc(24px * var(--ratio))" }}></div>
          <div>
            <Button type="submit" disabled={!country || !role || !password || loading}>{loading ? "loading..." : "Create an account"}</Button>
            {
              errMsg && <div style={{ color: "#DC362E", fontSize: "calc(14px * var(--ratio))", fontWeight: 500, lineHeight: 1.14, textAlign: "center", marginTop: "calc(8px * var(--ratio))" }}>{errMsg}</div>
            }
            <div style={{ height: "calc(32px * var(--ratio))" }}></div>
            <div style={{ color: "#161A19", fontSize: "calc(14px * var(--ratio))", fontWeight: 400, lineHeight: 1.42, textAlign: "center" }}>Already Have An Account? <span onClick={() => n("/login")} style={{ color: "#6A9B9A", cursor: "pointer" }}>Log In</span></div>
          </div>
        </form>
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: #FFF;
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
`

const Content = styled.div`
  width: calc(490px * var(--ratio));
  margin: 0 auto;
  padding: calc(40px * var(--ratio));
`

const Title = styled.div`
  color: var(--black, #161A19);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: calc(48px * var(--ratio));
  font-style: normal;
  font-weight: 800;
  line-height: 1.16;
  padding-left: calc(32px * var(--ratio));
  margin-bottom: calc(8px * var(--ratio));
`

const LogoWrapper = styled.div`
    display: flex;
    align-items: center;
    padding-left: calc(32px * var(--ratio));
    padding-top: calc(32px * var(--ratio));
`

const LogoTxt = styled.span`
    color: #161A19;
    font-size: calc(20px * var(--ratio));
    font-style: normal;
    font-weight: 500;
`


export default Index