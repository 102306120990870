import ReactFlow, {
    MiniMap,
    Background,
    useNodesState,
    useEdgesState,
    ReactFlowProvider
} from 'reactflow';
import React, { useEffect, useRef, useMemo, useCallback, useState, useContext } from 'react';
import 'reactflow/dist/style.css';
import Logo from '../../static/img/20240226-170657.png'
import Input from './input';
import Quiz from '../../components/QuizCard'
import ShortAnswer from './short_answer'
import Related from './related'
import Article from './article'
import WordCard from '../../components/WordCard'
import VideoCard from './video'
import ThreeChat from './chat_3d'
import VideoIframe from './video_iframe'
import VideoSummary from './video_summary'
import AddInputIcon from '../../static/img/20240226-174233.png'
import AddImgIcon from '../../static/img/20240226-174340.png'
import MoreIcon from '../../static/img/20240226-174450.png'
import { cloneDeep } from 'lodash';
import mitt from 'mitt'
import { MsgProgressIng, MsgTypeArticleVoice, MsgTypeVoice, MsgProgressEnd, MsgTypeChat, MsgTypeQuizParseFree, MsgTypeVoiceQuizParseFree, MsgTypeHandleVideoSummary, MsgTypeHandleVideoSummaryVoice, MsgTypeV2WWWSearchSummaryRelated, MsgTypeV2WWWSearchSummaryArticle } from '../../utils/define'
import { EventEmitter as Remitter, Context as AuthContext } from '../auth'
import { useNavigate, useParams } from 'react-router-dom';
import * as apis from '../../lib/api'
import ZoomContron from './zoom'
import { message } from 'antd';
import { sendServiceWorker } from '../../utils/service_worker'
import styled from "styled-components";
import { AudioPool } from '../../utils'
import BackIcon from '../../static/img/20231024-173109.png'
import axios from 'axios'
import { Context as RequestContext } from '../request'

export const emitter = mitt()

const Index = () => {
    const currNodesEdgesData = useRef(null)
    const param = useParams()
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const zoomUtilRef = useRef()
    const times = useRef({})
    const audioPool = useRef(new AudioPool())
    const [zoomScroll, setZoomScroll] = useState(true)
    const n = useNavigate()
    const requestCtx = useContext(RequestContext)
    const request = requestCtx.useRequest()
    const authCtx = useContext(AuthContext)

    // const onInputSearch = useCallback((value, xPos, yPos, id, targetId) => {
    //     const position = { x: xPos + 400, y: yPos + 200 }
    //     const newNode = {
    //         id: targetId,
    //         type: 'shortAnswer',
    //         data: {
    //             searchVal: value.title,
    //             value: '',
    //             context: value.context,
    //             sources: JSON.parse(value.sources)
    //         },
    //         position
    //     }
    //     setNodes(pre => {
    //         const cpNodes = cloneDeep(pre)
    //         cpNodes.push(newNode)
    //         return cpNodes
    //     })
    //     setEdges(pre => {
    //         const cpEdges = cloneDeep(pre)
    //         cpEdges.push({ id: `random-id-${new Date().getTime()}`, source: id, target: targetId, animated: true })
    //         return cpEdges
    //     })
    //     times.current[new Date().getTime()] = setTimeout(() => {
    //         zoomUtilRef.current.moveToNodeId(targetId)
    //         setNodes(pre => {
    //             const node = pre.find(v => v.id === id)
    //             if (node) {
    //                 onNodeDrag({}, node)
    //             }
    //             return pre
    //         })
    //     }, 300)
    // }, [])

    const onDisabledFocus = useCallback(({ id, disabled }) => {
        setNodes(pre => {
            const cpPre = cloneDeep(pre)
            const fidx = cpPre.findIndex(i => i.id === id)
            if (fidx !== -1) {
                cpPre[fidx].data.disabled = disabled
            }
            return cpPre
        })
    }, [])

    const onInputChange = useCallback(({ id, val }) => {
        setNodes(pre => {
            const cpPre = cloneDeep(pre)
            const fidx = cpPre.findIndex(i => i.id === id)
            if (fidx !== -1) {
                cpPre[fidx].data.value = val
            }
            return cpPre
        })
    }, [])


    const onInputFocus = (id) => {
        //zoomUtilRef.current.moveToNodeId(id)
    }


    const updateData = async () => {
        // console.log(flow.getViewport())
        times.current[new Date().getTime()] = setTimeout(async () => {
            if (!currNodesEdgesData.current) {
                updateData()
                return
            }
            const flow = await zoomUtilRef.current.getFlow()
            sendServiceWorker('WORKER_EVENT_REQUEST', {
                url: `${process.env.REACT_APP_API_HOST}${apis.FREE_UPDATE}`,
                options: {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': localStorage.getItem('token')
                    },
                    body: JSON.stringify({
                        hash_id: param.id,
                        data: JSON.stringify({
                            ...currNodesEdgesData.current,
                            viewPort: flow.getViewport()
                        })
                    })
                }
            })
            updateData()
        }, 3000)
    }

    useEffect(() => {
        const obj = {}
        obj.nodes = nodes
        obj.edges = edges
        // console.log(JSON.stringify(obj))
        currNodesEdgesData.current = obj
    }, [nodes, edges])

    const onMessage = (message) => {
        if (message.msg_type === MsgTypeQuizParseFree) {
            if (message.msg_progress === MsgProgressIng) {
                setNodes(pre => {
                    const cpNodes = cloneDeep(pre)
                    const nodeIndex = cpNodes.findIndex(v => v.id === message.node_id)
                    if (nodeIndex !== -1) {
                        const quizIdx = cpNodes[nodeIndex].data.value.findIndex(i => i.id === message.quiz_id)
                        cpNodes[nodeIndex].data.value[quizIdx].parse_status = 2
                        cpNodes[nodeIndex].data.value[quizIdx].parse = cpNodes[nodeIndex].data.value[quizIdx].parse || ''
                        cpNodes[nodeIndex].data.value[quizIdx].parse += message.msg
                    }
                    return cpNodes
                })
            }
        }
        if (message.msg_type === MsgTypeHandleVideoSummary) {
            if (message.msg_progress === MsgProgressIng) {
                setNodes(pre => {
                    const cpNodes = cloneDeep(pre)
                    const nodeIndex = cpNodes.findIndex(v => v.id === message.node_id)
                    if (nodeIndex !== -1) {
                        cpNodes[nodeIndex].data.value = cpNodes[nodeIndex].data.value || ''
                        cpNodes[nodeIndex].data.value += message.msg
                    }
                    return cpNodes
                })
            }
        }
    }
    //
    const getVideoSummary = async (nodeId, videoId) => {
        try {
            await request.post('/client/api/message/send', {
                msg_type: MsgTypeHandleVideoSummary,
                node_id: nodeId,
                video_id: videoId
            })
            return true
        } catch (error) {
            message.info("The video has no content or Youtube has not authorized it")
            return false
        }
    }


    const uploadCover = (call = () => { }) => {
        const { html2canvas } = window
        const flowDom = document.querySelector(".react-flow")
        if (!flowDom) {
            return
        }
        html2canvas(flowDom).then(function (canvas) {
            canvas.toBlob(b => {
                const formData = new FormData();
                formData.append('file', b); // 将blob文件添加到FormData
                formData.append('hash_id', param.id); // 添加其他参数
                axios.post(`${process.env.REACT_APP_API_HOST}/client/api/free/update/cover`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data', // 设置请求头，必须使用multipart/form-data格式
                        'Authorization': localStorage.getItem('token') // 添加header
                    }
                }).then(response => {
                    call()
                    // 处理响应数据
                }).catch(error => {
                    // 处理错误
                });
            }, 'image/jpeg', 0.5)
        });
    }


    useEffect(() => {
        request.get(`/client/api/free/detail?hash_id=${param.id}`).then(async detail => {
            await authCtx.useEventSource()
            Remitter.on('message', onMessage)
            if (!detail.data) {
                detail.data = JSON.stringify({ nodes: [], edges: [], viewPort: { x: 0, y: 0, zoom: 1 } })
            }
            try {
                detail.data = JSON.parse(detail.data)
                detail.data.nodes = detail.data.nodes || []
                detail.data.edges = detail.data.edges || []
                detail.data.viewPort = detail.data.viewPort || { x: 0, y: 0, zoom: 1 }
                detail.data.viewPort.zoom = 1

                currNodesEdgesData.current = detail.data
                setNodes(detail.data.nodes)
                setEdges(detail.data.edges)
                const flow = await zoomUtilRef.current.getFlow()
                flow.setViewport(detail.data.viewPort, { duration: 300 })
                if (detail.data.nodes.length === 0) {
                    addInput(false)
                }

                times.current[new Date().getTime()] = setTimeout(uploadCover, 1000)

            } catch (error) {

            }
            updateData()
        })


        const unUseCollectOnlineTime = authCtx.useCollectOnlineTime(new Date().getTime(), "FREE")

        const ts = times.current
        return () => {
            setTimeout(() => {
                unUseCollectOnlineTime()
            }, 0)
            Remitter.off('message', onMessage)
            audioPool.current.destroy()
            for (let i in ts) {
                clearTimeout(ts[i])
            }
            authCtx.unUseEventSource()
        }
        // eslint-disable-next-line
    }, [])

    const getNextNodePosition = (newNodeWidth, newNodeHeight) => {
        // 例如简单策略：从左上角开始横向寻找，找到空位为止
        let x = 0;
        let y = 0;
        const gap = 50; // 节点之间保留的最小间隙

        while (nodeOverlapsAnyOtherNode(x, y, newNodeWidth, newNodeHeight, currNodesEdgesData.current.nodes, gap)) {
            // 假设我们从左往右，从上往下寻找空闲位置
            // 这里的逻辑可以根据实际需要更复杂
            x += newNodeWidth + gap;
            if (x + newNodeWidth + gap > document.body.clientWidth) {
                x = 0;
                y += newNodeHeight + gap;
            }
        }

        return { x, y };
    };

    const nodeOverlapsAnyOtherNode = (x, y, newNodeWidth, newNodeHeight, nodes, gap) => {
        return nodes.some(node => {
            // 检查提供的坐标是否与现有节点重叠
            return (
                x + newNodeWidth + gap > node.position.x &&
                x < node.position.x + node.width + gap &&
                y + newNodeHeight + gap > node.position.y &&
                y < node.position.y + node.height + gap
            );
        });
    };

    const addInput = (pos = true) => {
        const position = { x: 200, y: 200 }
        const newNode = {
            type: 'diyinput', position, data: {
                value: ''
            }
        }
        // newNode.position = getNextNodePosition(508, 36)
        // reactFlowInstance.setViewport(newNode.position);
        newNode.id = `random-id-${new Date().getTime()}`
        setNodes(pre => {
            const cpPre = cloneDeep(pre)
            cpPre.push(newNode)
            return cpPre
        })
        if (pos) {
            times.current[new Date().getTime()] = setTimeout(() => {
                zoomUtilRef.current.moveToNodeId(newNode.id)
            }, 300)
        }

    }

    const addChat = () => {
        const f = currNodesEdgesData.current.nodes.find(v => v.type === 'threeChat')
        if (f) {
            zoomUtilRef.current.moveToNodeId(f.id)
            return
        }
        const position = { x: 200, y: 200 }
        const cpNodes = cloneDeep(nodes)
        const newNode = {
            type: 'threeChat', position, data: {
                value: '',
                hashId: param.id
            }
        }
        newNode.position = getNextNodePosition(700, 400)
        // reactFlowInstance.setViewport(newNode.position);
        newNode.id = `random-id-${new Date().getTime()}`
        cpNodes.push(newNode)
        setNodes(cpNodes)
        times.current[new Date().getTime()] = setTimeout(() => {
            zoomUtilRef.current.moveToNodeId(newNode.id)
        }, 300)
    }

    const findNodes = (id) => {
        const nodes = []
        for (let i = 0; i < currNodesEdgesData.current.edges.length; i++) {
            let item = currNodesEdgesData.current.edges[i]
            if (item.target === id) {
                const node = currNodesEdgesData.current.nodes.find(v => v.id === item.source)
                if (node) {
                    nodes.push(node)
                }
            }
            if (item.source === id) {
                const node = currNodesEdgesData.current.nodes.find(v => v.id === item.target)
                if (node) {
                    nodes.push(node)
                }
            }
        }
        return nodes
    }

    const findNodesPointsPosition = (nodes) => {
        const points = []
        for (let i = 0; i < nodes.length; i++) {
            let node = nodes[i]
            const { width, height, id } = node
            const { x, y } = node.position
            const pointArr = {
                "node_id": id,
                "points": {
                    "top": {
                        id: id + "top",
                        x: x + width / 2,
                        y
                    },
                    "bottom": {
                        id: id + "bottom",
                        x: x + width / 2,
                        y: y + height
                    },
                    "left": {
                        id: id + "left",
                        x,
                        y: y + height / 2
                    },
                    "right": {
                        id: id + "right",
                        x: x + width,
                        y: y + height / 2
                    }
                }
            }
            points.push(pointArr)
        }
        return points
    }

    const calculateDistance = (point1, point2) => {
        return Math.sqrt(Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2));
    };

    const findNearPoints = (source, targets) => {
        // console.log("source,targets", JSON.stringify(source), JSON.stringify(targets))
        let minDistance = Infinity;
        let closestPair = {
            source: null,
            target: null
        };
        // For each point in source's points
        for (const sourcePointKey of Object.keys(source.points)) {
            const sourcePoint = source.points[sourcePointKey];
            // Compare with each target and their points
            for (const target of targets) {
                for (const targetPointKey of Object.keys(target.points)) {
                    const targetPoint = target.points[targetPointKey];
                    const distance = calculateDistance(sourcePoint, targetPoint);
                    // If the calculated distance is less than minDistance, update minDistance
                    if (distance < minDistance) {
                        minDistance = distance;

                        closestPair.source = {
                            node_id: source.node_id,
                            ...sourcePoint,
                            position: sourcePointKey,
                        };
                        closestPair.target = {
                            node_id: target.node_id,
                            ...targetPoint,
                            position: sourcePointKey,
                        };
                    }
                }
            }
        }

        return closestPair;
    };

    const onNodeDrag = (e, node) => {
        const { x, y } = node.position
        const { width, height, id } = node
        const sourcePoint = {
            "node_id": id,
            "points": {
                "top": {
                    id: id + "top",
                    x: x + width / 2,
                    y
                },
                "bottom": {
                    id: id + "bottom",
                    x: x + width / 2,
                    y: y + height
                },
                "left": {
                    id: id + "left",
                    x,
                    y: y + height / 2
                },
                "right": {
                    id: id + "right",
                    x: x + width,
                    y: y + height / 2
                }
            }
        }
        //找到和它相连的节点
        const nearNodes = findNodes(id)
        //找出相连的节点内所有的连接点坐标
        const targetPointArr = findNodesPointsPosition(nearNodes)
        const nearPoints = []
        for (let i = 0; i < targetPointArr.length; i++) {
            const item = targetPointArr[i]
            const nearPoint = findNearPoints(sourcePoint, [item])
            nearPoints.push(nearPoint)
        }
        setEdges(edges => {
            let cpEdges = cloneDeep(edges)
            const sources = []
            for (let i = 0; i < cpEdges.length; i++) {
                const item = cpEdges[i]
                if (item.source === id || item.target === id) {
                    sources.push(i)
                }
            }
            for (let i = 0; i < sources.length; i++) {
                const index = sources[i]
                cpEdges[index].animated = true
                for (let j = 0; j < nearPoints.length; j++) {
                    const item = nearPoints[j]
                    if (item.source.node_id === cpEdges[index].target && item.target.node_id === cpEdges[index].source) {
                        cpEdges[index].sourceHandle = `source${item.target.id}`
                        cpEdges[index].targetHandle = `target${item.source.id}`
                    }
                    if (item.source.node_id === cpEdges[index].target && item.target.node_id === cpEdges[index].target) {
                        cpEdges[index].sourceHandle = `source${item.source.id}`
                        cpEdges[index].targetHandle = `target${item.target.id}`
                    }
                }
            }
            return cpEdges
        })
        return
    }



    const onRelatedClick = useCallback(async (title, xPos, yPos, id) => {
        //将title设置为已生成
        setNodes(pre => {
            const cpNodes = cloneDeep(pre)
            const fidx = cpNodes.findIndex(v => v.id === id)
            if (fidx !== -1) {
                cpNodes[fidx].data.makeds = cpNodes[fidx].data.makeds || {}
                cpNodes[fidx].data.makeds[title] = true
            }
            return cpNodes
        })
        const targetId = `random-id-${new Date().getTime()}`
        //添加文章节点
        const position = { x: xPos + 400, y: yPos + 200 }
        const newNode = {
            id: targetId,
            type: 'article',
            data: {
                hashId: "",
                title,
                relatedId: id
            },
            position
        }
        setNodes(pre => {
            const cpNodes = cloneDeep(pre)
            cpNodes.push(newNode)
            return cpNodes
        })
        setEdges(pre => {
            const cpEdges = cloneDeep(pre)
            cpEdges.push({ id: `random-id-${new Date().getTime()}`, source: id, target: targetId, animated: true })
            return cpEdges
        })
        times.current[new Date().getTime()] = setTimeout(() => {
            zoomUtilRef.current.moveToNodeId(targetId)
            setNodes(pre => {
                const node = pre.find(v => v.id === targetId)
                if (node) {
                    onNodeDrag({}, node)
                }
                return pre
            })
        }, 300)
        //生成文章
        try {
            const info = await request.post('/client/api/message/send', {
                msg_type: MsgTypeV2WWWSearchSummaryArticle,
                title,
                node_id: targetId,
                llm_prompt_id: 26
            })
            //更新文章的hashId
            setNodes(pre => {
                const cpNodes = cloneDeep(pre)
                const fidx = cpNodes.findIndex(v => v.id === targetId)
                if (fidx !== -1) {
                    cpNodes[fidx].data.hashId = info.hash_id
                }
                return cpNodes
            })
        } catch (error) {

        }
    }, [])

    const onArticleLoadWordCard = useCallback(async (id, xPos, yPos, getData) => {
        setNodes(pre => {
            const cpNodes = cloneDeep(pre)
            const fidx = cpNodes.findIndex(v => v.id === id)
            if (fidx !== -1) {
                cpNodes[fidx].data.makeds = cpNodes[fidx].data.makeds || {}
                cpNodes[fidx].data.makeds["wordCard"] = true
            }
            return cpNodes
        })
        const targetId = `random-id-${new Date().getTime()}`
        const position = { x: xPos + 400, y: yPos + 200 }
        const newNode = {
            id: targetId,
            type: 'wordCard',
            data: {
                value: []
            },
            position
        }
        setNodes(pre => {
            const cpNodes = cloneDeep(pre)
            cpNodes.push(newNode)
            return cpNodes
        })
        setEdges(pre => {
            const cpEdges = cloneDeep(pre)
            cpEdges.push({ id: `random-id-${new Date().getTime()}`, source: id, target: targetId, animated: true })
            return cpEdges
        })
        times.current[new Date().getTime()] = setTimeout(() => {
            zoomUtilRef.current.moveToNodeId(targetId)
            setNodes(pre => {
                const node = pre.find(v => v.id === id)
                if (node) {
                    onNodeDrag({}, node)
                }
                return pre
            })
        }, 300)
        try {
            const data = await getData()
            //更新文章的hashId
            setNodes(pre => {
                const cpNodes = cloneDeep(pre)
                const fidx = cpNodes.findIndex(v => v.id === targetId)
                if (fidx !== -1) {
                    cpNodes[fidx].data.value = data
                }
                return cpNodes
            })
        } catch (error) {
            console.log(error)
        }
    }, [])

    const onArticleLoadQuizCard = useCallback(async (hashId, id, xPos, yPos, getData) => {
        setNodes(pre => {
            const cpNodes = cloneDeep(pre)
            const fidx = cpNodes.findIndex(v => v.id === id)
            if (fidx !== -1) {
                cpNodes[fidx].data.makeds = cpNodes[fidx].data.makeds || {}
                cpNodes[fidx].data.makeds["quizCard"] = true
            }
            return cpNodes
        })
        const targetId = `random-id-${new Date().getTime()}`
        const position = { x: xPos + 400, y: yPos + 200 }
        const newNode = {
            id: targetId,
            type: 'quizCard',
            data: {
                value: [],
                hashId
            },
            position
        }
        setNodes(pre => {
            const cpNodes = cloneDeep(pre)
            cpNodes.push(newNode)
            return cpNodes
        })
        setEdges(pre => {
            const cpEdges = cloneDeep(pre)
            cpEdges.push({ id: `random-id-${new Date().getTime()}`, source: id, target: targetId, animated: true })
            return cpEdges
        })
        times.current[new Date().getTime()] = setTimeout(() => {
            zoomUtilRef.current.moveToNodeId(targetId)
            setNodes(pre => {
                const node = pre.find(v => v.id === id)
                if (node) {
                    onNodeDrag({}, node)
                }
                return pre
            })
        }, 300)
        try {
            const data = await getData()
            setNodes(pre => {
                const cpNodes = cloneDeep(pre)
                const fidx = cpNodes.findIndex(v => v.id === targetId)
                if (fidx !== -1) {
                    cpNodes[fidx].data.value = data
                }
                return cpNodes
            })
        } catch (error) {
            console.log(error)
        }
    }, [])

    const onQuizDataChange = useCallback((id, data, isUpdate) => {
        setNodes(pre => {
            const cpNodes = cloneDeep(pre)
            const fidx = cpNodes.findIndex(v => v.id === id)
            if (fidx !== -1) {
                cpNodes[fidx].data.value = data
            }
            return cpNodes
        })
    }, [])

    const onArticleLoadVideoCard = useCallback(async (id, xPos, yPos, getData) => {
        setNodes(pre => {
            const cpNodes = cloneDeep(pre)
            const fidx = cpNodes.findIndex(v => v.id === id)
            if (fidx !== -1) {
                cpNodes[fidx].data.makeds = cpNodes[fidx].data.makeds || {}
                cpNodes[fidx].data.makeds["videoCard"] = true
            }
            return cpNodes
        })
        const targetId = `random-id-${new Date().getTime()}`
        const position = { x: xPos + 400, y: yPos + 200 }
        const newNode = {
            id: targetId,
            type: 'videoCard',
            data: {
                value: {}
            },
            position
        }
        setNodes(pre => {
            const cpNodes = cloneDeep(pre)
            cpNodes.push(newNode)
            return cpNodes
        })
        const edgesId = `random-id-${new Date().getTime()}`
        setEdges(pre => {
            const cpEdges = cloneDeep(pre)
            cpEdges.push({ id: edgesId, source: id, target: targetId, animated: true })
            return cpEdges
        })
        times.current[new Date().getTime()] = setTimeout(() => {
            zoomUtilRef.current.moveToNodeId(targetId)
            setNodes(pre => {
                const node = pre.find(v => v.id === id)
                if (node) {
                    onNodeDrag({}, node)
                }
                return pre
            })
        }, 300)
        try {
            const data = await getData()
            if (!data) {
                //删除节点
                setNodes(pre => {
                    const cpNodes = cloneDeep(pre)
                    const fidx = cpNodes.findIndex(v => v.id === targetId)
                    if (fidx !== -1) {
                        cpNodes.splice(fidx, 1)
                    }
                    const fidx2 = cpNodes.findIndex(v => v.id === id)
                    if (fidx2 !== -1) {
                        cpNodes[fidx2].data.makeds["videoCard"] = false
                    }
                    return cpNodes
                })
                //删除连线
                setEdges(pre => {
                    const cpEdges = cloneDeep(pre)
                    const fidx = cpEdges.findIndex(v => v.id === edgesId)
                    if (fidx !== -1) {
                        cpEdges.splice(fidx, 1)
                    }
                    return cpEdges
                })
            } else {
                setNodes(pre => {
                    const cpNodes = cloneDeep(pre)
                    const fidx = cpNodes.findIndex(v => v.id === targetId)
                    if (fidx !== -1) {
                        cpNodes[fidx].data.value = data
                    }
                    return cpNodes
                })
            }

        } catch (error) {
            console.log(error)
        }
    }, [])

    const onVideoIfameSummary = useCallback(async (id, xPos, yPos, videoId) => {
        setNodes(pre => {
            const cpNodes = cloneDeep(pre)
            const fidx = cpNodes.findIndex(v => v.id === id)
            if (fidx !== -1) {
                cpNodes[fidx].data.makeds = cpNodes[fidx].data.makeds || {}
                cpNodes[fidx].data.makeds["summary"] = true
            }
            return cpNodes
        })
        const targetId = `random-id-${new Date().getTime()}`
        const position = { x: xPos + 400, y: yPos + 200 }
        const newNode = {
            id: targetId,
            type: 'videoSummary',
            data: {
                value: ''
            },
            position
        }
        setNodes(pre => {
            const cpNodes = cloneDeep(pre)
            cpNodes.push(newNode)
            return cpNodes
        })
        const edgesId = `random-id-${new Date().getTime()}`
        setEdges(pre => {
            const cpEdges = cloneDeep(pre)
            cpEdges.push({ id: edgesId, source: id, target: targetId, animated: true })
            return cpEdges
        })
        times.current[new Date().getTime()] = setTimeout(() => {
            zoomUtilRef.current.moveToNodeId(targetId)
            setNodes(pre => {
                const node = pre.find(v => v.id === id)
                if (node) {
                    onNodeDrag({}, node)
                }
                return pre
            })
        }, 300)
        try {
            const ok = await getVideoSummary(targetId, videoId)
            if (!ok) {
                //删除节点
                setNodes(pre => {
                    const cpNodes = cloneDeep(pre)
                    const fidx = cpNodes.findIndex(v => v.id === targetId)
                    if (fidx !== -1) {
                        cpNodes.splice(fidx, 1)
                    }
                    const fidx2 = cpNodes.findIndex(v => v.id === id)
                    if (fidx2 !== -1) {
                        cpNodes[fidx2].data.makeds["summary"] = false
                    }
                    return cpNodes
                })
                //删除连线
                setEdges(pre => {
                    const cpEdges = cloneDeep(pre)
                    const fidx = cpEdges.findIndex(v => v.id === edgesId)
                    if (fidx !== -1) {
                        cpEdges.splice(fidx, 1)
                    }
                    return cpEdges
                })
            }
        } catch (error) {
            console.log(error)
        }
    }, [])

    const onVideoPlay = useCallback((id, xPos, yPos, value) => {
        setNodes(pre => {
            const cpNodes = cloneDeep(pre)
            const fidx = cpNodes.findIndex(v => v.id === id)
            if (fidx !== -1) {
                cpNodes[fidx].data.makeds = cpNodes[fidx].data.makeds || {}
                cpNodes[fidx].data.makeds[value.id.videoId] = true
            }
            return cpNodes
        })
        const targetId = `random-id-${new Date().getTime()}`
        const position = { x: xPos + 400, y: yPos + 200 }
        const newNode = {
            id: targetId,
            type: 'videoIframe',
            data: {
                value: value.id.videoId
            },
            position
        }
        setNodes(pre => {
            const cpNodes = cloneDeep(pre)
            cpNodes.push(newNode)
            return cpNodes
        })
        setEdges(pre => {
            const cpEdges = cloneDeep(pre)
            cpEdges.push({ id: `random-id-${new Date().getTime()}`, source: id, target: targetId, animated: true })
            return cpEdges
        })
        times.current[new Date().getTime()] = setTimeout(() => {
            zoomUtilRef.current.moveToNodeId(targetId)
            setNodes(pre => {
                const node = pre.find(v => v.id === id)
                if (node) {
                    onNodeDrag({}, node)
                }
                return pre
            })
        }, 300)
    }, [])

    const onChat3DMouseOver = useCallback((b) => {
        setZoomScroll(!b)
    }, [])

    const onInputSearch = useCallback((info, searchVal, id, shortAnswerId) => {
        setNodes(pre => {
            const cpNodes = cloneDeep(pre)
            const fidx = cpNodes.findIndex(v => v.id === id)
            if (fidx !== -1) {
                let images
                try {
                    info.images = JSON.parse(info.images)
                } catch (error) {

                }
                if (info.images) {
                    images = info.images
                }
                cpNodes[fidx].data.shorAnswerData = {
                    searchVal,
                    id: shortAnswerId,
                    showShorAnswer: true,
                    value: '',
                    context: info.context,
                    sources: JSON.parse(info.sources),
                    images
                }
            }
            return cpNodes
        })
    }, [])

    const onShorAnswerValue = useCallback((id, msg) => {
        setNodes(pre => {
            const cpNodes = cloneDeep(pre)
            const fidx = cpNodes.findIndex(v => v.id === id)
            if (fidx !== -1) {
                cpNodes[fidx].data.shorAnswerData.value += msg
            }
            return cpNodes
        })
    }, [])

    const onShorAnswerValueEnd = useCallback(async ({ id, xPos, yPos, context, title, getRelated }) => {
        const targetId = `random-id-${new Date().getTime()}`
        const position = { x: xPos + 800, y: yPos + 200 }
        const newNode = {
            id: targetId,
            type: 'related',
            data: {
                value: []
            },
            position
        }
        setNodes(pre => {
            const cpNodes = cloneDeep(pre)
            cpNodes.push(newNode)
            return cpNodes
        })
        setEdges(pre => {
            const cpEdges = cloneDeep(pre)
            cpEdges.push({ id: `random-id-${new Date().getTime()}`, source: id, target: targetId, animated: true })
            return cpEdges
        })
        times.current[new Date().getTime()] = setTimeout(async () => {
            zoomUtilRef.current.moveToNodeId(targetId)
            setNodes(pre => {
                const node = pre.find(v => v.id === targetId)
                if (node) {
                    onNodeDrag({}, node)
                }
                return pre
            })
        }, 300)
        const relateds = await getRelated(title, context)
        setNodes(pre => {
            const cpNodes = cloneDeep(pre)
            const idx = pre.findIndex(v => v.id === targetId)
            if (idx !== -1) {
                cpNodes[idx].data.value = relateds
            }
            return cpNodes
        })
    }, [/* ...dependencies... */]);

    const memoizedNodeTypes = useMemo(() => {
        return {
            diyinput: props => (
                <Input
                    onInputChange={onInputChange}
                    onInputFocus={onInputFocus}
                    onDisabledFocus={onDisabledFocus}
                    onInputSearch={onInputSearch}
                    onShorAnswerValue={onShorAnswerValue}
                    onShorAnswerValueEnd={onShorAnswerValueEnd}
                    {...props}
                />
            ),
            related: props => (
                <Related
                    {...props}
                    onRelatedClick={onRelatedClick}
                />
            ),

            threeChat: props => (
                <ThreeChat
                    {...props}
                    onChat3DMouseOver={onChat3DMouseOver}
                    audioPool={audioPool.current}
                />
            ),
            article: props => (
                <Article
                    {...props}
                    audioPool={audioPool.current}
                    onArticleLoadWordCard={onArticleLoadWordCard}
                    onArticleLoadQuizCard={onArticleLoadQuizCard}
                    onArticleLoadVideoCard={onArticleLoadVideoCard}
                    onArticleDelete={(nodeId) => {
                        setNodes(pre => {
                            const cpNodes = cloneDeep(pre)
                            const idx = cpNodes.findIndex(v => v.id === nodeId)
                            if (idx !== -1) {
                                const relatedIdx = cpNodes.findIndex(j => j.id === cpNodes[idx].data.relatedId)
                                cpNodes[relatedIdx].data.makeds[cpNodes[idx].data.title] = false
                                cpNodes.splice(idx, 1)
                            }
                            return cpNodes
                        })
                    }}
                />
            ),
            wordCard: props => (
                <WordCardWrapper>
                    <WordCard
                        {...props}
                    />
                </WordCardWrapper>
            ),
            videoCard: props => (
                <VideoCard
                    {...props}
                    onVideoPlay={onVideoPlay}
                />
            ),
            videoIframe: props => (
                <VideoIframe
                    {...props}
                    onVideoIfameSummary={onVideoIfameSummary}
                />
            ),
            videoSummary: VideoSummary,
            quizCard: props => (
                <QuizCardWrapper>
                    <Quiz
                        {...props}
                        onChange={(data, needUpdate) => onQuizDataChange(props.id, data, needUpdate)}
                        audioPool={audioPool.current}
                    />
                </QuizCardWrapper>
            )
        }
    }, [
        onInputChange,
        onDisabledFocus,
        onArticleLoadWordCard
    ]);

    return (
        <div style={{ width: '100%', height: '100vh', backgroundColor: 'rgb(254,254,250)', position: 'relative' }}>
            <BackWrapper onClick={() => {
                uploadCover(() => {
                    n("/freelist")
                })
            }}>
                <img src={BackIcon} style={{ width: 48, height: 48, marginTop: 6, marginRight: 24 }} alt="" />
            </BackWrapper>
            <LogoWrapper>
                <img style={{ width: 32, height: 32, marginLeft: 11 }} src={Logo} alt='' />
                <div style={{ marginRight: 14 }}>BigRead.ai</div>
            </LogoWrapper>
            <Left>
                <LeftItem onClick={() => addInput(true)}>
                    <img src={AddInputIcon} alt='' />
                </LeftItem>
                <LeftItem>
                    <img src={AddImgIcon} alt='' />
                </LeftItem>
                <LeftItem style={{ border: 'none' }} onClick={addChat}>
                    <img src={MoreIcon} alt='' />
                </LeftItem>
            </Left>
            <ReactFlowProvider>
                <ReactFlow
                    zoomOnScroll={zoomScroll}
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    // onConnect={onConnect}
                    nodeTypes={memoizedNodeTypes}
                    onNodeDrag={onNodeDrag}
                    onMove={() => {
                        try {
                            zoomUtilRef.current.updateZoomVal()
                        } catch (error) {

                        }
                    }}
                >
                    {/* <Controls /> */}
                    {/* <MiniMap pannable={true} zoomable={false} /> */}
                    <ZoomContron action={zoomUtilRef} />
                    <Background color='rgba(0,0,0,0.05)' variant="dots" gap={20} size={4} />
                </ReactFlow>
            </ReactFlowProvider>
        </div>
    )
}

const WordCardWrapper = styled.div`
    border-radius: 6px;
    border: 2px solid #1F1D0F;
    background: #EDF2F3;
    box-shadow: 2px 2px 0px 0px #1F1D0F;
    width: 632px;
    height: 430px;
    padding: 32px;
`
const QuizCardWrapper = styled.div`
    border-radius: 6px;
    border: 2px solid #1F1D0F;
    background: #FFF2D9;
    box-shadow: 2px 2px 0px 0px #1F1D0F;
    width: 596px;
    padding: 20px;
    min-height: 300px;
`

const LogoWrapper = styled.div`
    border-radius: 6px;
    border: 2px solid #1F1D0F;
    background: #FFF;
    box-shadow: 2px 2px 0px 0px #1F1D0F;
    width: 147px;
    height: 40px;
    position: fixed;
    color: #1F1D0F;
    font-family: "Roboto Slab800";
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 9;
    left: 75px;
    top: 16px;
`

const BackWrapper = styled.div`
    position: fixed;
    z-index: 9;
    left: 16px;
    top: 9px;
    cursor: pointer;
`

const Left = styled.div`
    width: 40px;
    position: absolute;
    z-index: 9;
    left: 16px;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 128px;
    background: #FFF;
    border: 2px solid #1F1D0F;
    box-shadow: 2px 2px 0px 0px #1F1D0F;
    border-radius: 6px;
    overflow: hidden;
`

const LeftItem = styled.div`
    width: 100%;
    height: 42px;
    border-bottom: 2px solid #1F1D0F;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img{
        width: 24px;
        height: 24px;
    }
    &:hover{
        background: #FF5C5C;
    }
`

export default Index