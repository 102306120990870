import {
  Animation,
  Vector3,
} from "babylonjs";
import { MAIN_CAMERA_NAME, models } from "../constants";

export const v3 = (x = 0, y = 0, z = 0) => {
  return new Vector3(x, y, z);
};

export const getMorphTargetNames = (morphTargetManager) => {
  const morphTargetNames = [];

  for (let i = 0; i < morphTargetManager.numTargets; i++) {
    morphTargetNames.push(morphTargetManager.getTarget(i).name);
  }

  return morphTargetNames;
};

export const playMorphTargetAnim = (
  name,
  durationsSeconds,
  values,
  morphTarget,
  endCallback,
  scene
) => {
  const keyFrames = [];
  const framesPerSecond = 60;

  let previousFrame = 0;
  for (let i = 0; i < values.length; i++) {
    const currentFrame = previousFrame + durationsSeconds[i] * framesPerSecond;

    keyFrames.push({
      frame: currentFrame,
      value: values[i],
    });

    previousFrame = currentFrame;
  }

  const lastFrame = keyFrames.at(-1);

  var morphAnimation = new Animation(
    name,
    "influence",
    framesPerSecond,
    Animation.ANIMATIONTYPE_FLOAT
  );
  morphAnimation.setKeys(keyFrames);

  morphTarget.animations = [morphAnimation];

  if (!lastFrame) {
    throw new Error("lastFrame is undefined");
  }

  scene.beginAnimation(morphTarget, 0, lastFrame.frame, false, 1, endCallback);
};

// TODO: FIXME: I don't think this is working when models switch.
export const updateCamera = (modelName, scene) => {
  const modelConfig = models[modelName];

  const modelCameraConfig = modelConfig.cameraConfig;

  // Get camera by name:
  scene.cameras.forEach((camera) => {
    if (camera.name === MAIN_CAMERA_NAME) {
      (camera).alpha = modelCameraConfig.alpha;
      (camera).beta = modelCameraConfig.beta;
      (camera).radius = modelCameraConfig.radius;
      (camera).target = modelCameraConfig.target;

      // Collisions may override the values we set. Disable collisions just to compute the world matrix.
      (camera).checkCollisions = false;
      (camera).computeWorldMatrix();
      (camera).checkCollisions = true;

      // XXX: For debugging purposes:
      // @ts-ignore
      window.camera = camera;
    }
  });
};

export const getMorphTargetIndex = (
  morphTargetManager,
  targetName
) => {
  for (let i = 0; i < morphTargetManager.numTargets; i++) {
    const target = morphTargetManager.getTarget(i);

    if (target.name === targetName) {
      return i;
    }
  }

  return -1;
};
