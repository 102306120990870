import styled from "styled-components";
import ExportBtn from '../../components/icons/export_pdf_btn'
import { TaskInfoTag, TaskInfoTagIcon } from '../reading_path'
import StepProgress from '../../components/StepProgress'
import { useEffect, useState, useContext, useRef, useImperativeHandle } from "react";
import emptyImg from '../../static/img/20230831-183719.png'
import { Context as RequestContext } from '../request'
import { useParams } from "react-router-dom";
import { Context as AuthContext } from '../auth'
import { MsgTypeTTSVoice, MsgTypeArticle, MsgTypeArticleTitle, MsgTypeArticleCover, MsgProgressEnd, MsgTypeArticleWorkFlowProgress } from '../../utils/define'
import { cloneDeep } from "lodash";
import soundtrackLoadingJson from '../../static/lottie/soundtrack_loading.json'
import BallLoading from '../../components/icons/ball_loading'
import LoadingJson from '../../components/icons/loading_json'
import md5 from 'md5'
import Mark from "mark.js"
import { sleep } from "../../utils";
import { Speed08xActiveClick, Speed1xActiveClick, Speed1xUnActive, Speed08xUnActive, Speed1xActive, Speed08xActive, PlayIcon, PauseIcon, UnPlayIcon } from '../../components/icons/article'
import ArticleRAZ from './article_raz'





const Index = (props) => {
  const params = useParams()
  const { audioPool, hash_id = params.id, onLoad = () => { }, miniWindow = false, next = () => { }, tabarValue = {} } = props
  const [article, setArticle] = useState({ wordcount: 0 })
  const requestCtx = useContext(RequestContext)
  const request = requestCtx.useRequest()
  const [currentStep, setCurrentStep] = useState(0)
  const authCtx = useContext(AuthContext)
  const emitter = authCtx.useEmitter()
  const socket = useRef()
  const [loadingStep, setLoadingStep] = useState([])
  const articleAudioId = useRef(audioPool.getAudio("articleAudio"))
  const instance = useRef()
  const articleDom = useRef()
  const articleTitleDom = useRef()
  const [articleContentPlayStatus, setArticleContentPlayStatus] = useState(0) //0.未播放；1.正在播放;2.暂停;3下载；4继续播放
  const [playbackRate, setPlaybackRate] = useState(1)
  const [exportPDFLoading, setExportPDFLoading] = useState(false)
  const intersectionObserverRef = useRef()
  const [titleIntersection, setTitleIntersection] = useState(true)
  const razArticleRef = useRef()

  const getArticleDom = async () => {
    if (!articleDom.current) {
      await sleep(50)
      return await getArticleDom()
    } else {
      if (!articleDom.current.innerText) {
        await sleep(50)
        return await getArticleDom()
      }
    }
    return articleDom.current
  }

  const getFlowSteps = async () => {
    try {
      const steps = await request.get("/client/v2/api/llmflow/steps?id=1")
      if (steps) {
        setLoadingStep(steps.steps || [])
        setCurrentStep(0)
      }
      return steps
    } catch (error) {

    }
  }

  const init = async () => {
    emitter.on(MsgTypeArticleWorkFlowProgress, onMsgTypeArticleWorkFlowProgress)
    emitter.on(MsgTypeArticleTitle, onMsgTypeArticleTitle)
    emitter.on(MsgTypeArticle, onMsgTypeArticle)
    emitter.on(MsgTypeArticleCover, onMsgTypeArticleCover)
    emitter.on(MsgTypeTTSVoice, onMsgTypeTTSVoice)
    socket.current = await authCtx.useSocket("ARTICLE")
    await getFlowSteps()
    const _data = await getArticle(hash_id)

    audioPool.onBePause((aid) => {
      if (aid === articleAudioId.current) {
        setArticleContentPlayStatus(pre => {
          if (pre === 1) {
            return 4
          }
          return pre
        })
      }
    })
    onLoad(_data)
  }

  const getArticleInstance = async () => {
    if (!instance.current) {
      if (article.make_type === "QUIZ") {
        instance.current = await razArticleRef.current.useArticleContentDomInstance()
        return instance.current
      }
      instance.current = new Mark(await getArticleDom());
      return instance.current
    }
    return instance.current
  }

  useImperativeHandle(props.action, () => ({
    init,
    useArticleContentDomInstance: async () => {
      return await getArticleInstance()
    },
    useArticleDocs: () => {
      return article.docs
    },
    reloadData: () => {
      getArticle(hash_id)
    }
  }));


  const getArticle = async (_hash_id) => {
    try {
      const articleInfo = await request.get("/client/v2/api/article/detail?hash_id=" + _hash_id)
      if (articleInfo.make_type === "QUIZ") {
        articleInfo.page_content = JSON.parse(articleInfo.page_content)
      }
      setArticle(articleInfo)
      return articleInfo
    } catch (error) {

    }
  }

  const onMsgTypeArticleTitle = ({ msg, data }) => {
    if (data.hash_id !== params.id) {
      return
    }
    setArticle(pre => {
      let cpPre = cloneDeep(pre)
      cpPre.title = cpPre.title || ''
      cpPre.title += msg
      return cpPre
    })
  }

  const onMsgTypeArticleCover = ({ msg, data }) => {
    if (data.hash_id !== hash_id) {
      return
    }
    setArticle(pre => {
      let cpPre = cloneDeep(pre)
      cpPre.img = msg
      return cpPre
    })
  }

  const onArticlePlayStart = async (txt) => {
    setArticleContentPlayStatus(1)
    const _instance = await getArticleInstance()
    console.log("_instance", _instance)
    _instance.unmark({ className: "articleTTTShighlight" })
    _instance.mark(txt, {
      "className": "articleTTTShighlight",
      "separateWordSearch": false
    });
  }

  const onArticlePlayLoading = () => {
    setArticleContentPlayStatus(3)
  }

  const onArticlePlayEnd = async () => {
    setArticleContentPlayStatus(0)
    const _instance = await getArticleInstance()
    _instance.unmark()
  }

  const onMsgTypeTTSVoice = ({ msg, data }) => {
    setArticle(_article => {
      if (data.flag !== md5(_article.content)) {
        return _article
      }
      msg = JSON.parse(msg)
      audioPool.playAudioArr(articleAudioId.current, msg, onArticlePlayStart, onArticlePlayEnd, onArticlePlayLoading)
      return _article
    })

  }

  const onMsgTypeArticle = ({ msg, data }) => {
    if (data.hash_id !== hash_id) {
      return
    }
    if (data.msg_progress === MsgProgressEnd) {
      getArticle(hash_id).then((info) => {
        onLoad(info)
      })
      return
    }
    setArticle(pre => {
      let cpPre = cloneDeep(pre)
      cpPre.content = cpPre.content || ''
      cpPre.content += msg
      return cpPre
    })
  }

  const onMsgTypeArticleWorkFlowProgress = ({ msg }) => {
    msg = JSON.parse(msg)
    setLoadingStep(msg.steps)
    setCurrentStep(msg.current)
  }

  useEffect(() => {
    intersectionObserverRef.current = new IntersectionObserver(async (list) => {
      setTitleIntersection(list[0].isIntersecting)
    }, {
      threshold: 0
    })
    return () => {
      authCtx.unUseSocket()
      emitter.off(MsgTypeArticleWorkFlowProgress, onMsgTypeArticleWorkFlowProgress)
      emitter.off(MsgTypeArticleTitle, onMsgTypeArticleTitle)
      emitter.off(MsgTypeArticle, onMsgTypeArticle)
      emitter.off(MsgTypeArticleCover, onMsgTypeArticleCover)
      emitter.off(MsgTypeTTSVoice, onMsgTypeTTSVoice)
      if (intersectionObserverRef.current) {
        intersectionObserverRef.current.disconnect()
      }
    }
  }, [])

  const playArticle = () => {
    setArticleContentPlayStatus(3)
    socket.current.send(JSON.stringify({
      msg_type: MsgTypeTTSVoice,
      content: article.content,
      flag: md5(article.content),
      voice_type: "TTS_ARTICLE"
    }))
  }

  const goonPlayArticle = () => {
    audioPool.goonPlayAudio(articleAudioId.current)
    setArticleContentPlayStatus(1)
  }

  const exportPDF = async () => {
    setExportPDFLoading(true)
    try {
      const path = await request.get("/client/v2/api/article/pdf/export?hash_id=" + params.id)
      const a = document.createElement("a")
      a.href = process.env.REACT_APP_OSS_HOST + path
      a.target = "_blank"
      a.click()
    } catch (error) {

    }
    setExportPDFLoading(false)
  }

  const showSpeedBtn = () => {
    let leftSpeedBtn = <div>
      <Speed1xUnActiveWrapper />
    </div>
    let rightSpeedBtn = <div>
      <Speed08xUnActiveWrapper />
    </div>
    if (articleContentPlayStatus === 1) {
      if (playbackRate === 1) {
        leftSpeedBtn = <div style={{ background: "#161A19" }}>
          <Speed1xActiveWrapper />
        </div>
        rightSpeedBtn = <div><Speed08xActiveClickIcon onClick={() => {
          setPlaybackRate(0.8)
          audioPool.setPlaybackRate(articleAudioId.current, 0.8)
        }} /></div>
      }
      if (playbackRate === 0.8) {
        leftSpeedBtn = <div><Speed1xActiveClickIcon onClick={() => {
          setPlaybackRate(1)
          audioPool.setPlaybackRate(articleAudioId.current, 1)
        }} /></div>
        rightSpeedBtn = <div style={{ background: "#161A19" }}>
          <Speed08xActiveWrapper />
        </div>
      }
    }
    return { leftSpeedBtn, rightSpeedBtn }
  }

  const { leftSpeedBtn, rightSpeedBtn } = showSpeedBtn()

  return (
    <Wrapper $makeType={article.make_type} $miniWindow={miniWindow} onClick={() => setCurrentStep(pre => pre + 1)}>
      {
        !article.content && <div style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}>
          <div style={{ height: "calc(22px * var(--ratio))" }}></div>
          <StepProgress current={currentStep} steps={loadingStep} />
        </div>
      }
      {
        article.content && <>
          <Title ref={e => {
            if (e && !articleTitleDom.current) {
              articleTitleDom.current = e
              intersectionObserverRef.current.observe(e)
            }
          }} style={{ fontSize: miniWindow && "calc(24px * var(--ratio))" }}>{article.title}</Title>
          <PlayBar>
            <div style={{ display: "flex", visibility: titleIntersection ? "visible" : "hidden" }}>
              {
                article.lexile && <TaskInfoTag style={{
                  fontSize: "calc(14px * var(--ratio))",
                  height: "calc(26px * var(--ratio))",
                  lineHeight: "calc(26px * var(--ratio))",
                  padding: "0 calc(13px * var(--ratio))"
                }} $bgColor="linear-gradient(90deg, rgba(246, 195, 213, 0.60) 0%, rgba(255, 116, 113, 0.60) 100%)">Lexile: {article.lexile}</TaskInfoTag>
              }
              {
                parseInt(article.wordcount) !== 0 && <TaskInfoTag style={{
                  fontSize: "calc(14px * var(--ratio))",
                  height: "calc(26px * var(--ratio))",
                  lineHeight: "calc(26px * var(--ratio))",
                  padding: "0 calc(13px * var(--ratio))"
                }} $bgColor="#F5F0F0">
                  <TaskInfoTagIcon src="https://www.bigread.ai/icon/20240521-162328.png"></TaskInfoTagIcon>
                  Words: {article.wordcount}
                </TaskInfoTag>
              }
              {
                article.subject && <TaskInfoTag style={{
                  fontSize: "calc(14px * var(--ratio))",
                  height: "calc(26px * var(--ratio))",
                  lineHeight: "calc(26px * var(--ratio))",
                  padding: "0 calc(13px * var(--ratio))"
                }} $bgColor="#F5F0F0" $icon="https://www.bigread.ai/icon/20240521-162328.png">
                  <TaskInfoTagIcon src="https://www.bigread.ai/icon/20240521-162337.png"></TaskInfoTagIcon>
                  {article.subject}
                </TaskInfoTag>
              }
            </div>
            <div style={{ display: "flex", alignItems: "end" }}>
              <PlayWrapper>
                <PlayItem>
                  {
                    articleContentPlayStatus === 0 && <PlayIcon onClick={playArticle} style={{ width: "calc(18px * var(--ratio))", height: "calc(20px * var(--ratio))", marginLeft: "calc(8px * var(--ratio))" }} />
                  }
                  {
                    articleContentPlayStatus === 4 && <PlayIcon onClick={goonPlayArticle} style={{ width: "calc(18px * var(--ratio))", height: "calc(20px * var(--ratio))", marginLeft: "calc(8px * var(--ratio))" }} />
                  }
                  {
                    articleContentPlayStatus === 1 && <PauseIcon onClick={() => {
                      audioPool.pauseAudio(articleAudioId.current)
                      setArticleContentPlayStatus(4)
                    }} style={{ width: "calc(18px * var(--ratio))", height: "calc(20px * var(--ratio))", marginLeft: "calc(8px * var(--ratio))" }} />
                  }
                  {
                    articleContentPlayStatus === 3 && <BallLoading style={{ width: "calc(18px * var(--ratio))", height: "calc(20px * var(--ratio))", marginLeft: "calc(8px * var(--ratio))" }} />
                  }
                </PlayItem>
                <PlayItem>
                  {
                    articleContentPlayStatus === 1 ? <LoadingJson style={{ width: "calc(30px * var(--ratio))", height: "calc(22px * var(--ratio))", marginRight: "calc(8px * var(--ratio))" }} loadingJson={soundtrackLoadingJson}></LoadingJson> : <UnPlayIcon style={{ width: "calc(30px * var(--ratio))", height: "calc(22px * var(--ratio))", marginRight: "calc(8px * var(--ratio))" }} />
                  }
                </PlayItem>
              </PlayWrapper>
              <Speed $afterHide={true}>
                {leftSpeedBtn}
                <div style={{ width: 1, height: "100%", background: "rgb(219, 212, 212)" }}></div>
                {rightSpeedBtn}
              </Speed>
              {
                !exportPDFLoading && <ExportBtn onClick={exportPDF} style={{ width: "calc(40px * var(--ratio))", height: "calc(40px * var(--ratio))", display: article.make_type === "QUIZ" && "none" }} />
              }
              {
                exportPDFLoading && <BallLoading style={{ width: "calc(40px * var(--ratio))", height: "calc(40px * var(--ratio))" }} />
              }
            </div>
          </PlayBar>
          {
            article.make_type === "QUIZ" && <ArticleRAZ
              action={razArticleRef}
              article={article}
              tabarValue={tabarValue}
              next={next}
              miniWindow={miniWindow}
            />
          }
          {
            article.make_type !== "QUIZ" && <div>
              <CoverWrapper>
                <Cover src={!article.img ? emptyImg : process.env.REACT_APP_OSS_HOST + article.img + '?x-oss-process=image/resize,w_600'} />
                <CoverDesc>{article.title}</CoverDesc>
              </CoverWrapper>
              <ArticleContent ref={articleDom}>{article.content}</ArticleContent>
            </div>
          }
          {
            (!miniWindow && article.make_type !== "QUIZ") && <NextStepButton $disabled={tabarValue["vocabulary"] === 0} onClick={next}>Next Step</NextStepButton>
          }
        </>
      }
    </Wrapper>
  )
}

const Wrapper = styled.div`
  ${props => {
    let sty = ""
    if (props.$makeType !== "QUIZ") {
      sty += `width: ${props.$miniWindow ? "calc(770px * var(--ratio))" : "calc(1220px * var(--ratio))"};`
      return sty
    }
    sty += `width: ${props.$miniWindow ? "calc(770px * var(--ratio))" : "calc(850px * var(--ratio))"};`
    sty += "margin: 0 auto;"
    return sty
  }};
  border-radius: calc(30px * var(--ratio));
  border:  calc(1px * var(--ratio)) solid var(--Gray-400, #DBD4D4);
  background: linear-gradient(180deg, #FFF 78.6%, #FFFAFA 100%);
  padding: calc(40px * var(--ratio));
  height: calc(var(--vh) - calc(194px * var(--ratio)));
  overflow: auto;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  &::-webkit-scrollbar{
    display: none;
  }
`

const SpeedItem = styled.div`
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  position: relative;
  background: #000;
`

export const PlayWrapper = styled.div`
  border-radius: calc(30px * var(--ratio));
  background: var(--icon-color, #FFF);
  overflow: hidden;
  border: calc(1px * var(--ratio)) solid #DBD4D4;
  width: calc(96px * var(--ratio));
  height: calc(38px * var(--ratio));
  margin-right: calc(4px * var(--ratio));
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

const PlayItem = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const PlayBar = styled.div`
  display: flex; 
  align-items: center;
  justify-content: space-between;
  padding: calc(20px * var(--ratio)) 0;
  background: #FFF;
  position: sticky;
  top: calc(-40px * var(--ratio));
`

const Speed = styled.div`
  & > div{
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & > div:nth-child(1){
    &::after{
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: calc(1px * var(--ratio));
      height: calc(8px * var(--ratio));
      background-color: #DBD4D4;
      margin: auto;
      display: ${props => props.$afterHide ? "none" : "block"};
    }
  }
  border-radius: calc(30px * var(--ratio));
  background: var(--icon-color, #FFF);
  overflow: hidden;
  border: calc(1px * var(--ratio)) solid #DBD4D4;
  width: calc(123px * var(--ratio));
  height: calc(38px * var(--ratio));
  margin-right: calc(4px * var(--ratio));
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`


const Cover = styled.img`
  width:100%;
`

const CoverDesc = styled.div`
  color: #948B8C;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: calc(14px * var(--ratio));
  font-style: normal;
  font-weight: 400;
  line-height: 185.714%;
`

const CoverWrapper = styled.div`
  float: right;
  padding-left: calc(32px * var(--ratio));
  margin-bottom: calc(18px * var(--ratio));
  max-width: calc(352px * var(--ratio));
  text-align: center;
  & img{

  };
`

const ArticleContent = styled.div`
  white-space: pre-wrap;
  color: #251F1F;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: calc(18px * var(--ratio));
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`

const PlayIconWrapper = styled(PlayIcon)`
  width: calc(82px * var(--ratio));
  height: calc(40px * var(--ratio));
  margin-right: calc(4px * var(--ratio));
  cursor: pointer;
`

const Speed1xUnActiveWrapper = styled(Speed1xUnActive)`
  width: calc(25px * var(--ratio));
  height: calc(20px * var(--ratio));
  margin-right: calc(4px * var(--ratio));
  cursor: pointer;
  margin-top: calc(2px * var(--ratio));
`

const Speed08xUnActiveWrapper = styled(Speed08xUnActive)`
  width: calc(25px * var(--ratio));
  height: calc(20px * var(--ratio));
  margin-right: calc(4px * var(--ratio));
  margin-top: calc(2px * var(--ratio));
  cursor: pointer;
`

const Speed1xActiveWrapper = styled(Speed1xActive)`
  width: calc(25px * var(--ratio));
  height: calc(20px * var(--ratio));
  margin-right: calc(4px * var(--ratio));
  margin-top: calc(2px * var(--ratio));
  cursor: pointer;
`

const Speed08xActiveWrapper = styled(Speed08xActive)`
  width: calc(25px * var(--ratio));
  height: calc(20px * var(--ratio));
  margin-right: calc(4px * var(--ratio));
  cursor: pointer;
  margin-top: calc(2px * var(--ratio));
`

const Speed08xActiveClickIcon = styled(Speed08xActiveClick)`
  width: calc(25px * var(--ratio));
  height: calc(20px * var(--ratio));
  margin-right: calc(4px * var(--ratio));
  cursor: pointer;
  margin-top: calc(2px * var(--ratio));
`

const Speed1xActiveClickIcon = styled(Speed1xActiveClick)`
  width: calc(25px * var(--ratio));
  height: calc(20px * var(--ratio));
  margin-right: calc(4px * var(--ratio));
  cursor: pointer;
  margin-top: calc(2px * var(--ratio));
`

const PauseIconWrapper = styled(PauseIcon)`
  width: calc(50px * var(--ratio));
  height: calc(40px * var(--ratio));
  margin-right: calc(4px * var(--ratio));
  cursor: pointer;
`

const NextStepButton = styled.button`
  display: flex;
  height: calc(50px * var(--ratio));
  padding: 8px 0px;
  justify-content: center;
  align-items: center;
  border-radius: calc(26px * var(--ratio));
  background: #36B7B1;
  color: var(--white, var(--icon-color, #FFF));
  font-size: calc(20px * var(--ratio));
  font-style: normal;
  font-weight: 600;
  border: none;
  width: 100%;
  margin-top: calc(40px * var(--ratio));
  pointer-events: ${props => props.$disabled ? "none" : "all"};
  opacity: ${props => props.$disabled ? 0.3 : 1};
`

export const Title = styled.div`
  color: #161A19;
  font-size: calc(32px * var(--ratio));
  font-weight: 600;
  line-height: 130%; /* 41.6px */
  font-family: Inter;
  font-feature-settings: 'clig' off, 'liga' off;
`

export default Index