import { useRef, useEffect, useState, useContext } from 'react';
import { Skeleton, message } from 'antd'
import emptyImg from '../../static/img/20230831-183719.png'
import CikaIcon from '../../static/img/20240229-150111.png'
import QuizIcon from '../../static/img/20240229-150830.png'
import VideoIcon from '../../static/img/20240229-150433.png'
import { MsgProgressEnd, MsgTypeArticleVoice, MsgTypeArticle, MsgTypeArticleTitle, MsgTypeArticleCover, MsgProgressIng, MsgTypeTTSVoice } from '../../utils/define'
import { sleep } from '../../utils'
import HandlePoint from '../../components/HandlePoint'
import { cloneDeep } from 'lodash';
import styled from "styled-components";
import {
    LoadingOutlined,
    PlayCircleOutlined,
    PauseCircleOutlined
} from '@ant-design/icons';
import { Context as RequestContext } from '../request'
import { EventEmitter as Remitter } from '../auth'

const Index = (props) => {
    const { audioPool, id, data, onArticleLoadWordCard, onArticleLoadQuizCard, onArticleLoadVideoCard, xPos, yPos, onArticleDelete } = props
    const [mouseOver, setMouseOver] = useState(false)
    const [articleContentPlayStatus, setArticleContentPlayStatus] = useState(0) //0.未播放；1.正在播放;2.暂停;3下载；4继续播放
    const { hashId, makeds = {} } = props.data
    const [articleInfo, setArticleInfo] = useState({ title: '', content: '' })
    const articleInfoRef = useRef({})
    const quizDataRef = useRef([])
    const [toolBtnDisabled, setToolBtnDisabled] = useState(false)
    const articleVoices = useRef([])
    const audioId = useRef()
    const copyContentRef = useRef("")
    const requestCtx = useContext(RequestContext)
    const request = requestCtx.useRequest()

    //获取文章的所有quiz
    const getQuizs = async (hashId) => {
        try {
            quizDataRef.current = await request.get(`/client/api/article/quiz?hash_id=${hashId}`)
        } catch (error) {

        }
        return []
    }

    const getArticle = async (hash_id = hashId) => {
        try {
            let ret = await request.get(`/client/api/article/detail?hash_id=${hash_id}`)
            if (ret.tts_info && ret.tts_info.length !== 0) {
                ret.tts_info = JSON.parse(ret.tts_info)
            }
            if (ret.word_parsing) {
                ret.word_parsing = JSON.parse(ret.word_parsing)
            }
            if (ret.related) {
                ret.related = JSON.parse(ret.related)
            }
            if (ret.sources) {
                ret.sources = JSON.parse(ret.sources)
            }
            if (ret.v_info) {
                ret.v_info = JSON.parse(ret.v_info)
            }
            copyContentRef.current = ret.content
            setArticleInfo(ret)
        } catch (error) {
            // setLoading(false)
        }
    }

    const onMessage = (message) => {
        if (message.node_id !== id) {
            return
        }
        if (message.msg_status === "Err") {
            alert(message.msg)
            onArticleDelete(id)
            return
        }
        if (message.msg_type === MsgTypeArticle) {
            if (message.msg_progress === MsgProgressIng) {
                setToolBtnDisabled(true)
                setArticleInfo(pre => {
                    let cpPre = cloneDeep(pre)
                    cpPre.content += message.msg
                    return cpPre
                })
            }
            if (message.msg_progress === MsgProgressEnd) {
                setToolBtnDisabled(false)
                getArticle(message.hash_id)
            }
        }
        if (message.msg_type === MsgTypeArticleTitle) {
            setArticleInfo(pre => {
                let cpPre = cloneDeep(pre)
                cpPre.title += message.msg
                return cpPre
            })
        }
        if (message.msg_type === MsgTypeArticleCover) {
            setArticleInfo(pre => {
                let cpPre = cloneDeep(pre)
                cpPre.img = message.msg
                return cpPre
            })
        }
        if (message.msg_type === MsgTypeTTSVoice) {
            if (message.msg_progress === MsgProgressIng) {
                const tts = JSON.parse(message.msg)
                articleVoices.current.push(tts)
            }
            if (message.msg_progress === MsgProgressEnd) {
                setArticleContentPlayStatus(1)
                audioPool.playAudioArr(audioId.current, articleVoices.current, onPlayStart, onPlayEnd, () => { })
            }
        }
    }

    useEffect(() => {
        Remitter.on('message', onMessage)
        if (hashId) {
            getArticle(hashId)
        }
        audioPool.onBePause((aid) => {
            if (aid === audioId.current) {
                setArticleContentPlayStatus(4)
            }
        })
        return () => {
            Remitter.off('message', onMessage)
        }
        // eslint-disable-next-line
    }, [])

    //提示样式
    const setArticleHighlight = (txt) => {
        if (txt == "") {
            setArticleInfo(pre => {
                const cpPre = cloneDeep(pre)
                const cpCon = cloneDeep(copyContentRef.current)
                cpPre.content = cpCon
                return cpPre
            })
            return
        }
        setArticleInfo(pre => {
            const cpPre = cloneDeep(pre)
            const cpCon = cloneDeep(copyContentRef.current)
            cpPre.content = cpCon.replaceAll(txt, `<span style="color: #2FAA20;position: relative;">${txt}</span>`)
            return cpPre
        })
    }


    const onPlayStart = (txt) => {
        setArticleHighlight(txt)
    }

    const onPlayEnd = () => {
        setArticleHighlight("")
        setArticleContentPlayStatus(0)
    }

    const loadTts = async () => {
        if (!audioId.current) {
            audioId.current = audioPool.getAudio()
        }
        audioPool.playAudio(audioId.current, process.env.PUBLIC_URL + "/audio/silence.mp3")
        setArticleContentPlayStatus(3)
        try {
            await request.post('/client/api/message/send', {
                msg_type: MsgTypeTTSVoice,
                content: articleInfo.content,
                node_id: id,
                voice_type: "article"
            })
        } catch (error) {
            setArticleContentPlayStatus(0)
        }
    }

    useEffect(() => {
        articleInfoRef.current = articleInfo
    }, [articleInfo])

    const getWordCardData = async () => {
        try {
            const _data = await request.get(`/client/v2/api/article/vocabulary?hash_id=${hashId}`)
            if (_data && _data.length !== 0) {
                return _data
            }
        } catch (error) {

        }
        await sleep(1000)
        return await getWordCardData()
    }

    const getVideoData = async () => {
        try {
            const info = await request.post(`/client/api/article/video/make?hash_id=${hashId}`)
            if (!info.v_info) {
                message.info("No related videos were found")
                return
            }
            info.v_info = JSON.parse(info.v_info)
            if (info.v_info.items.length === 0) {
                message.info("No related videos were found")
                return
            }
            return info.v_info
        } catch (error) {
            if (error === "china") {
                message.info("本功能由Youtube提供支持，您所在的国家或地区暂不开放。This feature is supported by YouTube and is currently not available in your country or region.")
                return
            }
        }
    }


    const getQuizCardData = async () => {
        try {
            const _data = await request.get(`/client/v2/api/article/quiz?hash_id=${hashId}`)
            if (_data && _data.length !== 0) {
                for (let i = 0; i < _data.length; i++) {
                    _data[i].options = JSON.parse(_data[i].options)
                    _data[i].quiz_user = _data[i].quiz_user || {}
                }
                return _data
            }
        } catch (error) {

        }
        await sleep(1000)
        return await getQuizCardData()
    }

    return (
        <>
            <ArticleContentBox onMouseOver={() => setMouseOver(true)} onMouseOut={() => setMouseOver(false)}>
                <div style={{ display: "flex", justifyContent: "right" }}>
                    <ArticleTool>
                        {
                            articleContentPlayStatus === 3 && <ArticleToolItem style={{
                                pointerEvents: toolBtnDisabled || !articleInfo.content ? "none" : "auto",
                                opacity: toolBtnDisabled || !articleInfo.content ? 0.5 : 1
                            }}><LoadingOutlined /></ArticleToolItem>
                        }
                        {
                            articleContentPlayStatus === 0 && <ArticleToolItem style={{
                                pointerEvents: toolBtnDisabled || !articleInfo.content ? "none" : "auto",
                                opacity: toolBtnDisabled || !articleInfo.content ? 0.5 : 1
                            }} onClick={loadTts}><PlayCircleOutlined /></ArticleToolItem>
                        }
                        {
                            articleContentPlayStatus === 4 && <ArticleToolItem style={{
                                pointerEvents: toolBtnDisabled || !articleInfo.content ? "none" : "auto",
                                opacity: toolBtnDisabled || !articleInfo.content ? 0.5 : 1
                            }} onClick={() => {
                                audioPool.goonPlayAudio(audioId.current)
                                setArticleContentPlayStatus(1)
                            }}><PlayCircleOutlined /></ArticleToolItem>
                        }
                        {
                            articleContentPlayStatus === 1 && <ArticleToolItem style={{
                                pointerEvents: toolBtnDisabled || !articleInfo.content ? "none" : "auto",
                                opacity: toolBtnDisabled || !articleInfo.content ? 0.5 : 1
                            }} onClick={() => {
                                audioPool.pauseAudio(audioId.current)
                                setArticleContentPlayStatus(4)
                            }}><PauseCircleOutlined /></ArticleToolItem>
                        }
                        <ArticleToolItem style={{
                            pointerEvents: makeds["wordCard"] || toolBtnDisabled || !articleInfo.content ? "none" : "auto",
                            opacity: makeds["wordCard"] || toolBtnDisabled || !articleInfo.content ? 0.5 : 1
                        }} onClick={() => {
                            onArticleLoadWordCard(id, xPos, yPos, getWordCardData)
                        }}>
                            <img style={{ width: 24, height: 24 }} src={CikaIcon} alt='' />
                        </ArticleToolItem>
                        <ArticleToolItem style={{
                            pointerEvents: makeds["quizCard"] || toolBtnDisabled || !articleInfo.content ? "none" : "auto",
                            opacity: makeds["quizCard"] || toolBtnDisabled || !articleInfo.content ? 0.5 : 1
                        }} onClick={() => {
                            onArticleLoadQuizCard(hashId, id, xPos, yPos, getQuizCardData)
                        }}>
                            <img style={{ width: 24, height: 24 }} src={QuizIcon} alt='' />
                        </ArticleToolItem>
                        <ArticleToolItem style={{
                            pointerEvents: makeds["videoCard"] || toolBtnDisabled || !articleInfo.content ? "none" : "auto",
                            opacity: makeds["videoCard"] || toolBtnDisabled || !articleInfo.content ? 0.5 : 1
                        }} onClick={() => {
                            onArticleLoadVideoCard(id, xPos, yPos, getVideoData)
                        }}>
                            <img style={{ width: 24, height: 24 }} src={VideoIcon} alt='' />
                        </ArticleToolItem>
                    </ArticleTool>
                </div>
                <div style={{ padding: 16, display: articleInfo.content ? "none" : "block" }}>
                    <Skeleton.Input active />
                    <Skeleton active paragraph={{ rows: 10 }} />
                </div>
                <div style={{ display: !articleInfo.content ? "none" : "block" }}>
                    <ArticleTitle>{articleInfo.title}</ArticleTitle>
                    {/* <div style={{ padding: 12, paddingBottom: 0 }}>
                        <img style={{ width: "100%" }} src={!articleInfo.img ? emptyImg : process.env.REACT_APP_OSS_HOST + articleInfo.img} alt='' />
                    </div> */}
                    <ArticleContent dangerouslySetInnerHTML={{
                        __html: `
                        <img align="right" style="max-width: 270px;margin-left: 20px;margin-bottom: 20px;" src="${!articleInfo.img ? emptyImg : process.env.REACT_APP_OSS_HOST + articleInfo.img}" alt='' />
                        ${articleInfo.content}
                    ` }}></ArticleContent>
                </div>
            </ArticleContentBox>
            <HandlePoint mouseOver={mouseOver} id={id} />
        </>
    );
}

const ArticleTool = styled.div`
    width: 130px;
    height: 40px;
    border-radius: 6px 6px 0 0;
    border: 2px solid #1F1D0F;
    background: #FFFFFF;
    box-shadow: 2px 0px 0px 0px #1F1D0F;
    overflow: hidden;
    display: flex;
    align-items: center;
    margin-top: -44px;
    margin-right: -2px;
`

const ArticleToolItem = styled.div`
    width: 25%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        background: #FF5251;
    }
`
const ArticleContentBox = styled.div`
    border-radius: 6px 0px 6px 6px;
    border: 2px solid #1F1D0F;
    background: #FCFFF2;
    box-shadow: 2px 2px 0px 0px #1F1D0F;
    width: 540px;
`

const ArticleContent = styled.div`
    color: #1F1D0F;
    font-family: "Roboto Flex";
    font-size: 14px;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    padding: 16px;
    white-space: pre-wrap;
`
const ArticleTitle = styled.div`
    padding: 14px 24px;
    background: #F2FFBD;
    color: #1F1D0F;
    font-family: "Roboto Slab600";
    font-size: 16px;
    line-height: 1;
    border-radius: 6px;
`

export default Index;
