


const Index = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 14" fill="none">
      <path d="M17.0002 1L6.3335 13L0.999512 7" stroke="#59BC57" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default Index

