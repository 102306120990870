import ThreeChat from '../../components/ThreeChat'
import { useEffect, useState, useContext } from 'react'
import styled from "styled-components";
import { Context as RequestContext } from '../request'


const Index = (props) => {
    const { data, onChat3DMouseOver, audioPool } = props
    const [userInfo, setUserInfo] = useState({ profile_info: {}, user_info: {}, ai_role: {} })
    const requestCtx = useContext(RequestContext)
    const request = requestCtx.useRequest()

    const loadUserInfo = async () => {
        try {
            let userInfo = await request.get('/client/api/user/info')
            setUserInfo(userInfo)
        } catch (error) {

        }
    }

    useEffect(() => {
        loadUserInfo()

    }, [])

    return (
        <ChatBox onMouseOver={() => onChat3DMouseOver(true)} onMouseOut={() => onChat3DMouseOver(false)}>
            <ThreeChat
                hashId={data.hashId}
                userInfo={userInfo}
                audioPool={audioPool}
            />
        </ChatBox>
    )
}

const ChatBox = styled.div`
    border-radius: 6px;
    border: 2px solid #1F1D0F;
    background: #FFF;
    box-shadow: 2px 2px 0px 0px #1F1D0F;
    width: 632px;
    height: 620px;
    overflow: auto;
`

export default Index