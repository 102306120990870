

const Index = (props) => {
  return (
    <svg {...props} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.366667" y="0.366667" width="39.2667" height="39.2667" rx="19.6333" stroke="#DBD4D4" strokeWidth="0.733333" />
      <path d="M14.5977 12.6501C14.5977 11.7388 15.3364 11 16.2477 11H23.748C24.6593 11 25.3981 11.7388 25.3981 12.6501V15.5002H14.5977V12.6501Z" fill="#948B8C" />
      <rect x="9.19922" y="15.0498" width="21.6008" height="10.8004" rx="1.65006" fill="#161A19" />
      <rect x="12.7969" y="21.7998" width="14.4006" height="7.20028" rx="1.65006" fill="#948B8C" />
      <rect x="17.2969" y="18.2012" width="5.40021" height="0.900034" rx="0.450017" fill="#948B8C" />
    </svg>

  )
}

export default Index




