import { initBabylon } from "./babylonjs/init";
import { setHumanoid } from "./babylonjs/setHumanoid";
import { updateCamera } from "./babylonjs/utils";
import React, { useEffect, useRef, useState, useContext } from "react";
import { DEFAULT_MODEL } from './constants'
import styled from "styled-components";
import ChatList from '../ChatList'
import { MsgTypeChat } from '../../utils/define'
import { SoundOutlined, MutedOutlined } from '@ant-design/icons'
import { Context as RequestContext } from '../../pages/request'



const CanvasThatDoesNotReRender = ({
  modelName,
  hashId
}) => {

  const didInit = useRef(false);
  useEffect(() => {
    // This use effect is called when the model changes. If there is an existing model, it
    // first removes the existing model from the scene, then adds the new model to the scene.
    if (modelName && window.humanoid) {
      const scene = window.humanoid.scene;
      window.humanoid.dispose();
      setHumanoid(modelName, scene);
      updateCamera(modelName, scene);
    }
  }, [modelName]);

  useEffect(() => {

    if (!modelName) {
      // If modelName is not set, then we probably haven't loaded the settings from the URL yet.
      return;
    }

    if (didInit.current) {
      // If we"re here, then strict mode is on - reactStrictMode is true on the next.config.js
      console.warn("Warning - tried to initialize twice. Will skip 2nd initialization.");
      return;
    }

    const setIsLoading = () => {

    }

    try {
      const scene = initBabylon(setIsLoading, modelName);
      setHumanoid(modelName, scene);
    } catch (error) {
      console.error(error);
      if (error instanceof Error) {

      }
    }
    didInit.current = true;

    // eslint-disable-next-line
  }, [modelName]);

  return (
    <canvas
      id="renderCanvas"
      style={{
        position: "absolute",
        height: "100%",
        width: "100%",
        pointerEvents: "none"
      }}
    />
  );
};

const CanvasParent = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  filter: ${props => props.$calltutor === "true" ? "blur(0px)" : "blur(14px)"};
`

const ChatListWrapper = styled.div`
  
`

const SoundIcon = styled.div`
  position: absolute;
  left: 12px;
  top: 12px;
  z-index: 99;
  font-size: 16px;
  color: #FFFFFF;
`

const TutorBox = styled.div`
  position: absolute;
  z-index: 11;
  transform: scale(0.8);
  top: 0;
  bottom: 196px;
  left: 0;
  right: 0;
  margin: auto;
  width: 178px;
  height: 186px;
`

const CallTutorButton = styled.div`
  border: 2px solid rgb(31, 29, 15);
  background: rgb(255, 249, 120);
  position: absolute;
  width: 180px;
  height: 48px;
  text-align: center;
  line-height: 48px;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 11;
  border-radius: 6px;
  display: ${props => props.$calltutor === "true" ? "none" : "block"};
  font-feature-settings: "clig" off, "liga" off;
  box-shadow: 4px 4px 0 0 #1f1d0f;
  color: #1f1d0f;
  cursor: pointer;
  font-family: Roboto Flex600;
  font-size: 16px;
  font-style: normal;
`

const Index = (props) => {
  const [soundOff, setSoundOff] = useState(true)
  const [callTutor, setCallTutor] = useState(false)
  const { hashId = '123456', userInfo, audioPool } = props
  const chatListAction = useRef({})
  const requestCtx = useContext(RequestContext)
  const request = requestCtx.useRequest()

  return (
    <CanvasParent $calltutor="true">
      <div style={{ position: "absolute", zIndex: 10, left: 0, right: 0, top: 0, bottom: 0, display: callTutor ? 'none' : 'block' }}></div>
      <TutorBox style={{ display: (callTutor && userInfo) ? 'none' : 'block' }}>
        <img style={{
          width: 188,
          marginRight: -33,
          marginBottom: 96
        }} src={process.env.REACT_APP_OSS_HOST + userInfo?.ai_role?.role_avatar} alt='' />
      </TutorBox>
      <CallTutorButton $calltutor={callTutor.toString()} onClick={() => {
        setCallTutor(true)
        chatListAction.current.openAudio()
        setSoundOff(false)
        request.post('/client/api/message/send', {
          msg_type: MsgTypeChat,
          hash_id: hashId,
          msg: "$CMD_HELLO$"
        }).catch(() => { })
      }}>Call Tutor</CallTutorButton>
      <CanvasParent $calltutor={callTutor.toString()}>
        <SoundIcon onClick={() => {
          if (soundOff) {

          } else {
            chatListAction.current.stopAudio()
          }
          setSoundOff(!soundOff)
        }}>
          {
            soundOff ? <MutedOutlined /> : <SoundOutlined />
          }
        </SoundIcon>
        <CanvasThatDoesNotReRender
          modelName={DEFAULT_MODEL}
          hashId={hashId}
        />
        <ChatListWrapper>
          <ChatList
            hashId={hashId}
            action={chatListAction}
            soundOff={soundOff}
            audioPool={audioPool}
            onStopAudio={() => {
              setSoundOff(true)
            }}
          />
        </ChatListWrapper>
      </CanvasParent>
    </CanvasParent>
  );
};

export default Index;
