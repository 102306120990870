import styles from './index.module.css'


const Index = (props) => {
    const { intensity = 0, width = 360 } = props

    return (
        <div style={{
            borderRadius: 90,
            background: "rgba(0, 0, 0, 0.70)",
            width,
            height: 48,
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <div className={styles.box}>
                <div className={styles.yinjie} style={{background: "#6adbea", transform: `scale(1,${Math.random()*intensity+0.1})`, WebkitTransform: `scale(1,${Math.random()*intensity+0.1})`}}></div>
                <div className={styles.yinjie} style={{background: "#76dee2", transform: `scale(1,${Math.random()*intensity+0.1})`, WebkitTransform: `scale(1,${Math.random()*intensity+0.1})`}}></div>
                <div className={styles.yinjie} style={{background: "#83e1d8", transform: `scale(1,${Math.random()*intensity+0.1})`, WebkitTransform: `scale(1,${Math.random()*intensity+0.1})`}}></div>
                <div className={styles.yinjie} style={{background: "#8ee4d1", transform: `scale(1,${Math.random()*intensity+0.1})`, WebkitTransform: `scale(1,${Math.random()*intensity+0.1})`}}></div>
                <div className={styles.yinjie} style={{background: "#99e7c7", transform: `scale(1,${Math.random()*intensity+0.1})`, WebkitTransform: `scale(1,${Math.random()*intensity+0.1})`}}></div>
                <div className={styles.yinjie} style={{background: "#a5eabf", transform: `scale(1,${Math.random()*intensity+0.1})`, WebkitTransform: `scale(1,${Math.random()*intensity+0.1})`}}></div>
                <div className={styles.yinjie} style={{background: "#b1edb6", transform: `scale(1,${Math.random()*intensity+0.1})`, WebkitTransform: `scale(1,${Math.random()*intensity+0.1})`}}></div>
                <div className={styles.yinjie} style={{background: "#beefad", transform: `scale(1,${Math.random()*intensity+0.1})`, WebkitTransform: `scale(1,${Math.random()*intensity+0.1})`}}></div>
                <div className={styles.yinjie} style={{background: "#caf3a4", transform: `scale(1,${Math.random()*intensity+0.1})`, WebkitTransform: `scale(1,${Math.random()*intensity+0.1})`}}></div>
                <div className={styles.yinjie} style={{background: "#d5f69d", transform: `scale(1,${Math.random()*intensity+0.1})`, WebkitTransform: `scale(1,${Math.random()*intensity+0.1})`}}></div>
                <div className={styles.yinjie} style={{background: "#e1f894", transform: `scale(1,${Math.random()*intensity+0.1})`, WebkitTransform: `scale(1,${Math.random()*intensity+0.1})`}}></div>
                <div className={styles.yinjie} style={{background: "#edfb8a", transform: `scale(1,${Math.random()*intensity+0.1})`, WebkitTransform: `scale(1,${Math.random()*intensity+0.1})`}}></div>
                <div className={styles.yinjie} style={{background: "#f9fe82", transform: `scale(1,${Math.random()*intensity+0.1})`, WebkitTransform: `scale(1,${Math.random()*intensity+0.1})`}}></div>
                <div className={styles.yinjie} style={{background: "#faff83", transform: `scale(1,${Math.random()*intensity+0.1})`, WebkitTransform: `scale(1,${Math.random()*intensity+0.1})`}}></div>
                <div className={styles.yinjie} style={{background: "#f7ff87", transform: `scale(1,${Math.random()*intensity+0.1})`, WebkitTransform: `scale(1,${Math.random()*intensity+0.1})`}}></div>
                <div className={styles.yinjie} style={{background: "#f3ff8a", transform: `scale(1,${Math.random()*intensity+0.1})`, WebkitTransform: `scale(1,${Math.random()*intensity+0.1})`}}></div>
                <div className={styles.yinjie} style={{background: "#efff8e", transform: `scale(1,${Math.random()*intensity+0.1})`, WebkitTransform: `scale(1,${Math.random()*intensity+0.1})`}}></div>
                <div className={styles.yinjie} style={{background: "#ecff92", transform: `scale(1,${Math.random()*intensity+0.1})`, WebkitTransform: `scale(1,${Math.random()*intensity+0.1})`}}></div>
                <div className={styles.yinjie} style={{background: "#e8ff97", transform: `scale(1,${Math.random()*intensity+0.1})`, WebkitTransform: `scale(1,${Math.random()*intensity+0.1})`}}></div>
                <div className={styles.yinjie} style={{background: "#e4ff9a", transform: `scale(1,${Math.random()*intensity+0.1})`, WebkitTransform: `scale(1,${Math.random()*intensity+0.1})`}}></div>
                <div className={styles.yinjie} style={{background: "#e1ff9e", transform: `scale(1,${Math.random()*intensity+0.1})`, WebkitTransform: `scale(1,${Math.random()*intensity+0.1})`}}></div>
            </div>
        </div>	
    )
}

export default Index