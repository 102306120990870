import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from '../pages/home'
import ArticleV2 from '../pages/article/index_v2'
import React, { useRef, useEffect, useState } from "react";
import LeveledReading from '../pages/LeveledReading'
import FreeList from '../pages/free/list'
import mitt from 'mitt'
import Auth from '../pages/auth'
import Bridge from '../pages/Bridge'
import Welcome from '../pages/welcome'
import PayV2 from '../pages/pay_v2'
import PaySuccess from '../pages/pay_v2/success'
import Request from "../pages/request"
import Login from "../pages/login"
import Register from "../pages/login/register"
import RegisterPre from '../pages/login/register_pre'
import Fogot from '../pages/login/forgot'
import Reset from '../pages/login/reset'
import LoginGoogle from '../pages/login_google'
import ProgressV2 from '../pages/progress/v2'
import Free from '../pages/free'
import Profile from '../pages/profile'
import LiveRoom from '../pages/liveroom'


export const Context = React.createContext({})



const R = () => {
    const [pVisible, setPVisible] = useState(false)
    const emitter = useRef(mitt())

    const useEmitter = () => {
        return emitter.current
    }

    const onresize = () => {
        const basepx = 1366

        let vw = document.body.clientWidth
        let vh = document.body.clientHeight
        // let vw = document.body.clientWidth
        // let vh = vw / 1.33
        // if (document.body.clientHeight < vh) {
        //     vw = document.body.clientHeight * 1.33
        // }


        document.documentElement.style.setProperty("--ratio", vw / basepx);
        document.documentElement.style.setProperty(`--vh`, vh + "px");
        document.documentElement.style.setProperty(`--vw`, vw + "px");
        emitter.current.emit("resize", { vw, vh })
    }

    useEffect(() => {
        document.addEventListener("visibilitychange", function () {
            if (document.visibilityState === "visible") {
                setPVisible(true)
            } else {
                setPVisible(false)
            }
        });
        window.addEventListener("resize", onresize)
        window.onload = () => {
            emitter.current.emit("onload", window)
            setTimeout(() => {
                onresize()
            }, 0)
        }
        return () => {

        }
        // eslint-disable-next-line
    }, [])


    return (
        <Context.Provider value={{
            pVisible,
            useEmitter,
            onresize
        }}>
            <BrowserRouter>
                <Request>
                    <Routes>
                        <Route path="/*" element={
                            <Routes>
                                <Route path="/" element={
                                    <Auth>
                                        <Home>
                                            <LeveledReading />
                                        </Home>
                                    </Auth>
                                } />
                                <Route path="/freelist" element={
                                    <Auth>
                                        <Home>
                                            <FreeList />
                                        </Home>
                                    </Auth>
                                } />
                                <Route path="/progress" element={
                                    <Auth>
                                        <Home>
                                            <ProgressV2 />
                                        </Home>
                                    </Auth>
                                } />
                            </Routes>
                        } />
                        <Route path="/profile" element={<Auth><Profile /></Auth>} />
                        <Route path="/liveroom" element={<Auth><LiveRoom /></Auth>} />
                        <Route path="/free/:id" element={<Auth><Free /></Auth>} />
                        <Route path="/article/v2/:id" element={<Auth><ArticleV2 /></Auth>} />
                        <Route path="/welcome" element={<Auth><Welcome /></Auth>} />
                        <Route path="/pay" element={<Auth><PayV2 /></Auth>} />
                        <Route path="/pay/success" element={<Auth><PaySuccess /></Auth>} />
                        <Route path="/bridge/:token" element={<Bridge />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/fogot" element={<Fogot />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/register/pre/:flag" element={<RegisterPre />} />
                        <Route path="/reset/:token" element={<Reset />} />
                        <Route path="/login/google" element={<LoginGoogle />} />
                    </Routes>
                </Request>
            </BrowserRouter>
        </Context.Provider>
    )
}

export default R
