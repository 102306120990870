import { useEffect, useRef, useState, useContext, useImperativeHandle } from 'react';
import { cloneDeep } from "lodash";
import styled from "styled-components";
import { Context as RequestContext } from '../request'
import { MsgTypeQuizParse, MsgTypeQuiz } from '../../utils/define'
import { Context as AuthContext } from '../auth'
import preloadAudio from '../../utils/audio_preload'
import { sleep, formatQuizParse } from '../../utils';
import LoadingBall from '../../components/icons/ball_loading'


const Index = (props) => {
  let { hash_id, articleAction, onLoad = () => { }, onCorrect = () => { }, onClear = () => { }, onParse = () => { } } = props
  const [quizIndex, setQuizIndex] = useState(-1)
  const [uploadScoreLoading, setUploadScoreLoading] = useState(false)
  const requestCtx = useContext(RequestContext)
  const request = requestCtx.useRequest()
  const [data, setData] = useState([])
  const authCtx = useContext(AuthContext)
  const emitter = authCtx.useEmitter()
  const socket = useRef()
  const audios = useRef({})
  const [isResult, setIsResult] = useState(false)
  const [scoreInfo, setScoreInfo] = useState({ star: 0 })
  const [show, _setShow] = useState(false)
  const _show = useRef(false)

  const init = async () => {
    try {
      socket.current = await authCtx.useSocket()
      const _data = await getData(hash_id)
      audios.current = await preloadAudio([
        "https://file.bigread.ai/audio/zhengque.mp3",
        "https://file.bigread.ai/audio/cuowu.mp3",
        "https://file.bigread.ai/audio/level-passed.mp3"
      ])
      emitter.on(MsgTypeQuiz, onMsgTypeQuiz)
      emitter.on(MsgTypeQuizParse, onMsgTypeQuizParse)
      await sleep(300)
      choiceQuiz(0)
      onLoad(_data)
    } catch (error) {

    }
  }

  useImperativeHandle(props.action, () => ({
    init,
    setShow: (_s) => {
      _show.current = _s
      _setShow(_s)
      if (!_s) {
        onClear()
      } else {
        if (quizIndex !== -1) {
          choiceQuiz(quizIndex)
        } else {
          choiceQuiz(0)
        }
      }
    }
  }));

  const getData = async (_hash_id) => {
    try {
      const _data = await request.get("/client/v2/api/article/quiz?hash_id=" + _hash_id) || []
      if (_data && _data.length !== 0) {
        for (let i = 0; i < _data.length; i++) {
          _data[i].options = JSON.parse(_data[i].options)
          _data[i].answer = _data[i].answer.split(",")
          _data[i].quiz_user = _data[i].quiz_user || {}
          if (_data[i].quiz_user.user_answer) {
            _data[i].quiz_user.user_answer = _data[i].quiz_user.user_answer.split(",")
          } else {
            _data[i].quiz_user.user_answer = []
          }
          if (_data[i].quiz_user.answer_parse) {
            // _data[i].quiz_user.answer_parse = formatQuizParse(_data[i].quiz_user.answer_parse, (p1, index) => {
            //   console.log(p1, index)
            // })
          }
        }
        setData(_data)
      }
      return _data
    } catch (error) {

    }
  }

  const onMsgTypeQuiz = async ({ msg, data }) => {
    if (data.hash_id !== hash_id) {
      return
    }
    try {
      const _data = await getData(hash_id)
      await sleep(300)
      choiceQuiz(0)
      onLoad(_data)
    } catch (error) {

    }
  }

  const onMsgTypeQuizParse = ({ msg, data }) => {
    if (data.hash_id !== hash_id) {
      return
    }
    setData(pre => {
      const cpPre = cloneDeep(pre)
      const fidx = cpPre.findIndex(v => v.id === data.quiz_id)
      cpPre[fidx].quiz_user.answer_parse = cpPre[fidx].quiz_user.answer_parse || ''
      cpPre[fidx].quiz_user.answer_parse += msg
      cpPre[fidx].answerParseHTML = cpPre[fidx].answerParseHTML || ''
      for (let i = 0; i < cpPre[fidx].options.length; i++) {
        cpPre[fidx].options[i].parseLoading = false
      }
      const html = formatQuizParse(cpPre[fidx].quiz_user.answer_parse, (p1, index) => {
        //console.log(p1, index)
      }, (_changes) => {
        if (_show.current) {
          onParse(_changes)
        }
      })
      cpPre[fidx].answerParseHTML = html
      return cpPre
    })
  }

  useEffect(() => {
    if (!props.action) {
      init()
    }
    return () => {
      authCtx.unUseSocket()
      emitter.off(MsgTypeQuiz, onMsgTypeQuiz)
      emitter.off(MsgTypeQuizParse, onMsgTypeQuizParse)
    }

  }, [])

  const getCheckBtnStatus = (v) => {
    if (uploadScoreLoading) {
      return true
    }
    if (v.quiz_user.user_answer.length === 0) {
      return true
    }
    return false
  }


  const isCorrect = (answer, userAnswer) => {
    userAnswer.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
    answer.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
    return JSON.stringify(answer) === JSON.stringify(userAnswer)
  }

  const inArrays = (answer, userAnswer) => {
    for (let i = 0; i < answer.length; i++) {
      let item = answer[i]
      for (let j = 0; j < userAnswer.length; j++) {
        let jitem = userAnswer[j]
        if (item === jitem) {
          return true
        }
      }
    }
    return false
  }

  const getNoStatus = (v, k) => {
    if (quizIndex === k && v.quiz_user.answer_result === 1) {
      return 4
    }
    if (quizIndex === k && v.quiz_user.answer_result === 2) {
      return 5
    }
    if (v.quiz_user.answer_result === 1) {
      return 2
    }
    if (v.quiz_user.answer_result === 2) {
      return 3
    }
    if (quizIndex === k) {
      return 1
    }

    return 0
  }

  const getOptionStatus = (superV, v) => {
    if (superV.quiz_user.answer_result === 1) { //显示正确
      for (let i = 0; i < superV.answer.length; i++) {
        let item = superV.answer[i]
        if (item === v.option) {
          return 2
        }
      }
      return
    }
    if (superV.quiz_user.answer_result === 2) { //答错
      //显示正确答案
      for (let i = 0; i < superV.answer.length; i++) {
        let item = superV.answer[i]
        if (item === v.option) {
          return 2
        }
      }
      //选中的都红
      for (let i = 0; i < superV.quiz_user.user_answer.length; i++) {
        let item = superV.quiz_user.user_answer[i]
        if (item === v.option) {
          return 3
        }
      }
      return 0
    }
    for (let i = 0; i < superV.quiz_user.user_answer.length; i++) {
      let item = superV.quiz_user.user_answer[i]
      if (item === v.option) {
        return 1
      }
    }
    return 0
  }

  const choiceQuiz = (qindex) => {
    setQuizIndex(qindex)
    setData(pre => {
      const cpPre = cloneDeep(pre) || []
      if (!cpPre[qindex]) {
        return pre
      }
      if (!cpPre[qindex].quiz_user.answer_parse) {
        cpPre[qindex].answerParseHTML = ""
        onClear()
        return cpPre
      }
      const html = formatQuizParse(cpPre[qindex].quiz_user.answer_parse, (p1, index) => {
        // console.log(p1, index)
      }, (_changes) => {
        if (_show.current) {
          onParse(_changes)
        }
      })
      cpPre[qindex].answerParseHTML = html
      return cpPre
    })
  }


  return (
    <Wrapper $show={show} $isResult={isResult} $disabled={uploadScoreLoading}>
      <Title>Quiz</Title>
      <Result $show={isResult}>
        <div style={{
          color: "#161A19",
          textAlign: "center",
          fontFeatureSettings: "'clig' off, 'liga' off",
          fontSize: "calc(28px * var(--ratio))",
          fontWeight: 500,
          marginTop: "calc(7px * var(--ratio))",
          marginBottom: "calc(16px * var(--ratio))"
        }}>Congratulations!</div>
        <div style={{
          color: "#7FB1B0",
          textAlign: "center",
          fontFeatureSettings: "'clig' off, 'liga' off",
          fontSize: "calc(20px * var(--ratio))",
          fontWeight: 500,
        }}>"Great job on the quiz!"</div>
        <img style={{
          position: "absolute",
          left: 0,
          right: 0,
          top: 0,
          margin: "auto",
          maxWidth: "100%"
        }} src="https://www.bigread.ai/icon/20240618-143950ui.png" alt="" />
        <PointButton>
          <span>+</span>
          <span>{scoreInfo.star}</span>
          <span>Points</span>
        </PointButton>
        <Button onClick={() => {
          choiceQuiz(0)
          setIsResult(false)
        }}>Review</Button>
      </Result>
      <Content $show={isResult}>
        <Nos>
          {
            data.map((v, k) => (
              <No onClick={() => {
                choiceQuiz(k)
              }} $status={getNoStatus(v, k)} $key={k} key={k}>{k + 1}</No>
            ))
          }
        </Nos>
        {
          data.map((v, k) => (
            <QuestionWrapper $active={k === quizIndex} key={k}>
              <div style={{ display: "flex", alignItems: "flex-start" }}>
                <QuestionNo>Q{k + 1}:&nbsp;</QuestionNo>
                <Question>{v.question}</Question>
              </div>
              <div style={{ height: "calc(16px * var(--ratio))" }}></div>
              {
                v.answer.length > 1 && <div style={{
                  color: "#7FB1B0",
                  fontFamily: "Inter",
                  fontSize: "calc(14px * var(--ratio))",
                  fontWeight: 500,
                  textAlign: "center"
                }}>(Select all that apply)</div>
              }
              <div style={{ height: "calc(16px * var(--ratio))" }}></div>
              <Options>
                {
                  v.options.map((v2, k2) => (
                    <Option key={k2} $status={getOptionStatus(v, v2)} $parseLoading={v.quiz_user.answer_parse || v2.parseLoading} onClick={() => {
                      const cpData = cloneDeep(data)
                      let item = cpData[k]
                      if ((item.quiz_user.answer_result === 2 || item.quiz_user.answer_result === 1) && inArrays(v.answer, [v2.option])) {
                        for (let i = 0; i < v.answer.length; i++) {
                          const idx = v.options.findIndex(j => j.option === v.answer[i])
                          item.options[idx].parseLoading = true
                        }
                        setData(cpData)
                        socket.current.send(JSON.stringify({
                          msg_type: MsgTypeQuizParse,
                          hash_id,
                          quiz_id: v.id,
                          user_answer: item.quiz_user.user_answer.join(",")
                        }))
                      }
                      if (item.quiz_user.answer_result) {
                        return
                      }
                      if (v.answer.length > 1) { //多选题
                        const choicedIndex = item.quiz_user.user_answer.findIndex(j => j === v2.option)
                        if (choicedIndex !== -1) { //已经选择就去掉
                          item.quiz_user.user_answer.splice(choicedIndex, 1)
                        } else {
                          item.quiz_user.user_answer.push(v2.option)
                        }
                      } else { //单选
                        item.quiz_user.user_answer = [v2.option]
                      }
                      setData(cpData)
                    }}>
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span style={{ marginRight: "calc(16px * var(--ratio))" }}>{v2.option}</span>
                          <span>{v2.txt}</span>
                        </div>
                        <OptionExplanationWrapper $parseLoading={v.quiz_user.answer_parse || v2.parseLoading} $status={getOptionStatus(v, v2)}>
                          {
                            !v2.parseLoading && <span>Explanation</span>
                          }
                          {
                            v2.parseLoading && <LoadingBall style={{ width: "calc(12px * var(--ratio))", height: "calc(12px * var(--ratio))" }}></LoadingBall>
                          }
                        </OptionExplanationWrapper>
                      </div>
                    </Option>
                  ))
                }
              </Options>
              <Parse style={{ display: v.answerParseHTML ? "block" : "none" }} dangerouslySetInnerHTML={{ __html: v.answerParseHTML }}></Parse>
              {
                (!v.quiz_user.answer_result || uploadScoreLoading) && <div style={{ display: "flex", justifyContent: "center" }}>
                  <CheckButton onClick={async () => {
                    const cpData = cloneDeep(data)
                    const item = cpData[k]
                    const a = new Audio()
                    if (isCorrect(item.answer, item.quiz_user.user_answer)) {
                      item.quiz_user.answer_result = 1
                      a.src = audios.current["https://file.bigread.ai/audio/zhengque.mp3"]
                      a.play()
                    } else {
                      item.quiz_user.answer_result = 2
                      a.src = audios.current["https://file.bigread.ai/audio/cuowu.mp3"]
                      a.play()
                    }
                    setData(cpData)
                    const is_correct = item.quiz_user.answer_result === 1
                    setUploadScoreLoading(true)
                    try {
                      const _scoreInfo = await request.post("/client/v2/api/article/quiz/answer", {
                        quiz_id: item.id,
                        user_answer: item.quiz_user.user_answer.join(","),
                        is_correct
                      })
                      if (is_correct && _scoreInfo) {
                        onCorrect(_scoreInfo)
                      }
                      setScoreInfo(_scoreInfo)
                      if (_scoreInfo.quiz_sum === _scoreInfo.quiz_answer_num) {
                        await sleep(1000)
                        a.src = audios.current["https://file.bigread.ai/audio/level-passed.mp3"]
                        a.play()
                        setIsResult(true)
                      }
                    } catch (error) {

                    }
                    setUploadScoreLoading(false)
                  }} $disabled={getCheckBtnStatus(v)}>{uploadScoreLoading ? "uploading.." : "Check"}</CheckButton>
                </div>
              }
              {
                (v.quiz_user.answer_result && k !== data.length - 1 && !uploadScoreLoading) && <div style={{ display: "flex", justifyContent: "center" }}>
                  <CheckButton onClick={() => {
                    choiceQuiz(quizIndex + 1)
                  }}>Next</CheckButton>
                </div>
              }
            </QuestionWrapper>
          ))
        }
      </Content>
    </Wrapper >
  )
}

const Title = styled.div`
  color: #161A19;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: calc(15px * var(--ratio));
  font-style: normal;
  font-weight: 500;
  padding-left: calc(24px * var(--ratio));
  padding-top: calc(16px * var(--ratio));
`

const Parse = styled.div`
  color: #251F1F;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: calc(14px * var(--ratio));
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  white-space: pre-wrap;
`

const Button = styled.div`
  display: flex;
  width: calc(138px * var(--ratio));
  height: calc(48px * var(--ratio));
  justify-content: center;
  align-items: center;
  border-radius: calc(26px * var(--ratio));
  border: calc(1px * var(--ratio)) solid #6A9B9A;
  background: #CFF1F0;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
  top: calc(746px * var(--ratio));
  color: #161A19;
  font-size: calc(20px * var(--ratio));
  font-style: normal;
  font-weight: 600;
`

const PointButton = styled.div`
  width: calc(208px * var(--ratio));
  height: calc(84px * var(--ratio));
  border-radius: calc(40px * var(--ratio));
  border: calc(1px * var(--ratio)) solid var(---, #BBC6CD);
  background: linear-gradient(180deg, #E1F7F6 0%, rgba(255, 255, 255, 0.00) 100%), #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
  top: calc(555px * var(--ratio));
  & span:nth-child(1){
    color: #6A9B9A;
    text-align: center;
    font-size: 64px;
    font-weight: 400;
  }
  & span:nth-child(2){
    color: #6A9B9A;
    font-size: calc(64px * var(--ratio));
    font-style: normal;
    font-weight: 900;
    margin-right: calc(13px * var(--ratio));
  }
  & span:nth-child(3){
    color: #161A19;
    text-align: center;
    font-size: calc(16px * var(--ratio));
    font-style: normal;
    font-weight: 500;
  }
`

const Line = styled.div`
  background: #E4DFE0;
  width: 100%;
  height: calc(1px * var(--ratio));
  margin-top: calc(8px * var(--ratio));
  margin-bottom: calc(16px * var(--ratio));
`

const Wrapper = styled.div`
  width: calc(432px * var(--ratio));
  height: calc(var(--vh) - calc(116px * var(--ratio)));
  overflow: auto;
  display: ${props => props.$show ? "block" : "none"};
  border-radius: calc(30px * var(--ratio));
  border: calc(1px * var(--ratio)) solid var(--Gray-400, #DBD4D4);
  background: ${props => props.$isResult ? "linear-gradient(180deg, #E1F7F6 0%, #FFF 62.39%)" : "linear-gradient(180deg, #FFF 78.6%, #FFFAFA 100%)"};
  pointer-events: ${props => props.$disabled ? "none" : "all"};
  &::-webkit-scrollbar{
    display: none;
  }
  position: relative;
`

const Options = styled.div`
  
`

const OptionExplanationWrapper = styled.div`
  width: calc(102px * var(--ratio));
  height: calc(24px * var(--ratio));
  border-radius: calc(27px * var(--ratio));
  background: ${props => props.$parseLoading ? "#A2E39D" : "#FFF"};
  color: ${props => props.$parseLoading ? "#C1B8B9" : "#161A19"};
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: calc(12px * var(--ratio));
  font-style: normal;
  font-weight: 700;
  display: ${props => props.$status === 2 ? "flex" : "none"};
  margin-left: calc(8px * var(--ratio));
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
`

const Option = styled.div`
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: calc(14px * var(--ratio));
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  padding: calc(8px * var(--ratio)) calc(8px * var(--ratio)) calc(8px * var(--ratio)) calc(16px * var(--ratio));
  border-radius: calc(24px * var(--ratio));
  border: calc(1px * var(--ratio)) solid #DBD4D4;
  user-select: none;
  display: flex;
  align-items: center;
  pointer-events: ${props => props.$parseLoading ? "none" : "all"};;
  margin-bottom: calc(16px * var(--ratio));
  & > div > span:first-child{
    margin-right: calc(16px * var(--ratio));
    font-size: calc(16px * var(--ratio));
    font-weight: 600;
  }
  cursor: pointer;
  /* &:hover{
    background: #36B7B1;
    color: #FFF;
  } */  
  ${props => {
    let sty = ""
    if (props.$status === 0) {
      sty += "background: #FFF;"
      sty += "color: #161A19;"
    } else if (props.$status === 2) {
      sty += "background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, #A5FFA9 100%);"
      sty += "color: #161A19;"
    } else if (props.$status === 1) {
      sty += "background: #36B7B1;"
      sty += "color: #FFF;"
    } else if (props.$status === 3) {
      sty += "background: linear-gradient(90deg, #FFF 0%, #FF9494 100%);"
      sty += "color: #161A19;"
    }
    return sty
  }}
  justify-content: space-between;
`

const Content = styled.div`
  padding: calc(24px * var(--ratio));
  display: ${props => !props.$show ? "block" : "none"};
`

const Result = styled.div`
  padding: calc(24px * var(--ratio));
  display: ${props => props.$show ? "block" : "none"};
`

const Nos = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: calc(32px * var(--ratio));
`

const QuestionWrapper = styled.div`
  display: ${props => props.$active ? "block" : "none"};
`

const Question = styled.div`
  color: #161A19;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: calc(16px * var(--ratio));
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
`
const QuestionNo = styled.div`
  color: #161A19;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: calc(16px * var(--ratio));
  font-style: normal;
  font-weight: 500;
  line-height: 112.5%
`

const No = styled.div`
  display: flex;
  width: calc(32px * var(--ratio));
  height: calc(32px * var(--ratio));
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: calc(16px * var(--ratio));
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: ${props => props.$key % 10 === 0 ? 0 : "calc(4px * var(--ratio))"};
  margin-top: ${props => props.$key < 10 ? 0 : "calc(4px * var(--ratio))"};
  ${props => {
    let sty = ""
    if (props.$status === 0) {
      sty += "border: calc(1.2px * var(--ratio)) dashed #C1B8B9;"
      sty += "color: #DBD4D4;"
      sty += "background: #FFF;"
    } else if (props.$status === 1) {
      sty += "border: calc(1.2px * var(--ratio)) solid #161A19;"
      sty += "color: #161A19;"
      sty += "background: #FFF;"
    } else if (props.$status === 2) {
      sty += "border: calc(1.2px * var(--ratio)) solid #55D252;"
      sty += "color: #FFF;"
      sty += "background: #55D252;"
    } else if (props.$status === 3) {
      sty += "border: calc(1.2px * var(--ratio)) solid #ED8395;"
      sty += "color: #FFF;"
      sty += "background: #ED8395;"
    } else if (props.$status === 4) {
      sty += "border: calc(1.2px * var(--ratio)) solid #161A19;"
      sty += "color: #FFF;"
      sty += "background: #55D252;"
    } else if (props.$status === 5) {
      sty += "border: calc(1.2px * var(--ratio)) solid #161A19;"
      sty += "color: #FFF;"
      sty += "background: #ED8395;"
    }
    return sty
  }}
  cursor: pointer;
`

const CheckButton = styled.button`
  border-radius: calc(26px * var(--ratio));
  background:${props => props.$disabled ? "#CFF1F0" : "#36B7B1"};
  width: calc(180px * var(--ratio));
  height: calc(50px * var(--ratio));
  color: ${props => props.$disabled ? "#DBD4D4" : "#FFF"};
  pointer-events: ${props => props.$disabled ? "none" : "all"};
  border: none;
  font-size: calc(20px * var(--ratio));
  font-style: normal;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: calc(16px * var(--ratio));
`







export default Index