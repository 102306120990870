import styled from "styled-components";


const Index = (props) => {
  const { options, value = "1", onChange = () => { }, ...others } = props
  return (
    <Wrapper {...others}>
      {
        options.map((v, k) => (
          <TabItem key={k} onClick={() => onChange(v.value)} $v={v.value} $value={value}>{v.label}</TabItem>
        ))
      }
    </Wrapper>
  )
}

const Wrapper = styled.div`
  --h: calc(50px * var(--ratio));
  border-radius:calc(100px * var(--ratio));
  border: 1px solid #6E6566;
  width:fit-content;
  height:var(--h);
  display:flex;
  align-items:center;
`

const TabItem = styled.span`
  user-select: none;
  border-radius: calc(100px * var(--ratio));
  background: ${props => props.$value === props.$v && "rgba(22, 26, 25, 0.90)"};
  padding: 0 calc(24px * var(--ratio));
  color: ${props => props.$value === props.$v ? "#fff" : "rgba(22, 26, 25, 0.9)"};
  font-size: calc(15px * var(--ratio));
  font-weight: 300;
  display: inline-flex;
  height: calc(100% - calc(4px * var(--ratio)));
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: calc(-15px * var(--ratio));
  &:first-child{
    margin-left: calc(2px * var(--ratio));
  }
  &:last-child{
    margin-right: calc(2px * var(--ratio));
  }
`

export default Index