import axios from 'axios'

const urlsBlob = {}

const Index = async (urls) => {
    // 将所有的 URL 转换为加载它们的 promise
    let promises = urls.map(url => {
        return new Promise((resolve, reject) => {
            if (urlsBlob[url]) {
                resolve(urlsBlob)
                return
            }
            // 发送GET请求获取MP3文件
            axios.get(url, {
                responseType: 'arraybuffer'
            }).then(response => {
                urlsBlob[url] = URL.createObjectURL(new Blob([response.data], { type: 'audio/mpeg' }))
                resolve(urlsBlob)
            }).catch(error => {
                reject(error)
            });
        });
    });
    // 等待所有音频文件加载完毕
    return Promise.all(promises).then(audios => {
        const obj = {}
        // 所有音频文件预加载完成，返回 Audio 对象数组
        for (let i = 0; i < audios.length; i++) {
            for (let k in audios[i]) {
                obj[k] = audios[i][k]
            }
        }
        return obj;
    });
}

export default Index
