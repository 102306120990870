import { useEffect, useRef } from "react"

const Index = (props) => {
  const circumference = useRef(339.292)
  const { size = 100,
    strokeUnActivite = "#FFF",
    strokeActivite = "#ED8395",
    children,
    progress = 0
  } = props
  const svgRef = useRef()

  const getProgress = (p) => {
    let offset = circumference.current - (p / 100) * circumference.current;
    return offset.toString()
  }


  return (
    <div style={{
      width: `calc(${size}px * var(--ratio))`,
      height: `calc(${size}px * var(--ratio))`,
      borderRadius: "50%",
      position: "relative"
    }}>
      {
        new Array(100).fill('').map((v, k) => (
          <div key={k} style={{
            position: "absolute",
            width: `calc(${size * 0.018867924528301886}px * var(--ratio))`,
            height: `calc(${size * 0.03773584905660377}px * var(--ratio))`,
            backgroundColor: k + 1 <= progress ? strokeActivite : "#FFF",
            transformOrigin: `center calc(${size / 2 + size * 0.03773584905660377}px * var(--ratio))`,
            transform: `rotate(${360 / 100 * k}deg)`,
            left: 0,
            right: 0,
            top: `calc(${size * 0.03773584905660377 * -1}px * var(--ratio))`,
            margin: "auto",
          }}></div>
        ))
      }
      <div style={{
        borderRadius: "50%",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: "auto",
        width: `calc(100% - calc(${size * 0.04716981132075472 * 2.5}px * var(--ratio)))`,
        height: `calc(100% - calc(${size * 0.04716981132075472 * 2.5}px * var(--ratio)))`
      }}>{children}</div>
      <svg width="100%" height="100%" viewBox="0 0 120 120">
        <circle cx="60" cy="60" r="54" fill="none" stroke={strokeUnActivite} strokeWidth={`calc(${size * 0.04716981132075472}px * var(--ratio))`} />
        <circle cx="60" cy="60" r="54" fill="none" stroke={strokeActivite} strokeWidth={`calc(${size * 0.04716981132075472}px * var(--ratio))`} strokeDasharray={circumference.current} strokeDashoffset={getProgress(progress)} style={{
          transition: "stroke-dashoffset 1s",
          transform: "rotate(-90deg)",
          transformOrigin: "center"
        }} ref={svgRef} />
      </svg>
    </div>
  )
}


export default Index