import styled from "styled-components";
import BackIcon from '../../components/icons/back'
import { PaypalIcon, WechatIcon } from '../../components/icons/pay'
import { useEffect, useImperativeHandle, useRef, useState, useContext } from 'react'
import { Logo } from '../../components/icons/welcome'
import { Context as RequestContext } from '../request'
import LoadingBall from '../../components/icons/ball_loading'

const Index = (props) => {
  const { onChoicePay = () => { }, onClose = () => { } } = props
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const requestCtx = useContext(RequestContext)
  const request = requestCtx.useRequest()

  useImperativeHandle(props.action, () => ({
    open: (open) => {
      if (open) {

      } else {

      }
      setShow(open)
    },
    payPalPay
  }));

  useEffect(() => {
    if (!show) {
      onClose()
    }
  }, [show])


  const payPalPay = async (sku_plan_id) => {
    setLoading(true)
    try {
      const info = await request.post("/client/v2/api/sub/stripe", {
        sku_plan_id,
        return_url: `${window.location.origin}/pay/success`,
        cancel_url: `${window.location.origin}/pay`
      })
      // return
      window.location.href = info.pay_link
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  return (
    <Wrapper $show={show}>
      <LogoWrapper>
        <Logo style={{ width: "calc(157px * var(--ratio))", height: "calc(58px * var(--ratio))" }} color="#161A19" />
      </LogoWrapper>
      <BackIcon onClick={() => setShow(false)} style={{
        width: "calc(52px * var(--ratio))",
        height: "calc(52px * var(--ratio))",
        position: "fixed",
        left: "calc(310px * var(--ratio))",
        top: "calc(79px * var(--ratio))",
        zIndex: 11
      }} />
      <Content>
        <div style={{ height: "calc(80px * var(--ratio))" }}></div>
        <div style={{ color: "#161A19", fontSize: "calc(32px * var(--ratio))", fontWeight: 600, lineHeight: 1.6 }}>Check out to get BigRead Plus</div>
        <div style={{ height: "calc(101px * var(--ratio))" }}></div>
        <div style={{ color: "#161A19", fontSize: "calc(22px * var(--ratio))", fontWeight: 700 }}>Quick checkout</div>
        <div style={{ height: "calc(1px * var(--ratio))", background: "#CFF1F0", margin: "calc(23px * var(--ratio)) 0" }}></div>
        <div style={{ display: 'flex' }}>
          {
            loading ? <LoadingBall style={{ cursor: "pointer", width: "calc(129px * var(--ratio))", height: "calc(48px * var(--ratio))" }} /> : <PaypalIcon onClick={() => onChoicePay(1)} style={{ cursor: "pointer", width: "calc(129px * var(--ratio))", height: "calc(48px * var(--ratio))" }} />
          }
          <WechatIcon onClick={() => onChoicePay(2)} style={{ cursor: "pointer", width: "calc(188px * var(--ratio))", height: "calc(48px * var(--ratio))", marginLeft: "calc(13px * var(--ratio))" }} />
        </div>
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: #FFF;
  width: 100%;
  height: 100%;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: ${props => props.$show ? "block" : "none"};
`

const Content = styled.div`
  width: calc(536px * var(--ratio));
  margin: 0 auto;
`

const LogoWrapper = styled.div`
    display: flex;
    align-items: center;
    padding-left: calc(32px * var(--ratio));
    padding-top: calc(32px * var(--ratio));
`

const LogoTxt = styled.span`
    color: #161A19;
    font-size: calc(20px * var(--ratio));
    font-style: normal;
    font-weight: 500;
`

export default Index