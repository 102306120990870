import styled from "styled-components";
import lottie from 'lottie-web'
import fullpageloading from '../../static/lottie/fullpageloading.json'
import { useEffect, useRef } from "react";
import StepComplateIcon from '../../components/icons/step_complate'

const Index = (props) => {
  const { steps = [], current = 0 } = props
  const animateDom = useRef()
  const animate = useRef()

  useEffect(() => {
    if (current < 0 || current > steps.length - 1) {
      if (animate.current) {
        animate.current.destroy()
      }
      return
    }
    animate.current = lottie.loadAnimation({
      container: animateDom.current, // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: fullpageloading
    })
    return () => {
      animate.current.destroy()
    }
  }, [current, steps])

  useEffect(() => {
    // playAnimate()

    return () => {
      // animate?.current?.destroy()
    }

  }, [])



  return (
    <Wrapper>
      {
        steps.map((v, k) => (
          <StepItem $dataLen={steps.length} $current={current} key={k}>
            <div>
              {
                current === k && <IconWrapper ref={animateDom}></IconWrapper>
              }
              {
                k > current && <IconWrapper><Dot></Dot></IconWrapper>
              }
              {
                k < current && <IconWrapper><Com></Com></IconWrapper>
              }
            </div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
              <StepItemNum>0{k + 1}.</StepItemNum>
              <StepItemTxt $current={current} $k={k}>{v.title}</StepItemTxt>
              <StepItemDesc $current={current} $k={k}>
                {
                  current === k && "In Progress"
                }
                {
                  k < current && "Finished"
                }
                {
                  k > current && "Waiting"
                }
              </StepItemDesc>
            </div>
          </StepItem>
        ))
      }
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
`

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(32px * var(--ratio));
  height: calc(32px * var(--ratio));
  margin-right: calc(10px * var(--ratio));
`

const Dot = styled.div`
  background: #A8DCDA;
  width: calc(14px * var(--ratio));
  height: calc(14px * var(--ratio));
  border-radius: 50%;
`

const Com = styled(StepComplateIcon)`
  width: calc(32px * var(--ratio));
  height: calc(32px * var(--ratio));
`

const StepItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: calc(${props => 660 / props.$dataLen}px * var(--ratio));
  position: relative;
  &::after{
    content: '';
    background: ${props => props.$k >= props.$current ? "#CFF1F0" : "#7FB1B0"};
    height: calc(2px * var(--ratio));
    width: calc(${props => 600 / props.$dataLen}px * var(--ratio));
    position: absolute;
    top: 0;
    bottom: 0;
    right: calc(${props => -639 / props.$dataLen}px * var(--ratio));;
    margin: auto;
    transition: 0.3s;
    -webkit-transition: 0.3s;
  }
  &:last-child::after{
    display: none;
  }
  &:last-child{
    margin-right: calc(10px * var(--ratio));
  }
`

const StepItemNum = styled.div`
  color: #C1B8B9;
  text-align: center;
  font-size: calc(14px * var(--ratio));
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

const StepItemTxt = styled.div`
  font-size:calc(14px * var(--ratio));
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: calc(4px * var(--ratio)) 0;
  ${props => {
    let sty = ""
    if (props.$current === props.$k) {
      sty += "color: #161A19"
    } else if (props.$k > props.$current) {
      sty += "color: #C1B8B9"
    } else if (props.$k < props.$current) {
      sty += "color: #161A19"
    }
    return sty
  }};
`

const StepItemDesc = styled.div`
  ${props => {
    let sty = ""
    if (props.$current === props.$k) {
      sty += "color: #21968F"
    } else if (props.$k > props.$current) {
      sty += "color: #C1B8B9"
    } else if (props.$k < props.$current) {
      sty += "color: #161A19"
    }
    return sty
  }};
  text-align: center;
  font-size: calc(14px * var(--ratio));
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export default Index