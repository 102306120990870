import styled from "styled-components";
import { useEffect, useState, useContext } from 'react'
import { Input, Button } from './components'
import { Context as RouterContext } from '../../router'
import { Context as RequestContext } from '../request'
import { useNavigate, useParams } from "react-router-dom";
import { Logo } from '../../components/icons/welcome'

const Index = (props) => {
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState("")
  const [password2, setPassword2] = useState("")
  const [success, setSuccess] = useState(false)
  const rctx = useContext(RouterContext)
  const requestCtx = useContext(RequestContext)
  const request = requestCtx.useRequest(false)
  const [errMsg, setErrMsg] = useState("")
  const n = useNavigate()
  const params = useParams()

  useEffect(() => {


    rctx?.onresize()
  }, [])

  const onSubmit = async (e) => {
    e.preventDefault()
    if (password !== password2) {
      setErrMsg("the two passwords are different")
      return
    }
    setLoading(true)
    try {
      await request.post(`/client/open/reset/do`, {
        password,
        token: params.token
      })
      setSuccess(true)
    } catch (error) {
      console.log(error)
      setErrMsg(error)
    }
    setLoading(false)
  }


  return (
    <Wrapper>
      <LogoWrapper>
        <Logo style={{ width: "calc(157px * var(--ratio))", height: "calc(58px * var(--ratio))" }} color="#161A19" />
      </LogoWrapper>
      <div style={{ height: "calc(223px * var(--ratio))" }}></div>
      <Content $success={success}>
        <Title>Reset your password</Title>
        <div style={{ background: "#CFF1F0", height: "calc(1px * var(--ratio))", margin: "calc(24px * var(--ratio)) 0" }}></div>
        <div style={{ height: "calc(24px * var(--ratio))" }}></div>
        <form onSubmit={onSubmit}>
          <Input type="password" value={password} onChange={e => {
            setPassword(e.target.value)
          }} label="New password" placeholder="Enter new password" />
          <div style={{ height: "calc(46px * var(--ratio))" }}></div>
          <Input type="password" value={password2} onChange={e => {
            setPassword2(e.target.value)
          }} label="Confirm your password" placeholder="Enter new password again" />
          <div style={{ height: "calc(24px * var(--ratio))" }}></div>
          <Button type="submit" disabled={!password || !password2 || loading}>{loading ? "loading" : "Reset password"}</Button>
          {
            errMsg && <div style={{ color: "#DC362E", fontSize: "calc(14px * var(--ratio))", fontWeight: 500, lineHeight: 1.14, textAlign: "center", marginTop: "calc(8px * var(--ratio))" }}>{errMsg}</div>
          }
        </form>
      </Content>
      <Content $success={!success}>
        <Title>Success!</Title>
        <div style={{
          paddingLeft: "calc(32px * var(--ratio))",
          color: "#161A19",
          fontSize: "calc(18px * var(--ratio))",
          fontWeight: 400,
          lineHeight: 1.11
        }}>Your password has been changed.</div>
        <div style={{ background: "#CFF1F0", height: "calc(1px * var(--ratio))", margin: "calc(24px * var(--ratio)) 0" }}></div>
        <Button onClick={() => n("/login")}>Back to Login</Button>
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: #FFF;
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
`

const Content = styled.div`
  width: calc(490px * var(--ratio));
  margin: 0 auto;
  padding: calc(40px * var(--ratio));
  display: ${props => props.$success ? "none" : "block"};
`

const Title = styled.div`
  color: var(--black, #161A19);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: calc(48px * var(--ratio));
  font-style: normal;
  font-weight: 800;
  line-height: 1.16;
  padding-left: calc(32px * var(--ratio));
`

const LogoWrapper = styled.div`
    display: flex;
    align-items: center;
    padding-left: calc(32px * var(--ratio));
    padding-top: calc(32px * var(--ratio));
`

const LogoTxt = styled.span`
    color: #161A19;
    font-size: calc(20px * var(--ratio));
    font-style: normal;
    font-weight: 500;
`


export default Index