import HandlePoint from '../../components/HandlePoint'
import { useEffect, useRef, useState } from 'react';
import MoreIcon from '../../static/img/20240226-174450.png'
import styled from "styled-components";
var player;
var event
const Index = (props) => {
    const { id, data, onVideoIfameSummary, xPos, yPos } = props
    const { value = '', makeds = {} } = data
    const [mouseOver, setMouseOver] = useState(false)
    const eventRef = useRef({})
    const videoPause = useRef(true)

    function onYouTubeIframeAPIReady() {
        player = new window.YT.Player(`youtube_player_${value}`, {
            width: 560,
            height: 360,
            videoId: value,  // 替换成你的YouTube视频ID
            playerVars: {
                controls: 0 // 隐藏菜单
            },
            events: {
                'onReady': onPlayerReady,
                'onStateChange': onPlayerStateChange
            }
        });
    }

    function onPlayerReady(event) {
        event = event
        eventRef.current = event
        //event.target.playVideo(); // 视频加载完毕后自动播放
    }

    function onPlayerStateChange(event) {
        // 可以在这里处理视频的状态变化，如播放、暂停、停止等事件
    }

    useEffect(() => {
        if (value !== '') {
            onYouTubeIframeAPIReady()
        }
    }, [value])


    return (
        <div style={{ position: "relative" }}>
            <div style={{ display: "flex", justifyContent: "right" }}>
                <VideoIfameTool>
                    <VideoIfameToolItem style={{ pointerEvents: makeds["summary"] ? "none" : "auto" }} onClick={() => {
                        onVideoIfameSummary(id, xPos, yPos, value)
                    }}>
                        <img style={{ width: 24, height: 24 }} src={MoreIcon} alt='' />
                    </VideoIfameToolItem>
                </VideoIfameTool>
            </div>
            <VideoIfameContetnBox
                onMouseOver={() => setMouseOver(true)}
                onMouseOut={() => setMouseOver(false)}
            >
                <div onClick={() => {
                    if (videoPause.current) {
                        eventRef.current.target.playVideo()
                        videoPause.current = false
                    } else {
                        eventRef.current.target.pauseVideo()
                        videoPause.current = true
                    }
                }} style={{
                    position: "absolute",
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    margin: "auto",
                    zIndex: 99
                }}></div>
                <div id={`youtube_player_${value}`}></div>
                <HandlePoint mouseOver={mouseOver} id={id}></HandlePoint>
            </VideoIfameContetnBox >
        </div >
    )
}

const VideoIfameContetnBox = styled.div`
    width: 560px;
    height: 360px;
    border-radius: 6px;
    border: 2px solid #1F1D0F;
    background: #FFF;
    box-shadow: 2px 2px 0px 0px #1F1D0F;
    position: relative;
`

const VideoIfameTool = styled.div`
    border: 2px solid #1F1D0F;
    background: #FFF;
    border-radius: 6px 6px 0 0;
    display: flex;
    justify-content: right;
    width: 44px;
    height: 44px;
    box-shadow: 2px 2px 0px 0px #1F1D0F;
    margin-bottom: -2px;
    margin-right: 1px;
    border-bottom: none;
    overflow: hidden;
`

const VideoIfameToolItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #FFF;
    overflow: hidden;
`

export default Index