import HandlePoint from '../../components/HandlePoint'
import { useState } from 'react';
import { Skeleton } from 'antd'
import styled from "styled-components";

const Index = (props) => {
    const { id, onVideoPlay, xPos, yPos } = props
    const { value = {}, makeds = {} } = props.data
    const [mouseOver, setMouseOver] = useState(false)
    return (
        <div>
            <VideoContentBox onMouseOver={() => setMouseOver(true)} onMouseOut={() => setMouseOver(false)}>
                {
                    (!value || (value.items && value.items.length === 0)) && <div>
                        <Skeleton active paragraph={{ rows: 10 }} />
                    </div>
                }
                {
                    value?.items?.map((v, index) => (
                        <VideoContentBoxItem key={v.id.videoId} style={{ pointerEvents: makeds[v.id.videoId] ? "none" : "auto" }} onClick={() => {
                            onVideoPlay(id, xPos, yPos, v)
                        }}>
                            <VideoContentBoxItemVideo style={{
                                background: `url(${v.snippet.thumbnails.high.url})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center center",
                                backgroundRepeat: "no-repeat"
                            }}>
                                <VideoContentBoxItemVideoShadow>
                                    Play
                                </VideoContentBoxItemVideoShadow>
                            </VideoContentBoxItemVideo>
                            <div>
                                <VideoContentBoxItemTitle>{v.snippet.title}</VideoContentBoxItemTitle>
                            </div>
                        </VideoContentBoxItem>
                    ))
                }
            </VideoContentBox>
            <HandlePoint mouseOver={mouseOver} id={id} />
        </div>
    )
}

const VideoContentBoxItemTitle = styled.div`
    color: #1F1D0F;
    font-family: "Roboto Slab700";
    font-size: 16px;
    line-height: 24px; /* 150% */
`

const VideoContentBoxItemVideo = styled.div`
    width: 236px;
    height: 132px;
    border-radius: 6px;
    border: 2px solid #1F1D0F;
    background: #EEE;
    box-shadow: 2px 2px 0px 0px #1F1D0F;
    margin-bottom: 20px;
    flex-shrink: 0;
    margin-right: 20px;
    overflow: hidden;
    position: relative;
`

const VideoContentBoxItemVideoShadow = styled.div`
    position: absolute;
    background: rgba(0,0,0,0.7);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    color: #FFF;
    text-align: center;
    line-height: 132px;
    ${VideoContentBoxItemVideo}:hover & {
        display: block;
    }
`

const VideoContentBoxItem = styled.div`
    display: flex;
`



const VideoContentBox = styled.div`
    border-radius: 6px;
    border: 2px solid #1F1D0F;
    background: #FFF;
    box-shadow: 2px 2px 0px 0px #1F1D0F;
    padding: 20px;
    width: 516px;
    padding-bottom: 0;
`

export default Index