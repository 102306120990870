import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"

const Index = () => {
  const params = useParams()
  const n = useNavigate()
  useEffect(() => {
    localStorage.setItem("token", params.token)
    const urlParam = new URLSearchParams(window.location.search)
    if (urlParam.get("path")) {
      n(decodeURIComponent(urlParam.get("path")))
    } else {
      n("/")
    }
  }, [])
  return null
}

export default Index