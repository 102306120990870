import * as echarts from 'echarts';
import { useEffect, useRef, useContext, useState } from 'react';
import { Context as RouteContext } from '../../router'
import { useNavigate } from 'react-router-dom';
import { Context as RequestContext } from '../request'
import styled from 'styled-components';
import { Context as AuthContext } from '../auth'
import { UpgradeShadow, UpgradeBox, LockIcon, UpgradeBtn, WhitePlusIcon } from './ReadingTime'

const Index = (props) => {
  let { show = false } = props
  const dom = useRef()
  const routeCtx = useContext(RouteContext)
  const myChart = useRef({})
  const [data, setData] = useState({ stan_dard: [] })
  const requestCtx = useContext(RequestContext)
  const request = requestCtx.useRequest()
  const doms = useRef({})
  const titleWidthObserver = useRef()
  const nos = useRef(["1.0", "2.0", "3.0"])
  const n = useNavigate()
  const authCtx = useContext(AuthContext)


  const loadData = async () => {
    try {
      let d = await request.get(`/client/api/profile/progress`)
      d.isLoad = true
      setData(d)
      return d
    } catch (error) {

    }
  }

  const getXYdata = (_data) => {
    const x = []
    const y = []
    for (let i of _data.knowledges) {
      y.push(i.knowledge)
      x.push(i.correct_rate)
    }
    return { x, y }
  }

  const initTable = async (key, chartDom, _data) => {
    const { x, y } = getXYdata(_data)
    myChart.current[key] = echarts.init(chartDom);
    const option = {
      title: {
        show: false
      },
      tooltip: {
        trigger: 'item',
        axisPointer: {
          type: 'shadow'
        },
        extraCssText: `background: rgba(22, 26, 25, 0.80);color:#FFF !important;font-size: 12px;font-weight: 400;border:none`,
        formatter: function (params) {
          const { data, name } = params
          let content = `<div>
            <div>${name}</div>
            <div style="display:flex;align-items: center;">
              <div style="width: 20px;height:10px;background:#35B7B2;margin-right:10px"></div>
              <div>${(data * 100).toFixed(1) + '%'}</div>
            </div>
          </div>`
          return content
        }
      },
      legend: {
        show: false
      },
      grid: {
        left: '342px',
        right: '64px',
        bottom: 24,
        top: 10
        // containLabel: true
      },
      xAxis: {
        type: 'value',
        axisLabel: {
          color: "#121212",
          fontSize: 12,
          fontWeight: 300,
          formatter: function (value, index) {
            return (value * 100).toFixed(1) + '%';
          }
        },
        splitLine: { show: true },
      },
      yAxis: {
        type: 'category',
        data: y,
        inverse: true,
        axisLabel: {
          color: "#000",
          fontSize: 12,
          fontWeight: 500
        },
        splitLine: { show: true },
        axisTick: {
          show: false  // 这里设置为 false 来隐藏 Y 轴的刻度
        }
      },
      series: [
        {
          name: '2011',
          type: 'bar',
          data: x,
          sampling: 'none',
          itemStyle: {
            color: '#35B7B2'
          },
          // 鼠标 hover 时的样式
          emphasis: {
            itemStyle: {
              // 鼠标移入时柱形的颜色
              color: '#41e4de'
            }
          }
        },
      ]
    };
    myChart.current[key].setOption(option);
  }

  if (show && !data.isLoad) {
    loadData()
  }

  const onObserver = (doms) => {
    for (let k in myChart.current) {
      const option = myChart.current[k].getOption()
      if (titleWidthObserver.current) {
        option.grid[0].left = `${titleWidthObserver.current.clientWidth}px`
        myChart.current[k].setOption(option)
      }
      if (dom.current) {
        myChart.current[k].resize({ width: dom.current.clientWidth })
      }
    }
  }

  useEffect(() => {

    const observer = new ResizeObserver(onObserver)
    observer.observe(dom.current)

    return () => {
      observer.disconnect()
    }

  }, [])


  return (
    <div style={{ display: show ? "block" : "none", width: "100%", height: "100%", position: "relative", overflow: !authCtx?.userInfo?.vip_info?.is_vip ? "hidden" : "auto" }} ref={dom}>
      {
        data.stan_dard.map((v, k) => (
          <div key={k}>
            <Item>
              <ItemHeader>
                <ItemHeaderTitle ref={e => {
                  if (e) {
                    titleWidthObserver.current = e
                  }
                }}>{nos.current[k]} {v.title}</ItemHeaderTitle>
                <ItemCardBox>
                  <ItemCard>
                    <QuestionAnswerd>{v.quiz_sum}</QuestionAnswerd>
                    <Desc>Question <br /> Answered</Desc>
                  </ItemCard>
                  <ItemCard>
                    <QuestionAnswerd style={{ color: "#59BC57" }}>{v.quiz_sum === 0 ? 0 : parseInt(v.quiz_correct / v.quiz_sum * 100)}%</QuestionAnswerd>
                    <Desc>Answered <br /> Correctly</Desc>
                  </ItemCard>
                  <ItemCard>
                    <QuestionAnswerd style={{ color: "#E46962" }}>{v.quiz_sum === 0 ? 0 : 100 - parseInt(v.quiz_correct / v.quiz_sum * 100)}%</QuestionAnswerd>
                    <Desc>Answered <br /> Incorrectly</Desc>
                  </ItemCard>
                </ItemCardBox>
              </ItemHeader>
              <ItemTable style={{ height: k === 2 ? 400 : 200 }} ref={async ele => {
                if (ele && !doms.current[v.title] && show) {
                  doms.current[v.title] = ele
                  await initTable(v.title, ele, v)
                }
              }}></ItemTable>
            </Item>
            {
              k !== data.stan_dard.length - 1 && <div style={{ background: "#7A7A7A", height: "calc(1px * var(--ratio))", width: "100%" }}></div>
            }
          </div>
        ))
      }
      {
        !authCtx?.userInfo?.vip_info?.is_vip && <UpgradeShadow>
          <UpgradeBox>
            <LockIcon />
            <div>To unlock full function of progress.</div>
            <div>View your child’s progress by <span>multiple</span> dimensions.</div>
            <UpgradeBtn onClick={() => n("/pay")}>
              <WhitePlusIcon />
              <span>Upgrade Now</span>
            </UpgradeBtn>
          </UpgradeBox>
        </UpgradeShadow>
      }

    </div>
  )
}

const Item = styled.div`
  margin-bottom: calc(56px * var(--ratio));
  margin-top: calc(56px * var(--ratio));
`

const ItemTable = styled.div`
  height: 200px;
`

const TitleWidthObserver = styled.div`
  width: calc(342px * var(--ratio));
  display: none;
`

const QuestionAnswerd = styled.div`
  color: rgba(22, 26, 25, 0.80);
  font-size: calc(40px * var(--ratio));
  font-style: normal;
  font-weight: 700;
  margin-right: calc(16px * var(--ratio));
`

const Desc = styled.div`
  color: #6E6566;
  font-size: calc(14px * var(--ratio));
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`

const ItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ItemHeaderTitle = styled.div`
  color: #146A67;
  font-size: calc(24px * var(--ratio));
  font-weight: 500;
  line-height: normal;
  width: calc(342px * var(--ratio));
  text-align: right;
`

const ItemCardBox = styled.div`
  display: flex;
  padding-right: calc(32px * var(--ratio));
`

const ItemCard = styled.div`
  width: calc(158px * var(--ratio));
  height: calc(38px * var(--ratio));
  padding: calc(16px * var(--ratio)) calc(32px * var(--ratio));
  border-radius: calc(12px * var(--ratio));
  border: calc(1px * var(--ratio)) solid #F7F5F5;
  background: rgba(255, 255, 255, 0.60);
  box-shadow: 0px calc(2px * var(--ratio)) calc(20px * var(--ratio)) calc(-12px * var(--ratio)) rgba(0, 0, 0, 0.30);
  display: flex;
  margin-right: calc(10px * var(--ratio));
  &:last-child{
    margin-right: 0;
  }
`

export default Index