import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web'
import ballLoading from '../../static/lottie/word_playing.json'


const Index = (props) => {
  const animateDom = useRef()
  const animate = useRef()

  useEffect(() => {
    playAnimate()

    return () => {
      animate?.current?.destroy()
    }
  }, [])

  const playAnimate = () => {
    animate.current = lottie.loadAnimation({
      container: animateDom.current, // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: ballLoading
    })
  }
  return (
    <div {...props} ref={animateDom}></div>
  )
}

export default Index

