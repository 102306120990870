
import { Swiper, SwiperSlide } from 'swiper/react';
import { useEffect, useRef, useState, useContext } from 'react';
import styled from "styled-components";
import CurvedArrowIcon from '../../components/icons/curvedarrow'
import LineArrowIcon from '../../components/icons/linearrow'
import GoIcon from '../../components/icons/go'
import FlagIcon from '../../components/icons/flag'
import WelcomeIcon from '../../components/icons/welcome'
import { Points, PointsNum, PointsTxt } from '../home'
import { TaskInfoTag, TaskInfoTagIcon, LevelItemProgressTxt } from '../reading_path'
import LineProgress from '../../components/LineProgress'
import BackIcon from '../../components/icons/back'
import LevelItemProgress from '../../components/RoundProgress'
import AddButtonIcon from '../../components/icons/add_button'
import { WeekWrapper, WeekItem } from '../reading_path'
import Button from '../../components/ButtonV2'
import TopicShadow from './topic_shadow'
import { Context } from '../auth'
import { Context as RouterContext } from '../../router'
import { Context as RequestContext } from '../request'
import { MsgTypeTopic, MsgTypeArticle, MsgTypeArticleHashId, MsgTypeWorkFlowProgress } from '../../utils/define'
import { sleep } from '../../utils'
import { useNavigate } from 'react-router-dom';
import { LoadingWrapper } from '../../utils/full_loading'
import { cloneDeep } from 'lodash';
import emptyImg from '../../static/img/20230831-183719.png'



const Index = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [orgData, setOrgData] = useState([])
  const swiperRef = useRef()
  const [readWeek, setReadWeek] = useState([])
  const [starNum, setStarNum] = useState(0)
  const [canAdd, setCanAdd] = useState(false)
  const topicShadow = useRef()
  const ctx = useContext(Context)
  const rctx = useContext(RouterContext)
  const requestCtx = useContext(RequestContext)
  const request = requestCtx.useRequest()
  const socket = useRef()
  const emitter = ctx.useEmitter()
  const ob = useRef()

  const n = useNavigate()


  const handleProgressData = (data, uinfo) => {
    let num = 0
    const arr = []
    for (let i = 0; i < data.length; i++) {
      let item = data[i]
      if (item.pre_make_param) {
        item.pre_make_param = JSON.parse(item.pre_make_param)
      }
      if (i === 0) {
        arr.push({ tp: "" })
      }
      arr.push(item)
      if (item.tp === "LEVEL_FLAG") {
        arr.push({ tp: "GO_FLAG" })
      }
      if (item.tp === "ARTICLE_CARD") {
        arr.push({ tp: "" })
      }
      try {
        if (data[i + 1].tp === "LEVEL_FLAG") {
          arr.push({ tp: "" })
        }
      } catch (error) {

      }
      if (item.ar_level === uinfo.ar_level && item.tp === "ARTICLE_CARD" && (item.status == 1 || item.status == 2)) {
        num++
      }
    }
    arr.push({ tp: "CURRENT" })

    if (arr.length < 9) {
      const fullNum = 9 - arr.length
      for (let i = 0; i < fullNum; i++) {
        arr.push({ tp: "" })
      }

    } else {
      arr.push({ tp: "" })
      arr.push({ tp: "" })
    }

    arr.push({ tp: "" })
    setCanAdd(num < 2)
    return arr
  }

  const addTopic = () => {
    socket.current.send(JSON.stringify({
      msg_type: MsgTypeTopic
    }))
    topicShadow.current.open(true)
    topicShadow.current.setLoading(true)
    topicShadow.current.setLexile(`${ctx?.userInfo?.min_lexile}L - ${ctx?.userInfo?.max_lexile}L`)
  }

  const onMsgTypeTopic = async ({ msg }) => {
    msg = JSON.parse(msg)
    topicShadow.current.setStepIndex(pre => {
      return pre + 1
    })
    await sleep(500)
    topicShadow.current.setStepIndex(pre => {
      return pre + 1
    })
    await sleep(500)
    topicShadow.current.setData(msg || [])
    topicShadow.current.setLoading(false)
  }

  const onMsgTypeWorkFlowProgress = ({ msg }) => {
    msg = JSON.parse(msg)
    console.log(msg)
    topicShadow.current.setSteps(msg.steps)
    topicShadow.current.setStepIndex(msg.current)
  }

  const onAddToPath = async ({ topic, hash_id }) => {
    try {
      if (topic) {
        await request.post("/client/v2/api/learn/arlevel/progress/add", {
          topic,
          lexile: `${ctx?.userInfo?.min_lexile}L - ${ctx?.userInfo?.max_lexile}L`
        })
      }
      if (hash_id) {
        await request.post("/client/v2/api/learn/arlevel/progress/add", {
          hash_id,
          type: `THIRD`
        })
      }
      const userInfo = await ctx.loadUserInfo()
      await refreshProgress(userInfo)
      topicShadow.current.open(false)
    } catch (error) {
      console.log(error)
    }
  }


  const refreshProgress = async (uinfo) => {
    const data = await request.get("/client/v2/api/learn/arlevel/progress") || []
    setOrgData(data || [])
    const _data = handleProgressData(data, uinfo)
    setData(_data)
    await sleep(300)
    swiperRef.current.update()
    if (_data.length > 10) {
      swiperRef.current.slideTo(_data.length - 1, 0)
    } else {
      swiperRef.current.slideTo(0, 0)
    }
    if (!ob.current) {
      ob.current = new IntersectionObserver((list) => {
        for (let i = 0; i < list.length; i++) {
          let item = list[i]
          if (item.isIntersecting) {
            item.target.style.background = `url(${item.target.dataset.src}) lightgray 50% / cover no-repeat`
            ob.current.unobserve(item.target)
          }
        }
      }, {
        threshold: 0
      })
    }
    const articleCovers = document.querySelectorAll("div[data-src]")
    for (let i = 0; i < articleCovers.length; i++) {
      ob.current.observe(articleCovers[i])
    }
  }

  const onMsgTypeArticleHashId = ({ data }) => {
    n(`/article/v2/${data.hash_id}`)
  }

  useEffect(() => {
    emitter.on(MsgTypeWorkFlowProgress, onMsgTypeWorkFlowProgress)
    emitter.on(MsgTypeTopic, onMsgTypeTopic)
    emitter.on(MsgTypeArticleHashId, onMsgTypeArticleHashId)


    request.get("/client/v2/api/user/read/week").then(async r => {
      socket.current = await ctx.useSocket()
      setStarNum(await request.get("/client/v2/api/user/star/count"))
      for (let i of r) {
        if (i.is_read) {
          i.status = 3
        } else
          if (i.be_fore) {
            i.status = 2
          } else if (i.after) {
            i.status = 1
          }
      }
      setReadWeek(r)
      const userInfo = await ctx.loadUserInfo()
      await refreshProgress(userInfo)
      await sleep(300)
      setLoading(false)
    }).catch(() => { })
    rctx?.onresize()
    return () => {
      requestCtx.unUseRequest()
      swiperRef.current.destroy()
      ctx.unUseSocket()
      emitter.off(MsgTypeWorkFlowProgress, onMsgTypeWorkFlowProgress)
      emitter.off(MsgTypeTopic, onMsgTypeTopic)
      emitter.off(MsgTypeArticleHashId, onMsgTypeArticleHashId)
    }

  }, [])

  const cardTxt = () => {
    if (orgData.length === 1) {
      return 'Start to challenge'
    }
    if (canAdd) {
      return 'Continue to challenge'
    }
    return 'Sorry, you still have two unfinished reading mission.'
  }

  return (
    <Wrapper>
      <LoadingWrapper bgColor="#FFDBE1" loading={loading} position="absolute">
        <TopicShadow action={topicShadow} onAddToPath={onAddToPath} />
        <div style={{ height: "calc(24px * var(--ratio))" }}></div>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "right", marginRight: "calc(31px * var(--ratio))" }}>
          <Points style={{
            width: "calc(90px * var(--ratio))",
            borderRadius: "calc(25px * var(--ratio))",
          }}>
            <div style={{ textAlign: "center" }}>
              <PointsNum style={{
                fontWeight: 500,
                fontSize: "calc(16px * var(--ratio))",
                color: "#161A19"
              }}>{starNum}</PointsNum>
              <div style={{ height: 2 }}></div>
              <PointsTxt style={{
                color: "#161A19",
                fontWeight: 300,
                fontSize: "calc(12px * var(--ratio))"
              }}>Total Points</PointsTxt>
            </div>
          </Points>
          <WeekWrapper>
            {
              readWeek.map((v, k) => (
                <WeekItem key={k} $status={v.status}>{v.week}</WeekItem>
              ))
            }
          </WeekWrapper>
        </div>
        <div style={{ height: "calc(74px * var(--ratio))" }}></div>
        <Swiper
          style={{ height: "calc(675px * var(--ratio))" }}
          onSwiper={s => {
            swiperRef.current = s
            // SwiperCore.use([FreeMode])
          }}
          freeMode={{
            enabled: true, // 开启FreeMode
            // 设置FreeMode的其他参数，例如：
            momentum: true, // 当你释放滑动时，保持动量继续滑动（默认为 true）
          }}
          slidesPerView={"auto"}
        >
          {
            data.map((v, k) => (
              <SwiperSlide style={{ width: "calc(151px * var(--ratio))", zIndex: -1 * k }} key={k}>
                <Ruler $key={k}>
                  {
                    v.tp === "LEVEL_FLAG" && <>
                      <RulerLevelFlag>
                        <div style={{ height: "calc(41px * var(--ratio))" }}></div>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                          <WelcomeIcon style={{ width: "calc(56px * var(--ratio))", height: "calc(37px * var(--ratio))" }} />
                        </div>
                        <Welcome>
                          Welcome to
                        </Welcome>
                        <div style={{ height: "calc(3px * var(--ratio))" }}></div>
                        <ArleveTxt>
                          AR Level {v.ar_level}
                        </ArleveTxt>
                      </RulerLevelFlag>
                      <CurvedArrowIcon style={{
                        position: "absolute",
                        right: "calc(-8px * var(--ratio))",
                        bottom: "calc(40px * var(--ratio))",
                        width: "calc(79px * var(--ratio))",
                        height: "calc(106px * var(--ratio))"
                      }} />
                      <Points style={{
                        width: "calc(113px * var(--ratio))",
                        height: "calc(70px * var(--ratio))",
                        borderRadius: "calc(100px * var(--ratio))",
                        border: "1px solid #898081",
                        position: "absolute",
                        right: "calc(-67px * var(--ratio))",
                        bottom: "calc(-102px * var(--ratio))",
                      }}>
                        <div style={{ textAlign: "center" }}>
                          <PointsNum style={{
                            fontWeight: 400,
                            fontSize: "calc(12px * var(--ratio))",
                            color: "#251F1F"
                          }}>Lexile levels</PointsNum>
                          <div style={{ height: 2 }}></div>
                          <PointsTxt style={{
                            color: "#251F1F",
                            fontWeight: 600,
                            fontSize: "calc(24px * var(--ratio))",
                          }}>{v.lexile}L</PointsTxt>
                        </div>
                      </Points>
                    </>
                  }
                  {
                    v.tp === "GO_FLAG" && <>
                      <GoIcon style={{
                        position: "absolute",
                        right: "calc(-42px * var(--ratio))",
                        bottom: "calc(20px * var(--ratio))",
                        width: "calc(80px * var(--ratio))",
                        height: "calc(87px * var(--ratio))",
                        zIndex: 1
                      }} />
                    </>
                  }
                  {
                    v.tp === "ARTICLE_CARD" && <>
                      <LineArrowIcon style={{
                        position: "absolute",
                        right: "calc(-10px * var(--ratio))",
                        bottom: "calc(40px * var(--ratio))",
                        width: "calc(18px * var(--ratio))",
                        height: "calc(107px * var(--ratio))"
                      }} />
                      {
                        (v.status === 1 || v.status === 4) ? <ArticleCard style={{
                          background: "linear-gradient(180deg, #E1F7F6 0%, #FFF 100%)",
                        }}>
                          <div style={{ padding: "calc(16px * var(--ratio))" }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                              <TaskInfoTag $bgColor="linear-gradient(90deg, rgba(153, 246, 243, 0.60) 0%, rgba(192, 194, 255, 0.60) 100%)">Lexile: {v.lexile}</TaskInfoTag>
                            </div>
                            <div style={{ height: "calc(47px * var(--ratio))" }}></div>
                            <div>
                              <img style={{
                                width: "calc(165px * var(--ratio))",
                                height: "calc(165px * var(--ratio))",
                                display: "block",
                                margin: "0 auto"
                              }} src="https://www.bigread.ai/icon/20240530-141147.png" alt='' />
                            </div>
                            <div style={{ height: "calc(12px * var(--ratio))" }}></div>
                            <TopicTitle>{v.pre_make_param.topic}</TopicTitle>
                            <div style={{ height: "calc(9px * var(--ratio))" }}></div>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                              <Button disabled={v.status === 4} onClick={() => {
                                setData(pre => {
                                  const cpPre = cloneDeep(pre)
                                  const index = cpPre.findIndex(i => i.id === v.id)
                                  cpPre[index].status = 4
                                  return cpPre
                                })
                                socket.current.send(JSON.stringify({
                                  msg_type: MsgTypeArticle,
                                  ar_level_progress_id: v.id
                                }))
                              }}>{v.status === 4 ? 'Generating...' : 'Start to Read'}</Button>
                            </div>
                          </div>
                        </ArticleCard> : <ArticleCard>
                          <div style={{ padding: "calc(16px * var(--ratio))" }} onClick={() => {
                            n(`/article/v2/${v.hash_id}`)
                          }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                              <TaskInfoTag $bgColor="linear-gradient(90deg, rgba(153, 246, 243, 0.60) 0%, rgba(192, 194, 255, 0.60) 100%)">Lexile: {v.lexile}</TaskInfoTag>
                              {
                                v.status === 3 && <TaskInfoTag $bgColor="rgba(0,0,0,0)" style={{
                                  color: "#161A19",
                                  fontWeight: 400
                                }}>{v.correct_star * 2} points</TaskInfoTag>
                              }
                            </div>
                            <div style={{ height: "calc(12px * var(--ratio))" }}></div>
                            <LineProgress value={v.status === 2 ? 0 : parseInt(v.correct_star / v.sum_star * 100)}>
                              {
                                v.status === 2 && <ProgressTxt>Accuracy shows after reading</ProgressTxt>
                              }
                              {
                                v.status === 3 && <ProgressTxt style={{ textAlign: "left" }}>
                                  <span>Accuracy:</span>
                                  <span style={{ color: parseInt(v.correct_star / v.sum_star * 100) <= 50 && "#B3261E" }}>{parseInt(v.correct_star / v.sum_star * 100)}%</span>
                                </ProgressTxt>
                              }
                            </LineProgress>
                            <div style={{ height: "calc(8px * var(--ratio))" }}></div>
                            <ArticleCover data-src={process.env.REACT_APP_OSS_HOST + v.cover + "?x-oss-process=image/resize,w_600"} $src={emptyImg}></ArticleCover>
                            <div style={{ height: "calc(16px * var(--ratio))" }}></div>
                            <ArticleTitle>{v.title}</ArticleTitle>
                            <ArticleInfo>
                              <div>
                                {
                                  v.word_count !== 0 && <TaskInfoTag $bgColor="#F5F0F0">
                                    <TaskInfoTagIcon src="https://www.bigread.ai/icon/20240521-162328.png"></TaskInfoTagIcon>
                                    Words: {v.word_count}
                                  </TaskInfoTag>
                                }
                                <div style={{ height: "calc(4px * var(--ratio))" }}></div>
                                {
                                  v.subject !== "" && <TaskInfoTag $bgColor="#F5F0F0" $icon="https://www.bigread.ai/icon/20240521-162328.png">
                                    <TaskInfoTagIcon src="https://www.bigread.ai/icon/20240521-162337.png"></TaskInfoTagIcon>
                                    {v.subject}
                                  </TaskInfoTag>
                                }
                              </div>
                              <div>
                                <BackIcon style={{ width: "calc(44px * var(--ratio))", height: "calc(44px * var(--ratio))", transform: "rotate(180deg)", WebkitTransform: "rotate(180deg)" }} />
                              </div>
                            </ArticleInfo>
                          </div>
                        </ArticleCard>
                      }

                    </>
                  }
                  {
                    v.tp === "FLAG" && <>
                      <FlagIcon style={{
                        position: "absolute",
                        right: "calc(-112px * var(--ratio))",
                        bottom: "calc(23px * var(--ratio))",
                        width: "calc(115px * var(--ratio))",
                        height: "calc(82px * var(--ratio))",
                        zIndex: 1
                      }} />
                    </>
                  }
                  {
                    v.tp === "CURRENT" && <>
                      <LineArrowIcon style={{
                        position: "absolute",
                        right: "calc(-10px * var(--ratio))",
                        bottom: "calc(40px * var(--ratio))",
                        width: "calc(18px * var(--ratio))",
                        height: "calc(107px * var(--ratio))"
                      }} />
                      <ArticleCard style={{
                        background: "linear-gradient(180deg, #E1F7F6 0%, #E9F9F8 25.51%, #FFF 100%)"
                      }}>
                        <div style={{
                          padding: "calc(16px * var(--ratio))"
                        }}>
                          <div style={{ height: "calc(22px * var(--ratio))" }}></div>
                          <div style={{ display: "flex", justifyContent: "center" }}>
                            <LevelItemProgress
                              size={154}
                              progress={parseInt(ctx?.userInfo?.star / ctx?.userInfo?.upgrade_star * 100)}
                            >
                              <div style={{
                                width: "100%",
                                height: "100%",
                                background: "#ED8395",
                                borderRadius: "50%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                color: "#FFF"
                              }}>
                                <div>
                                  <span style={{
                                    fontSize: "calc(50px * var(--ratio))",
                                    fontWeight: 900
                                  }}>{parseInt(ctx?.userInfo?.star / ctx?.userInfo?.upgrade_star * 100)}</span>
                                  <span style={{
                                    fontSize: "calc(20px * var(--ratio))",
                                    fontWeight: 900
                                  }}>%</span>
                                </div>
                                <div style={{
                                  fontSize: "calc(12px * var(--ratio))",
                                  fontWeight: 400
                                }}>Collected</div>
                              </div>
                            </LevelItemProgress>
                          </div>
                          <div style={{ height: "calc(14px * var(--ratio))" }}></div>
                          <LevelItemProgressTxt style={{
                            position: "static",
                            fontSize: "calc(15px * var(--ratio))",
                            width: "calc(117px * var(--ratio))",
                            height: "calc(24px * var(--ratio))"
                          }}>
                            <span>{ctx?.userInfo?.star || 0}</span>
                            <span>/{ctx?.userInfo?.upgrade_star || 0}Points</span>
                          </LevelItemProgressTxt>
                          <div style={{
                            height: "calc(46px * var(--ratio))",
                            color: "#898081",
                            fontSize: "calc(12px * var(--ratio))",
                            fontWeight: 300,
                            display: "flex",
                            alignItems: "center",
                          }}>
                            {
                              canAdd && <div style={{ lineHeight: 1.2 }}>
                                You have already earned <span style={{ color: "#E0788A", fontWeight: 500 }}>{ctx?.userInfo?.star || 0}</span> points, and you need {ctx?.userInfo?.upgrade_star - ctx?.userInfo?.star || 0} more to level up!
                              </div>
                            }

                          </div>
                          <div style={{
                            color: "#161A19",
                            fontWeight: 500,
                            fontSize: "calc(15px * var(--ratio))",
                            textAlign: "center",
                            padding: "0 calc(12px * var(--ratio))",
                            height: "calc(38px * var(--ratio))",
                            lineHeight: 1.2
                          }}>{cardTxt()}</div>
                          <div style={{ height: "calc(6px * var(--ratio))" }}></div>
                          <div style={{ display: "flex", justifyContent: "center" }}>
                            <AddButtonIcon disabled={!canAdd} onClick={addTopic} style={{
                              width: "calc(108px * var(--ratio))",
                              height: "calc(43px * var(--ratio))",
                              pointerEvents: canAdd ? "auto" : "none"
                            }} />
                          </div>
                        </div>

                      </ArticleCard>
                    </>
                  }
                </Ruler>
              </SwiperSlide>
            ))
          }
        </Swiper>
      </LoadingWrapper>
    </Wrapper>
  )
}

const TopicTitle = styled.div`
  color: #161A19;
  font-size: calc(15px * var(--ratio));
  font-weight: 500;
  line-height: 1.2;
  height: calc(54px * var(--ratio));
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* 指定最多显示的行数 */
  -webkit-box-orient: vertical;
`

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  background: linear-gradient(135deg, rgba(255, 219, 225, 0.00) 28.16%, #D3F5F4 100%), linear-gradient(152deg, #FFDBE1 18.63%, #D3F5F4 89.16%);
  backdrop-filter: blur(250px);
  overflow: auto;
  position: relative;
  &::-webkit-scrollbar {
    display: none;
  }
`

const ArticleTitle = styled.div`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: #161A19;
  font-size: calc(16px * var(--ratio));
  font-weight: 500;
  max-height: calc(60px * var(--ratio));
  line-height: 120%;
`

const ArticleInfo = styled.div`
  display: flex;
  justify-content: space-between;
  height: calc(48px * var(--ratio));
  position: absolute;
  bottom: calc(55px * var(--ratio));
  width: calc(100% - calc(32px * var(--ratio)));
`

const ProgressTxt = styled.div`
  color: #898081;
  text-align: center;
  font-size: calc(12px * var(--ratio));
  font-weight: 400;
  margin-bottom: calc(4px * var(--ratio));
  & span:nth-child(1){
    color: #161A19;
    font-size: calc(13px * var(--ratio));
    font-weight: 400;
  }
  & span:nth-child(2){
    color: #4EB04D;
    font-size: calc(13px * var(--ratio));
    font-weight: 600;
  }
`

const ArleveTxt = styled.div`
  color: #163C3C;
  font-size: calc(40px * var(--ratio));
  font-weight: 400;
  text-align: center;
`

const Welcome = styled.div`
  color: #163C3C;
  text-align: center;
  font-family: Roboto;
  font-size: calc(40px * var(--ratio));
  font-weight: 700;
`



const ArticleCard = styled.div`
  width: calc(252px * var(--ratio));
  height: calc(428px * var(--ratio));
  border-radius: calc(24px * var(--ratio));
  border: 1px solid #898081;
  background: #FFF;
  position: absolute;
  bottom: calc(138px * var(--ratio));
  left: calc(25px * var(--ratio));
`

const ArticleCover = styled.div`
  width: 100%;
  height: calc(165px * var(--ratio));
  border-radius: calc(6.48px * var(--ratio));
  border: 1px solid #898081;
  position: relative;
  overflow: hidden;
  background: url(${props => props.$src}) lightgray 50% / cover no-repeat;
`

const Ruler = styled.div`
  width: calc(152px * var(--ratio));
  height: calc(40px * var(--ratio));
  background: #E0788A;
  position: absolute;
  bottom: calc(102px * var(--ratio));
  &::after{
    content: '';
    position: absolute;
    height: 35%;
    width: calc(3px * var(--ratio));
    background: ${props => props.$key === 0 ? "transparent" : "#FFF"};
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
  }
`

const RulerLevelFlag = styled.div`
  position: absolute;
  width: calc(291px * var(--ratio));
  height: calc(214px * var(--ratio));
  border-radius: calc(25.661px * var(--ratio));
  background: #FFF;
  bottom: calc(140px * var(--ratio));;
  z-index: 1;
  left: calc(-55px * var(--ratio));
`

export default Index