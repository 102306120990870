import { useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import * as echarts from 'echarts';
import { Context as RequestContext } from '../request'
import { Context as RouteContext } from '../../router'
import moment from 'moment'
import { getCurrentMonthRange, getAllDatesBetween } from './LexileLevelProgression'
import { Context as AuthContext } from '../auth'
import { Lock } from '../../components/icons/welcome'
import { WhitePlus } from '../../components/icons/profile'
import { useNavigate } from 'react-router-dom';

const Index = (props) => {
  const { show } = props
  const myChart = useRef()
  const dom = useRef()
  const routeCtx = useContext(RouteContext)
  const requestCtx = useContext(RequestContext)
  const request = requestCtx.useRequest()
  const leftDom = useRef()
  const observer = useRef()
  const authCtx = useContext(AuthContext)
  const n = useNavigate()

  const reseGrid = () => {
    if (leftDom.current && myChart.current) {
      const option = myChart.current.getOption()
      if (option) {
        option.grid[0].left = `${leftDom.current.clientWidth}px`
        myChart.current.setOption(option)
      }
    }
  }

  const onObserver = (doms) => {
    reseGrid()
    if (myChart.current) {
      myChart.current.resize()
    }
  }

  useEffect(() => {


    return () => {
      if (observer.current) {
        observer.current.disconnect()
      }
    }
  }, [])

  const getData = async () => {
    try {
      const _data = await request.get("/client/v2/api/learn/times")

      let today = moment()
      const userInfo = await authCtx.useUserInfo()
      let createRoleTime = moment(userInfo.created_at)
      // 获取5天之前的时间
      const fiveDaysAgo = moment().subtract(60, 'days');
      // 判断是否在5天之前
      if (!createRoleTime.isBefore(fiveDaysAgo)) {
        // const diffDuration = moment.duration(fiveDaysAgo.diff(createRoleTime));
        // const diffDays = Math.abs(diffDuration.days());
        createRoleTime = fiveDaysAgo
      }
      const days = getAllDatesBetween(createRoleTime.format("YYYY-MM-DD"), today.add(29, "days"))
      const newArr = []
      for (let day of days) {
        let hasArticle = false
        let hasFree = false
        for (let i of _data) {
          if (i.date_str === day && i.from === "ARTICLE") {
            newArr.push(i)
            hasArticle = true
          }
          if (i.date_str === day && i.from === "FREE") {
            newArr.push(i)
            hasFree = true
          }
        }
        if (!hasArticle && !hasFree) {
          newArr.push({ date_str: day, time_sec: 0, from: "ARTICLE" })
          newArr.push({ date_str: day, time_sec: 0, from: "FREE" })
        } else if (!hasArticle) {
          newArr.push({ date_str: day, time_sec: 0, from: "ARTICLE" })
        } else if (!hasFree) {
          newArr.push({ date_str: day, time_sec: 0, from: "FREE" })
        }
      }
      return newArr
    } catch (error) {

    }
  }

  const handleData = (_data) => {
    const x = []
    const dateX = {}
    const obj = {
      type: 'bar',
      stack: 'total',
      // barWidth: 41,
    }
    const y = [{ name: "Leveled Reading", data: [], color: "#FAA4B1", ...obj }, { name: "Endless Learning", color: "#35B7B2", data: [], ...obj }]
    for (let i of _data) {
      const formattedDate = moment(i.date_str).format('DD MMMM YYYY');
      const f = x.find(v => v === formattedDate)
      if (!f) {
        x.push(formattedDate)
      }
      if (!dateX[formattedDate]) {
        dateX[formattedDate] = [i]
      } else {
        dateX[formattedDate].push(i)
      }
    }
    for (let date of x) {
      for (let i of dateX[date]) {
        if (i.from === "ARTICLE") {
          y[0].data.push({ value: (i.time_sec / 60).toFixed(1), all: dateX[date], date })
        }
        if (i.from === "FREE") {
          y[1].data.push({ value: (i.time_sec / 60).toFixed(1), all: dateX[date], date })
        }
      }
    }
    return {
      x,
      y
    }
  }


  const initTable = async (ele) => {
    observer.current = new ResizeObserver(onObserver)
    observer.current.observe(ele)
    routeCtx.onresize()
    myChart.current = echarts.init(ele);
    const _data = await getData()
    const { x, y } = handleData(_data)


    const { startIndex, endIndex } = getCurrentMonthRange(x, 5)
    const grid = {
      left: 0,
      right: 32,
      bottom: 54,
      containLabel: true
    };
    const option = {
      legend: {
        show: false
      },
      grid,
      tooltip: {
        show: true,
        trigger: 'item',
        axisPointer: {
          type: 'shadow'
        },
        extraCssText: `background: rgba(22, 26, 25, 0.80);color:#FFF !important;font-size: 12px;font-weight: 400;border:none`,
        formatter: function (params) {
          const { data, date } = params
          let total = 0
          let leveledReading = 0
          let endlessLearning = 0
          for (let i of data.all) {
            total += i.time_sec
            if (i.from === "ARTICLE") {
              leveledReading = i.time_sec
            }
            if (i.from === "FREE") {
              endlessLearning = i.time_sec
            }
          }
          let content = `<div>
            <div style="font-weight: 500;">Total Reading: ${(total / 60).toFixed(1)} mins</div>
            <div style="display:flex;align-items: center;">
              <div style="width: 20px;height:10px;background:#FAA4B1;margin-right:10px"></div>
              <div>Leveled Reading: ${(leveledReading / 60).toFixed(1)} mins</div>
            </div>
            <div style="display:flex;align-items: center;">
              <div style="width: 20px;height:10px;background:#35B7B2;margin-right:10px"></div>
              <div>Endless Learning: ${((total / 60).toFixed(1) - (leveledReading / 60).toFixed(1)).toFixed(1)} mins</div>
            </div>
            <div>${data.date}</div>
          </div>`
          return content
        }
      },
      dataZoom: [
        {
          type: 'inside',
          startValue: startIndex,
          endValue: endIndex,
          show: true,
        },
        {
          show: true,
          type: 'slider'
        }
      ],
      yAxis: {
        type: 'value',
        axisLabel: {
          color: "#161A19",
          fontSize: 13,
          fontWeight: 400,
          formatter: function (value, index) {
            // 添加单位，并保留小数点后两位
            return value + 'mins';
          }
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: "#161A19"
          }
        }
      },
      xAxis: {
        type: 'category',
        data: x,
        axisLabel: {
          color: "#161A19",
          fontSize: 12,
          fontWeight: 300,
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: "#161A19"
          }
        },
        axisLabel: {
          formatter: function (value, index) {
            // 添加单位，并保留小数点后两位
            // console.log("value",)
            const ds = value.split(" ")
            return ds[0] + " " + ds[1]
          }
        }
      },
      series: y
    };

    myChart.current.setOption(option);
    reseGrid()
  }




  return (
    <SuperWrapper style={{ display: show ? "block" : "none" }}>
      <Wrapper ref={e => {
        if (e && !dom.current && show) {
          dom.current = e
          initTable(e)
        }
      }}></Wrapper>
      <div ref={leftDom} style={{ width: "calc(70px * var(--ratio))", position: "fixed", zIndex: -1 }}></div>
      {
        !authCtx?.userInfo?.vip_info?.is_vip && <UpgradeShadow>
          <UpgradeBox>
            <LockIcon />
            <div>To unlock full function of progress.</div>
            <div>View your child’s progress by <span>multiple</span> dimensions.</div>
            <UpgradeBtn onClick={() => n("/pay")}>
              <WhitePlusIcon />
              <span>Upgrade Now</span>
            </UpgradeBtn>
          </UpgradeBox>
        </UpgradeShadow>
      }
    </SuperWrapper>
  )

}

const SuperWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`

export const LockIcon = styled(Lock)`
  width: calc(76px * var(--ratio));
  height: calc(88px * var(--ratio));
`

export const WhitePlusIcon = styled(WhitePlus)`
  width: calc(24px * var(--ratio));
  height: calc(24px * var(--ratio));
`

export const UpgradeBtn = styled.div`
    cursor: pointer;
    width: calc(232px * var(--ratio));
    height: calc(62px * var(--ratio));
    border-radius: calc(30px * var(--ratio));
    background: #B229D6;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-family: Inter;
    font-size: calc(22px * var(--ratio));
    font-weight: 500;
    ${WhitePlusIcon}{
        margin-right: calc(8px * var(--ratio));
    }
    margin-bottom: calc(64px * var(--ratio));
`

export const UpgradeShadow = styled.div`
  border-radius: 0px 0px calc(30px * var(--ratio)) calc(30px * var(--ratio));
  background: linear-gradient(180deg, rgba(102, 102, 102, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const UpgradeBox = styled.div`
  width: 36%;
  /* height: 53%; */
  border-radius: calc(16px * var(--ratio));
  border: calc(1px * var(--ratio)) solid #E4DFE0;
  background: rgba(255, 255, 255, 0.90);
  box-shadow: 0px calc(6px * var(--ratio)) calc(32px * var(--ratio)) calc(-4px * var(--ratio)) rgba(0, 0, 0, 0.10);
  backdrop-filter: blur(calc(75px * var(--ratio)));
  display: flex;
  flex-direction: column;
  align-items: center;
  ${LockIcon}{
    margin-top: calc(42px * var(--ratio));
    margin-bottom: calc(42px * var(--ratio));
  }
  & div:nth-child(2){
    color: #161A19;
    font-size: calc(24px * var(--ratio));
    font-style: normal;
    font-weight: 400;
    line-height: 1.33;
  }
  & div:nth-child(3){
    color: #161A19;
    font-size: calc(16px * var(--ratio));
    font-style: normal;
    font-weight: 400;
    line-height: 2;
  }
  & div:nth-child(3) span{
    color: #B229D6;
    font-weight: 600;
  }
  ${UpgradeBtn}{
    margin-top: calc(56px * var(--ratio));
  }
`

export default Index