import { useCallback, useEffect, useRef, useState, useContext } from 'react';
import { Skeleton, Image } from 'antd';
import * as apis from '../../lib/api'
import http from '../../lib/http'
import { MsgTypeV2WWWSearchSummaryRelated, MsgTypeV2WWWSearchSummary, MsgProgressIng, MsgProgressEnd } from '../../utils/define'
import styled from "styled-components";
import { sleep } from '../../utils'
import { Context as RequestContext } from '../request'
import { EventEmitter as Remitter } from '../auth'


const Index = (props) => {
  const { id, onShorAnswerValue, onShorAnswerValueEnd, xPos, yPos } = props
  const { searchVal, value, context, sources = [], images = [] } = props.data
  const relatedData = useRef([])
  const [visibleIndex, setVisibleIndex] = useState(-1)
  const requestCtx = useContext(RequestContext)
  const request = requestCtx.useRequest()


  const getRelated = async (t, c) => {
    if (relatedData.current && relatedData.current.length !== 0) {
      return relatedData.current
    }
    try {
      let info = await request.post('/client/api/message/send', {
        title: t,
        context: c,
        msg_type: MsgTypeV2WWWSearchSummaryRelated,
        llm_prompt_id: 27
      })
      relatedData.current = JSON.parse(info)
    } catch (error) {
      console.log(error)
    }
    await sleep(1000)
    return await getRelated(t, c)
  }


  const onMessage = useCallback((message) => {
    if (message.node_id !== id) {
      return
    }
    if (message.msg_type === MsgTypeV2WWWSearchSummary) {
      if (message.msg_progress === MsgProgressIng) {
        message.msg = message.msg || ''
        onShorAnswerValue({ id, msg: message.msg })
      }
      if (message.msg_progress === MsgProgressEnd) {
        onShorAnswerValueEnd({ id, xPos, yPos, context, title: searchVal, getRelated })
      }
    }
  }, [searchVal, xPos, yPos, context, onShorAnswerValue, id, onShorAnswerValueEnd])

  useEffect(() => {
    Remitter.on('message', onMessage)

    return () => {
      Remitter.off('message', onMessage)
    }
  }, [onMessage])


  return (
    <ModulecardBox>
      {
        (!value) && <Skeleton active />
      }
      {
        (value) && <>
          <Title>Short Answer</Title>
          <Msg>{value}</Msg>
          {
            (images && images.length !== 0) && <>
              <div style={{ height: 20 }}></div>
              <Title>Images</Title>
              <div style={{
                display: "grid",
                gridTemplateColumns: "repeat(3,calc(33.33% - 8px))",
                // gridTemplateRows: "repeat(3,calc(38.33% - 12px))",
                // gridRowGap: 12,
                gridColumnGap: 12,
                alignItems: "center",
                width: "100%"
              }}>
                {
                  images.map((v, k) => (
                    <div key={k}>
                      <div onClick={() => {
                        setVisibleIndex(k)
                      }} style={{
                        background: `url(${v.url})`,
                        width: "100%",
                        height: 110,
                        backgroundSize: "cover",
                        backgroundPosition: "center center"
                      }}></div>
                    </div>
                  ))
                }

                <div style={{ display: "none" }}>
                  <Image.PreviewGroup
                    preview={{
                      visible: visibleIndex !== -1,
                      current: visibleIndex,
                      onVisibleChange: v => {
                        console.log(v)
                        if (!v) {
                          setVisibleIndex(-1)
                        }
                      },
                      onChange: (current, prev) => setVisibleIndex(current),
                    }}
                  >
                    {
                      images.map((v, k) => (
                        <Image src={v.url} key={k}></Image>
                      ))
                    }
                  </Image.PreviewGroup>
                </div>

              </div>

            </>
          }
          <div style={{ height: 20 }}></div>
          <Title>Sources</Title>
          <div style={{
            display: "grid",
            gridTemplateColumns: "repeat(3,calc(33.33% - 8px))",
            gridTemplateRows: "repeat(3,calc(33.33% - 12px))",
            gridRowGap: 12,
            gridColumnGap: 12,
            width: "100%"
          }}>
            {
              sources?.map((v, k) => (
                <div key={k} onClick={() => {
                  window.open(v.url)
                }} style={{
                  padding: "6px 6px",
                  border: "2px solid rgb(31, 29, 15)",
                  boxShadow: "4px 4px 0px 0px #1F1D0F",
                  // marginBottom: 12,
                  cursor: "pointer",
                  fontFamily: 'Inter',
                  fontWeight: 500,
                  fontFeatureSettings: "'ss04','tnum'",
                  fontVariantNumeric: "tabular-nums",
                  fontSize: 14,
                  lineHeight: 1.5,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between"
                }}>
                  <SourceTxt style={{
                    marginBottom: 12,
                  }}>{v.name}</SourceTxt>
                  <div style={{
                    color: "#999",
                    display: "flex",
                    alignItems: "center",
                    height: 18,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    fontFamily: 'Inter',
                    fontWeight: 500,
                    fontFeatureSettings: "'ss04','tnum'",
                    fontVariantNumeric: "tabular-nums",
                    fontSize: 14,
                    lineHeight: 1.5
                  }}>
                    {/* <img style={{ marginRight: 6, width: 14, height: 14 }} src={`https://www.google.com/s2/favicons?domain=${v.domain}&sz=14`} alt="" /> */}
                    <span>{v.domain}</span>
                  </div>
                </div>
              ))
            }
          </div>
        </>
      }
    </ModulecardBox >
  );
}


const SourceTxt = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 限制在两行 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
`

const ModulecardBox = styled.div`
  border-radius: 6px;
  border: 2px solid #1F1D0F;
  background: #FFF;
  box-shadow: 2px 2px 0px 0px #1F1D0F;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 500px;
  min-width: 200px;
`

const Title = styled.div`
  color: #1f1d0f;
  font-family: "Roboto Flex600";
  font-size: 24px;
  margin-bottom: 20px;
`
const Msg = styled.div`
  white-space: pre-wrap;
  font-family: Inter;
  font-weight: 500;
  font-feature-settings: "ss04", "tnum";
  font-variant-numeric: tabular-nums;
  font-size: 16px;
  line-height: 1.5;
`

export default Index;
