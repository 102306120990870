import * as echarts from 'echarts';
import { useEffect, useRef, useContext, useState } from 'react';
import { Context as RouteContext } from '../../router'
import moment from 'moment'
import { useNavigate } from 'react-router-dom';
import { accuracyQuiz } from '../../utils'
import { Context as RequestContext } from '../request'
import { Context as AuthContext } from '../auth'
import { markLines } from '../../utils/define'


export const getCurrentMonthRange = (xAxisData = [], afday = 0) => {
  // if (afday !== 0) {
  //   fourDaysLater = moment().add(afday, 'days');
  // }
  // 结果变量
  let startIndex = -1;
  let endIndex = -1;

  // 遍历输入的日期数组
  xAxisData.forEach((dateString, index) => {
    // 解析日期字符串
    //console.log(moment().add(-5, "days").format("DD MMMM YYYY"))
    if (moment().add(-60, "days").format("DD MMMM YYYY") === dateString) {
      startIndex = index
    }
    if (moment().add(29, "days").format("DD MMMM YYYY") === dateString) {
      endIndex = index
    }
  });
  // 返回结果
  return {
    startIndex,
    endIndex
  };
}

export const getAllDatesBetween = (start, end) => {
  const startDate = moment(start);
  const endDate = moment(end);
  let dateArray = [];

  // 当开始时间较晚，结束时间较早时，返回空数组
  if (startDate.isAfter(endDate)) {
    return dateArray;
  }

  let currentDate = startDate.startOf('day');

  while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, 'day')) {
    // 将当前日期的格式化字符串加入数组
    dateArray.push(currentDate.format('YYYY-MM-DD'));
    // 当前日期加1天
    currentDate = currentDate.add(1, 'days');
  }

  return dateArray;
}

const Index = (props) => {
  let { show = false, onData = () => { } } = props
  const dom = useRef()
  const leftDom = useRef()
  const routeCtx = useContext(RouteContext)
  const myChart = useRef()
  const n = useNavigate()
  const requestCtx = useContext(RequestContext)
  const request = requestCtx.useRequest()
  const observer = useRef()
  const authCtx = useContext(AuthContext)
  const option = {
    // backgroundColor: 'none',
    title: {
      text: 'Stacked Area Chart',
      show: false
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985'
        }
      }
    },
    legend: {
      data: ['Email'],
      show: false
    },
    toolbox: {
      feature: {
        saveAsImage: {
          show: false
        }
      }
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        data: [],
        splitLine: { show: false },
        axisLine: {
          lineStyle: {
            color: "#161A19"
          }
        },
        axisLabel: {
          formatter: function (value, index) {
            // 添加单位，并保留小数点后两位
            const ds = value.split(" ")
            return ds[0] + " " + ds[1]
            // return moment(value).format("DD MMMM")
          }
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        // startValue: 500,
        // min: 51,
        // maxInterval: 50,
        // splitNumber: 5,
        // maxInterval: 100,
        scale: true,
        axisTick: {
          // lineStyle: {
          //   opacity: 0
          // }
        },
        axisLabel: {
          color: "#161A19",
          fontSize: 13,
          fontWeight: 400,
          formatter: function (value, index) {
            // 添加单位，并保留小数点后两位
            return value + 'L';
          }
        },
        splitLine: { show: false },
        splitArea: {
          show: false // 隐藏y轴分割区域
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: "#161A19"
          }
        }
      }
    ],
    grid: {
      right: 32,
      bottom: 54,
      containLabel: true
    },
    dataZoom: [
      {
        type: 'inside',
        startValue: 0,
        endValue: 2,
        show: true,
      },
      {
        show: true,
        type: 'slider'
      }
    ],
    series: []
  }

  const reseGrid = () => {
    if (leftDom.current && myChart.current) {
      const option = myChart.current.getOption()
      if (option) {
        option.grid[0].left = `${leftDom.current.clientWidth}px`
        myChart.current.setOption(option)
      }
    }
  }

  const formatData = (_data) => {
    const x = []
    const y = []
    const yHash = []
    const dateObj = {}
    const series = []
    let maxLexileNum = 0
    const flags = []
    for (let i of _data) {
      if (i.tp === "LEVEL_FLAG") {
        flags.push(i)
        // continue
      }
      const formattedDate = moment(i.created_at).format('DD MMMM YYYY');
      const f = x.find(v => v === formattedDate)
      if (!f) {
        x.push(formattedDate)
      }
      if (!dateObj[formattedDate]) {
        dateObj[formattedDate] = [i]
      } else {
        dateObj[formattedDate].push(i)
      }
      if (i.lexile_num >= maxLexileNum) {
        maxLexileNum = i.lexile_num
      }
    }
    const others = []
    for (let i in dateObj) {
      const arr = dateObj[i]
      let preStar = 0
      let max = null
      for (let j of arr) {
        if (j.tp === "LEVEL_FLAG") {
          max = j
          break
        }
        if (j.star >= preStar) {
          preStar = j.star
          max = j
        }
      }
      const colorInfo = accuracyQuiz(max.quiz_correct_num, max.quiz_answer_num, max.quiz_sum)
      y.push({
        value: max.lexile_num,
        symbol: `image://${max.tp === "LEVEL_FLAG" ? "https://file.bigread.ai/img/point.svg" : colorInfo.icon}`,
        arr,
        date: i,
        max
      })
      if (max.article_hash_id) {
        yHash.push(max.article_hash_id)
      }
    }

    for (let j in dateObj) {
      for (let m of dateObj[j]) {
        const f = yHash.find(v => v === m.article_hash_id)
        if (!f && m.article_hash_id) {
          const colorInfo = accuracyQuiz(m.quiz_correct_num, m.quiz_answer_num, m.quiz_sum)
          others.push({
            name: '自定义标注',
            coord: [j, m.lexile_num], // 指定标注的坐标
            symbol: `image://${colorInfo.icon}`, // 其他可选形状：'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow', 'none'
            symbolSize: [24, 24], // 标注大小
            dataIndex: x.findIndex(v => v === j),
            arr: dateObj[j],
            date: j
          })
        }
      }
    }

    for (let i of flags) {
      others.push({
        name: 'flag',
        coord: [moment(i.created_at).format('DD MMMM YYYY'), parseInt(i.lexile)], // 指定标注的坐标
        symbol: `image://https://file.bigread.ai/img/sanjiao.svg`, // 其他可选形状：'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow', 'none'
        symbolSize: [8, 6], // 标注大小
        symbolOffset: [0, "-210%"],
        label: {
          formatter: `{ar|* AR *} {arval|${i.ar_level}} \n {lx|Lexile: ${i.lexile}L}`,
          backgroundColor: 'rgba(22, 26, 25, 0.80)',
          borderRadius: 2,
          padding: [11, 7],
          lineHeight: 18,
          position: 'top',
          distance: -1,
          rich: {
            ar: {
              color: 'rgba(255, 255, 255, 0.90)',
              fontSize: 12,
              fontWeight: 700
            },
            arval: {
              fontSize: 16,
              color: "#FFF",
              fontWeight: 700
            },
            lx: {
              color: 'rgba(255, 255, 255, 0.90)',
              fontSize: 12,
              fontWeight: 500
            }
          }
        }
      })
    }

    series.push({
      type: "line",
      markLine: {
        silent: true,
        symbol: 'none', // 去掉标记线两端的标记
        emphasis: {
          disabled: true
        },
        label: {
          position: 'insideEndTop', // 设置标签显示位置为在末端
          formatter: [
            '{icon|}',
            '{text|{b}}' // {b} 表示标记线的名称
          ].join('\n'),  // 标签内容，可以自定义
          rich: {
            icon: {
              // 指定图标的样式
              height: 20,
              width: 46,
              align: 'right',
              backgroundColor: {
                image: 'https://file.bigread.ai/img/line_icon.png' // 这里替换为你的图片路径
              }
            },
            text: {
              // 指定文本样式
              align: 'right',
              color: 'rgb(22, 26, 25)',
              fontSize: 12
              // 其他需要定制的文本样式
            }
          }
        },
        data: markLines,
        lineStyle: {
          // 自定义样式
          color: '#CCC', // 线条颜色
          type: 'dashed', // 线条类型，例如 'solid', 'dashed', 'dotted'
          width: 1, // 线条宽度
        }
      },
      zlevel: -1
    })

    series.push({
      zlevel: 2,
      name: 'Date',
      type: 'scatter',
      smooth: true,
      symbolSize: 24,
      symbol: 'image://https://file.bigread.ai/img/point.svg',
      itemStyle: {
        borderColor: "#161A19",
        color: "#161A19",
        borderWidth: 1,
        opacity: 1 // 确保完全不透明
      },
      lineStyle: {
        color: "#161A19",
        width: 1,
      },
      areaStyle: {
        color: "#E1F9DF",
        // opacity: 0.28
      },
      emphasis: {
        focus: 'series',
        areaStyle: {
          color: "#E1F9DF",
          // opacity: 0.28
        }
      },
      markPoint: {
        data: others,
      },
      data: y,

    })



    //console.log("yyyyy", y)

    // const cloneY = cloneDeep(y)
    // let indexs = []
    // cloneY.map((v, k) => {
    //   if (v.max.tp === "LEVEL_FLAG") {
    //     indexs.push(k)
    //   }
    // })
    // console.log("indexsindexs", y.slice(indexs[0], indexs[1]))
    // const line1 = cloneDeep(y).map((v, k) => {
    //   if (k < indexs[1]) {
    //     v.value = 731
    //   } else if (k >= indexs[0] && k <= indexs[1]) {
    //     v.value = 731
    //   } else if (k > indexs[1]) {
    //     v.value = 750
    //   } else {
    //     v.value = 0
    //   }
    //   return v
    // })

    // const line2 = cloneDeep(y).map((v, k) => {
    //   if (k < indexs[1]) {
    //     v.value = 750
    //   } else if (k >= indexs[0] && k <= indexs[1]) {
    //     v.value = 750
    //   } else if (k > indexs[1]) {
    //     v.value = 769
    //   } else {
    //     v.value = 0
    //   }
    //   return v
    // })

    // series.push({
    //   name: 'Line 1',
    //   type: 'line',
    //   smooth: true,
    //   lineStyle: {
    //     width: 2
    //   },
    //   showSymbol: false,
    //   // areaStyle: {
    //   //   opacity: 0.8,
    //   //   color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    //   //     {
    //   //       offset: 0,
    //   //       color: 'rgb(255, 0, 135)'
    //   //     },
    //   //     {
    //   //       offset: 1,
    //   //       color: 'rgb(135, 0, 157)'
    //   //     }
    //   //   ])
    //   // },
    //   emphasis: {
    //     focus: 'series'
    //   },
    //   data: line1
    // })

    // series.push({
    //   name: 'Line 1',
    //   type: 'line',

    //   smooth: true,
    //   lineStyle: {
    //     width: 2
    //   },
    //   showSymbol: false,
    //   // areaStyle: {
    //   //   opacity: 0.8,
    //   //   color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    //   //     {
    //   //       offset: 0,
    //   //       color: 'rgb(128, 255, 165)'
    //   //     },
    //   //     {
    //   //       offset: 1,
    //   //       color: 'rgb(1, 191, 236)'
    //   //     }
    //   //   ])
    //   // },
    //   emphasis: {
    //     focus: 'series'
    //   },
    //   data: line2
    // })

    // console.log("line1", line1)
    // console.log("series", series)
    return {
      maxLexileNum,
      dateObj,
      series,
      tableData: [
        x,
        y
      ]
    }
  }






  const getData = async () => {
    try {
      const _data = await request.get("/client/v2/api/statistics/read_finish_articles") || []
      //_data.push({ tp: "LEVEL_FLAG", ar_level: "4.8", created_at: "2024-07-07T14:34:50+08:00", lexile: 750, quiz_correct_num: 0, star: 0 })
      for (let i of _data) {
        if (i.tp === "LEVEL_FLAG") {
          i.lexile_num = parseInt(i.lexile)
        }
        if (i.done_time) {
          i.created_at = i.done_time
        }
      }
      let today = moment()
      const userInfo = await authCtx.useUserInfo()
      let createRoleTime = moment(userInfo.created_at)
      // 获取5天之前的时间
      const fiveDaysAgo = moment().subtract(60, 'days');
      // 判断是否在5天之前
      if (!createRoleTime.isBefore(fiveDaysAgo)) {
        // const diffDuration = moment.duration(fiveDaysAgo.diff(createRoleTime));
        // const diffDays = Math.abs(diffDuration.days());
        createRoleTime = fiveDaysAgo
      }
      const days = getAllDatesBetween(createRoleTime.format("YYYY-MM-DD"), today.add(29, "days"))
      const newArr = []



      for (let day of days) {
        let has = false
        for (let i of _data) {
          if (moment(i.created_at).format("YYYY-MM-DD") === day) {
            newArr.push(i)
            has = true
          }
        }
        if (!has) {
          newArr.push({ created_at: day, quiz_correct_num: 0, lexile_num: parseInt(userInfo.lexile), star: 0, quiz_sum: 0 })
        }
      }
      return newArr
    } catch (error) {
      console.log(error)
    }
  }

  const initTable = async (ele) => {
    myChart.current = echarts.init(ele);
    myChart.current.setOption(option);
    const _data = await getData() || []
    if (_data.length === 0) {
      onData([])
      return
    }
    window.goToArticle = function (hashId) {
      n(`/article/v2/${hashId}`)
    }
    const { tableData, maxLexileNum, series } = formatData(_data)
    option.yAxis.max = maxLexileNum
    option.xAxis[0].data = tableData[0]
    const { startIndex, endIndex } = getCurrentMonthRange(option.xAxis[0].data)
    option.dataZoom[0].startValue = startIndex
    option.dataZoom[0].endValue = endIndex

    option.series = series
    option.tooltip.triggerOn = "click"
    option.tooltip.trigger = 'item'
    option.tooltip.extraCssText = `border-radius: 12px;
border: 1px solid var(--Function-white, #FFF);
background: rgba(255, 255, 255, 1);
box-shadow: 2px 8px 20px -12px rgba(36, 36, 36, 0.60);
backdrop-filter: blur(40px);width:236px;pointer-events: all !important`
    option.tooltip.formatter = function (params) {
      // console.log(params)
      if (params.data.name === "flag") {
        return
      }
      const { arr, date } = params.data
      const dateDatas = arr
      let content = ""
      for (let i of dateDatas) {
        if (!i.article_hash_id) {
          return content
        }
        // console.log(i)
        const colorInfo = accuracyQuiz(i.quiz_correct_num, i.quiz_answer_num, i.quiz_sum)
        content += `<div>
            <div style="display:flex;align-items: center;justify-content: space-between;">
              <div onclick="goToArticle('${i.article_hash_id}')" style="overflow: hidden;color: #161A19;text-overflow: ellipsis;font-family: Roboto;font-size: 12px;font-style: normal;font-weight: 400;line-height: 170%;width:210px;">${i.title}</div><img src="https://file.bigread.ai/img/right_icon.svg" alt="" />
            </div>
            <div style="height: 11px"></div>
            <div style="display:flex;align-items: center;justify-content: space-between;">
              <span style="border-radius: 131.982px;background: linear-gradient(90deg, rgba(246, 195, 213, 0.60) 0%, rgba(255, 116, 113, 0.60) 100%);display: inline-block;color: #161A19;font-family: Roboto;font-size: 12px;font-style: normal;font-weight: 400;line-height: normal; padding: 3px 6px;">Lexile: <span>${i.lexile_num}L</span></span>
              <span style="color: #161A19;text-align: right;font-family: Roboto;font-size: 12px;font-style: normal;font-weight: 300;line-height: 170%; letter-spacing: -0.48px;">Accuracy: <span style="color: ${colorInfo.color};font-family: Roboto;font-size: 12px;font-style: normal;font-weight: 300;line-height: 170%;letter-spacing: -0.48px;">${colorInfo.txt}</span></span>
            </div>
            <div style="height: 8px"></div>
            <div style="background: rgba(22, 26, 25, 0.02);width:100%;height:1px;"></div>
            <div style="height: 8px"></div>
          </div>
          `
      }
      if (dateDatas.length > 1) {
        content += `<div style="height: 1px;background: rgba(22, 26, 25, 0.20);margin-bottom:8px"></div>`
      }
      content += `<div style="color: var(--Function-black, #161A19);font-family: Roboto;font-size: 12px;font-style: normal;font-weight: 100;line-height: 170%; letter-spacing: -0.48px;">${date}</div></div>`
      return content;
    }
    myChart.current.setOption(option);
    onData(_data)
  }

  const onObserver = () => {
    reseGrid()
    if (myChart.current) {
      myChart.current.resize()
    }
  }

  useEffect(() => {

    return () => {
      if (observer.current) {
        observer.current.disconnect()
      }
    }

  }, [])


  return (
    <>
      <div style={{ width: "100%", height: "100%", display: show ? "block" : "none", position: "relative" }} ref={async e => {
        if (e && !dom.current && show) {
          routeCtx.onresize()
          observer.current = new ResizeObserver(onObserver)
          observer.current.observe(e)
          dom.current = e
          await initTable(e)
        }
      }}></div>
      <div ref={leftDom} style={{ width: "calc(70px * var(--ratio))", position: "fixed", zIndex: -1 }}></div>
      <div style={{ display: show ? "block" : "none", position: "absolute", bottom: 72, left: 20, color: "rgba(9, 9, 9, 0.80)", fontSize: "calc(12px * var(--ratio))", lineHeight: 1.3 }}>
        <div>Lexile</div>
        <div>Level</div>
      </div>
    </>
  )

}

export default Index