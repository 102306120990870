
const Index = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <circle cx="16.1113" cy="16" r="3" fill="#ED8395" />
      <path d="M9 16C9 12.134 12.134 9 16 9C19.866 9 23 12.134 23 16C23 19.866 19.866 23 16 23C12.134 23 9 19.866 9 16Z" fill="#96C9C8" />
      <path d="M19 14L15.0002 19L13 16.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default Index



