


const Index = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M10.2068 30.7012C6.31935 30.7012 3.16797 27.5498 3.16797 23.6624V2.54591C3.16797 1.57405 3.95581 0.786203 4.92767 0.786203H15.4859C16.4578 0.786203 17.2456 1.57405 17.2456 2.54591V23.6624C17.2456 27.5498 14.0942 30.7012 10.2068 30.7012ZM8.44711 23.6623C8.93304 23.6623 9.32696 24.0563 9.32696 24.5422C9.32696 25.0281 8.93304 25.4221 8.44711 25.4221C7.96118 25.4221 7.56726 25.0281 7.56726 24.5422C7.56726 24.0563 7.96118 23.6623 8.44711 23.6623Z" fill="#948B8C" />
      <path fillRule="evenodd" clipRule="evenodd" d="M4.04675 27.1183C2.10304 23.7516 3.25652 19.4468 6.62314 17.5031L24.9105 6.94483C25.7522 6.4589 26.8284 6.74727 27.3143 7.58893L32.5934 16.7326C33.0794 17.5743 32.791 18.6505 31.9493 19.1364L13.662 29.6946C10.2953 31.6384 5.99047 30.4849 4.04675 27.1183ZM9.46803 23.9518C9.84138 24.5985 9.61981 25.4254 8.97315 25.7987C8.32649 26.1721 7.4996 25.9505 7.12625 25.3038C6.7529 24.6572 6.97446 23.8303 7.62112 23.4569C8.26779 23.0836 9.09467 23.3052 9.46803 23.9518Z" fill="#7FB1B0" />
      <path fillRule="evenodd" clipRule="evenodd" d="M3.16797 23.6619C3.16797 19.7744 6.31935 16.623 10.2068 16.623L31.3232 16.623C32.2951 16.623 33.0829 17.4109 33.0829 18.3828L33.0829 28.941C33.0829 29.9128 32.2951 30.7007 31.3232 30.7007L10.2068 30.7007C6.31935 30.7007 3.16797 27.5493 3.16797 23.6619ZM9.32696 24.5417C9.32696 25.0276 8.93304 25.4216 8.44711 25.4216C7.96118 25.4216 7.56726 25.0276 7.56726 24.5417C7.56726 24.0558 7.96118 23.6619 8.44711 23.6619C8.93304 23.6619 9.32696 24.0558 9.32696 24.5417Z" fill="#A8DCDA" />
    </svg>
  )
}

export const AddWordTableIcon = (props) => {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 9V19.4C3 19.9601 3 20.2399 3.10899 20.4538C3.20487 20.642 3.35774 20.7952 3.5459 20.8911C3.7596 21 4.0395 21 4.59846 21H15.0001M14 13V10M14 10V7M14 10H11M14 10H17M7 13.8002V6.2002C7 5.08009 7 4.51962 7.21799 4.0918C7.40973 3.71547 7.71547 3.40973 8.0918 3.21799C8.51962 3 9.08009 3 10.2002 3H17.8002C18.9203 3 19.4801 3 19.9079 3.21799C20.2842 3.40973 20.5905 3.71547 20.7822 4.0918C21.0002 4.51962 21.0002 5.07969 21.0002 6.19978L21.0002 13.7998C21.0002 14.9199 21.0002 15.48 20.7822 15.9078C20.5905 16.2841 20.2842 16.5905 19.9079 16.7822C19.4805 17 18.9215 17 17.8036 17H10.1969C9.07899 17 8.5192 17 8.0918 16.7822C7.71547 16.5905 7.40973 16.2842 7.21799 15.9079C7 15.4801 7 14.9203 7 13.8002Z" stroke="#161A19" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export const HearIcon = (props) => {
  return (
    <svg {...props} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M28.4453 35.8575C33.0917 33.8195 36.3483 29.3905 36.3483 24.001C36.3483 18.6116 33.0917 14.1825 28.4453 12.1445V14.7793C31.5899 16.6052 33.7136 20.1108 33.7136 24.001C33.7136 27.8913 31.5899 31.3969 28.4453 33.2228V35.8575Z" fill="#161A19" />
      <path d="M28.4435 17.4131V30.5869C30.0573 29.1378 31.0782 26.3331 31.0782 24C31.0782 21.6669 30.0573 18.8622 28.4435 17.4131ZM12.6348 30.5869H16.1878L25.8087 37V11L16.1878 17.4131H12.6348C11.1817 17.4131 10 18.5948 10 20.0478V27.9522C10 29.4052 11.1817 30.5869 12.6348 30.5869Z" fill="#948B8C" />
      <path d="M12.772 30.5005H16.2784L25.7729 36.8293V11.1709L16.2784 17.4997H12.772C11.338 17.4997 10.1719 18.6659 10.1719 20.0999V27.9004C10.1719 29.3344 11.338 30.5005 12.772 30.5005Z" fill="#161A19" />
    </svg>
  )
}

export const FlipIcon = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 12 14" fill="none">
      <path d="M7.19021 9.62956H10.4766V12.9159M4.56111 4.37135H1.27472V1.08496M10.751 5.03086C10.3825 4.11873 9.7655 3.32827 8.97013 2.74934C8.17475 2.1704 7.23305 1.82605 6.2518 1.75572C5.27055 1.68538 4.28904 1.8917 3.41922 2.35128C2.5494 2.81085 1.82606 3.50541 1.33121 4.35565M1 8.97036C1.36853 9.88249 1.98554 10.6729 2.78092 11.2519C3.5763 11.8308 4.51896 12.1747 5.50021 12.2451C6.48146 12.3154 7.46217 12.1091 8.33199 11.6495C9.20181 11.19 9.92465 10.4955 10.4195 9.64529" stroke="#161A19" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export const DefineIcon = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="12" height="18" viewBox="0 0 12 18" fill="none">
      <path d="M3.33464 17H8.66797M6.0013 1C3.05578 1 0.667969 3.38781 0.667969 6.33333C0.667969 7.41289 0.988718 8.41753 1.54012 9.25715C2.38803 10.5483 2.81162 11.1934 2.86665 11.2899C3.35622 12.1476 3.26608 11.8451 3.32769 12.8307C3.33462 12.9415 3.33464 13.1092 3.33464 13.4444C3.33464 13.9354 3.7326 14.3333 4.22352 14.3333L7.77908 14.3333C8.27 14.3333 8.66797 13.9354 8.66797 13.4444C8.66797 13.1092 8.66797 12.9415 8.67489 12.8307C8.73651 11.8451 8.64585 12.1476 9.13542 11.2899C9.19045 11.1934 9.61476 10.5483 10.4627 9.25715C11.0141 8.41753 11.3348 7.41289 11.3348 6.33333C11.3348 3.38781 8.94682 1 6.0013 1Z" stroke="#161A19" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export const SlideIcon = (props) => {
  return (
    <svg {...props} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.04167" y="1.04167" width="47.9167" height="47.9167" rx="23.9583" fill="white" />
      <rect x="1.04167" y="1.04167" width="47.9167" height="47.9167" rx="23.9583" stroke="#C2C2C2" strokeWidth="2.08333" />
      <path d="M29.1667 35.4163L18.75 24.9997L29.1667 14.583" stroke="#212121" strokeWidth="3.125" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export const HalfRound = (props) => {
  const { bgColor, ...others } = props
  return (
    <svg {...others} xmlns="http://www.w3.org/2000/svg" width="375" height="181" viewBox="0 0 375 181" fill="none">
      <path d="M374.76 181H0C3.42 80.46 86.01 0 187.38 0C239.16 0 286.03 20.99 319.96 54.92C352.46 87.42 373.09 131.8 374.76 181Z" fill={bgColor} />
    </svg>
  )
}
















export default Index






