import styled from "styled-components";
import ArticleIcon from '../../components/icons/article'
import VocabularyIcon from '../../components/icons/vocabulary'
import QuizIcon from '../../components/icons/quiz'
import ArticleIngIcon from '../../components/icons/article_ing'
import { cloneDeep } from "lodash";
import { useState, useImperativeHandle } from "react";
import BallLoading from '../../components/icons/ball_loading'

//status: 0.加载；1.已解锁；3.未解锁;2.活动状态

const Index = (props) => {
  const { onChange = () => { }, value = {
    "article": 0,
    "vocabulary": 0,
    "quiz": 0
  } } = props

  useImperativeHandle(props.action, () => ({
    setValue: (k) => {
      clickTabItem(k)
    }
  }))


  const [options, setOptions] = useState([
    {
      "value": "article",
      "label": "01. Article",
      "Icon": (props) => {
        return <ArticleIcon {...props} />
      }
    },
    {
      "value": "vocabulary",
      "label": "02. Vocabulary",
      "status": 1,
      "Icon": (props) => {
        return <VocabularyIcon {...props} />
      }
    },
    {
      "value": "quiz",
      "label": "03. Quiz",
      "Icon": (props) => {
        return <QuizIcon {...props} />
      }
    }
  ])

  const clickTabItem = (k) => {
    const v = options[k]
    if (value[v.value] === 1 || value[v.value] === 3) {
      const cpOptions = cloneDeep(options)
      const nv = {}
      for (let i = 0; i < cpOptions.length; i++) {
        let item = cpOptions[i]
        if (value[item.value] === 2) {
          nv[item.value] = 1
        } else {
          nv[item.value] = value[item.value]
        }
      }
      nv[v.value] = 2
      onChange(nv)
    }
  }

  return (
    <Wrapper>
      {
        options.map((v, k) => (
          <TabItem onClick={() => {
            clickTabItem(k)
          }} key={v.value} $status={value[v.value]}>
            {
              value[v.value] === 0 && <BallLoading style={{ width: "calc(24px * var(--ratio))", height: "calc(24px * var(--ratio))", marginRight: "calc(3px * var(--ratio))" }} />
            }
            <v.Icon style={{ width: "calc(26px * var(--ratio))", height: "calc(26px * var(--ratio))", marginRight: "calc(10px * var(--ratio))" }} />
            <div>{v.label}</div>
          </TabItem>
        ))
      }
    </Wrapper>
  )
}

const Wrapper = styled.div`
  border-radius: calc(40px * var(--ratio));
  border: calc(1px * var(--ratio)) solid #DBD4D4;
  background: rgba(255, 255, 255, 0.80);
  box-shadow: calc(-4px * var(--ratio)) calc(8px * var(--ratio)) calc(20px * var(--ratio)) 0px rgba(0, 0, 0, 0.04);
  backdrop-filter: blur(calc(50px * var(--ratio)));
  height: calc(58px * var(--ratio));
  display: flex;
`

const TabItem = styled.div`
  cursor: pointer;
  color: #161A19;
  font-size: calc(14px * var(--ratio));
  font-weight: 400;
  display: flex;
  height: 100%;
  align-items: center;
  &:nth-child(1){
    padding-left: calc(38px * var(--ratio));
  }
  padding-right: calc(40px * var(--ratio));
  padding-left: calc(16px * var(--ratio));
  position: relative;
  &::after{
    content: '';
    width: calc(1px * var(--ratio));
    height: calc(12px * var(--ratio));
    background: #EFE9E9;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    display: ${props => props.$status === 2 ? "none" : "block"};
  }
  border-radius: calc(40px * var(--ratio));
  ${props => {
    let sty = ""
    if (!props.$status || props.$status === 3) {
      sty += "opacity: 0.5;"
      sty += "pointer-events: none;"
    } else if (props.$status === 1) {
      sty += "opacity: 1;"
      sty += "pointer-events: all;"
    } else if (props.$status === 2) {
      sty += "opacity: 1;"
      sty += "pointer-events: all;"
      sty += "background: #161A19;"
      sty += "color: #EFE9E9;"
    }
    return sty
  }}
  
  
`

export default Index