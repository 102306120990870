import React, { useState, useRef, useContext, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom"
import styled from "styled-components";
import { Swiper, SwiperSlide } from 'swiper/react';
import PayMethodShadow from './pay_method_shadow'
import { Context as RouteContext } from '../../router'
import { cloneDeep } from "lodash";
import { Context as RequestContext } from '../request'
import emptyImg from '../../static/img/20230831-183719.png'
import { DiscountIcon, DiscountBestIcon, CloseIcon } from '../../components/icons/pay'
import { Context as AuthContext } from '../auth'
import LoadingBall from '../../components/icons/ball_loading'
import { Logo } from '../../components/icons/welcome'

const Index = () => {
  const l = useLocation()
  const n = useNavigate()
  const payTempData = useRef({})
  const routeCtx = useContext(RouteContext)
  const authCtx = useContext(AuthContext)
  const requestCtx = useContext(RequestContext)
  const request = requestCtx.useRequest()
  const [cancelSubConfirm, setCancelSubConfirm] = useState(false)
  const [loading, setLoading] = useState(false)
  const swiperRef = useRef()
  const payMethodShadowAction = useRef()
  const payResultTimer = useRef()
  const [wechatPayData, setWechatPayData] = useState()

  const [data, setData] = useState([
    {
      "z": "https://www.bigread.ai/icon/pay1.svg",
      "f": "https://www.bigread.ai/icon/pay2.svg"
    }, {
      "z": "https://www.bigread.ai/icon/pay3.svg",
      "f": "https://www.bigread.ai/icon/pay4.svg"
    }, {
      "z": "https://www.bigread.ai/icon/pay5.svg",
      "f": "https://www.bigread.ai/icon/pay6.svg"
    }, {
      "z": "https://www.bigread.ai/icon/pay7.svg",
      "f": "https://www.bigread.ai/icon/pay8.svg"
    }, {
      "z": "https://www.bigread.ai/icon/pay9.svg",
      "f": "https://www.bigread.ai/icon/pay10.svg"
    }, {
      "z": "https://www.bigread.ai/icon/pay11.svg",
      "f": "https://www.bigread.ai/icon/pay12.svg"
    }, {
      "z": "https://www.bigread.ai/icon/pay13.svg",
      "f": "https://www.bigread.ai/icon/pay14.svg"
    }, {
      "z": "https://www.bigread.ai/icon/pay15.svg",
      "f": "https://www.bigread.ai/icon/pay16.svg"
    }, {
      "z": "https://www.bigread.ai/icon/pay17.svg",
      "f": "https://www.bigread.ai/icon/pay18.svg"
    }, {
      "z": "https://www.bigread.ai/icon/pay19.svg",
      "f": "https://www.bigread.ai/icon/pay20.svg"
    }
  ])

  useEffect(() => {
    setTimeout(() => {
      swiperRef.current.update()
      swiperRef.current.slideTo(0, 0)
      const ob = new IntersectionObserver((list) => {
        for (let i = 0; i < list.length; i++) {
          let item = list[i]
          if (item.isIntersecting) {
            item.target.src = item.target.dataset.src
            ob.unobserve(item.target)
          }
        }
      }, {
        threshold: 0
      })
      const articleCovers = document.querySelectorAll("img[data-src]")
      for (let i = 0; i < articleCovers.length; i++) {
        ob.observe(articleCovers[i])
      }
    }, 300)

    routeCtx.onresize()
    return () => {
      clearTimeout(payResultTimer.current)
      // observer.disconnect()
    }
  }, [])

  const getTryNow = async () => {
    setLoading(true)
    try {
      await request.get("/client/v2/api/sub/try")
      await authCtx.loadUserInfo()
      n(`/pay/success?price=$0`)
    } catch (error) {

    }
    setLoading(false)
  }

  const createSub = async (sku_plan_id, price = "$9.9") => {
    const priceMap = { "1": "$9.9", "2": "$99", "3": "$1.9" }
    payMethodShadowAction.current.open(true)
    payTempData.current = { sku_plan_id, price: priceMap[sku_plan_id] }
  }


  const getWechatPayResult = async () => {
    const { price } = payTempData.current
    try {
      const info = await request.get(`/client/v2/api/sub/wechat/result`)
      if (info) {
        payResultTimer.current = setTimeout(getWechatPayResult, 1000)
      } else {
        clearTimeout(payResultTimer.current)
        window.location.href = `${window.location.origin}/pay/success?price=${price}`
      }
      console.log(info)
    } catch (error) {
      console.log(error)
    }
  }

  const wechatPay = async () => {
    const { sku_plan_id } = payTempData.current
    setLoading(true)
    try {
      const info = await request.post(`/client/v2/api/sub/wechat?sku_plan_id=${sku_plan_id}`)
      console.log(info)
      setWechatPayData(info)
      getWechatPayResult()
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  const cancelSub = async () => {
    setLoading(true)
    try {
      await request.post("/client/v2/api/sub/stripe/cancel")
      await authCtx.loadUserInfo()
    } catch (error) {

    }
    setLoading(false)
  }

  const payPalPay = async () => {
    const { sku_plan_id } = payTempData.current
    setLoading(true)
    await payMethodShadowAction.current.payPalPay(sku_plan_id)
    setLoading(false)
  }

  return (
    <Wrapper>
      {
        wechatPayData && <div style={{
          position: "fixed",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          margin: "auto",
          background: "rgba(255,255,255,1)",
          zIndex: 13,
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <WelComeTitle>Use wechat to scan</WelComeTitle>
            <div style={{ height: "calc(24px * var(--ratio))" }}></div>
            <div><img src={wechatPayData.qrcode_link} alt="" /></div>
            <div style={{ height: "calc(24px * var(--ratio))" }}></div>
            <CloseIcon onClick={() => {
              setWechatPayData(null)
              clearTimeout(payResultTimer.current)
            }} style={{
              width: "calc(52px * var(--ratio))",
              height: "calc(52px * var(--ratio))",
              cursor: "pointer"
            }} />
          </div>
        </div>
      }

      <PayMethodShadow onClose={() => clearTimeout(payResultTimer.current)} onChoicePay={async (patMethod) => {
        if (patMethod === 1) {
          await payPalPay()
        } else {
          wechatPay()
        }
      }} action={payMethodShadowAction} />
      <LogoWrapper>
        <Logo style={{ width: "calc(157px * var(--ratio))", height: "calc(58px * var(--ratio))" }} color="#161A19" />
      </LogoWrapper>
      <WelComeTitle>For future learning, use BigRead first</WelComeTitle>
      <WelComeSubTitle>Accelerate growth, move students into the 99th percentile.</WelComeSubTitle>
      <div style={{ height: "calc(80px * var(--ratio))" }}></div>

      <div style={{ display: "flex", justifyContent: "center", marginBottom: "calc(16px * var(--ratio))" }}>
        <img style={{ width: "calc(900px * var(--ratio))", height: "calc(40px * var(--ratio))" }} src="https://www.bigread.ai/icon/pay_txt.svg" alt="" />
      </div>
      <div style={{ display: !authCtx.userInfo?.vip_info?.is_vip || authCtx.userInfo?.vip_info?.type === "FREE_DEMO" || (authCtx.userInfo?.vip_info?.type === "ONCE_ACTIVE" && authCtx.userInfo?.vip_info?.sub_plan_id === 3) ? "flex" : "none", justifyContent: "center" }}>
        {
          !authCtx.userInfo?.demo_buy && <PayPriceWrapper>
            <div style={{ paddingLeft: "calc(20px * var(--ratio))" }}>
              <PlusTxt>Plus</PlusTxt>
              <div>
                <PriceInt>$1.9</PriceInt>
                <PriceUnit>/14 days</PriceUnit>
              </div>
              <PriceDesc>One-time payment</PriceDesc>
            </div>
            <div style={{ height: "calc(20px * var(--ratio))" }}></div>
            <PriceButton onClick={() => createSub(3)} $loading={loading}>Upgrade To Plus</PriceButton>
          </PayPriceWrapper>
        }
        <PayPriceWrapper>
          <div style={{ paddingLeft: "calc(20px * var(--ratio))" }}>
            <PlusTxt>Plus</PlusTxt>
            <div>
              <PriceInt>$9.9</PriceInt>
              <PriceUnit>/month</PriceUnit>
            </div>
            <PriceDesc>Flexible plan - Billed Monthly</PriceDesc>
          </div>
          <div style={{ height: "calc(20px * var(--ratio))" }}></div>
          <PriceButton onClick={() => createSub(1)} $loading={loading}>Subscribe</PriceButton>
        </PayPriceWrapper>
        <PayPriceWrapper style={{
          background: "linear-gradient(270deg, #7DCECC 0%, #46B6B4 100%)"
        }}>
          <div style={{ paddingLeft: "calc(20px * var(--ratio))" }}>
            <PlusTxt>Plus</PlusTxt>
            <div>
              <PriceInt style={{ color: "#FFF" }}>$99</PriceInt>
              <PriceUnit style={{
                color: "#FFF",
                textDecoration: "line-through",
                fontWeight: 600
              }}>$118.8</PriceUnit>
              <PriceUnit style={{
                color: "#FFF"
              }}>/year</PriceUnit>
            </div>
            <div style={{ height: "calc(4px * var(--ratio))" }}></div>
            <PriceDesc style={{
              borderRadius: "calc(6px * var(--ratio))",
              background: "rgba(255, 255, 255, 0.60)",
              color: "#B229D6",
              fontSize: "calc(13px * var(--ratio))",
              fontWeight: 400,
              lineHeight: "123.077%",
              width: "fit-content",
              padding: "calc(2px * var(--ratio)) calc(12px * var(--ratio))",
              display: "flex",
              alignItems: "center"
            }}>Best Value - <span style={{ fontWeight: 700 }}>16% Discount</span><DiscountIcon style={{ width: "calc(14px * var(--ratio))", height: "calc(14px * var(--ratio))", marginLeft: "calc(2px * var(--ratio))" }} /></PriceDesc>
          </div>
          <div style={{ height: "calc(11px * var(--ratio))" }}></div>
          <PriceButton onClick={() => createSub(2, "$99")} $loading={loading} style={{ color: "#161A19", background: "#FFF", fontWeight: 600 }}>Lock Discount</PriceButton>
          <DiscountBestIcon style={{
            width: "calc(78px * var(--ratio))",
            height: "calc(78px * var(--ratio))",
            position: "absolute",
            top: "calc(24px * var(--ratio))",
            right: "calc(34px * var(--ratio))"
          }} />
        </PayPriceWrapper>
        {/* <PayPriceWrapper>
          <div style={{ paddingLeft: "calc(20px * var(--ratio))" }}>
            <PlusTxt>Plus</PlusTxt>
            {
              !(!authCtx.userInfo?.vip_info?.is_vip && authCtx.userInfo?.vip_info?.type !== "") && <>
                <div>
                  <PriceInt>$0</PriceInt>
                  <PriceUnit>/14-day free trail</PriceUnit>
                </div>
                <PriceDesc style={{
                  color: "#467776",
                  fontWeight: 500
                }}>Opportunity Knocks - Full BigRead.ai Content</PriceDesc>
              </>
            }
          </div>
          <div style={{ height: "calc(20px * var(--ratio))" }}></div>
          {
            (authCtx.userInfo?.vip_info?.is_vip && authCtx.userInfo?.vip_info?.type === "FREE_DEMO") && <PriceButton $loading={loading} style={{ background: "rgba(247, 245, 245, 0.50)", color: "#161A19", fontWeight: 400, fontSize: "calc(18px * var(--ratio))" }}>Free {authCtx.userInfo?.vip_info?.last_demo_day}-day trial remining</PriceButton>
          }
          {
            (!authCtx.userInfo?.vip_info?.is_vip && !authCtx.userInfo?.vip_info?.type) && <PriceButton $loading={loading} onClick={getTryNow}>Try Now</PriceButton>
          }
          {
            (!authCtx.userInfo?.vip_info?.is_vip && authCtx.userInfo?.vip_info?.type !== "") && <div style={{
              color: "#161A19",
              fontWeight: 500,
              lineHeight: 1.3,
              fontSize: "calc(24px * var(--ratio))",
              paddingLeft: "calc(20px * var(--ratio))"
            }}>Free trail Expired</div>
          }
        </PayPriceWrapper> */}
      </div>
      {
        (authCtx.userInfo?.vip_info?.is_vip && authCtx.userInfo?.vip_info?.type === "ONCE_ACTIVE" && authCtx.userInfo?.vip_info?.sub_plan_id !== 3) && <div style={{ display: "flex", justifyContent: "center" }}>
          <PayPriceWrapper>
            <div style={{ paddingLeft: "calc(20px * var(--ratio))" }}>
              <PlusTxt>Plus<span style={{ color: "#B229D6", fontWeight: 400 }}>-{authCtx.userInfo?.vip_info?.sub_plan_id === 1 ? 'Monthly' : 'Yearly'}</span></PlusTxt>
              <div style={{ height: "calc(12px * var(--ratio))" }}></div>
              <div style={{ fontSize: "calc(24px * var(--ratio))", color: "#161A19", fontWeight: 400, lineHeight: "130%" }}>Expires on {authCtx.userInfo?.vip_info?.english_format_date}</div>
              {
                authCtx.userInfo?.vip_info?.sub_plan_id === 1 && <>
                  <div style={{ height: "calc(20px * var(--ratio))" }}></div>
                  <div style={{ color: "#467776", lineHeight: 1.3, fontSize: "calc(14px * var(--ratio))" }}>Flexible plan - Billed Monthly</div>
                </>
              }
            </div>
            {
              authCtx.userInfo?.vip_info?.sub_plan_id === 1 ? <div style={{ height: "calc(16px * var(--ratio))" }}></div> : <div style={{ height: "calc(54px * var(--ratio))" }}></div>
            }
            <PriceButton onClick={() => createSub(authCtx.userInfo?.vip_info?.sub_plan_id)} $loading={loading}>Renew</PriceButton>
          </PayPriceWrapper>
        </div>
      }
      {
        (authCtx.userInfo?.vip_info?.is_vip && authCtx.userInfo?.vip_info?.type === "ACTIVE") && <div style={{ display: "flex", justifyContent: "center" }}>
          <PayPriceWrapper>
            <div style={{ height: "calc(24px * var(--ratio))" }}></div>
            <div style={{ paddingLeft: "calc(20px * var(--ratio))" }}>
              <PlusTxt>Plus<span style={{ color: "#B229D6", fontWeight: 400 }}>-{authCtx.userInfo?.vip_info?.sub_plan_id === 1 ? 'Monthly' : 'Yearly'}</span></PlusTxt>
              <div style={{ height: "calc(12px * var(--ratio))" }}></div>
              <div style={{ fontSize: "calc(24px * var(--ratio))", color: "#161A19", fontWeight: 500, lineHeight: "130%" }}>Renews {authCtx.userInfo?.vip_info?.english_format_date}</div>
              <div style={{ color: "#467776", fontSize: "calc(14px * var(--ratio))", fontFamily: "Inter", lineHeight: 1.3 }}>{authCtx.userInfo?.vip_info?.sub_plan_id === 1 ? '$9.9/month' : '$99/year'}</div>
              <div style={{ height: "calc(32px * var(--ratio))" }}></div>
              <div onClick={() => setCancelSubConfirm(true)} style={{ color: "#E46962", fontSize: "calc(14px * var(--ratio))", fontWeight: 400, lineHeight: 1.3, cursor: "pointer" }}>Cancel Subscription</div>
            </div>
            {
              cancelSubConfirm && <PayPriceConfirm>
                <div style={{ padding: "0 calc(48px * var(--ratio))" }}>
                  <div style={{ height: "calc(10px * var(--ratio))" }}></div>
                  <PayPriceConfirmTitle>Confirm Cancellation</PayPriceConfirmTitle>
                  <PayPriceConfirmDesc>If you confirm and end your subscription now, you can still access <span style={{ color: "#B229D6", fontWeight: 700 }}>Plus</span> until {authCtx.userInfo?.vip_info?.english_format_date}</PayPriceConfirmDesc>
                  <div style={{ height: "calc(32px * var(--ratio))" }}></div>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <PayPriceConfirmButton onClick={() => setCancelSubConfirm(false)}>Not Now</PayPriceConfirmButton>
                    <PayPriceConfirmButton $loading={loading} style={{ background: "#FFF", color: "#161A19", display: "flex", justifyContent: "center" }} onClick={cancelSub}>{loading ? <LoadingBall style={{ width: "calc(24px * var(--ratio))", height: "calc(24px * var(--ratio))" }}></LoadingBall> : "Confirm"}</PayPriceConfirmButton>
                  </div>
                </div>
              </PayPriceConfirm>
            }
          </PayPriceWrapper>
        </div>
      }
      {
        (authCtx.userInfo?.vip_info?.is_vip && authCtx.userInfo?.vip_info?.type === "CANCEL" && authCtx.userInfo?.vip_info?.sub_plan_id === 1) && <div style={{ display: "flex", justifyContent: "center" }}>

          <PayPriceWrapper style={{
            background: "linear-gradient(270deg, #7DCECC 0%, #46B6B4 100%)"
          }}>
            <div style={{ paddingLeft: "calc(20px * var(--ratio))" }}>
              <PlusTxt>Plus</PlusTxt>
              <div>
                <PriceInt style={{ color: "#FFF" }}>$99</PriceInt>
                <PriceUnit style={{
                  color: "#FFF",
                  textDecoration: "line-through",
                  fontWeight: 600
                }}>$118.8</PriceUnit>
                <PriceUnit style={{
                  color: "#FFF"
                }}>/year</PriceUnit>
              </div>
              <div style={{ height: "calc(4px * var(--ratio))" }}></div>
              <PriceDesc style={{
                borderRadius: "calc(6px * var(--ratio))",
                background: "rgba(255, 255, 255, 0.60)",
                color: "#B229D6",
                fontSize: "calc(13px * var(--ratio))",
                fontWeight: 400,
                lineHeight: "123.077%",
                width: "fit-content",
                padding: "calc(2px * var(--ratio)) calc(12px * var(--ratio))",
                display: "flex",
                alignItems: "center"
              }}>Best Value - <span style={{ fontWeight: 700 }}>16% Discount</span><DiscountIcon style={{ width: "calc(14px * var(--ratio))", height: "calc(14px * var(--ratio))", marginLeft: "calc(2px * var(--ratio))" }} /></PriceDesc>
            </div>
            <div style={{ height: "calc(11px * var(--ratio))" }}></div>
            <PriceButton onClick={() => createSub(2, "$99")} $loading={loading} style={{ color: "#161A19", background: "#FFF", fontWeight: 600 }}>Lock Discount</PriceButton>
          </PayPriceWrapper>
          <PayPriceWrapper>
            <div style={{ paddingLeft: "calc(20px * var(--ratio))" }}>
              <PlusTxt>Plus</PlusTxt>
              <div style={{ height: "calc(12px * var(--ratio))" }}></div>
              <div style={{ fontSize: "calc(24px * var(--ratio))", color: "#161A19", fontWeight: 400, lineHeight: "130%" }}>Expires on {authCtx.userInfo?.vip_info?.english_format_date}</div>
              <div style={{ height: "calc(12px * var(--ratio))" }}></div>
              <div style={{ color: "#DC362E", fontFamily: "Inter", fontSize: "calc(14px * var(--ratio))" }}>You have cancelled your subscription.</div>
              <div style={{ height: "calc(16px * var(--ratio))" }}></div>
            </div>
            <PriceButton onClick={() => createSub(1)} $loading={loading}>Renew: $9.9/month</PriceButton>
          </PayPriceWrapper>
        </div>
      }
      {
        (authCtx.userInfo?.vip_info?.is_vip && authCtx.userInfo?.vip_info?.type === "CANCEL" && authCtx.userInfo?.vip_info?.sub_plan_id === 2) && <div style={{ display: "flex", justifyContent: "center" }}>
          <PayPriceWrapper>
            <div style={{ paddingLeft: "calc(20px * var(--ratio))" }}>
              <PlusTxt>Plus</PlusTxt>
              <div style={{ height: "calc(12px * var(--ratio))" }}></div>
              <div style={{ fontSize: "calc(24px * var(--ratio))", color: "#161A19", fontWeight: 400, lineHeight: "130%" }}>Expires on {authCtx.userInfo?.vip_info?.english_format_date}</div>
              <div style={{ height: "calc(12px * var(--ratio))" }}></div>
              <div style={{ color: "#DC362E", fontFamily: "Inter", fontSize: "calc(14px * var(--ratio))" }}>You have cancelled your subscription.</div>
              <div style={{ height: "calc(16px * var(--ratio))" }}></div>
            </div>
            <PriceButton $loading={loading} onClick={() => createSub(2, "$99")}>Renew: $99/year</PriceButton>
          </PayPriceWrapper>
          <PayPriceWrapper>
            <div style={{ paddingLeft: "calc(20px * var(--ratio))" }}>
              <PlusTxt>Plus</PlusTxt>
              <div>
                <PriceInt>$9.9</PriceInt>
                <PriceUnit>/month</PriceUnit>
              </div>
              <PriceDesc>Flexible plan - Billed Monthly</PriceDesc>
            </div>
            <div style={{ height: "calc(20px * var(--ratio))" }}></div>
            <PriceButton $loading={loading} onClick={() => createSub(1)}>Subscribe</PriceButton>
          </PayPriceWrapper>
        </div>
      }
      <Swiper
        style={{ paddingBottom: "calc(80px * var(--ratio))", paddingTop: "calc(80px * var(--ratio))", paddingRight: "calc(48px * var(--ratio))" }}
        onSwiper={s => {
          swiperRef.current = s
        }}
        freeMode={{
          enabled: true, // 开启FreeMode
          // 设置FreeMode的其他参数，例如：
          momentum: true, // 当你释放滑动时，保持动量继续滑动（默认为 true）
        }}
        slidesPerView={"auto"}
      >
        {
          data.map((v, k) => (
            <SwiperSlide onClick={async () => {
              setData(pre => {
                const cpPre = cloneDeep(pre)
                cpPre[k].flip = !cpPre[k].flip
                return cpPre
              })
            }} style={{
              width: "calc(270px * var(--ratio))",
              height: "calc(340px * var(--ratio))",
              marginLeft: k === 0 ? "calc(48px * var(--ratio))" : "calc(16px * var(--ratio))"
            }} key={k}>
              <FlipWrapper $flip={v.flip}>
                <img style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  zIndex: 1,
                  objectFit: "cover",
                  left: 0,
                  top: 0,
                  WebkitBackfaceVisibility: "hidden",
                  backfaceVisibility: "hidden"
                }} data-src={v.z} src={emptyImg} alt="" />
                <img style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  left: 0,
                  top: 0,
                  transform: "rotateY(180deg)",
                  zIndex: 0,
                  objectFit: "cover"
                }} data-src={v.f} src={emptyImg} alt="" />
              </FlipWrapper>
            </SwiperSlide>
          ))}
      </Swiper>
      <CloseIcon onClick={() => n("/")} style={{
        width: "calc(52px * var(--ratio))",
        height: "calc(52px * var(--ratio))",
        position: "absolute",
        top: "calc(71px * var(--ratio))",
        right: "calc(104px * var(--ratio))",
        cursor: "pointer"
      }} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: linear-gradient(180deg, #E1F7F6 0%, #FFF 100%);
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
`

const PayPriceConfirm = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 9;
  background: #fff;
  border-radius: calc(16px * var(--ratio));
`

const PayPriceConfirmTitle = styled.div`
  color: #161A19;
  font-size: calc(24px * var(--ratio));
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 31.2px */
  text-align: center;
  margin:  calc(16px * var(--ratio)) 0;
`


const PayPriceConfirmDesc = styled.div`
  color: #467776;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: calc(14px * var(--ratio));
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
`

const PayPriceConfirmButton = styled.div`
  cursor: pointer;
  border-radius: calc(20px * var(--ratio));
  border: calc(1px * var(--ratio)) solid #E4DFE0;
  background: #161A19;
  width: calc(138px * var(--ratio));
  height: calc(40px * var(--ratio));
  color: #FFF;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: calc(20px * var(--ratio));
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 26px */
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: ${props => props.$loading ? 'none' : 'all'};
  opacity: ${props => props.$loading ? 0.5 : 1}
`

const PriceDesc = styled.div`
  color: #161A19;
  text-align: left;
  font-size: calc(13px * var(--ratio));
  font-style: normal;
  font-weight: 400;
  line-height: 123.077%
`

const PriceInt = styled.span`
  color: #161A19;
  font-size: calc(40px * var(--ratio));
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 52px */
`

const PriceUnit = styled.span`
  color: #161A19;
  font-size: calc(18px * var(--ratio));
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
`

const PriceButton = styled.div`
  border-radius: calc(26px * var(--ratio));
  border: calc(1px * var(--ratio)) solid #DBD4D4;
  background: #36B7B1;
  display: flex;
  width: calc(318px * var(--ratio));
  height: calc(48px * var(--ratio));
  justify-content: center;
  align-items: center;
  color: #FFF;
  font-size: calc(20px * var(--ratio));
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  pointer-events: ${props => props.$loading ? 'none' : 'all'};
  opacity: ${props => props.$loading ? 0.5 : 1}
`

const FlipWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  transform: ${props => props.$flip ? "rotateY(180deg)" : "rotateY(0deg)"};
  -webkit-transform: ${props => props.$flip ? "rotateY(180deg)" : "rotateY(0deg)"};
  transition: 0.3s;
  border-radius: calc(14px * var(--ratio));
  /* overflow: hidden; */
  -webkit-transition: 0.3s;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  box-shadow: 0px calc(-8px * var(--ratio)) calc(16px * var(--ratio)) 0px rgba(0, 0, 0, 0.04), 0px calc(8px * var(--ratio)) calc(30px * var(--ratio)) calc(-20px * var(--ratio)) rgba(35, 4, 4, 0.72);
`

const PayPriceWrapper = styled.div`
  width: calc(318px * var(--ratio));
  height: calc(174px * var(--ratio));
  border-radius: calc(16px * var(--ratio));
  border: calc(1px * var(--ratio)) solid #E4DFE0;
  background: #FFF;
  position: relative;
  padding: calc(16px * var(--ratio)) calc(48px * var(--ratio));
  margin-left: calc(9px * var(--ratio));
  cursor: pointer;
  position: relative;
  &:nth-child(1){
    margin-left: 0;
  }
`

const PlusTxt = styled.div`
  color: #B229D6;
  font-size: calc(20px * var(--ratio));
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 26px */
  top: calc(16px * var(--ratio));
  left: calc(68px * var(--ratio));;
`

const WelComeTitle = styled.div`
  color: #163C3C;
  text-align: center;
  font-size: calc(32px * var(--ratio));
  font-weight: #161A19;
  font-weight: 600;
  line-height: 160%;
`

const WelComeSubTitle = styled.div`
  color: #898081;
  text-align: center;
  font-size: calc(24px * var(--ratio));
  font-weight: 400;
  line-height: 130%;
`

const LogoWrapper = styled.div`
    display: flex;
    align-items: center;
    padding-left: calc(32px * var(--ratio));
    padding-top: calc(32px * var(--ratio));
`

const LogoTxt = styled.span`
    color: #161A19;
    font-size: calc(20px * var(--ratio));
    font-style: normal;
    font-weight: 500;
`

export default Index