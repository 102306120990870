


const Index = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
      <rect x="9" width="19" height="19" rx="3" fill="#948B8C" />
      <path d="M0 14.3114V23.0225C0 24.7649 0 25.6356 0.33909 26.3011C0.637362 26.8865 1.11296 27.3629 1.69835 27.6612C2.3632 28 3.23398 28 4.97296 28H13.6944C15.4334 28 16.3029 28 16.9678 27.6612C17.5532 27.3629 18.0296 26.886 18.3279 26.3007C18.6666 25.6358 18.6666 24.766 18.6666 23.027V23.027V18.6666L18.6666 14.3063C18.6666 12.5673 18.6666 11.6965 18.3279 11.0317C18.0296 10.4463 17.5532 9.97068 16.9678 9.67241C16.3023 9.33332 15.4316 9.33332 13.6892 9.33332H9.33332H4.97807C3.23569 9.33332 2.36385 9.33332 1.69835 9.67241C1.11296 9.97068 0.637363 10.4463 0.33909 11.0317C0 11.6972 0 12.569 0 14.3114Z" fill="#A8DCDA" />
      <path d="M13 17L9 21L7 19" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default Index

