import React, { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom"
import styled from "styled-components";
import { Context as RouteContext } from '../../router'
import { SuccessIcon } from '../../components/icons/pay'
import { Context as AuthContext } from '../auth'
import { Logo } from '../../components/icons/welcome'

const Index = () => {
  const l = useLocation()
  const n = useNavigate()
  const routeCtx = useContext(RouteContext)
  const [priceStr, setPriceStr] = useState('')
  const authCtx = useContext(AuthContext)


  useEffect(() => {
    const urlParam = new URLSearchParams(l.search)
    setPriceStr(urlParam.get("price"))

    routeCtx.onresize()
  }, [])




  return (
    <Wrapper>
      <LogoWrapper>
        <Logo style={{ width: "calc(157px * var(--ratio))", height: "calc(58px * var(--ratio))" }} color="#161A19" />
      </LogoWrapper>
      <div style={{ height: "calc(167px * var(--ratio))" }}></div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <SuccessIcon style={{ width: "calc(80px * var(--ratio))", height: "calc(80px * var(--ratio))" }} />
      </div>
      <div style={{ height: "calc(24px * var(--ratio))" }}></div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ background: "#CFF1F0", width: "calc(198px * var(--ratio))", height: "calc(1px * var(--ratio))" }}></div>
      </div>
      <div style={{ height: "calc(24px * var(--ratio))" }}></div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ color: "#898081", fontSize: "calc(22px * var(--ratio))" }}>Payment successful</div>
      </div>
      {/* <div style={{ height: "calc(24px * var(--ratio))" }}></div>
      <div style={{
        color: "#161A19",
        fontSize: "calc(48px * var(--ratio))",
        fontWeight: 700,
        lineHeight: 1.6,
        display: "flex",
        justifyContent: "center"
      }}>{priceStr}</div> */}
      <div style={{ height: "calc(237px * var(--ratio))" }}></div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button onClick={async () => {
          await authCtx.loadUserInfo()
          n("/")
        }}>Back to homepage</Button>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: linear-gradient(180deg, #E1F7F6 0%, #FFF 100%);
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
`

const Button = styled.div`
  cursor: pointer;
  border-radius: calc(26px * var(--ratio));
  border: calc(1px * var(--ratio)) solid #E4DFE0;
  background: #36B7B1;
  width: calc(279px * var(--ratio));
  height: calc(50px * var(--ratio));
  color: #FFF;
  font-size: calc(20px * var(--ratio));
  font-weight: 600;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
`

const LogoWrapper = styled.div`
    display: flex;
    align-items: center;
    padding-left: calc(32px * var(--ratio));
    padding-top: calc(32px * var(--ratio));
`

const LogoTxt = styled.span`
    color: #161A19;
    font-size: calc(20px * var(--ratio));
    font-style: normal;
    font-weight: 500;
`

export default Index