import { useEffect, useRef, useState, useContext } from 'react'
import SoundRipple from '../../components/SoundRipple'
import axios from 'axios'
import { Context as AuthContext } from '../auth'
import { MsgTypeTTSVoice, MsgTypeChat } from '../../utils/define'
import { AudioPool, sleep } from "../../utils";
import { LoadingWrapper } from '../../utils/full_loading'
import { Context as RouteContext } from '../../router'
import { multibandTrackVolume } from '../../hooks/useTrackVolume'
import { AgentMultibandAudioVisualizer } from '../../components/visualization/AgentMultibandAudioVisualizer'

const { vad } = window

function encodeWAV(samples, sampleRate, numChannels) {
  // 创建 WAV 头部信息
  let buffer = new ArrayBuffer(44 + samples.length * 2);
  let view = new DataView(buffer);

  function writeString(view, offset, string) {
    for (let i = 0; i < string.length; i++) {
      view.setUint8(offset + i, string.charCodeAt(i));
    }
  }

  function floatTo16BitPCM(output, offset, input) {
    for (let i = 0; i < input.length; i++, offset += 2) {
      let s = Math.max(-1, Math.min(1, input[i]));
      output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7FFF, true);
    }
  }

  // RIFF identifier 'RIFF'
  writeString(view, 0, 'RIFF');
  // file length
  view.setUint32(4, 36 + samples.length * 2, true);
  // RIFF type 'WAVE'
  writeString(view, 8, 'WAVE');
  // format chunk identifier 'fmt '
  writeString(view, 12, 'fmt ');
  // format chunk length
  view.setUint32(16, 16, true);
  // sample format (raw)
  view.setUint16(20, 1, true);
  // channel count
  view.setUint16(22, numChannels, true);
  // sample rate
  view.setUint32(24, sampleRate, true);
  // byte rate (sample rate * block align)
  view.setUint32(28, sampleRate * 4, true);
  // block align (channel count * bytes per sample)
  view.setUint16(32, numChannels * 2, true);
  // bits per sample
  view.setUint16(34, 16, true);
  // data chunk identifier 'data'
  writeString(view, 36, 'data');
  // data chunk length
  view.setUint32(40, samples.length * 2, true);

  floatTo16BitPCM(view, 44, samples);

  return view;
}

export const Index = () => {
  const [intensity, setIntensity] = useState([])
  const authCtx = useContext(AuthContext)
  const emitter = authCtx.useEmitter()
  const socket = useRef()
  const timeInterval = useRef()
  const audioPool = useRef(new AudioPool())
  const articleAudioId = useRef(audioPool.current.getAudio("chatAudio"))
  const [connected, setConnected] = useState(false)
  const [loading, setLoading] = useState(false)
  const myvad = useRef()
  const routeCtx = useContext(RouteContext)



  const getVolume = (stream, onVolume) => {
    const ctx = new AudioContext();
    const source = ctx.createMediaStreamSource(stream);
    const analyser = ctx.createAnalyser();
    analyser.fftSize = 32;
    analyser.smoothingTimeConstant = 0;
    source.connect(analyser);
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);
    const updateVolume = () => {
      analyser.getByteFrequencyData(dataArray);
      let sum = 0;
      for (let i = 0; i < dataArray.length; i++) {
        const a = dataArray[i];
        sum += a * a;
      }
      const volume = Math.sqrt(sum / dataArray.length) / 255
      onVolume(volume)
    };
    const interval = setInterval(updateVolume, 1000 / 30);
    return interval
  }

  const onArticlePlayStart = async (txt) => {

  }

  const onArticlePlayLoading = () => {

  }

  const onArticlePlayEnd = async () => {
    myvad.current.start()
  }

  const onMsgTypeTTSVoice = ({ msg }) => {
    console.log(msg)
    msg = JSON.parse(msg)
    audioPool.current.playAudioArr(articleAudioId.current, msg, onArticlePlayStart, onArticlePlayEnd, onArticlePlayLoading)
  }

  async function main() {
    setLoading(true)
    socket.current = await authCtx.useSocket()
    emitter.on(MsgTypeTTSVoice, onMsgTypeTTSVoice)
    myvad.current = await vad.MicVAD.new({
      onSpeechEnd: async (audio) => {
        myvad.current.pause()
        console.log(audio)
        // do something with `audio` (Float32Array of audio samples at sample rate 16000)...
        let wavView = encodeWAV(audio, 16000, 1);
        const blob = new Blob([wavView], { type: 'audio/wav' });
        console.log("blob", blob)
        const formData = new FormData();
        formData.append('blob', blob, 'audio.wav'); // 第一个参数是字段名，第二个参数是 Blob，第三个参数是文件名
        // 发送 POST 请求来上传 Blob 对象
        let response = await axios.post(`https://api.bigread.ai/client/open/tts/parse`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        console.log("response.data.data", response.data.data)
        if (response.data.data) {
          socket.current.send(JSON.stringify({
            msg_type: MsgTypeChat,
            msg: response.data.data
          }))
        }

      },
    })
    console.log(myvad)
    myvad.current.start()

    timeInterval.current = multibandTrackVolume(
      myvad.current.stream,
      5,
      100,
      600,
      (arr) => {
        setIntensity(arr)
      }
    );

    // console.log(subscribedVolumes)

    // timeInterval.current = getVolume(myvad.current.stream, v => {
    //   // console.log("volume", v)
    //   setIntensity(v * 3)
    // })
    setConnected(true)
    setLoading(false)
  }

  const disconnect = () => {
    authCtx.unUseSocket()
    if (timeInterval.current) {
      timeInterval.current()
    }
    setConnected(false)
    if (myvad.current) {
      myvad.current.pause()
      myvad.current.destroy()
    }
  }

  useEffect(() => {
    routeCtx.onresize()
    return () => {
      disconnect()
    }
  }, [])


  return (
    <div style={{ width: "100%", height: "100%" }}>
      <LoadingWrapper loading={loading} bgColor={"#FFF"}>
        <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", height: "100%" }}>
          <div style={{ width: 400, display: "flex", justifyContent: "center", flexDirection: "column" }}>
            {/* <SoundRipple width={`100%`} intensity={intensity} /> */}
            <AgentMultibandAudioVisualizer
              state="speaking"
              barWidth={30}
              minBarHeight={30}
              maxBarHeight={150}
              // accentColor={config.settings.theme_color}
              accentShade={500}
              frequencies={intensity}
              borderRadius={12}
              gap={16}
            ></AgentMultibandAudioVisualizer>
            <div style={{ height: 20 }}></div>
            {
              !connected && <button style={{ height: 40 }} onClick={main}>connect</button>
            }
            {
              connected && <button style={{ height: 40 }} onClick={disconnect}>disconnect</button>
            }
          </div>
        </div>
      </LoadingWrapper>
    </div>
  )
}

export default Index