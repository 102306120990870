import { useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useContext, useRef, useCallback } from "react";
import styled from "styled-components";
import { Context } from '../auth'
import { Context as RouteContext } from '../../router'
import Avvvatars from 'avvvatars-react'
import { Logo } from '../../components/icons/welcome';


const Index = (props) => {
    const routeCtx = useContext(RouteContext)
    const ctx = useContext(Context)
    const emitter = routeCtx.useEmitter()
    const AvatarBox = useRef()
    const [avatarSize, setAvatarSize] = useState(AvatarBox.current?.clientWidth)
    const [menus, setMenus] = useState([{
        "label": "Leveled Reading",
        "path": "/",
        "bgColor": "#FFDBE1",
        "ac_left": 438,
        "inactivation": "https://www.bigread.ai/icon/20240521-091037.png",
        "active": "https://www.bigread.ai/icon/20240521-091013.png"
    }, {
        "label": "Endless Learning",
        "path": "/freelist",
        "bgColor": "#E1F7F6",
        "ac_left": 603,
        "inactivation": "https://www.bigread.ai/icon/20240521-091133.png",
        "active": "https://www.bigread.ai/icon/20240521-091058.png"
    }, {
        "label": "Progress",
        "ac_left": 762,
        "path": "/progress",
        "bgColor": "#EBEEF5",
        "inactivation": "https://www.bigread.ai/icon/20240521-091208.png",
        "active": "https://www.bigread.ai/icon/20240521-091156.png"
    }])
    const [curMenu, setCurMenu] = useState({})
    const { children } = props
    const l = useLocation()
    const n = useNavigate()

    const onObserver = (list) => {
        const width = list[0].target.clientWidth
        if (!width) {
            return
        }
        setAvatarSize(width)
    }

    useEffect(() => {
        const observer = new ResizeObserver(onObserver)
        observer.observe(AvatarBox.current)
        setCurMenu(menus[0])
        return () => {
            observer.disconnect()
        }
    }, [])

    useEffect(() => {
        const f = menus.find(v => v.path === l.pathname)
        if (f) {
            setCurMenu(f)
        }
    }, [l.pathname])



    const showUpgradeIconInfo = useCallback(() => {
        const vip_info = ctx.userInfo?.vip_info
        if (!vip_info?.is_vip) {
            if (!vip_info?.type) {
                return "Upgrade Now"
                //return "Upgrade: free 14-day trial"
            }
            return "Upgrade Now"
        }
        if (vip_info?.type === "FREE_DEMO") {
            return `Free ${vip_info?.last_demo_day}-day trial remining`
        }
        return ""
    }, [ctx])

    useEffect(() => {
        setAvatarSize(AvatarBox.current?.clientWidth)
    }, [ctx])

    const vipShowName = showUpgradeIconInfo()

    return (

        <div style={{
            width: "var(--vw)",
            height: "var(--vh)",
            overflow: "auto"
        }}>
            <Header>
                <svg style={{
                    position: "absolute",
                    left: `calc(${curMenu.ac_left}px * var(--ratio))`,
                    bottom: -1,
                    width: "calc(164px * var(--ratio))",
                    height: "calc(16px * var(--ratio))",
                    transition: "0.3s"
                }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 164 16" fill="none">
                    <path d="M163.54 16C145.946 14.8113 135.493 11.636 122.395 7.65705C119.19 6.68324 115.826 5.6613 112.16 4.60856C100.123 1.15189 90.2658 -0.0470632 81.7701 0.0014051C73.2744 -0.0470626 63.4174 1.15189 51.3803 4.60856C47.7144 5.66129 44.3503 6.68323 41.1448 7.65703C28.0468 11.636 17.5943 14.8113 0 16H163.54Z" fill={curMenu.bgColor} />
                </svg>
                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between", height: "100%" }}>
                    <LogoWrapper>
                        <Logo style={{ width: "calc(157px * var(--ratio))", height: "calc(58px * var(--ratio))" }} />
                    </LogoWrapper>
                    <MenuWrapper>
                        {
                            menus.map((v, k) => (
                                <MenuItem key={k} onClick={() => {
                                    // if (k === 2) {
                                    //     window.location.href = `${process.env.REACT_APP_V1_AI_PROGRESS}`
                                    //     return
                                    // }
                                    n(v.path)
                                }}>
                                    <img style={{ width: "calc(30px * var(--ratio))", height: "calc(30px * var(--ratio))", marginRight: 3 }} src={l.pathname === v.path ? v.active : v.inactivation} alt="" />
                                    <MenuTxt style={{
                                        color: v.path === l.pathname && "#FFF"
                                    }}>{v.label}</MenuTxt>
                                </MenuItem>
                            ))
                        }
                    </MenuWrapper>
                    <Pinfo>
                        <UserInfo $vip={ctx?.userInfo?.vip_info?.is_vip} onClick={() => n("/profile")}>
                            <UserName>{ctx?.userInfo?.pro_file_name}</UserName>
                            <div ref={AvatarBox} style={{
                                width: "calc(32px * var(--ratio))",
                                height: "calc(32px * var(--ratio))",
                                borderRadius: "50%",
                                marginLeft: 8,
                                overflow: 'hidden',
                                border: ctx?.userInfo?.vip_info?.is_vip ? "1px solid #FFF" : "none",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                                <Avvvatars style="shape" size={(avatarSize || 0) - 2 < 0 ? 0 : (avatarSize || 0) - 2} value={ctx?.userInfo?.pro_file_name} />
                            </div>
                        </UserInfo>
                        <VipInfo onClick={() => n("/pay")}>
                            <div>
                                <img src="https://www.bigread.ai/icon/20240521-111026.png" style={{
                                    width: "calc(18px * var(--ratio))",
                                    height: "calc(18px * var(--ratio))"
                                }} alt="" />
                            </div>
                            {
                                vipShowName && <div style={{ marginLeft: "calc(6px * var(--ratio))" }}>
                                    <VipInfoDays>{vipShowName}</VipInfoDays>
                                </div>
                            }
                        </VipInfo>
                    </Pinfo>
                </div>
            </Header>
            <Content>{children}</Content>
        </div>

    )
}

const Content = styled.div`
    height: calc(var(--vh) - var(--head-height));
    width: var(--vw);
    overflow: hidden;
`

const Header = styled.div`
    height: var(--head-height);
    display: flex;
    align-items: center;
    --left-right-padding: calc(32px * var(--ratio));
    background-color: #141111;
    position: relative;
`

const LogoWrapper = styled.div`
    height: 27px;
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: var(--left-right-padding);
    width: calc(31% - var(--left-right-padding));
    flex-shrink: 0;
`


const Pinfo = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    padding-right: var(--left-right-padding);
    width: calc(34% - var(--left-right-padding));
    flex-shrink: 0;
    justify-content: right;
`

const VipInfo = styled.div`
    border-radius: calc(25px * var(--ratio));
    background: var(--Plus, #B229D6);
    padding: calc(14px * var(--ratio)) calc(12px * var(--ratio));
    display: flex;
    align-items: center;
    cursor: pointer;
`

const VipInfoDays = styled.div`
    color: var(--white, var(--icon-color, #FFF));
    font-size: calc(14px * var(--ratio));
    font-style: normal;
    font-weight: 500;
`

const VipInfoType = styled.div`
    color: var(--Primary-300, #FFDADF);
    font-size: calc(12px * var(--ratio));
    font-style: normal;
    font-weight: 600;
`

export const Points = styled.div`
    border-radius: 50%;
    border: 1px solid var(--Gray-700, #898081);
    width: calc(49px * var(--ratio));
    height: calc(49px * var(--ratio));
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const PointsTxt = styled.div`
    color: var(--white, var(--icon-color, #FFF));
    font-size: calc(12px * var(--ratio));
    font-style: normal;
    font-weight: 400;
`

export const PointsNum = styled.div`
    color: var(--white, var(--icon-color, #FFF));
    font-size: calc(16px * var(--ratio));
    font-style: normal;
    font-weight: 500;
`

const UserInfo = styled.div`
    border: ${props => props.$vip ? "none" : "1px solid var(--Gray-700, #898081)"};
    padding: calc(8px * var(--ratio)) calc(10px * var(--ratio));
    border-radius: calc(25px * var(--ratio));
    display: flex;
    align-items: center;
    margin-right: 8px;
    background: ${props => props.$vip ? "#B229D6" : "none"};
    cursor: pointer;
`

const UserName = styled.span`
    color: var(--white, var(--icon-color, #FFF));
    font-size: calc(16px * var(--ratio));
    font-style: normal;
    font-weight: 600;
    max-width: calc(60px * var(--ratio));
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

const MenuTxt = styled.div`
    color: #948B8C;
    text-align: center;
    font-size: calc(16px * var(--ratio));
    font-style: normal;
    font-weight: 600;
`

const MenuWrapper = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 35%;
    flex-shrink: 0;
`

const MenuItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: auto;
    /* width: 33.33333333%; */
    height: 100%;
    flex-shrink: 0;
    user-select: none;
    cursor: pointer;
`

const LogoTxt = styled.span`
    color: var(--icon-color, #FFF);
    font-family: Roboto;
    font-size: calc(20px * var(--ratio));
    font-style: normal;
    font-weight: 500;
`


export default Index