import React, { useState, useRef, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom"
import styled from "styled-components";
import BackIcon from '../../components/icons/back'
import Avvvatars from 'avvvatars-react'
import { Context as RouteContext } from '../../router'
import { GradeOptions } from '../../utils/define'
import { cloneDeep } from "lodash";
import { isMobile } from 'react-device-detect';
import { Context as RequestContext } from '../request'
import { Logo, NextBtn } from '../../components/icons/welcome'

const Index = () => {
  const n = useNavigate()
  const [userName, setUserName] = useState("")
  const [grade, setGrade] = useState("")
  const [preLevel, setPreLevel] = useState(0)
  const AvatarBox = useRef()
  const [avatarSize, setAvatarSize] = useState(AvatarBox.current?.clientWidth)
  const routeCtx = useContext(RouteContext)
  const [step, setStep] = useState("1")
  const colors = useRef(["#FFDADF", "#FFEAEC", "#E1F7F6", "#CFF1F0", "#A8DCDA"])
  const activeColors = useRef(["#6A0828", "#B14E62", "#36B7B1", "#6A9B9A", "#467776"])
  const activeTxtColors = useRef(["#FFDADF", "#FFDADF", "#FFF", "#CFF1F0", "#E1F7F6"])
  const chooseBtnColors = useRef(["#E0788A", "#ED8395", "#CFF1F0", "#A8DCDA", "#7FB1B0"])
  const cardTxt = useRef([
    [
      {
        "type": "normal",
        "txt": "Far "
      },
      {
        "type": "key",
        "txt": "behind"
      }
    ],
    [
      {
        "type": "normal",
        "txt": "A little "
      },
      {
        "type": "key",
        "txt": "behind"
      }
    ],
    [
      {
        "type": "normal",
        "txt": "As "
      },
      {
        "type": "key",
        "txt": "expected"
      },
      {
        "type": "normal",
        "txt": " for"
      }
    ],
    [
      {
        "type": "normal",
        "txt": "A little "
      },
      {
        "type": "key",
        "txt": "ahead"
      },
      {
        "type": "normal",
        "txt": " of"
      }
    ],
    [
      {
        "type": "normal",
        "txt": "Far "
      },
      {
        "type": "key",
        "txt": "ahead"
      },
      {
        "type": "normal",
        "txt": " of"
      }
    ]
  ])
  const [cardBgColor, setCardBgColor] = useState({})
  const [cardBorColor, setCardBorColor] = useState({})
  const [btnBorColor, setBtnBorColor] = useState({})
  const requestCtx = useContext(RequestContext)
  const request = requestCtx.useRequest()
  const [arLevelPreInfo, setArLevelPreInfo] = useState([])
  const [loading, setLoading] = useState(false)
  const l = useLocation()

  useEffect(() => {
    if (l.search && l.search === "?profile") {
      setStep("2")
    }
  }, [l])


  const onObserver = (list) => {
    const width = list[0].target.clientWidth
    if (!width) {
      return
    }
    setAvatarSize(width)
  }

  useEffect(() => {
    const observer = new ResizeObserver(onObserver)
    observer.observe(AvatarBox.current)

    routeCtx.onresize()
    return () => {
      observer.disconnect()
    }
  }, [])

  const gotoStep = async () => {
    setLoading(true)
    try {
      setArLevelPreInfo(await request.get("/client/v2/api/learn/arlevel/pre?grade=" + grade) || [])
      setStep("4")
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  const gotoHome = async () => {
    setLoading(true)
    try {
      await request.post("/client/v2/api/user/profile/create", {
        profiles: [{
          name: userName,
          grade,
          ar_level_pre_step: arLevelPreInfo.find(v => v.id === preLevel).step
        }]
      })
      n("/")
    } catch (error) {

    }
    setLoading(false)
  }

  return (
    <Wrapper>
      <LogoWrapper>
        <Logo style={{ width: "calc(157px * var(--ratio))", height: "calc(58px * var(--ratio))" }} color="#161A19" />
      </LogoWrapper>
      <Step $show={step === "1"}>
        <div style={{ height: "calc(5px * var(--ratio))" }}></div>
        <WelComeTitle>Welcome to BigRead.ai</WelComeTitle>
        <WelComeSubTitle>Transform Reading & learning with AI</WelComeSubTitle>
        <NextBtnWrapper onClick={() => setStep("2")} />
        <div style={{ height: "calc(95px * var(--ratio))" }}></div>
        <img style={{
          width: "91%",
          margin: "0 auto",
          display: "block"
        }} src="https://aigc-file-hk.oss-cn-hongkong.aliyuncs.com/image/20240612-144806q.png" alt="" />
        <div style={{ height: "calc(100px * var(--ratio))" }}></div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button onClick={() => setStep("2")}>Let‘s go</Button>
        </div>
      </Step>
      <Step $show={step === "2"}>
        <BackIcon onClick={() => setStep("1")} style={{
          width: "calc(52px * var(--ratio))",
          height: "calc(52px * var(--ratio))",
          position: "fixed",
          left: "calc(198px * var(--ratio))",
          top: "calc(117px * var(--ratio))",
          cursor: "pointer"
        }} />
        <div style={{ height: "calc(20px * var(--ratio))" }}></div>
        <WelComeTitle>Let’s personalize your BigRead.ai. </WelComeTitle>
        <WelComeSubTitle>How would you like me to address you?</WelComeSubTitle>
        <NextBtnWrapper $disabled={!userName.trim()} onClick={() => setStep("3")} />
        <div style={{ height: "calc(218px * var(--ratio))" }}></div>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <div>
            <div style={{
              color: "#467776",
              fontSize: "calc(14px * var(--ratio))",
              fontWeight: 500,
              marginBottom: "calc(8px * var(--ratio))",
              textIndent: "calc(38px * var(--ratio))"
            }}>First name</div>
            <InputBox>
              <Input value={userName} onChange={e => {
                setUserName(e.target.value)
              }} placeholder="Your first name" />
            </InputBox>
          </div>
          <div ref={AvatarBox} style={{
            width: "calc(60px * var(--ratio))",
            height: "calc(60px * var(--ratio))",
            borderRadius: "50%",
            marginLeft: "calc(8px * var(--ratio))",
            overflow: 'hidden',
            marginTop: "calc(20px * var(--ratio))",
            transition: "0.3s",
            WebkitTransition: "0.3s"
          }}>
            <Avvvatars style="shape" size={avatarSize || 0} value={userName} />
          </div>
        </div>
        <div style={{ height: "calc(342px * var(--ratio))" }}></div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button $disabled={!userName.trim()} onClick={() => setStep("3")}>Next</Button>
        </div>
      </Step>
      <Step $show={step === "3"}>
        <BackIcon onClick={() => setStep("2")} style={{
          width: "calc(52px * var(--ratio))",
          height: "calc(52px * var(--ratio))",
          position: "fixed",
          left: "calc(198px * var(--ratio))",
          top: "calc(117px * var(--ratio))",
          cursor: "pointer"
        }} />
        <div style={{ height: "calc(42px * var(--ratio))" }}></div>
        <WelComeSubTitle>Which grade are you in?</WelComeSubTitle>
        <NextBtnWrapper onClick={gotoStep} $disabled={!grade || loading} />
        <div style={{ height: "calc(114px * var(--ratio))" }}></div>
        <div style={{
          width: "calc(1144px * var(--ratio))",
          height: "calc(384px * var(--ratio))",
          margin: "0 auto"
        }}>
          {
            GradeOptions.map((v, k) => (
              <GradeOption $active={v.value === grade} onClick={() => {
                setGrade(v.value)
              }} key={v.value}>{v.label}</GradeOption>
            ))
          }
        </div>
        <div style={{ height: "calc(155px * var(--ratio))" }}></div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button style={{
            pointerEvents: loading ? "none" : "all",
            opacity: loading ? 0.3 : 1
          }} $disabled={!grade} onClick={gotoStep}>{loading ? "loading..." : "Next"}</Button>
        </div>
      </Step>
      <Step $show={step === "4"}>
        <BackIcon onClick={() => setStep("3")} style={{
          width: "calc(52px * var(--ratio))",
          height: "calc(52px * var(--ratio))",
          position: "fixed",
          left: "calc(198px * var(--ratio))",
          top: "calc(117px * var(--ratio))",
          cursor: "pointer"
        }} />
        <div style={{ height: "calc(50px * var(--ratio))" }}></div>
        <WelComeSubTitle>Please choose an entry that suits you.</WelComeSubTitle>
        <NextBtnWrapper $disabled={!preLevel || loading} onClick={gotoHome} />
        <div style={{ height: "calc(69px * var(--ratio))" }}></div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ display: "flex", marginLeft: "calc(-85px * var(--ratio))" }}>
            <LxTitle>
              <div style={{ marginTop: "calc(5px * var(--ratio))" }}>Lexile level:</div>
              <div style={{ marginBottom: "calc(5px * var(--ratio))" }}>AR level:</div>
            </LxTitle>
            <div style={{ width: "calc(1018px * var(--ratio))" }}>
              <LxWrapper>
                {
                  arLevelPreInfo.map((v, k) => (
                    <Lx $after={true} style={{
                      background: preLevel === v.id ? activeColors.current[k] : colors.current[k],
                      color: preLevel === v.id && "#FFF"
                    }} key={k}>{v.lexile}L</Lx>
                  ))
                }
              </LxWrapper>
              <LxLine></LxLine>
              <LxWrapper>
                {
                  arLevelPreInfo.map((v, k) => (
                    <Lx style={{
                      background: preLevel === v.id ? activeColors.current[k] : colors.current[k],
                      color: preLevel === v.id && "#FFF"
                    }} key={k}>{v.ar_level}</Lx>
                  ))
                }
              </LxWrapper>
            </div>
          </div>
        </div>
        <div style={{ height: "calc(64px * var(--ratio))" }}></div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ display: "flex" }}>
            {
              arLevelPreInfo.map((v, k) => (
                <Card
                  onClick={() => {
                    setCardBgColor(pre => {
                      const cpPre = {}
                      cpPre[k] = activeColors.current[k]
                      return cpPre
                    })
                    setCardBorColor(pre => {
                      const cpPre = {}
                      cpPre[k] = activeColors.current[k]
                      return cpPre
                    })
                    setBtnBorColor(pre => {
                      const cpPre = {}
                      cpPre[k] = "rgba(0,0,0,0)"
                      return cpPre
                    })
                    setPreLevel(v.id)
                  }}
                  onMouseOver={() => {
                    if (isMobile) {
                      return
                    }
                    if (preLevel === v.id) {
                      return
                    }
                    setCardBgColor(pre => {
                      const cpPre = cloneDeep(pre)
                      cpPre[k] = "linear-gradient(180deg, #E1F7F6 0%, #FFF 100%)"
                      return cpPre
                    })
                    setCardBorColor(pre => {
                      const cpPre = cloneDeep(pre)
                      cpPre[k] = "#FFF"
                      return cpPre
                    })
                  }}
                  onMouseOut={() => {
                    if (isMobile) {
                      return
                    }
                    if (preLevel === v.id) {
                      return
                    }
                    setCardBgColor(pre => {
                      const cpPre = cloneDeep(pre)
                      delete cpPre[k]
                      return cpPre
                    })
                    setCardBorColor(pre => {
                      const cpPre = cloneDeep(pre)
                      delete cpPre[k]
                      return cpPre
                    })
                  }}
                  $bgColor={cardBgColor[k] || colors.current[k]}
                  $borColor={cardBorColor[k] || "rgba(0,0,0,0)"}
                  key={k}
                >
                  <PreLevelTxt style={{ color: preLevel === v.id && activeTxtColors.current[k] }} $active={preLevel === v.id}>
                    {
                      cardTxt.current[k].map((txt, tk) => (
                        <React.Fragment key={tk}>
                          {
                            txt.type === "normal" ? txt.txt : <PreLevelTxtkEY $active={preLevel === v.id}>{txt.txt}</PreLevelTxtkEY>
                          }
                        </React.Fragment>
                      ))
                    }
                    <br />
                    grade level.
                  </PreLevelTxt>
                  <ChooseBtn style={{ color: preLevel === v.id && chooseBtnColors.current[k] }} $btnBorColor={btnBorColor[k] || "#DBD4D4"}>Choose</ChooseBtn>
                </Card>
              ))
            }
          </div>
        </div>
        <div style={{ height: "calc(70px * var(--ratio))" }}></div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button style={{
            pointerEvents: loading ? "none" : "all",
            opacity: loading ? 0.3 : 1
          }} $disabled={!preLevel} onClick={gotoHome}>{loading ? "loading..." : "Start to read"}</Button>
        </div>
      </Step>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: linear-gradient(180deg, #E1F7F6 0%, #FFF 100%);
  width: 100%;
  height: 100%;
  overflow: auto;
`

const LxWrapper = styled.div`
  display: flex;
`

const Card = styled.div`
  border-radius: calc(38px * var(--ratio));
  width: calc(210px * var(--ratio));
  height: calc(360px * var(--ratio));
  margin-left: calc(30px * var(--ratio));
  position: relative;
  background: ${props => props.$bgColor};
  border: 2px solid ${props => props.$borColor};
  cursor: pointer;
  &:nth-child(1){
    margin-left: 0;
  }
`

const ChooseBtn = styled.div`
  border-radius: calc(20px * var(--ratio));
  border: 1px solid ${props => props.$btnBorColor};
  width: calc(168px * var(--ratio));
  height: calc(40px * var(--ratio));
  line-height: calc(40px * var(--ratio));
  text-align: center;
  color: ${props => props.$btnBorColor === "rgba(0,0,0,0)" ? "#FFF" : "#251F1F"};
  font-size: calc(18px * var(--ratio));
  font-weight: 500;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: calc(20px * var(--ratio));
`

const PreLevelTxt = styled.div`
  color: #6A0828;
  font-size: calc(22px * var(--ratio));
  font-weight: 400;
  line-height: 140%; /* 30.8px */
  padding-left: calc(20px * var(--ratio));
  padding-top: calc(218px * var(--ratio));;
`
const PreLevelTxtkEY = styled.span`
  color: ${props => props.$active ? "#FFF" : "#161A19"};
  font-size: calc(22px * var(--ratio));
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
`

const LxLine = styled.div`
  border-radius: calc(18px * var(--ratio));
  background: #E1F7F6;
  height: calc(18px * var(--ratio));
  width: 100%;
  margin: calc(10px * var(--ratio)) 0;
`

const Lx = styled.div`
  width: calc(58px * var(--ratio));
  height: calc(22px * var(--ratio));
  border-radius: calc(13px * var(--ratio));
  color: #251F1F;
  font-size: calc(14px * var(--ratio));
  font-weight: 400;
  line-height: calc(22px * var(--ratio));
  text-align: center;
  margin-left: calc(182px * var(--ratio));
  position: relative;
  &:nth-child(1){
    margin-left: 0;
  }
  &::after{
    content: '';
    position: absolute;
    background: #467776;
    width: calc(2px * var(--ratio));
    height: calc(8px * var(--ratio));
    left: 0;
    right: 0;
    margin: auto;
    bottom: calc(-23px * var(--ratio));
    display: ${props => props.$after ? "block" : "none"};
  }
`

const LxTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
  margin-right: calc(17px * var(--ratio));
  color: #251F1F;
  font-size: calc(12px * var(--ratio));
  font-weight: 600;
`

const GradeOption = styled.div`
  display: flex;
  width: calc(180px * var(--ratio));
  height: calc(180px * var(--ratio));
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: calc(16px * var(--ratio));
  border: calc(2px * var(--ratio)) solid #FFF;
  font-size: calc(20px * var(--ratio));
  font-weight: 400;
  margin-left: calc(8px * var(--ratio));
  margin-top: calc(8px * var(--ratio));
  &:nth-child(1),&:nth-child(7){
    margin-left: 0;
  }
  float: left;
  &:hover{
    ${props => {
    let sty = ""
    if (props.$active) {
      sty += "background: #36B7B1;"
      sty += "color: #FFF;"
    } else {
      sty += "background: linear-gradient(180deg, #E1F7F6 0%, #FFF 100%);"
      sty += "color: #163C3C;"
    }
    return sty
  }}
  }
  cursor: pointer;
  ${props => {
    let sty = ""
    if (props.$active) {
      sty += "background: #36B7B1;"
      sty += "color: #FFF;"
    } else {
      sty += "background: #FFF;"
      sty += "color: #163C3C;"
    }
    return sty
  }}
`

const InputBox = styled.div`
  border-radius: calc(40px * var(--ratio));
  border: 1px solid #A8DCDA;
  background: #FFF;
  box-shadow: 0px calc(4px * var(--ratio)) calc(20px * var(--ratio)) calc(-14px * var(--ratio)) rgba(0, 0, 0, 0.25);
  color: #163C3C;
  font-size: calc(20px * var(--ratio));
  font-weight: 400;
  width: calc(366px * var(--ratio));
  height: calc(60px * var(--ratio));
  display: flex;
  align-items: center;
`

const Input = styled.input`
  color: #163C3C;
  font-size: calc(20px * var(--ratio));
  font-weight: 400;
  width: calc(300px * var(--ratio));
  height: calc(30px * var(--ratio));
  margin-left: calc(40px * var(--ratio));
  &::placeholder{
    color: #DBD4D4;
  }
`

const Step = styled.div`
  display: ${props => props.$show ? "blokc" : "none"};
`

const NextBtnWrapper = styled(NextBtn)`
  width: calc(144px * var(--ratio));
  height: calc(50px * var(--ratio));
  cursor: pointer;
  position: fixed;
  top: calc(122px * var(--ratio));
  right: calc(146px * var(--ratio));
  pointer-events: ${props => props.$disabled ? "none" : "all"};
  opacity: ${props => props.$disabled ? 0.3 : 1};
`

const Button = styled.div`
  border-radius: calc(26px * var(--ratio));
  font-size: calc(20px * var(--ratio));;
  font-weight: 600;
  display: inline-block;
  padding: calc(15px * var(--ratio)) calc(95px * var(--ratio));
  cursor: pointer;
  ${props => {
    let sty = ""
    if (props.$disabled) {
      sty += "color: #DBD4D4;"
      sty += "background: #CFF1F0;"
      sty += "pointer-events: none;"
    } else {
      sty += "color: #FFF;"
      sty += "background: #163C3C;"
      sty += "pointer-events: all;"
    }
    return sty
  }}
`

const WelComeTitle = styled.div`
  color: #163C3C;
  text-align: center;
  font-size: calc(22px * var(--ratio));
  font-weight: 500;
  line-height: 150%;
`

const WelComeSubTitle = styled.div`
  color: #163C3C;
  text-align: center;
  font-size: calc(32px * var(--ratio));
  font-weight: 600;
  line-height: 150%;
`

const LogoWrapper = styled.div`
    display: flex;
    align-items: center;
    padding-left: calc(32px * var(--ratio));
    padding-top: calc(32px * var(--ratio));
`


export default Index