
const Index = (props) => {
    const { children,loading,loadingAnimate,disabled, ...others } = props



    return (
        <button disabled = {loading} {...others}>
            {
                (loading || loadingAnimate) && <div className="stage" style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <div className="dot-flashing"></div>
                </div>
            }
            {
                (!loading && !loadingAnimate) && <span>{children}</span>
            }
        </button>
    )
}

export default Index